import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IKnowledges } from "src/app/helpers/knowledge-helper";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class KnowledgesObservationService
  extends BasicSyncService<IKnowledges, IKnowledges[]>
  implements StaticImplements<INeedRefresh, typeof KnowledgesObservationService>
{
  public get needRefresh(): { value: boolean } {
    return KnowledgesObservationService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "knowledges_observation_",
      entityStoreKey: "list",
      getUrl: "/knowledges/observations",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { Activity } from "../models/careplan";

@Pipe({ name: "activityColor" })
export class ActivityColorPipe implements PipeTransform {
  constructor() {}

  transform(activity: Activity): string {
    if (moment().isBefore(activity.detail.scheduledString, "day")) {
      return "#278590";
    }
    if (moment().isAfter(activity.detail.scheduledString, "day")) {
      return "#989898";
    } else return "#fa9700";
  }
}

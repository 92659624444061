import { Injectable } from "@angular/core";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ConnectionStatus, NetworkService } from "../../network.service";
import { SysAccountService } from "../../sys-account.service";
import { AccountService } from "../account.service";
import { AppointmentService } from "../appointment.service";
import { BluetoothKnowledgeService } from "../bluetooth-knowledge.service";
import { CareplanService } from "../careplan.service";
import { CommunicationService } from "../communication.service";
import { ComunicareInfoService } from "../comunicare-info.service";
import { ConfigurationService } from "../configuration.service";
import { ContactService } from "../contact.service";
import { CountryService } from "../country.service";
import { DayStreamObservationsService } from "../dayStreamObservations.service";
import { DrugSchemaService } from "../drug-schema.service";
import { DrugService } from "../drug.service";
import { ExternalRessourceService } from "../external-ressource.service";
import { I18nService } from "../i18n.service";
import { KnowledgeDrugService } from "../knowledge-drug.service";
import { KnowledgeService } from "../knowledge.service";
import { KnowledgesDescriptionService } from "../knowledges-description.service";
import { KnowledgesFAQService } from "../knowledges-faq.service";
import { KnowledgesObservationService } from "../knowledges-observation.service";
import { KnowledgesRecommendationService } from "../knowledges-recommendation.service";
import { LanguagesService } from "../languagesService";
import { NoteService } from "../note.service";
import { NotificationsDrugsIntakeService } from "../notifications-drugs-intake.service";
import { ObservationDefinitionService } from "../observation-definition.service";
import { ObservationService } from "../observation.service";
import { QuestionnaireDefinitionService } from "../questionnaire-definition.service";
import { QuestionnaireService } from "../questionnaire.service";
import { QuizDefinitionService } from "../quiz-definition.service";
import { QuizResponseService } from "../quiz-response.service";
import { RelatedAppointmentsService } from "../related-appointments.service";
import { RelatedCareplansService } from "../related-careplans.service";
import { RelatedDrugsService } from "../related-drugs.service";
import { RelatedKnowledgeService } from "../related-knowledge.service";
import { RelatedPatientsService } from "../related-patients.service";
import { RelatedPersonsService } from "../related-persons.service";
import { RewardDefinitionsService } from "../reward-definitions.service";
import { RewardScoreService } from "../reward-score.service";
import { RulesAlertService } from "../rules-alert.service";
import { RulesService } from "../rules.service";
import { StepwiseDrugService } from "../stepwise-drug.service";
import { VitalProfileDefinitionsService } from "../vital-profile-definitions.service";
import { BasicSyncService } from "./basic-sync.service";
import { RequiredSynchroService } from "./required-synchro.service";

export enum ApiSyncServiceStatus {
  alreadyInProgress,
  success,
  error,
}

@Injectable({
  providedIn: "root",
})
export class ApiSyncService {
  private isSynchronizing = false;
  private servicesWave1: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave2: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave3: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave4: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave5: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave6: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave7: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave8: BasicSyncService<any, any[] | any>[] = [];
  private dataPatient: BasicSyncService<any, any[] | any>[] = [];

  constructor(
    private networkService: NetworkService,
    private sysAccountService: SysAccountService,
    private requiredSynchroService: RequiredSynchroService,
    // below, the services to synchronize
    externalRessourceService: ExternalRessourceService,
    communicationService: CommunicationService,
    noteService: NoteService,
    configurationService: ConfigurationService,
    private accountService: AccountService,
    careplanService: CareplanService,
    knowledgeService: KnowledgeService,
    drugService: DrugService,
    obsDefService: ObservationDefinitionService,
    observationService: ObservationService,
    questionnaireService: QuestionnaireService,
    questionnaireDefService: QuestionnaireDefinitionService,
    rulesService: RulesService,
    rulesAlertService: RulesAlertService,
    notificationsDrugsIntakeService: NotificationsDrugsIntakeService,
    appointmentService: AppointmentService,
    private relatedAppointmentsService: RelatedAppointmentsService,
    private relatedCareplansService: RelatedCareplansService,
    private relatedDrugsService: RelatedDrugsService,
    private relatedPatientsService: RelatedPatientsService,
    private relatedPersonsService: RelatedPersonsService,
    relatedKnService: RelatedKnowledgeService,
    contactService: ContactService,
    bluetoothKnowledgeService: BluetoothKnowledgeService,
    knowledgesDrugService: KnowledgeDrugService,
    rewardDefinitionService: RewardDefinitionsService,
    rewardScoreService: RewardScoreService,
    drugSchemaService: DrugSchemaService,
    stepwiseDrugService: StepwiseDrugService,
    vitalProfileDefinitionsService: VitalProfileDefinitionsService,
    knRecommendationService: KnowledgesRecommendationService,
    knDescriptionService: KnowledgesDescriptionService,
    languagesService: LanguagesService,
    quizDefinitionService: QuizDefinitionService,
    quizResponseService: QuizResponseService,
    KnFAQService: KnowledgesFAQService,
    knObservationService: KnowledgesObservationService,
    i18nService: I18nService,
    countryService: CountryService,
    comunicareInfoService: ComunicareInfoService,
    dayStremObservationsService: DayStreamObservationsService
  ) {
    // the order is important : https://comunicare.atlassian.net/wiki/spaces/CPD/pages/1112080385/Synchronisation+avec+le+Global+Data+Provider

    this.servicesWave1 = [
      accountService,
      configurationService,
      i18nService,
      externalRessourceService,
      vitalProfileDefinitionsService,
      languagesService,
      careplanService,
    ];

    this.servicesWave2 = [
      drugService,
      noteService,
      notificationsDrugsIntakeService,
      drugSchemaService,
      stepwiseDrugService,
      comunicareInfoService,
    ];

    this.servicesWave3 = [obsDefService, rulesService, appointmentService, questionnaireDefService];

    this.servicesWave4 = [communicationService, bluetoothKnowledgeService, knowledgesDrugService, questionnaireService, rulesAlertService];
    this.servicesWave5 = [
      observationService,
      rewardScoreService,
      quizDefinitionService,
      quizResponseService,
      countryService,
      dayStremObservationsService,
    ];
    this.servicesWave6 = [contactService, knowledgeService, knRecommendationService];
    this.servicesWave7 = [knDescriptionService, rewardDefinitionService, KnFAQService, knObservationService];
    this.servicesWave8 = accountService.isOnlyPatient() ? [] : [relatedKnService];

    this.dataPatient = [
      accountService,
      configurationService,
      drugService,
      appointmentService,
      communicationService,
      obsDefService,
      observationService,
      questionnaireDefService,
      questionnaireService,
      rulesService,
      vitalProfileDefinitionsService,
      rulesAlertService,
      comunicareInfoService,
      dayStremObservationsService,
    ];
  }

  public async initServices(): Promise<void> {
    this.addRelatedServices();
    const allSservices = [
      this.servicesWave1,
      this.servicesWave2,
      this.servicesWave3,
      this.servicesWave4,
      this.servicesWave5,
      this.servicesWave6,
      this.servicesWave7,
      this.servicesWave8,
    ];
    for (const services of allSservices) {
      const initPromises: Promise<void>[] = [];
      for (const service of services) {
        initPromises.push(service.init());
      }
      await Promise.all(initPromises);
    }
  }

  private addRelatedServices(): void {
    if (!this.accountService.isNotRelated()) {
      if (this.servicesWave2.findIndex((s) => s.serviceId === this.relatedPatientsService.serviceId) === -1) {
        this.servicesWave2.push(this.relatedPatientsService);
      }

      if (this.servicesWave2.findIndex((s) => s.serviceId === this.relatedCareplansService.serviceId) === -1) {
        this.servicesWave2.push(this.relatedCareplansService);
      }
    }

    if (!this.accountService.isOnlyRelated) {
      if (this.servicesWave2.findIndex((s) => s.serviceId === this.relatedPersonsService.serviceId) === -1) {
        this.servicesWave2.push(this.relatedPersonsService);
      }
    }

    if (!this.accountService.isNotRelated()) {
      if (this.servicesWave3.findIndex((s) => s.serviceId === this.relatedAppointmentsService.serviceId) === -1) {
        this.servicesWave3.push(this.relatedAppointmentsService);
      }

      if (this.servicesWave3.findIndex((s) => s.serviceId === this.relatedDrugsService.serviceId) === -1) {
        this.servicesWave3.push(this.relatedDrugsService);
      }
    }
  }

  public clearServices(): void {
    const services = this.servicesWave1.concat(
      this.servicesWave2,
      this.servicesWave3,
      this.servicesWave4,
      this.servicesWave5,
      this.servicesWave6,
      this.servicesWave7,
      this.servicesWave8
    );
    services.map((service: BasicSyncService<any, any[] | any>) => service.clear());
    this.removeRelatedServices();
  }

  private removeRelatedServices(): void {
    let index: number;
    index = this.servicesWave2.findIndex((s) => s.serviceId === this.relatedPatientsService.serviceId);
    if (index !== -1) {
      this.servicesWave2.splice(index, 1);
    }

    index = this.servicesWave2.findIndex((s) => s.serviceId === this.relatedCareplansService.serviceId);
    if (index !== -1) {
      this.servicesWave2.splice(index, 1);
    }

    index = this.servicesWave2.findIndex((s) => s.serviceId === this.relatedPersonsService.serviceId);
    if (index !== -1) {
      this.servicesWave2.splice(index, 1);
    }

    index = this.servicesWave3.findIndex((s) => s.serviceId === this.relatedAppointmentsService.serviceId);
    if (index !== -1) {
      this.servicesWave3.splice(index, 1);
    }

    index = this.servicesWave3.findIndex((s) => s.serviceId === this.relatedDrugsService.serviceId);
    if (index !== -1) {
      this.servicesWave3.splice(index, 1);
    }
  }

  /**
   * Sync all GDP services in online and offline (<-- load in cache)
   */
  public async sync(
    forceSynchroCheck = false,
    servicesWave1 = this.servicesWave1,
    servicesWave2 = this.servicesWave2,
    servicesWave3 = this.servicesWave3,
    servicesWave4 = this.servicesWave4,
    servicesWave5 = this.servicesWave5,
    servicesWave6 = this.servicesWave6,
    servicesWave7 = this.servicesWave7,
    servicesWave8 = this.servicesWave8
  ): Promise<ApiSyncServiceStatus> {
    try {
      if (this.isSynchronizing) {
        return ApiSyncServiceStatus.alreadyInProgress;
      }
      this.isSynchronizing = true;

      const allServices = [
        this.servicesWave1,
        this.servicesWave2,
        this.servicesWave3,
        this.servicesWave4,
        this.servicesWave5,
        this.servicesWave6,
        this.servicesWave7,
        this.servicesWave8,
      ];
      if (forceSynchroCheck && this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online) {
        for (const services of allServices) {
          const p = await this.requiredSynchroService.run(services);
          await p.toPromise().catch((err) => {
            FileLogger.error("ApiSyncService", "error during required synchro run", err);
          });
        }
        FileLogger.log("ApiSyncService", "sync -> required synchro check done");
      }

      await Promise.all(
        servicesWave1.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 1 done");

      await Promise.all(
        servicesWave2.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 2 done");

      await Promise.all(
        servicesWave3.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 3 done");

      await Promise.all(
        servicesWave4.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 4 done");

      await Promise.all(
        servicesWave5.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 5 done");

      await Promise.all(
        servicesWave6.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 6 done");

      await Promise.all(
        servicesWave7.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 7 done");

      await Promise.all(
        servicesWave8.map((s) => {
          return s.getFreshestData().catch((err) => {
            return err;
          }); // the "catch" allows not to block the promiseall in the case of a mistake
        })
      );
      FileLogger.log("ApiSyncService", "sync -> sync services wave 8 done");

      if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online) {
        await this.sysAccountService.updateLastSynchro();
        FileLogger.log("ApiSyncService", "sync -> update last synchro done");
      }

      this.isSynchronizing = false;
      FileLogger.log("ApiSyncService", "sync -> success !");
      return ApiSyncServiceStatus.success;
    } catch (error) {
      this.isSynchronizing = false;
      FileLogger.error("ApiSyncService", "sync", error);
      return ApiSyncServiceStatus.error;
    }
  }

  public syncOnlyDataPatient(): Promise<ApiSyncServiceStatus> {
    const servicesDataWave1 = this.servicesWave1.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave2 = this.servicesWave2.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave3 = this.servicesWave3.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave4 = this.servicesWave4.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave5 = this.servicesWave5.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave6 = this.servicesWave6.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave7 = this.servicesWave7.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave8 = this.servicesWave8.filter((service) => this.dataPatient.includes(service));

    return this.sync(
      false,
      servicesDataWave1,
      servicesDataWave2,
      servicesDataWave3,
      servicesDataWave4,
      servicesDataWave5,
      servicesDataWave6,
      servicesDataWave7,
      servicesDataWave8
    );
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

import { map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { CareplanService } from "./careplan.service";
import { ContactService } from "./contact.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

interface IStatEventData {
  eventDescription: string; // "read knowledge xxxx ", "page home visit"
  servicesIds?: string[]; // services of the user generating the event (if applicable)
  careplansIds?: string[]; // careplans of the user generating the event (if applicable)
}

@Injectable({
  providedIn: "root",
})
export class StatEventService
  extends BasicSyncService<IStatEventData, IStatEventData[]>
  implements StaticImplements<INeedRefresh, typeof StatEventService>
{
  public get needRefresh(): { value: boolean } {
    return StatEventService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService, private careplanService: CareplanService, private contactService: ContactService) {
    super(dataService);
  }

  protected clearWatch(): void {
    // this is needed in order to clear the data cached in your
    // service when the user disconnect
    // Most often your data is an array, so you need to create
    // a new BehaviorSubject and put an empty array in it
    this.data$ = new BehaviorSubject<IStatEventData[]>([]);
  }

  protected initWatch(): void {
    // this is needed in order to reset the data cached in your service
    // Most often your data is an array, so you need to put an empty array
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "statEvents_",
      entityStoreKey: null,
      getUrl: null,
      setUrl: "/mobile/statEvent",
      expirationDays: 10,
      encrypted: false,
    };
  }

  /**
   * There's never any event saved on the device (only in the queue)
   */
  public peekData(): IStatEventData[] {
    return [];
  }
  /**
   * Don't use this, we don't keep the event
   * @param data
   */
  public pokeData(_data: IStatEventData[]): void {
    super.pokeData([]);
  }
  /**
   * No changes in this service's data because we only send stat event, we don't keep them
   * @return a observable with the service's data
   */
  public watchData(): Observable<IStatEventData[]> {
    return this.data$.pipe(
      map(() => {
        return [];
      })
    );
  }

  /**
   * There's never any event saved on the device (only in the queue)
   */
  public async *getDataReader(): AsyncGenerator<IStatEventData[], IStatEventData[], IStatEventData[]> {
    yield [];
    return [];
  }

  /**
   * Only useful method. Create an event and send it to the server or save it in the queue for later
   * @param eventDescription
   * @param servicesIds
   * @param careplansIds
   */
  public async newEvent(eventDescription: string, dependOnCareplan = true, dependOnService = true): Promise<void> {
    try {
      const currentCareplan = this.careplanService.peekCurrentCareplan();
      const careplans = currentCareplan ? [currentCareplan] : await this.careplanService.getFirstDataAvailable();
      const careplansIds = currentCareplan ? [currentCareplan.support[0].reference] : careplans?.map((c) => c.support[0].reference);
      let serviceTeams = this.contactService.peekData(dependOnCareplan ? careplans : null);
      if (!serviceTeams || serviceTeams.length === 0) {
        serviceTeams = await this.contactService.getFirstDataAvailable(dependOnCareplan ? careplans : null);
      }
      const servicesIds = serviceTeams?.map((t) => t.healthService.identifier[0].value);
      const eventData: IStatEventData = {
        eventDescription,
        servicesIds: dependOnService ? servicesIds : null,
        careplansIds: careplansIds,
      };
      this.dataService.saveInArray(eventData, () => false, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST,
      });
    } catch (err) {
      FileLogger.error("StatEventService", "Error while creating new stat event: ", err);
    }
  }
}

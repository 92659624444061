import { OperationResult, BluetoothLE } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { Observable, Subject } from "rxjs";
import { MedicalBluetoothData } from "../medical-bluetooth-data";
import { MedicalBluetoothCharacteristic, MedicalBluetoothDevice, MedicalBluetoothService } from "../medical-bluetooth.service";
import { InfoAppService } from "../../info-app.service";
import { finalize, map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { MedicalBluetoothStatus } from "../medical-bluetooth-status.service";

export interface MedicalBluetoothThermometerData {
  celsiusTemperature: number;
  fahrenheitTemperature: number;
  time?: Date;
  temperatureType?: TemperatureType;
}

export enum TemperatureType {
  ARMPIT = "Armpit",
  BODY = "Body (general)",
  EAR = "Ear (usually ear lobe)",
  FINGER = "Finger",
  GASTRO_INTESTINAL_TRACT = "Gastro-intestinal Tract",
  MOUTH = "Mouth",
  RECTUM = "Rectum",
  TOE = "Toe",
  TYMPANUM = "Tympanum (ear drum)",
}

export class MedicalBluetoothThermometer extends MedicalBluetoothData {
  public serviceType = MedicalBluetoothService.HEALTH_THERMOMETER;
  public descriptor = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.TEMPERATURE_MEASUREMENT,
    service: MedicalBluetoothService.HEALTH_THERMOMETER,
  };

  public constructor(
    device: MedicalBluetoothDevice,
    handler: Observable<string>,
    infoAppService: InfoAppService,
    bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>,
    translateService: TranslateService,
    searchPreviousData: boolean,
    medicalBluetoothStatus: MedicalBluetoothStatus
  ) {
    super(device, handler, infoAppService, bluetoothle, onDestroy$, translateService, searchPreviousData, medicalBluetoothStatus);
  }

  public readData() {
    this.standby = false;
    return this.bluetoothle.subscribe(this.descriptor).pipe(
      map((r) => {
        const data = r as unknown as OperationResult;
        if (data.status === "subscribedResult") {
          const decodedData = this.decodeData(data.value);
          if (decodedData.celsiusTemperature > 50) {
            this._data.next(null);
            this._data.complete();
          } else {
            this._data.next(decodedData);
          }
        }
      }),
      finalize(() => {
        this._data.complete();
      })
    );
  }

  public getData(): Subject<MedicalBluetoothThermometerData> {
    return this._data;
  }

  public getHumanReadableData(data: MedicalBluetoothThermometerData) {
    return data.celsiusTemperature.toFixed(1) + " °C";
  }

  private decodeData(data: string): MedicalBluetoothThermometerData {
    const bytes = this.bytesFromString(data);
    const view = new DataView(bytes.buffer);

    let offset = 0;
    const result: MedicalBluetoothThermometerData = {
      celsiusTemperature: 0,
      fahrenheitTemperature: 0,
    };

    // Flags (Uint 8)
    const measurementUnit = view.getUint8(0) & 0x01;
    const hasTimeStamp = (view.getUint8(0) & 0x02) > 0;
    const hasTemperatureType = (view.getUint8(0) & 0x04) > 0;
    offset += 1;

    // Measure (Float 32)
    const temperature = this.floatFromIEEE_11073(view, offset);
    if (measurementUnit === 0) {
      result.celsiusTemperature = temperature;
      result.fahrenheitTemperature = temperature * 1.8 + 32;
    } else {
      result.fahrenheitTemperature = temperature;
      result.celsiusTemperature = (temperature - 32) / 1.8;
    }
    offset += 4;

    // Timestamp
    if (hasTimeStamp) {
      result.time = new Date(
        view.getUint16(offset, true),
        view.getUint8(offset + 2),
        view.getUint8(offset + 3),
        view.getUint8(offset + 4),
        view.getUint8(offset + 5),
        view.getUint8(offset + 5)
      );
      offset += 7;
    }

    // Temperature type
    if (hasTemperatureType) {
      switch (view.getUint8(offset)) {
        case 1:
          result.temperatureType = TemperatureType.ARMPIT;
          break;
        default:
        case 2:
          result.temperatureType = TemperatureType.BODY;
          break;
        case 3:
          result.temperatureType = TemperatureType.EAR;
          break;
        case 4:
          result.temperatureType = TemperatureType.FINGER;
          break;
        case 5:
          result.temperatureType = TemperatureType.GASTRO_INTESTINAL_TRACT;
          break;
        case 6:
          result.temperatureType = TemperatureType.MOUTH;
          break;
        case 7:
          result.temperatureType = TemperatureType.RECTUM;
          break;
        case 8:
          result.temperatureType = TemperatureType.TOE;
          break;
        case 9:
          result.temperatureType = TemperatureType.TYMPANUM;
          break;
      }
    }

    return result;
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/array-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { ENTITY_TYPE, EntityDrug, IEntitylink } from "src/app/models/entitylink";
import { NOTIFICATION_TYPE } from "src/app/models/notification";
import { IRelatedPerson } from "src/app/models/relatedPerson";
import { ACTION_STATUS_ENTITY, StaticImplements } from "src/app/models/sharedInterfaces";
import { NotificationsGeneratedService } from "../notificationsService/notifications-generated.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { RelatedPatientsService } from "./related-patients.service";

@Injectable({
  providedIn: "root",
})
export class RelatedDrugsService
  extends BasicSyncService<IEntitylink, IEntitylink[]>
  implements StaticImplements<INeedRefresh, typeof RelatedDrugsService>
{
  public get needRefresh(): { value: boolean } {
    return RelatedDrugsService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  public lastGenNotif: string = null;

  constructor(
    protected dataService: DataService,
    private notificationGeneratedService: NotificationsGeneratedService,
    private relatedPatientsService: RelatedPatientsService,
    private accountService: AccountService
  ) {
    super(dataService);
  }
  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
  public watchData(related: IRelatedPerson = null): Observable<IEntitylink[]> {
    return this.data$.pipe(
      map((data: IEntitylink[]) => {
        return this.processData(data, related);
      })
    );
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(related: IRelatedPerson = null): IEntitylink[] {
    return this.processData(super.peekData(), related);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IEntitylink[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "relatedentitylinks_drugs_",
      entityStoreKey: "list",
      getUrl: "/relatedEntitylinks?ENTITY_TYPE=" + ENTITY_TYPE.DRUG,
      setUrl: null,
      expirationDays: 10,
      encrypted: true,
    };
  }

  /**
   * Get the list of the drugs of related, if the current user is related.
   * If related = null, then this method return all drugs of all patients related of the current user
   * @param related (IRelatedPerson) the related person
   * @param noNotifs (boolean) whether or not we can generate notifications if needed
   */
  public async *getDataReader(
    related: IRelatedPerson = null,
    noNotifs = false
  ): AsyncGenerator<IEntitylink[], IEntitylink[], IEntitylink[]> {
    try {
      if (this.accountService.isNotRelated()) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IEntitylink[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, related);
        yield d;
      }
      this.dealWithNotif(noNotifs, d);
      return d;
    } catch (err) {
      FileLogger.error("RelatedDrugsService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IEntitylink[], related: IRelatedPerson) {
    try {
      const drugs = dataResult.filter((entity) => {
        return entity.actionStatus !== ACTION_STATUS_ENTITY.DELETED;
      });
      if (related) {
        return this.filterForARelated(drugs, related);
      } else {
        return drugs;
      }
    } catch (err) {
      FileLogger.error("RelatedDrugsService", "Error while processing relatedDrugsService data: ", err);
      return [];
    }
  }

  private dealWithNotif(noNotifs: boolean, data: IEntitylink[]) {
    if (!noNotifs && (this.needNotifGeneration || !this.lastGenNotif || moment(this.lastGenNotif).add(4, "hours").isBefore(moment()))) {
      this.generateNotifsDrugs(data);
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(related: IRelatedPerson = null, noNotifs = false): Promise<IEntitylink[]> {
    const dataReader = this.getDataReader(related, noNotifs);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(related: IRelatedPerson = null, noNotifs = false): Promise<IEntitylink[]> {
    const dataReader = this.getDataReader(related, noNotifs);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  /**
   * Generate the notifications for the drugs passed in parameters.
   * If the drugs list is empty, it will first try to download a new list
   * from the server.
   * @param _drugs the drugs we want to generate notifications for
   */
  public async generateNotifsDrugs(_drugs: IEntitylink[]): Promise<void> {
    if (_drugs && _drugs.length > 0) {
      this.generateNotifsForAllRelated(_drugs);
      return;
    }
    const drugs = await this.getFreshestData(null, true);
    this.generateNotifsForAllRelated(drugs);
  }

  /**
   * Get the list of all related and generate notifications for them
   * @param dr the drug list for all related
   */
  private async generateNotifsForAllRelated(dr: IEntitylink[]) {
    const relatedPatients = await this.relatedPatientsService.getFreshestData(true);
    const data: { drugs: IEntitylink[]; related: IRelatedPerson }[] = [];
    relatedPatients.forEach((rel) => {
      const drugsOfRel = this.filterForARelated(dr, rel);
      if (drugsOfRel.length > 0) {
        data.push({
          drugs: drugsOfRel,
          related: rel,
        });
      }
    });
    try {
      await this.notificationGeneratedService.generatedNotifications(data, NOTIFICATION_TYPE.RELATED_DRUG);
      this.needNotifGeneration = false;
      this.lastGenNotif = moment().format();
    } catch (err) {
      FileLogger.error("RelatedDrugsService", "generatedNotif", err);
    }
  }

  public async listSnomedRef(): Promise<string[]> {
    try {
      // we don't care if it's local or online, we just need the first we find:
      const drugs: IEntitylink[] = await this.getFirstDataAvailable();
      return this.getUniqueDrugsNames(drugs);
    } catch (err) {
      FileLogger.error("RelatedDrugsService", "listSnomedRef error: ", err);
    }
    return [];
  }

  private getUniqueDrugsNames(drugsList: IEntitylink[]): string[] {
    const filteredDrugs = drugsList.filter((drug) => {
      return drug.entityData && (drug.entityData as EntityDrug).name;
    });
    const drugNames = filteredDrugs.map((drug) => {
      return (drug.entityData as EntityDrug).name;
    });
    const uniqueNamesNoSpecialChar = drugNames.filter(ArrayHelper.onlyUnique).map(Tools.deleteAcccentSpecialcharacter);
    return uniqueNamesNoSpecialChar;
  }

  private filterForARelated(drugs: IEntitylink[], related: IRelatedPerson): IEntitylink[] {
    return drugs.filter((drug) => {
      return drug.caremateOwnerId === related.patient.reference;
    });
  }
}

import { ComputeDrugsService } from "src/app/services/compute-drugs.service";
import { IEntitylink } from "../../models/entitylink";
import { INotification } from "../../models/notification";
import { IVitalProfileDefinition, Reference } from "../../models/sharedInterfaces";
import { IAccount } from "../account-helper";
import { IObservation } from "../observation-helper";
import { TriggerRuleHelperEngineService } from "../trigger-rule-helper-engine/trigger-rule-helper-engine.service";

export class TriggerRuleHelperObservationService extends TriggerRuleHelperEngineService {
  /**
   *
   * @param patient
   * @param targets list of codes used in the rule, by type.
   * - For observation
   *   type = undefined, reference = loinc code
   * - For drugs
   *   type = "drugs", reference = cnk code or name of the drug
   * - For vitalProfile,
   *   type = vitalProfile, reference = loinc code
   * @param allObservations
   * @param currentObs
   * @param vitalSignDef
   * @param allDrugIntake if targets do not contain a drugs type, then this array is empty
   * @param allDrugs if targets do not contain a drugs type, then this array is empty
   */
  constructor(
    patient: IAccount,
    targets: Reference[],
    allObservations: IObservation[],
    currentObs: IObservation,
    vitalSignDef: IVitalProfileDefinition[],
    allDrugIntake: INotification[],
    allDrugs: IEntitylink[],
    computeDrugService: ComputeDrugsService
  ) {
    super();
    this.createOBSERVEDVariable(targets, allObservations, currentObs);
    this.createPATIENTVariable(patient);
    this.createVITALSIGNDEFVariable(vitalSignDef);
    this.createDRUG_INTAKEVariable(targets, allDrugIntake, allDrugs, computeDrugService);
  }
}

import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { from, Observable, of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { AppConstants } from "../appConstants";
import { ApiService } from "../services/api.service";
import { I18nTarget, II18nTranslation } from "../services/globalDataProvider/i18n.service";
import { LocalStorageService } from "../services/storage/local-storage.service";
import { IApiResponse } from "./iapi-response";
import { FileLogger } from "../helpers/fileLogger";

export class CustomI18nTranslateLoader implements TranslateLoader {
  private backupLoader: TranslateHttpLoader;

  constructor(private apiService: ApiService, private localStorage: LocalStorageService, private http: HttpClient) {
    this.backupLoader = new TranslateHttpLoader(this.http, "./assets/i18n/", ".json");
  }

  public getTranslation(lang: string): Observable<unknown> {
    const i18nStorageKey = AppConstants.TRANSLATIONS_KEYS + lang; // same key the i18nService uses
    // We will first try to get translations from local storage:
    const storagePromise: Promise<string> = this.localStorage.getData(i18nStorageKey, false).catch((_err) => {
      FileLogger.log("CustomI18nTranslateLoader", "No translations in storage for lang: " + lang);
      return null;
    });
    const translationsObservable = from(storagePromise).pipe(
      concatMap((transl) => {
        if (transl) {
          const parsedTranslation: II18nTranslation[] = JSON.parse(transl).data;
          if (parsedTranslation?.length > 0) {
            return of(parsedTranslation[0].i18n);
          }
        }
        // Not found in storage, try to get it from the server
        return from(this.getServerObservable(lang, i18nStorageKey));
      })
    );

    return translationsObservable;
  }

  private getServerObservable(lang: string, i18nStorageKey: string) {
    // Same url as in the i18nService
    // Why can't we just use the i18nService directly for this ?
    // Because of horrible circular dependencies in DI. Try it if you want, I have given up.
    const i18nUrl = "/i18nTranslation?target=" + I18nTarget.MOBILE + "&langs=" + lang;
    // Prepare server request, in case we do not have the translation in storage:
    const serverPromise = this.apiService
      .getWithPromise(i18nUrl)
      .then((response: IApiResponse) => {
        if (response && response.success && response.data) {
          const translations = response.data as II18nTranslation[];
          if (!translations || translations.length < 1) {
            FileLogger.error("CustomI18nTranslateLoader", "Server sent back empty translations");
            return null;
          }
          this.localStorage.storeEntity(i18nStorageKey, translations, false);
          return translations[0].i18n;
        } else {
          return null;
        }
      })
      .catch((err) => {
        FileLogger.error("CustomI18nTranslateLoader", "Error while downloading translations from server", err);
        return null;
      });
    const serverObservable = from(serverPromise).pipe(
      concatMap((serverTrsl) => {
        if (!serverTrsl) {
          // Happens when the user is not logged in
          // In that case, we use the json files in assets
          return this.backupLoader.getTranslation(lang);
        } else {
          return of(serverTrsl);
        }
      })
    );
    return serverObservable;
  }
}

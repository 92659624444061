import { SYNC_HTTP_METHOD } from "../services/globalDataProvider/core/request-sender.service";

export interface DataParameter {
  entityPrefix: string; // avoid key conflicting with ohers specific providers
  entityStoreKey: string; // unique key used for local storage
  getUrl: string;
  setUrl: string;
  method?: SYNC_HTTP_METHOD;
  expirationDays: number; // if local data outdated warn user that his local data are expired
  encrypted: boolean;
  customParam?: ICustomParam;
}

export type ICustomParam = {
  [key in CustomParamKey]?: any;
};

export enum CustomParamKey {
  overwriteQueue,
  mergeSetRequest,
  noPopupIfNoDataOffline,
}

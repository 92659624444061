import { Pipe, PipeTransform } from "@angular/core";
import { Tools } from "../helpers/tools-helper";

@Pipe({ name: "isValidDate" })
export class IsValidDatePipe implements PipeTransform {
  constructor() {}

  public transform(date: Date): boolean {
    return Tools.isValidEndDate(date);
  }
}

import { Injectable } from "@angular/core";
import { InfoAppService } from "./info-app.service";
import { PopupService } from "./popup.service";
import { ServerResponse } from "../helpers/server-response-helper";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor(protected infoAppService: InfoAppService, protected popupService: PopupService) {}

  /**
   * Handles server errors for Promise-based operations and retries a failed operation if the server is unreachable.
   * @param error - The error object.
   * @param retry - The function used recursively to retry the operation.
   * @returns Promise<T> - A Promise that resolves with the result of the retry function.
   * @template T - The type of the resolved value in the Promise returned by the retry function.
   */
  public handleErrorWithRetry<T>(error: any, retry: () => Promise<T>): Promise<T> {
    return new Promise<T>((reject) => {
      if (!this.infoAppService.isCordova() && error.code && error.code === ServerResponse.SERVER_UNREACHABLE.code) {
        retry();
        this.popupService.showAlert("error.general", "error.browser.unreachableServer");
      } else {
        reject(error);
      }
    });
  }
}

import { BluetoothLE } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { merge, Observable, Subject } from "rxjs";
import { finalize, map, takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools-helper";
import { InfoAppService } from "../../info-app.service";
import { MedicalBluetoothData } from "../medical-bluetooth-data";
import { MedicalBluetoothCharacteristic, MedicalBluetoothDevice, MedicalBluetoothService } from "../medical-bluetooth.service";
import { FileLogger } from "src/app/helpers/fileLogger";
import { MedicalBluetoothStatus } from "../medical-bluetooth-status.service";

export enum MedicalBluetoothGlucometerType {
  t0 = "Reserved for future use",
  t1 = "Capillary Whole blood",
  t2 = "Capillary Plasma",
  t3 = "Venous Whole blood",
  t4 = "Venous Plasma",
  t5 = "Arterial Whole blood",
  t6 = "Arterial Plasma",
  t7 = "Undetermined Whole blood",
  t8 = "Undetermined Plasma",
  t9 = "Interstitial Fluid (ISF)",
  t10 = "Control Solution",
}

export enum MedicalBluetoothGlucometerSampleLocation {
  s0 = "Reserved for future use",
  s1 = "Finger",
  s2 = "Alternate Site Test (AST)",
  s3 = "Earlobe",
  s4 = "Control solution",
  s15 = "Sample Location value not available",
}

export enum MedicalBluetoothGlucometerMeal {
  m0 = "RESERVED",
  m1 = "PREPRANDIAL",
  m2 = "POSTPRANDIAL",
  m3 = "FASTING",
  m4 = "CASUAL",
  m5 = "BEDTIME",
}

export interface MedicalBluetoothGlucometerData {
  sequenceNumber: number;
  mgDlGlucoseConcentration: number;
  mmolLGlucoseConcentration: number;
  date: Date;
  type: MedicalBluetoothGlucometerType;
  sampleLocation: MedicalBluetoothGlucometerSampleLocation;
  contextInformationFollows: boolean;
}

export interface MedicalBluetoothGlucometerContextData {
  sequenceNumber: number;
  meal?: MedicalBluetoothGlucometerMeal;
  carbohydrateId?: number;
  carbohydrate?: number;
  tester?: number;
  testerHealth?: number;
  exerciseDurationInSec?: number;
  exerciseIntensity?: number;
  medicationId?: number;
  medicationInKg?: number;
  medicationInL?: number;
  hbA1c?: number;
}

export interface MedicalBluetoothGlucometerPersonalizedData {
  mgDlGlucoseConcentration: number;
  date: Date;
  fasting?: boolean;
}

export class MedicalBluetoothGlucometer extends MedicalBluetoothData {
  public serviceType = MedicalBluetoothService.GLUCOSE;
  public descriptor = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.GLUCOSE_MEASUREMENT,
    service: MedicalBluetoothService.GLUCOSE,
  };
  public descriptorMeasurementContext = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.GLUCOSE_MEASUREMENT_CONTEXT,
    service: MedicalBluetoothService.GLUCOSE,
  };
  public descriptorRecordAccessPoint = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.GLUCOSE_RECORD_ACCESS_POINT,
    service: MedicalBluetoothService.GLUCOSE,
  };
  private isAllDataReceived = new Subject<boolean>();

  constructor(
    device: MedicalBluetoothDevice,
    handler: Observable<string>,
    infoAppService: InfoAppService,
    bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>,
    translateService: TranslateService,
    searchPreviousData: boolean,
    medicalBluetoothStatus: MedicalBluetoothStatus
  ) {
    super(device, handler, infoAppService, bluetoothle, onDestroy$, translateService, searchPreviousData, medicalBluetoothStatus);
  }
  public readData(): Observable<void> {
    const bytes = this.searchPreviousData ? new Uint8Array([0x01, 0x01]) : new Uint8Array([0x01, 0x06]); // [0x01, 0x01] = all records from glucose device, [0x01, 0x06] = last (most recent) record
    const encodedString = this.bluetoothle.bytesToEncodedString(bytes);

    this.standby = false;
    const measurement = this.bluetoothle.subscribe(this.descriptor);
    const measurementContext = this.bluetoothle.subscribe(this.descriptorMeasurementContext);
    const recordAccessPoint = this.bluetoothle.subscribe(this.descriptorRecordAccessPoint);

    const allDecodedData: MedicalBluetoothGlucometerData[] = [];
    const allDecodedContextData: MedicalBluetoothGlucometerContextData[] = [];
    return merge(
      measurement.pipe(
        map((dataResult) => {
          switch (dataResult.status.toString()) {
            case "subscribedResult": {
              const decodedData = this.decodeData(dataResult.value);
              FileLogger.log("MedicalBluetoothGlucometer", "readData - measurement - subscribedResult", JSON.stringify(decodedData));
              allDecodedData.push(decodedData);
              break;
            }
            case "subscribed":
              recordAccessPoint.subscribe(
                (record) => {
                  switch (record.status) {
                    case "subscribed":
                      /*
                                            The next promise indicated to the bluetooth device to emit a "subscribedResult" to
                                            the observables measurement, according to the value 
                                            "encodedString".
                                        */
                      this.bluetoothle
                        .write({
                          ...record,
                          value: encodedString,
                        })
                        .then(
                          (sendValue) => {
                            FileLogger.log("MedicalBluetoothGlucometer", "readData - measurement - write value - status", sendValue.status);
                          },
                          (err) => {
                            FileLogger.error(
                              "MedicalBluetoothGlucometer",
                              "readData - measurement - write value - error",
                              JSON.stringify(err)
                            );
                          }
                        );
                      break;
                    case "subscribedResult":
                      this.isAllDataReceived.next(true);
                      break;
                    default:
                      FileLogger.log(
                        "MedicalBluetoothGlucometer",
                        "readData - measurement - recordAccessPoint - status untreated",
                        record.status
                      );
                      break;
                  }
                },
                (err) => {
                  FileLogger.error(
                    "MedicalBluetoothGlucometer",
                    "readData - measurement - recordAccessPoint - error ",
                    JSON.stringify(err)
                  );
                }
              );
              break;

            default:
              FileLogger.log("MedicalBluetoothGlucometer", "readData - measurement - status untreated", dataResult.status);
              break;
          }
        })
      ),
      measurementContext.pipe(
        map((contextDataResult) => {
          switch (contextDataResult.status.toString()) {
            case "subscribedResult": {
              const decodedContextData = this.decodeContextData(contextDataResult.value);
              FileLogger.log(
                "MedicalBluetoothGlucometer",
                "readData - measurementContext - subscribedResult",
                JSON.stringify(decodedContextData)
              );
              allDecodedContextData.push(decodedContextData);
              break;
            }
            default:
              FileLogger.log("MedicalBluetoothGlucometer", "readData - measurementContext - status untreated", contextDataResult.status);
              break;
          }
        })
      )
    ).pipe(
      takeUntil(this.isAllDataReceived),
      finalize(() => {
        const finalData: MedicalBluetoothGlucometerPersonalizedData[] = [];
        if (this.searchPreviousData) {
          const allDecodedDataUnique = [...new Map(allDecodedData.map((item) => [item.sequenceNumber, item])).values()];
          allDecodedDataUnique
            .filter((data) => moment(data.date).isSameOrAfter(moment().subtract(1, "w")))
            .forEach((data) => {
              if (data.contextInformationFollows) {
                const context = allDecodedContextData.find((c) => c.sequenceNumber === data.sequenceNumber);
                if (context) {
                  finalData.push({
                    mgDlGlucoseConcentration: data.mgDlGlucoseConcentration,
                    date: data.date,
                    fasting: this.isFasting(context.meal),
                  });
                } else {
                  FileLogger.log("MedicalBluetoothGlucometer", "finalize - no context found for", data);
                }
              } else {
                finalData.push(data);
              }
            });
          this._data.next(finalData);
        } else {
          if (allDecodedData.length === 1) {
            finalData.push({
              mgDlGlucoseConcentration: allDecodedData[0].mgDlGlucoseConcentration,
              date: allDecodedData[0].date,
              fasting: this.isFasting(allDecodedContextData[0]?.meal),
            });
            this._data.next(finalData[0]);
          }
        }
        this._data.complete();
      })
    );
  }

  public getData(): Subject<MedicalBluetoothGlucometerPersonalizedData | MedicalBluetoothGlucometerPersonalizedData[]> {
    return this._data;
  }

  public getHumanReadableData(data: MedicalBluetoothGlucometerPersonalizedData | MedicalBluetoothGlucometerPersonalizedData[]): string {
    if (Array.isArray(data)) {
      data = data.length > 0 ? data[data.length - 1] : undefined;
    }
    if (!data) {
      return "nan";
    }
    let humanReadableData = data.mgDlGlucoseConcentration + " mg/dl";
    if (Tools.isDefined(data.fasting)) {
      humanReadableData +=
        " - " +
        this.translateService.instant("myobservations.fasting") +
        " : " +
        this.translateService.instant(data.fasting ? "application.yes" : "application.no");
    }
    return humanReadableData;
  }

  private isFasting(meal: MedicalBluetoothGlucometerMeal) {
    switch (meal) {
      case MedicalBluetoothGlucometerMeal.m1:
      case MedicalBluetoothGlucometerMeal.m3:
        return true;
      case MedicalBluetoothGlucometerMeal.m2:
        return false;
      default:
        return undefined;
    }
  }

  private decodeData(data: string): MedicalBluetoothGlucometerData {
    const bytes = this.bytesFromString(data);
    const view = new DataView(bytes.buffer);
    const result = {
      sequenceNumber: undefined,
      mgDlGlucoseConcentration: 0,
      mmolLGlucoseConcentration: 0,
      date: new Date(),
      type: MedicalBluetoothGlucometerType.t0,
      sampleLocation: MedicalBluetoothGlucometerSampleLocation.s0,
      contextInformationFollows: null,
    } as MedicalBluetoothGlucometerData;

    // 1 offset = 8 bit
    let offset = 0;

    // Flags field
    const timeOffsetPresent = (view.getUint8(0) & 0x01) > 0;
    const glucoseConcentrationPresent = (view.getUint8(0) & 0x02) > 0;
    const glucoseConcentrationUnits = view.getUint8(0) & 0x04;
    const sensorStatusAnnunciationPresent = (view.getUint8(0) & 0x08) > 0;
    const contextInformationFollows = (view.getUint8(0) & 0x10) > 0;
    offset += 1;

    // Sequence Number field
    result.sequenceNumber = view.getUint16(offset, true);
    offset += 2;

    // Base Time field
    const year = view.getUint16(offset, true);
    const month = view.getUint8(offset + 2);
    const day = view.getUint8(offset + 3);
    const hours = view.getUint8(offset + 4);
    const minutes = view.getUint8(offset + 5);
    const seconds = view.getUint8(offset + 6);
    offset += 7;

    // Time Offset field
    let minutesOffset = 0;
    if (timeOffsetPresent) {
      minutesOffset = view.getInt16(offset, true);
      offset += 2;
    }

    result.date = moment(new Date(year, month - 1, day, hours, minutes, seconds))
      .add(minutesOffset, "minutes")
      .toDate();

    // Glucose Concentration field
    const kgLTommolL = 62.06;
    const kgLTomgDl = (1000 * 1000) / 10;

    if (glucoseConcentrationPresent) {
      const concentration = this.sFloatFromIEEE_11073(view, offset);
      if (glucoseConcentrationUnits === 0) {
        result.mgDlGlucoseConcentration = Math.round(concentration * kgLTomgDl * 100) / 100;
        result.mmolLGlucoseConcentration = Math.round(concentration * kgLTommolL * 10000) / 10000;
      } else {
        result.mmolLGlucoseConcentration = Math.round(concentration * 10000) / 10000;
        result.mgDlGlucoseConcentration = Math.round((concentration / kgLTommolL) * kgLTomgDl * 100) / 100;
      }

      offset += 2;

      result.type = MedicalBluetoothGlucometerType[`t${view.getUint8(offset) & 0x0f}`];
      result.sampleLocation = MedicalBluetoothGlucometerSampleLocation[`s${(view.getUint8(offset) & 0xf0) >> 4}`];

      offset += 1;
    }

    // Sensor Status Annunciation field
    if (sensorStatusAnnunciationPresent) {
      const _batteryLowAtTimeMeasurement = (view.getUint16(offset, true) & 0x01) > 0;
      const _sensorMalfunction = (view.getUint16(offset, true) & 0x02) > 0;
      const _sampleSizeInsufficient = (view.getUint16(offset, true) & 0x04) > 0;
      const _stripInsertionError = (view.getUint16(offset, true) & 0x08) > 0;
      const _stripIncorrectForDevice = (view.getUint16(offset, true) & 0x10) > 0;
      const _sensorResultTooHigh = (view.getUint16(offset, true) & 0x20) > 0;
      const _sensorResultTooLow = (view.getUint16(offset, true) & 0x40) > 0;
      const _sensorTemperatureTooHigh = (view.getUint16(offset, true) & 0x80) > 0;
      const _sensorTemperatureTooLow = (view.getUint16(offset, true) & 0x100) > 0;
      const _sensorReadInterrupted = (view.getUint16(offset, true) & 0x200) > 0;
      const _generalDeviceFault = (view.getUint16(offset, true) & 0x400) > 0;
      const _timeFault = (view.getUint16(offset, true) & 0x800) > 0;
    }
    result.contextInformationFollows = contextInformationFollows;
    return result;
  }

  private decodeContextData(data: string): MedicalBluetoothGlucometerContextData {
    const bytes = this.bytesFromString(data);
    const view = new DataView(bytes.buffer);

    const result = {
      sequenceNumber: 0,
    } as MedicalBluetoothGlucometerContextData;

    let offset = 0;

    const carbohydratePresent = (view.getUint8(0) & 0x01) > 0;
    const mealPresent = (view.getUint8(0) & 0x02) > 0;
    const testerHealthPresent = (view.getUint8(0) & 0x04) > 0;
    const exercisePresent = (view.getUint8(0) & 0x08) > 0;
    const medicationPresent = (view.getUint8(0) & 0x10) > 0;
    const medicationUnit = view.getUint8(0) & 0x20;
    const hbA1cPresent = (view.getUint8(0) & 0x40) > 0;
    const extendedFlagsPresent = (view.getUint8(0) & 0x80) > 0;
    offset += 1;

    result.sequenceNumber = view.getUint16(offset, true);
    offset += 2;

    if (extendedFlagsPresent) {
      offset += 1;
    }

    if (carbohydratePresent) {
      result.carbohydrateId = view.getUint8(offset);
      result.carbohydrate = this.sFloatFromIEEE_11073(view, offset + 1);
      offset += 3;
    }

    if (mealPresent) {
      result.meal = MedicalBluetoothGlucometerMeal[`m${view.getUint8(offset)}`];
      offset += 1;
    }

    if (testerHealthPresent) {
      result.tester = view.getUint8(offset) & 0x0f;
      result.testerHealth = (view.getUint8(offset) & 0xf0) >> 4;
      offset += 1;
    }

    if (exercisePresent) {
      result.exerciseDurationInSec = view.getUint16(offset, true);
      result.exerciseIntensity = view.getUint8(offset + 2);
      offset += 3;
    }

    if (medicationPresent) {
      result.medicationId = view.getUint8(offset);
      if (medicationUnit === 0) {
        result.medicationInKg = this.sFloatFromIEEE_11073(view, offset + 1);
      } else {
        result.medicationInL = this.sFloatFromIEEE_11073(view, offset + 1);
      }

      offset += 3;
    }

    if (hbA1cPresent) {
      result.hbA1c = this.sFloatFromIEEE_11073(view, offset);
    }

    return result;
  }
}

import { IEntity, Identifier } from "./sharedInterfaces";
import { ITranslation } from "./translation";

export enum REWARD_PAGE_NAME {
  careplan = "careplan",
  mydrugs = "mydrugs",
  myagenda = "myagenda",
  mymessages = "mymessages",
  myobservations = "myobservations",
  myquestionnaires = "myquestionnaires",
  mytips = "mytips",
  mycontacts = "mycontacts",
  mynotes = "mynotes",
  myactivity = "myactivity",
  usefullinks = "usefullinks",
  myentourage = "myentourage",
}

export enum REWARD_CIBLE {
  step = "step",
  distance = "distance",
}

export enum REWARD_ACTION {
  onConsult = "onConsult",
  onAdd = "onAdd",
  onRead = "onRead",
  onSearch = "onSearch",
  onHealthActivity = "onHealthActivity",
  onClick = "onClick",
}

export interface IRewardDefinition extends IEntity {
  careplanIds: string[]; // [CAREPLAN_MM, CAREPLAN_BPCO, ...]
  identifier: Identifier[];
  rewardScore: number;
  rewardDuration: {
    duration: number;
    granularity: string; // (ex: '1 month', '7 days', '7 d', '1 m', ...)
  };
  rewardPonderation: IRewardPonderation[];
  rewardDisplayName: ITranslation;
  rewardDescription: ITranslation;
  dashboardRewardText: ITranslation; // Par exemple: "Crème pour les pieds LaRoche"
  patientRewardText: ITranslation; // Par exemple: "Vous avez attein votre objectif, rendez-vous dans votre officine pour récupérer votre cadeau!")
}

export interface IRewardPonderation {
  pageName: REWARD_PAGE_NAME;
  onConsult?: IPonderation; // à la consultation de la page
  onAdd?: IPonderation; //  Uniquement pour les pages où on peut ajouter des choses, par exemple RDV, Observations...
  onRead?: IPonderation; // Uniquement pour les pages où des choses sont 'readable' (conseils, artcles parcours, ...).
  onSearch?: IPonderation; // Uniquement pour les pages où une recherche est possible
  onHealthActivity?: IPonderation; // Uniquement pour la page 'Mon activité' et observation
  onClick?: IPonderation[]; // Au click sur une bouton, tuile, ...
}

export interface IPonderation {
  patientDescription: ITranslation;
  dashboardDescription: ITranslation;
  target?: REWARD_CIBLE; // seulement pour onHealthActivity
  score: number; // (si onActivity => score/1000steps / score/1km)
  max?: number;
  identifier: string;
}

import * as CryptoJS from "crypto-js";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import * as moment from "moment";
import random from "random";
import seedrandom from "seedrandom";
import { AppConstants } from "../appConstants";
import { KeyValue } from "../models/keyValue";
import { Timings } from "../models/timingData";
import { ITranslation } from "../models/translation";
import { FileLogger } from "./fileLogger";
const seed = moment().format();
random.use(seedrandom(seed));

/**
 * Common Tools
 */
export class Tools {
  /**
   * Generates a random number (integer or float) using a seed
   * @param integer
   * @param min default 0
   * @param max default 1
   * @returns the generated number
   */
  public static random(integer: boolean, min?: number, max?: number): number {
    if (integer) {
      return random.int(min, max);
    } else {
      return random.float(min, max);
    }
  }

  static mean(arrValues: number[]): number {
    if (arrValues?.length) {
      return arrValues.reduce((el, acc) => acc + el, 0) / arrValues?.length;
    } else {
      return 0;
    }
  }

  /**
   * Currently not used anywhere
   * @param arr
   * @returns a new array in which only the first occurrence of each element is kept with all falsey values removed
   */
  static uniqCompact(arr: string[]): string[] {
    return [...new Set(arr)].filter((x) => !!x);
  }

  /**
   * Make a deep copy of an object
   * @param obj
   * @returns the copied object
   */
  public static deepCopy<T>(obj: T): T {
    if (!obj) {
      return null;
    }
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Copies all propertie values from source to target object
   * @param source
   * @param target
   * @returns the modified target object
   */
  public static deepReplace(source: any, target: any) {
    return Object.assign(target, source);
  }

  /**
   * Check if the values are equal. By default, ignore the "_id" key in the comparison
   * @param value The first value
   * @param other The second value
   * @param keyNotToBeCompared (string[], default: ["_id"]) keys that should not be compared to determine if the objects are equal
   * @returns
   */
  public static isEqual(value: unknown, other: unknown, keyNotToBeCompared: string[] = ["_id"]): boolean {
    if (typeof value === "object" && typeof other === "object") {
      return (
        JSON.stringify(Tools.sortObjectByKeys(value, keyNotToBeCompared)) ===
        JSON.stringify(Tools.sortObjectByKeys(other, keyNotToBeCompared))
      );
    } else {
      return value === other;
    }
  }

  /**
   * The id must be < 2,147,483,647
   */
  public static genIdNumber(): number {
    return this.random(true, 0, 2147483646);
  }

  /**
   * Generate a mongo _id valid
   */
  public static genValidId(): string {
    return this.hex(Date.now() / 1000) + this.genGuid(16);
  }

  /**
   * Transform a value to hexa caracters
   * @param value
   */
  private static hex(value) {
    return Math.floor(value).toString(16);
  }

  /**
   * generate a n-digit number where digit are hexa caracters (for example, si n = 8 : f4ec1b63)
   * @param n
   */
  public static genGuid(n: number): string {
    return " ".repeat(n).replace(/./g, () => this.hex(this.random(false, 0, 15)));
  }

  /**
   * convert a moment string into second since Epoch
   *
   */
  public static time2Epoch(time: string): number {
    try {
      return moment(time).unix();
    } catch (err) {
      return moment().unix();
    }
  }

  /**
   * Return true if that day is saturday or sunday
   *
   */
  public static isWeekend(day: moment.Moment): boolean {
    try {
      const d = day.isoWeekday();
      return d === 6 || d === 7;
    } catch (err) {
      return false;
    }
  }

  /**
   *  return date string IS08601 now with 0 second set
   */
  public static nowISO8601(): string {
    moment.locale("fr");
    return moment().seconds(0).milliseconds(0).format();
  }

  /**
   * return date Now
   */
  public static getDate(): Date {
    return moment().toDate();
  }

  /**
   * return today (without hours, minutes, seconds,milliseconds)
   */
  public static getToday(): moment.Moment {
    return moment().hours(0).minutes(0).seconds(0).milliseconds(0);
  }

  /**
   * return a day without hours, minutes, seconds,milliseconds
   * If date is not a valid date, return "today"
   */
  public static momentNoHours(day: string): moment.Moment {
    const m = moment(day);
    if (m.isValid()) return m.hours(0).minutes(0).seconds(0).milliseconds(0);
    else return Tools.getToday();
  }

  /**
   *  return tomorrow (without hours, minutes, seconds, milliseconds)
   */
  public static getTomorrow(): moment.Moment {
    return moment().hours(0).minutes(0).seconds(0).milliseconds(0).add(1, "days");
  }

  /**
   * Return a day at defined hour (without hours, minutes, seconds, milliseconds)
   *
   *
   */
  public static dayAtHour(day: moment.Moment, hour: number): moment.Moment {
    return day.clone().hours(hour).minutes(0).seconds(0).milliseconds(0);
  }

  /**
   * return today at defined hour (without hours, minutes, seconds, milliseconds)
   */
  public static todayAtHour(hour: number): moment.Moment {
    return moment().hours(hour).minutes(0).seconds(0).milliseconds(0);
  }

  /**
   * return tomorrow at defined hour (without hours, minutes, seconds, milliseconds)
   */
  public static tomorrowAtHour(hour: number): moment.Moment {
    return moment().add(1, "days").hours(hour).minutes(0).seconds(0).milliseconds(0);
  }

  /**
   * return week days long name (in locale) and day number (1=monday, 7=sunday)
   */
  public static keyDayNames(keyStr?: boolean): KeyValue[] {
    const dayNames = new Array<KeyValue>();
    for (let i = 1; i <= 7; i++) {
      if (keyStr) dayNames.push(new KeyValue(i.toString(), moment().isoWeekday(i).format("dddd")));
      else dayNames.push(new KeyValue(i, moment().isoWeekday(i).format("dddd")));
    }
    return dayNames;
  }

  /**
   * @param min
   * @param max
   * @returns a random integer between min (included) and max (excluded)
   */
  public static getRandomInt(min: number, max: number): number {
    return this.random(true, min, max - 1);
  }

  /**
   * Check if the given value is a positive Integer
   * Currently not used anywhere
   * @param n
   * @returns true if value is a positive integer
   */
  public static isValidNumber(n: any): boolean {
    return Number.isInteger(n) && Math.sign(n) > 0;
  }

  /**
   * Check if it is a valid phone number
   *
   */
  public static isValidPhoneNumber(phone: string): boolean {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const tel = phoneUtil.parse(phone, "BE");
      return phoneUtil.isValidNumber(tel);
    } catch (err) {
      FileLogger.warn("Tools", "isValidPhoneNumber", err);
      return false;
    }
  }

  /**
   * Correctly format any phone number
   * @param phone
   * @returns the formatted phone number
   */
  public static getFormattedPhoneNumber(phone: string): string {
    try {
      if (!phone || typeof phone !== "string") return "";
      phone = phone.trim();
      if (!phone.length) return "";

      const phoneUtil = PhoneNumberUtil.getInstance();
      const tel = phoneUtil.parse(phone, "BE");
      const phoneFmt = phoneUtil.format(tel, PhoneNumberFormat.E164);
      return phoneFmt;
    } catch (err) {
      FileLogger.warn("Tools", "getFormattedPhoneNumber", err);
      return "";
    }
  }

  /**
   *
   * Check if date year is more than 2000
   */
  public static isValidEndDate(date: Date): boolean {
    if (!date || date.toString() === "Invalid Date") {
      FileLogger.warn("Tools", `isValidEndDate(date: Date) - Invalid Date -> ${date}`);
      return false;
    }
    return Number(date.toString()[0]) === 2;
  }

  /**
   * Useful if you need know if boolean value exist or just undefined
   * @param value
   */
  public static isValidBool(value: any): boolean {
    return typeof value === "boolean";
  }

  public static deleteAcccentSpecialcharacter(words: string): string {
    return words
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace("%", "");
  }

  /**
   * Check if it is a valid email
   *
   */
  public static isValidEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // import last CryptoHelper methods

  /**
   * Crypt some data
   * @param data
   * @param secret
   * @returns the crypted data
   */
  public static cryptItWithKey(data: any, secret: string): string {
    try {
      // secret must 16 length because of Mobile App library (Browserify-aes)
      secret = secret.padStart(16, "0");
      if (secret.length > 16) secret = secret.substring(0, 16);

      const key = CryptoJS.enc.Latin1.parse(secret);
      const iv = CryptoJS.enc.Latin1.parse(AppConstants.CRYPTO_IV);
      const dataParse = CryptoJS.enc.Latin1.parse(data);

      // AES-128
      const enc = CryptoJS.AES.encrypt(dataParse, key, {
        iv: iv,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding,
      });
      return enc.ciphertext.toString(CryptoJS.enc.Hex);
    } catch (err) {
      FileLogger.error("Tools", "cryptItWithKey", err);
      return null;
    }
  }

  /**
   * Compare two dates
   * @param date1
   * @param date2
   * @returns The number of days between two dates
   */
  public static differenceDate(date1: Date, date2: Date): number {
    const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    const day = 1000 * 60 * 60 * 24;
    return (date1utc - date2utc) / day;
  }

  /**
   * Check if a value is different of null and undefined
   * @param value
   * @returns
   */
  public static isDefined(value: any): boolean {
    return value !== null && value !== undefined;
  }

  /**
   * Check if a value is equal to null or undefined
   * @param value
   * @returns
   */
  public static isNotDefined(value: any): boolean {
    return !Tools.isDefined(value);
  }

  /**
   *
   * @param m
   * @returns number of minutes since midnight
   */
  public static toMinSinceMidnigth(m: moment.Moment): number {
    return m.hours() * 60 + m.minutes();
  }

  /**
   * Returns a copy of this object where the keys are sorted alphabetically in a recursive manner
   * and where the "keyNotToBeCompared" are set to "undefined"
   * @param obj
   * @param keyNotToBeCompared (string[], default: ["_id"]) keys that should not be compared to determine if the objects are equal
   * @returns
   */
  public static sortObjectByKeys<T extends object>(obj: T, keyNotToBeCompared: string[] = ["_id"]): T {
    if (Tools.isNotDefined(obj)) {
      return obj;
    }
    const ordered = Object.keys(obj)
      .sort()
      .reduce((obj2: T, key) => {
        if (keyNotToBeCompared.includes(key)) {
          obj2[key] = undefined;
        } else {
          const objKey = obj[key];
          if (typeof objKey === "object") {
            obj2[key] = this.sortObjectByKeys(objKey, keyNotToBeCompared);
          } else {
            obj2[key] = objKey;
          }
        }
        return obj2;
      }, {} as T);
    return ordered;
  }

  public static wait(milliseconds: number): Promise<{ success: boolean }> {
    return new Promise<{ success: boolean }>((resolve) => {
      setTimeout(() => {
        resolve({ success: true });
      }, milliseconds);
    });
  }

  public static getDateTimeLocaleFormat(langs: ITranslation[], appLang: string): string {
    const foundLang = langs.find((l) => l.term === appLang);
    if (appLang === foundLang.term) {
      return foundLang.timeLocaleFormat;
    } else {
      return "fr-FR";
    }
  }

  public static flattenArray<T>(a: T[][]): T[] {
    return a.reduce((accumulator, value) => accumulator.concat(value), []);
  }

  public static minutesToHours(minutes: number): string {
    const h = Math.floor(minutes / 60)
      .toString()
      .padStart(2, "0");
    const m = (minutes % 60).toString().padStart(2, "0");
    return h + ":" + m;
  }

  public static hoursToMinutes(hours: string): number {
    const split = hours.split(":");
    return Number(split[0]) * 60 + Number(split[1]);
  }

  public static async asyncForEach<T>(array: T[], callback: (a: T, b: number, c: unknown[]) => unknown): Promise<void> {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  public static getUserTimeCodeFromDate(dateString: string, timeCodes: Timings): string {
    const date = moment(dateString);
    const isWeekend = date.isoWeekday() > 5; // 6 = samedi, 7 = dimanche
    const minutesSinceMidnight = date.hours() * 60 + date.minutes();
    const codePrefix = isWeekend ? "_weekend" : "";
    if (minutesSinceMidnight === timeCodes["rising" + codePrefix]) {
      return "rising";
    } else if (minutesSinceMidnight === timeCodes["morning" + codePrefix]) {
      return "morning";
    } else if (minutesSinceMidnight === timeCodes["noon" + codePrefix]) {
      return "noon";
    } else if (minutesSinceMidnight === timeCodes["evening" + codePrefix]) {
      return "evening";
    } else if (minutesSinceMidnight === timeCodes["beding" + codePrefix]) {
      return "beding";
    }

    return null;
  }
}

import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { from, Observable, of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { Tools } from "./app/helpers/tools-helper";
import { ConfigurationService } from "./app/services/globalDataProvider/configuration.service";
import { SysAccountService } from "./app/services/sys-account.service";

/**
 * To use safely TranslateService.instant use this resolver.
 * Resolver wait to exec your component code until the observable return a value.
 * Source : https://stackoverflow.com/a/54244011
 */
@Injectable({
  providedIn: "root",
})
export class TranslationLoaderResolver implements Resolve<any> {
  private static lastLang: string = null;

  constructor(
    private translateService: TranslateService,
    private configService: ConfigurationService,
    private sysAccountService: SysAccountService,
    private platform: Platform
  ) {}

  resolve(): Observable<any> {
    return from(this.platform.ready()).pipe(
      concatMap(() => {
        if (Tools.isDefined(this.configService.getCacheConfiguration())) {
          return this.initTranslateService();
        }
        return from(this.sysAccountService.getSysAccount().catch(() => null)).pipe(
          concatMap((sA) => {
            if (sA) {
              return of(this.configService.peekData()).pipe(
                concatMap(() => {
                  return this.initTranslateService();
                })
              );
            } else {
              // no sysaccaount -> first login
              return this.initTranslateService();
            }
          })
        );
      })
    );
  }

  /**
   * From the this.configService.getCurrentLanguage(), update, if needed the translationService and moment
   * @returns
   */
  private initTranslateService() {
    return from(this.configService.getCurrentLanguageWithCheck()).pipe(
      concatMap((language) => {
        if (Tools.isDefined(TranslationLoaderResolver.lastLang) && TranslationLoaderResolver.lastLang === language) {
          return of(null);
        }
        TranslationLoaderResolver.lastLang = language;
        this.translateService.setDefaultLang(language);
        moment.locale(language);
        return this.translateService.use(language).pipe(
          concatMap(() => {
            return this.translateService.get("application.title");
          })
        );
      })
    );
  }
}

import { PopupService } from "../popup.service";
import { MedicalBluetooth } from "./medical-bluetooth.service";
import { NOTIFICATION_STATUS } from "src/app/models/notification";
import { ComputeDrugsService } from "../compute-drugs.service";
import { MedicalBluetoothSDKPillDispenserService } from "./medical-bluetooth-sdk-pilldispenser.service";
import { DrugService } from "../globalDataProvider/drug.service";
import { FileLogger } from "src/app/helpers/fileLogger";
import * as moment from "moment";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools-helper";
import { AccountService } from "../globalDataProvider/account.service";

export enum PopupBluetooth {
  toContinueSynchronisingMedicines,
  toConnectADevice,
}
export interface MedicalBluetoothSDKConstructor {
  new (
    medicalBluetoothService: MedicalBluetooth,
    popupService: PopupService,
    computeDrugsService: ComputeDrugsService,
    drugService: DrugService,
    accountService: AccountService
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any;
  Instance(
    medicalBluetoothService: MedicalBluetooth,
    popupService: PopupService,
    computeDrugsService: ComputeDrugsService,
    drugService: DrugService,
    accountService: AccountService
  ): InstanceType<this>;
}

export interface Usage {
  address: string;
  date: string;
  counterState: number;
}

export interface Device {
  address: string;
  type: number;
  state: number;
}

export abstract class MedicalBluetoothSDKDataPillDispenser {
  /* convention (for findAir, to be reviewed if you have another device) (see watchUsages method): 
     - if we only have one element of usages, then it's an element taken on the fly. 
     - If we receive an array of several elements of usages, then it's the complete history of the device (origin="history").*/
  protected $usages: BehaviorSubject<{ origin?: string; usages: Usage[] }> = new BehaviorSubject<{ origin?: string; usages: Usage[] }>({
    usages: [],
  });
  protected $devices = new BehaviorSubject<Device[]>([]);
  protected $error = new BehaviorSubject<unknown>(undefined);

  protected constructor(
    public medicalBluetoothService: MedicalBluetooth,
    protected popupService: PopupService,
    protected computeDrugsService: ComputeDrugsService,
    protected drugService: DrugService,
    private accountService: AccountService
  ) {
    this.watchUsages().subscribe((usageHistory) => {
      FileLogger.log("MedicalBluetoothSDKDataPillDispenser - watchUsagesHistory", JSON.stringify(usageHistory.usages.length));
    });
    this.watchError().subscribe((error) => {
      FileLogger.error("MedicalBluetoothSDKDataPillDispenser - watchError", JSON.stringify(error));
    });
  }

  abstract onInit(): Promise<void>;
  abstract stopScan(): Promise<void>;
  abstract makeARequestForGetUsagesHistory(): Promise<void>;
  abstract makeARequestForOnScanAndConnectWithNearbyDevice(disconnect: boolean): Promise<void>;
  abstract makeARequestForDisconnectDevice(device: { address: string }): Promise<void>;

  private watchUsages(): Observable<{ origin?: string; usages: Usage[] }> {
    return this.$usages.pipe(
      concatMap((usagesHistory) => {
        // let error = false; used for the lastSynchro field wich is disabled for now
        if (!usagesHistory?.usages || usagesHistory?.usages?.length === 0) {
          return of({ usages: [] });
        }
        const getCaremateIdentifier = new Promise<string>((resolve, reject) => {
          const caremateId = this.accountService.cachedCaremateId;
          if (!caremateId) {
            this.accountService.getFirstDataAvailable().then(
              (account) => {
                resolve(account?.caremateIdentifier);
              },
              (err) => {
                reject(err);
              }
            );
          } else {
            resolve(caremateId);
          }
        });
        return from(getCaremateIdentifier).pipe(
          concatMap((caremateId) => {
            return from(
              Tools.asyncForEach(
                usagesHistory.usages.filter((usage) => {
                  const device = MedicalBluetoothSDKPillDispenserService.bondedDevices.find(
                    (device) => device.address === usage.address && device.account === caremateId // to ensure that the right patient is added, if the phone is used by several people
                  );
                  if (!device) {
                    // the device associated with this "Usage" is not associated with one of our drugs
                    return false;
                  }
                  if (moment(device.start).isAfter(moment(usage.date))) {
                    // Usage dates from before the date indicated by the patient for starting synchro
                    return false;
                  }
                  if (device.lastSynchro && moment(device.lastSynchro).isAfter(moment(usage.date))) {
                    // Usage dates from before the lastSynchro with the historical method
                    return false;
                  }
                  return true;
                }),
                async (usage) => {
                  try {
                    if (moment(usage.date).isAfter(moment().subtract(2, "months"))) {
                      await this.addDrugIntake(usage);
                    }
                    /*
                    lastSynchro disabled : since we are not sure to retreive all the historic depending of the state of the device and since we are not able (yet) to manage the update of the device status,
                    we prefer deactivate this function. Note that there will not be duplicata in any case
                    if (!error && usagesHistory.usages.length > 1) {
                      // if there is more than one item, then the history has been used (i.e. all the "Usage" of the device have been covered).
                      // If not, we've recovered one use in real time, but we may have lost other uses -> the synchronisation date is not updated
                      const device = MedicalBluetoothSDKPillDispenserService.bondedDevices.find(
                        (device) => device.address === usage.address && device.account === caremateId
                      );
                      if (device?.status == 6) {
                        device.lastSynchro = moment(usage.date).format();
                        MedicalBluetoothSDKPillDispenserService.updateBondedDevices(device);
                      }
                    }
                      */
                  } catch (err) {
                    // error = true; lastSynchro disabled
                    this.$error.next(err);
                  }
                }
              )
            ).pipe(map(() => usagesHistory));
          })
        );
      })
    );
  }

  public watchError(): BehaviorSubject<unknown> {
    return this.$error;
  }

  public watchDevices(): BehaviorSubject<Device[]> {
    return this.$devices;
  }

  public peekDevices(): Device[] {
    return this.$devices.value;
  }

  public getUsagesSubject(): BehaviorSubject<{ origin?: string; usages: Usage[] }> {
    return this.$usages;
  }

  protected async initBluetooth(displayPopupError = true, popup = PopupBluetooth.toConnectADevice): Promise<void> {
    try {
      await this.medicalBluetoothService.ready();
    } catch (error) {
      if (displayPopupError) {
        let messageKey = "";
        switch (popup) {
          case PopupBluetooth.toConnectADevice:
            messageKey = "myBleDevices.pleaseActivateBluetooth";
            break;
          case PopupBluetooth.toContinueSynchronisingMedicines:
            messageKey = "myBleDevices.pleaseActivateBluetoothToContinueSynchronisingMedicines";
            break;
          default:
            messageKey = "myBleDevices.pleaseActivateBluetooth";
            break;
        }
        await this.popupService.showAlert("myBleDevices.inactiveBluetooth", messageKey);
      }
      throw error;
    }
  }

  public async addDrugIntake(usage: Usage): Promise<void> {
    const device = MedicalBluetoothSDKPillDispenserService.bondedDevices.find((device) => device.address === usage.address);
    if (device) {
      const drug = await this.drugService.getOne(device.drugId);
      if (drug) {
        FileLogger.log("MedicalBluetoothSDKDataPillDispenser", "addDrugIntake", `${usage.date} - ${usage.address}`, "none");
        await this.computeDrugsService.addTakingEntityDrugWithDevice(
          drug,
          device,
          usage.counterState.toString(),
          moment(usage.date).format(),
          NOTIFICATION_STATUS.ACCEPTED,
          5
        );
      }
    }
  }
}

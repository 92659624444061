import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { BackgroundService } from "./services/background.service";
import { NotificationsPluginService } from "./services/notificationsService/notifications-plugin.service";
import { ActivateDeeplinksService } from "./services/deeplinks/activateDeeplinks.service";
import { FileLogger } from "./helpers/fileLogger";
import { SysAccountService } from "./services/sys-account.service";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { AccountService } from "./services/globalDataProvider/account.service";
import { register } from "swiper/element/bundle";

register();
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private backgroundService: BackgroundService,
    private notificationPluginService: NotificationsPluginService,
    private deeplinks: ActivateDeeplinksService,
    private sysAccountService: SysAccountService,
    protected fontAwesomelibrary: FaIconLibrary,
    private accountService: AccountService
  ) {
    // The next line is needed to load all types of font-awesome icons
    fontAwesomelibrary.addIconPacks(fab, far, fas);
    this.initializeApp().then(
      async () => {
        this.initializeSomeServices();
        // Allow live reload to get the account. Also useful if the app crash and is reloaded automatically : see CMATE-5531
        if (this.sysAccountService.cachedSysAccount) {
          await this.accountService.getFirstDataAvailable();
        }
      },
      (err) => {
        FileLogger.error("AppComponent", "the application did not initialise correctly", err);
      }
    );
  }

  /**
   * waits for the platform to be ready, manages the style
   */
  private async initializeApp(): Promise<void> {
    await this.platform.ready();
    // init cache of sysaccount
    await this.sysAccountService.getSysAccount().catch(() => {
      FileLogger.error("initializeApp", "no sysaccount load");
    });
    this.statusBar.styleDefault();
  }

  /**
   * Initialize
   * - notifications
   * - backgroundSynchro
   * - deeplinks
   * - migration ionic5
   */
  private initializeSomeServices() {
    this.notificationPluginService.bindNotificationEvents();
    this.backgroundService.initBackgroundSynchro();
    this.deeplinks.init();
  }
}

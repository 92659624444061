import { OperationResult, BluetoothLE } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { InfoAppService } from "../../info-app.service";
import { MedicalBluetoothData } from "../medical-bluetooth-data";
import { MedicalBluetoothCharacteristic, MedicalBluetoothDevice, MedicalBluetoothService } from "../medical-bluetooth.service";
import { MedicalBluetoothStatus } from "../medical-bluetooth-status.service";

export interface MedicalBluetoothBloodPressureData {
  mmHgSystolic: number;
  kPaSystolic: number;
  mmHgDiastolic: number;
  kPaDiastolic: number;
  mmHgArterialPressure: number;
  kPaArterialPressure: number;
  time?: Date;
  pulseRate?: number;
  userId?: number;
  measurementStatus?: MeasurementStatus;
}

export interface MeasurementStatus {
  bodyMovement?: BodyMovementDetectionFlag;
  cuffFit?: CuffFitDetectionFlag;
  irregularPulse?: IrregularPulseDetectionFlag;
  pulseRateRange?: PulseRateRangeDetectionFlag;
  measurementPosition?: MeasurementPositionDetectionFlag;
}

enum BodyMovementDetectionFlag {
  NO_MOVEMENT = "No body movement",
  MOVEMENT = "Body movement during measurement",
}

enum CuffFitDetectionFlag {
  FITS_PROPERLY = "Cuff fits properly",
  TOO_LOOSE = "Cuff too loose",
}

enum IrregularPulseDetectionFlag {
  NO_IRREGULAR = "No irregular pulse detected",
  IRREGULAR = "Irregular pulse detected",
}

enum PulseRateRangeDetectionFlag {
  WITHIN = "Pulse rate is witin the range",
  EXCEED = "Pulse rate exceeds upper limit",
  LESS = "Pulse rate is less than lower limit",
}

enum MeasurementPositionDetectionFlag {
  PROPER = "Proper measurement position",
  IMPROPER = "Improper measurement position",
}

export class MedicalBluetoothBloodPressure extends MedicalBluetoothData {
  public serviceType = MedicalBluetoothService.BLOOD_PRESSURE;
  public descriptor = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.BLOOD_PRESSURE_MEASUREMENT,
    service: MedicalBluetoothService.BLOOD_PRESSURE,
  };

  public constructor(
    device: MedicalBluetoothDevice,
    handler: Observable<string>,
    infoAppService: InfoAppService,
    bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>,
    translateService: TranslateService,
    searchPreviousData: boolean,
    medicalBluetoothStatus: MedicalBluetoothStatus
  ) {
    super(device, handler, infoAppService, bluetoothle, onDestroy$, translateService, searchPreviousData, medicalBluetoothStatus);
  }

  public readData() {
    this.standby = false;
    return this.bluetoothle.subscribe(this.descriptor).pipe(
      map((r) => {
        const data = r as unknown as OperationResult;
        if (data.status === "subscribedResult") {
          const decodedData = this.decodeData(data.value);
          if (decodedData.mmHgSystolic > 250 || decodedData.mmHgDiastolic > 250) {
            this._data.next(null);
            this._data.complete();
          } else {
            this._data.next(decodedData);
          }
        }
      }),
      finalize(() => {
        this._data.complete();
      })
    );
  }

  public getData(): Subject<MedicalBluetoothBloodPressureData> {
    return this._data;
  }

  public getHumanReadableData(data: MedicalBluetoothBloodPressureData) {
    return "Sys. " + data.mmHgSystolic.toFixed(1) + " / Dia. " + data.mmHgDiastolic.toFixed(1);
  }

  private decodeData(data: string): MedicalBluetoothBloodPressureData {
    const bytes = this.bytesFromString(data);
    const view = new DataView(bytes.buffer);

    let offset = 0;
    const result: MedicalBluetoothBloodPressureData = {
      kPaArterialPressure: 0,
      kPaDiastolic: 0,
      kPaSystolic: 0,
      mmHgArterialPressure: 0,
      mmHgDiastolic: 0,
      mmHgSystolic: 0,
    };

    // Flags (Uint 8)
    const measurementUnit = view.getUint8(0) & 0x01;
    const hasTimeStamp = (view.getUint8(0) & 0x02) > 0;
    const hasPulseRate = (view.getUint8(0) & 0x04) > 0;
    const hasUserId = (view.getUint8(0) & 0x08) > 0;
    const hasMeasurementStatus = (view.getUint8(0) & 0x0f) > 0;
    offset += 1;

    const mmHgTokPa = 0.133322387415;

    // Measure Systolic (SFLOAT 16)
    const systolic = this.sFloatFromIEEE_11073(view, offset);
    if (measurementUnit === 0) {
      result.mmHgSystolic = systolic;
      result.kPaSystolic = systolic * mmHgTokPa;
    } else {
      result.kPaSystolic = systolic;
      result.mmHgSystolic = systolic / mmHgTokPa;
    }
    offset += 2;

    // Measure Diastolic (SFLOAT 16)
    const diastolic = this.sFloatFromIEEE_11073(view, offset);
    if (measurementUnit === 0) {
      result.mmHgDiastolic = diastolic;
      result.kPaDiastolic = diastolic * mmHgTokPa;
    } else {
      result.kPaDiastolic = diastolic;
      result.mmHgDiastolic = diastolic / mmHgTokPa;
    }
    offset += 2;

    // Measure Mean Arterial Pressure (SFLOAT 16)
    const arterialPressure = this.sFloatFromIEEE_11073(view, offset);
    if (measurementUnit === 0) {
      result.mmHgArterialPressure = arterialPressure;
      result.kPaArterialPressure = arterialPressure * mmHgTokPa;
    } else {
      result.kPaArterialPressure = arterialPressure;
      result.mmHgArterialPressure = arterialPressure / mmHgTokPa;
    }
    offset += 2;

    // Timestamp
    if (hasTimeStamp) {
      result.time = new Date(
        view.getUint16(offset, true),
        view.getUint8(offset + 2),
        view.getUint8(offset + 3),
        view.getUint8(offset + 4),
        view.getUint8(offset + 5),
        view.getUint8(offset + 5)
      );
      offset += 7;
    }

    // Pulse rate
    if (hasPulseRate) {
      result.pulseRate = this.sFloatFromIEEE_11073(view, offset);
      offset += 2;
    }

    // User Id
    if (hasUserId) {
      result.userId = view.getUint8(offset);
      offset += 1;
    }

    // Measurement status
    if (hasMeasurementStatus) {
      const measurementStatus = view.getUint16(offset, true);
      const measurementStatusResult: MeasurementStatus = {};

      // Body movement
      if ((measurementStatus & 0x01) > 0) {
        measurementStatusResult.bodyMovement = BodyMovementDetectionFlag.MOVEMENT;
      } else {
        measurementStatusResult.bodyMovement = BodyMovementDetectionFlag.NO_MOVEMENT;
      }

      // Cuff fit
      if ((measurementStatus & 0x02) > 0) {
        measurementStatusResult.cuffFit = CuffFitDetectionFlag.TOO_LOOSE;
      } else {
        measurementStatusResult.cuffFit = CuffFitDetectionFlag.FITS_PROPERLY;
      }

      // Irregular pulse
      if ((measurementStatus & 0x04) > 0) {
        measurementStatusResult.irregularPulse = IrregularPulseDetectionFlag.IRREGULAR;
      } else {
        measurementStatusResult.irregularPulse = IrregularPulseDetectionFlag.NO_IRREGULAR;
      }

      // Pulse rate range (TODO)
      if ((measurementStatus & 0x12) > 0) {
        measurementStatusResult.pulseRateRange = PulseRateRangeDetectionFlag.EXCEED;
      } else {
        measurementStatusResult.pulseRateRange = PulseRateRangeDetectionFlag.WITHIN;
      }

      // Measurement position
      if ((measurementStatus & 0x14) > 0) {
        measurementStatusResult.measurementPosition = MeasurementPositionDetectionFlag.IMPROPER;
      } else {
        measurementStatusResult.measurementPosition = MeasurementPositionDetectionFlag.PROPER;
      }

      result.measurementStatus = measurementStatusResult;
    }

    return result;
  }
}

import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { BasePage } from "src/app/baseClasses/base-page";
import { IAccount } from "src/app/helpers/account-helper";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { ApiService } from "src/app/services/api.service";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoaderService } from "src/app/services/loader.service";
import { PopupService } from "src/app/services/popup.service";
import { Activate2FAModalComponent } from "../activate2-famodal/activate2-famodal.component";
import { ChangeOwnPasswordModalComponent } from "./change-own-password-modal/change-own-password-modal.component";
import { FileLogger } from "src/app/helpers/fileLogger";

@Component({
  selector: "app-settings-security-page-modal",
  templateUrl: "./settings-security-page-modal.component.html",
  styleUrls: ["./settings-security-page-modal.component.scss"],
})
export class SettingsSecurityPageModalComponent extends BasePage {
  public account: IAccount;
  public sessionTimeout = 0;
  public isIOS: boolean;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    private accountService: AccountService,
    protected modalCtrl: ModalController,
    protected loaderService: LoaderService,
    private modalController: ModalController,
    protected apiService: ApiService,
    translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
    this.account = this.accountService.cachedAccount;
    this.isIOS = infoService.isIOS();
  }

  /**
   * save Registration data
   */
  public save(): void {
    // save modifications
    this.dismiss();
  }

  /**
   *  cancel modal
   */
  public dismiss(): void {
    this.modalController.dismiss();
  }

  /**
   * Enable 2 factor authentication
   */
  public onEnable2FA(): void {
    if (this.isIOS) {
      return;
    }
    this.apiService
      .post("/mfa/enable", {})
      .pipe(
        map((rep) => {
          const type = ServerResponse.type(rep);
          switch (type) {
            case SERVER_RESPONSE_TYPE.SUCCESS: {
              const secret2fa: string = rep.data;
              this.presentModalActivate2FA(secret2fa);
              break;
            }
            default:
              FileLogger.error("SettingsSecurityPageModalComponent", "enable2FA");
              this.popupService.showAlert("error.general", "settings.auth.mfa.enable_error", "");
              break;
          }
        })
      )
      .subscribe();
  }

  /**
   * Disable 2 factor authentication
   */
  public onDisable2FA(): void {
    this.apiService
      .post("/mfa/disable", {})
      .pipe(
        map((rep) => {
          const type = ServerResponse.type(rep);
          switch (type) {
            case SERVER_RESPONSE_TYPE.SUCCESS:
              // Update the account
              this.account.mfaActive = false;
              this.accountService.setAccount(this.account).subscribe();
              break;
            default:
              FileLogger.error("SettingsSecurityPageModalComponent", "disableFA");
              this.popupService.showAlert("error.general", "settings.auth.mfa.disable_error", "");
              break;
          }
        })
      )
      .subscribe();
  }

  /**
   * display 2FA activation view
   */
  private async presentModalActivate2FA(secret2fa: string): Promise<void> {
    if (this.isIOS) {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: Activate2FAModalComponent,
      componentProps: {
        secret2fa: secret2fa,
      },
    });
    modal.onDidDismiss().then((result) => {
      // Update the account
      if (result.data) {
        this.account.mfaActive = true;
        this.accountService.setAccount(this.account).subscribe();
      }
    });
    return modal.present();
  }

  /**
   * display change own password modal
   */
  public async presentModalChangeOwnPassword(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ChangeOwnPasswordModalComponent,
      componentProps: {
        login: this.account.mail,
      },
    });
    return modal.present();
  }
}

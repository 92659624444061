import * as ESAPI from "node-esapi";

export class FileLogger {
  private static intermediateMethod(elem: unknown): unknown {
    return elem;
  }

  public static log(source: string, message: string, param?: unknown, encoderType?: "html" | "javascript" | "url" | "none"): void {
    if (typeof param === "object" && encoderType !== "none") {
      param = JSON.stringify(param);
    }
    if (!encoderType || encoderType === "html") {
      console.log(ESAPI.encoder().encodeForHTML(source), ESAPI.encoder().encodeForHTML(message), ESAPI.encoder().encodeForHTML(param));
    } else if (encoderType === "javascript") {
      console.log(
        ESAPI.encoder().encodeForJavaScript(source),
        ESAPI.encoder().encodeForJavaScript(message),
        ESAPI.encoder().encodeForJavaScript(param)
      );
    } else if (encoderType === "url") {
      console.log(ESAPI.encoder().encodeForURL(source), ESAPI.encoder().encodeForURL(message), ESAPI.encoder().encodeForURL(param));
    } else if (encoderType === "none") {
      console.log(this.intermediateMethod(source), this.intermediateMethod(message), this.intermediateMethod(param));
    }
  }

  public static warn(source: string, message: string, param?: unknown, encoderType?: "html" | "javascript" | "url" | "none"): void {
    if (typeof param === "object" && encoderType !== "none") {
      param = JSON.stringify(param);
    }
    if (!encoderType || encoderType === "html") {
      console.warn(ESAPI.encoder().encodeForHTML(source), ESAPI.encoder().encodeForHTML(message), ESAPI.encoder().encodeForHTML(param));
    } else if (encoderType === "javascript") {
      console.warn(
        ESAPI.encoder().encodeForJavaScript(source),
        ESAPI.encoder().encodeForJavaScript(message),
        ESAPI.encoder().encodeForJavaScript(param)
      );
    } else if (encoderType === "url") {
      console.warn(ESAPI.encoder().encodeForURL(source), ESAPI.encoder().encodeForURL(message), ESAPI.encoder().encodeForURL(param));
    } else if (encoderType === "none") {
      console.warn(this.intermediateMethod(source), this.intermediateMethod(message), this.intermediateMethod(param));
    }
  }

  public static error(source: string, message: string, param?: unknown, encoderType?: "html" | "javascript" | "url" | "none"): void {
    if (typeof param === "object" && encoderType !== "none") {
      param = JSON.stringify(param);
    }
    if (!encoderType || encoderType === "html") {
      console.error(ESAPI.encoder().encodeForHTML(source), ESAPI.encoder().encodeForHTML(message), ESAPI.encoder().encodeForHTML(param));
    } else if (encoderType === "javascript") {
      console.error(
        ESAPI.encoder().encodeForJavaScript(source),
        ESAPI.encoder().encodeForJavaScript(message),
        ESAPI.encoder().encodeForJavaScript(param)
      );
    } else if (encoderType === "url") {
      console.error(ESAPI.encoder().encodeForURL(source), ESAPI.encoder().encodeForURL(message), ESAPI.encoder().encodeForURL(param));
    } else if (encoderType === "none") {
      console.error(this.intermediateMethod(source), this.intermediateMethod(message), this.intermediateMethod(param));
    }
  }
}

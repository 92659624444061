import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BasePage } from "src/app/baseClasses/base-page";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { IAdviceSettings, IConfiguration } from "src/app/models/configuration";
import { ACTION_STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { LanguagesService } from "src/app/services/globalDataProvider/languagesService";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoaderService } from "src/app/services/loader.service";
import { NotificationsEventsService } from "src/app/services/notificationsService/notifications-events.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-settings-notif-page-modal",
  templateUrl: "./settings-notif-page-modal.component.html",
  styleUrls: ["./settings-notif-page-modal.component.scss"],
})
export class SettingsNotifPageModalComponent extends BasePage {
  public configuration: IConfiguration;
  public sessionTimeout = 0;
  public notifSettingsScheduledBefore: Array<string>;
  public notifSettingsTimeWeek: Array<string>;
  public notifSettingsTimeWeekend: Array<string>;
  public advicePeriods: string;
  public adviceDays: number;
  public lastGen: Date;
  public dateTimeLocaleFormat: string;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    public configService: ConfigurationService,
    protected loaderService: LoaderService,
    private modalController: ModalController,
    private notificationsEventsService: NotificationsEventsService,
    protected translateService: TranslateService,
    protected languagesService: LanguagesService
  ) {
    super(translateService, configService, infoService, popupService);
    this.languagesService.getFirstDataAvailable().then((langs) => {
      this.dateTimeLocaleFormat = Tools.getDateTimeLocaleFormat(langs, this.configService.getCurrentLanguage());
    });
    this.configuration = this.configService.getCacheConfiguration();
    // convert settings to display format
    this.convertSettingsToDisplay();
  }

  /**
   *  Ionic Event: enter in view
   */
  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.initAdviceSettings(this.configuration);
  }

  /**
   * convert setting minutes to Ionic Datetime format
   */
  private convertSettingsToDisplay() {
    // convert "Scheduled Before"
    this.notifSettingsScheduledBefore = new Array<string>();
    for (let i = 0; i < this.configuration.settings.notificationSettings.scheduledBefore.length; i++) {
      let sb = this.configuration.settings.notificationSettings.scheduledBefore[i];
      if (Tools.isNotDefined(sb)) sb = 5;
      const setting = Tools.getToday().add(sb, "minutes");
      this.notifSettingsScheduledBefore.push(setting.format("HH:mm"));
    }
    //  handle old format where we only stored hours and not minutes
    this.notifSettingsTimeWeek = new Array<string>();
    let s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.riseHourWeek);
    this.notifSettingsTimeWeek.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.morningHourWeek);
    this.notifSettingsTimeWeek.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.noonHourWeek);
    this.notifSettingsTimeWeek.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.eveningHourWeek);
    this.notifSettingsTimeWeek.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.bedHourWeek);
    this.notifSettingsTimeWeek.push(s);

    this.notifSettingsTimeWeekend = new Array<string>();
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.riseHourWeekend);
    this.notifSettingsTimeWeekend.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.morningHourWeekend);
    this.notifSettingsTimeWeekend.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.noonHourWeekend);
    this.notifSettingsTimeWeekend.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.eveningHourWeekend);
    this.notifSettingsTimeWeekend.push(s);
    s = this.prepareSetting2Display(this.configuration.settings.notificationSettings.bedHourWeekend);
    this.notifSettingsTimeWeekend.push(s);
  }

  /**
   * set default if empty
   */
  public initAdviceSettings(config: IConfiguration): void {
    if (!config) {
      return;
    }
    if (!config.settings.notificationSettings.adviceSettings) {
      this.adviceDays = 1;
      this.advicePeriods = "morning";
      this.lastGen = null;
      config.settings.notificationSettings.adviceSettings = this.getAdviceSettings();
    } else {
      this.setAdviceSettings(config);
    }
  }

  /**
   * get default advice settings
   */
  public getAdviceSettings(): IAdviceSettings {
    return { adviceFrequency: Number(this.adviceDays), adviceTime: this.advicePeriods, lastGen: this.lastGen };
  }

  /**
   * init advice settings
   */
  public setAdviceSettings(config: IConfiguration): void {
    this.adviceDays = config.settings.notificationSettings.adviceSettings.adviceFrequency;
    this.advicePeriods = config.settings.notificationSettings.adviceSettings.adviceTime;
    this.lastGen = config.settings.notificationSettings.adviceSettings.lastGen;
  }

  /**
   * Convert one parameter to displayable format
   * @param setting
   */
  private prepareSetting2Display(setting: number): string {
    const settingDisplay = setting < 24 ? setting * 60 : setting; // handle old format where time was a full hour (without minutes)
    const mSetting = Tools.getToday().add(settingDisplay, "minutes");
    return mSetting.format("HH:mm");
  }

  /**
   * save Registration data
   */
  public save(): void {
    const configuration = Tools.deepCopy(this.configuration);

    // input transform number into string: revert it back
    for (let i = 0; i < configuration.settings.notificationSettings.scheduledBefore.length; i++) {
      const m = moment(this.notifSettingsScheduledBefore[i], "HH:mm");
      configuration.settings.notificationSettings.scheduledBefore[i] = Tools.toMinSinceMidnigth(m);
    }

    for (let i = 0; i < this.notifSettingsTimeWeek.length; i++) {
      const m = moment(this.notifSettingsTimeWeek[i], "HH:mm");
      switch (i) {
        case 0:
          if (!m.isValid()) configuration.settings.notificationSettings.riseHourWeek = 6 * 60;
          else configuration.settings.notificationSettings.riseHourWeek = Tools.toMinSinceMidnigth(m);
          break;
        case 1:
          if (!m.isValid()) configuration.settings.notificationSettings.morningHourWeek = 7 * 60;
          else configuration.settings.notificationSettings.morningHourWeek = Tools.toMinSinceMidnigth(m);
          break;
        case 2:
          if (!m.isValid()) configuration.settings.notificationSettings.noonHourWeek = 12 * 60;
          else configuration.settings.notificationSettings.noonHourWeek = Tools.toMinSinceMidnigth(m);
          break;
        case 3:
          if (!m.isValid()) configuration.settings.notificationSettings.eveningHourWeek = 19 * 60;
          else configuration.settings.notificationSettings.eveningHourWeek = Tools.toMinSinceMidnigth(m);
          break;
        case 4:
          if (!m.isValid()) configuration.settings.notificationSettings.bedHourWeek = 22 * 60;
          else configuration.settings.notificationSettings.bedHourWeek = Tools.toMinSinceMidnigth(m);
          break;
      }
    }

    for (let i = 0; i < this.notifSettingsTimeWeekend.length; i++) {
      const m = moment(this.notifSettingsTimeWeekend[i], "HH:mm");
      switch (i) {
        case 0:
          if (!m.isValid()) configuration.settings.notificationSettings.riseHourWeekend = 6 * 60;
          else configuration.settings.notificationSettings.riseHourWeekend = Tools.toMinSinceMidnigth(m);
          break;
        case 1:
          if (!m.isValid()) configuration.settings.notificationSettings.morningHourWeekend = 7 * 60;
          else configuration.settings.notificationSettings.morningHourWeekend = Tools.toMinSinceMidnigth(m);
          break;
        case 2:
          if (!m.isValid()) configuration.settings.notificationSettings.noonHourWeekend = 12 * 60;
          else configuration.settings.notificationSettings.noonHourWeekend = Tools.toMinSinceMidnigth(m);
          break;
        case 3:
          if (!m.isValid()) configuration.settings.notificationSettings.eveningHourWeekend = 19 * 60;
          else configuration.settings.notificationSettings.eveningHourWeekend = Tools.toMinSinceMidnigth(m);
          break;
        case 4:
          if (!m.isValid()) configuration.settings.notificationSettings.bedHourWeekend = 22 * 60;
          else configuration.settings.notificationSettings.bedHourWeekend = Tools.toMinSinceMidnigth(m);
          break;
      }
    }

    configuration.settings.notificationSettings.adviceSettings = this.getAdviceSettings();
    // save session check
    configuration.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;

    // save configuration
    this.configService.save(configuration).subscribe(
      () => {
        this.configuration = this.configService.getCacheConfiguration();
        this.notificationsEventsService.generateAll();
      },
      (err) => {
        FileLogger.error("SettingsNotifPageModalComponent", "save", err);
      },
      () => {
        this.dismiss(); // close page
      }
    );
  }

  /**
   *  cancel modal
   */
  public dismiss(): void {
    this.modalController.dismiss();
  }
}

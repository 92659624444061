import { Injectable } from "@angular/core";
import * as moment from "moment";
import { from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { AppConstants } from "src/app/appConstants";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ICheckSum, ICheckSumResponse } from "src/app/models/checksum";
import { IApiResponse } from "src/app/models/iapi-response";
import { ApiService } from "../../api.service";
import { InfoAppService } from "../../info-app.service";
import { NetworkService } from "../../network.service";
import { LocalStorageService } from "../../storage/local-storage.service";
import { BasicSyncService } from "./basic-sync.service";
import { RequestSenderService } from "./request-sender.service";

@Injectable({
  providedIn: "root",
})
export class RequiredSynchroService {
  private requiredSynchroRoute = "/requiredSynchro";
  private entityPrefix = "requiredSynchro";

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private networkService: NetworkService,
    private requestSenderService: RequestSenderService,
    private infoService: InfoAppService
  ) {}

  public async run(services: BasicSyncService<any, any[] | any>[]): Promise<Observable<ICheckSumResponse[]>> {
    const filteredServices = services.filter((s) => s.getUrl() !== null);
    const allNeedSynchro: ICheckSumResponse[] = filteredServices.map((service) => {
      return {
        nameRoute: service.getUrl(),
        needSync: true,
        requestDate: moment().format(),
        success: true,
      } as ICheckSumResponse;
    });

    const allNoNeedSynchro: ICheckSumResponse[] = filteredServices.map((service) => {
      return {
        nameRoute: service.getUrl(),
        needSync: false,
        requestDate: moment().format(),
        success: true,
      } as ICheckSumResponse;
    });

    if (!this.infoService.isCordova() && !AppConstants.LOCAL_DEV_MODE) {
      filteredServices.map((service) => {
        service.needRefresh.value = true;
      });
      return of(allNeedSynchro);
    }

    if (this.networkService.isCurrentOffline()) {
      return of(allNoNeedSynchro);
    }
    try {
      await this.requestSenderService.sync();
      const checksums: ICheckSum[] = await Promise.all(filteredServices.map((s) => s.checkSum()));
      const apiRequireSyncObs: Observable<ICheckSumResponse[]> = this.createChecksumsApiRequest(checksums, allNeedSynchro);
      return apiRequireSyncObs;
    } catch (err) {
      // problem with the route : the server is surely unreachable ; we block the other routes -> no synchro
      FileLogger.error("RequiredSynchroService", "ERROR requiredSynchro : ", JSON.stringify(err));
      const allSave = this.saveAllChecksumRespPromise(allNoNeedSynchro);
      const obsSave = from(allSave).pipe(
        map(() => {
          return allNoNeedSynchro;
        }),
        catchError(() => {
          return of(allNoNeedSynchro);
        })
      );
      return obsSave;
    }
  }

  private createChecksumsApiRequest(checkSums: ICheckSum[], allNeedSynchro: ICheckSumResponse[]): Observable<ICheckSumResponse[]> {
    const apiRequestObs: Observable<IApiResponse> = this.apiService.post(this.requiredSynchroRoute, checkSums);
    const checksumResponsesObs: Observable<ICheckSumResponse[]> = apiRequestObs.pipe(
      concatMap((response) => {
        if (response.success) {
          const data = response.data as ICheckSumResponse[];
          const allSave = this.saveAllChecksumRespPromise(data);
          const obsSave = from(allSave).pipe(
            map(() => {
              return data;
            }),
            catchError(() => {
              return of(data);
            })
          );
          return obsSave;
        } else {
          return of(allNeedSynchro);
        }
      })
    );
    return checksumResponsesObs;
  }

  private saveAllChecksumRespPromise(data: ICheckSumResponse[]): Promise<unknown[]> {
    const allSave = Promise.all(
      data.map((checkSumResponse) => {
        return this.save(checkSumResponse).catch((err) => {
          FileLogger.log("RequiredSynchroService", "RequiredSynchroService error during the save : ", err);
          return null;
        });
      })
    );
    return allSave;
  }

  public async needSynchro(service: BasicSyncService<any, any[] | any>): Promise<boolean> {
    if (this.networkService.isCurrentOffline() || !service.getUrl()) {
      return false;
    }
    const checkSumResponse = await this.getCheckSumResponse(service);
    return this.checkSumResponseNeedSynchro(checkSumResponse);
  }

  public async needSynchroAndRunIfNecessary(service: BasicSyncService<any, any[] | any>): Promise<boolean> {
    if (this.networkService.isCurrentOffline() || !service.getUrl()) {
      return false;
    }
    const rep = await this.getCheckSumResponse(service);
    if (!rep || moment(rep.requestDate).add(service.synchroTime, "minute").isSameOrBefore(moment(), "minute")) {
      return new Promise<boolean>((resolve, reject) => {
        let checkSum: ICheckSumResponse;
        this.run([service]).then((obsCheckSumResponse: Observable<ICheckSumResponse[]>) => {
          obsCheckSumResponse.subscribe(
            (checkSumResponse) => {
              if (checkSumResponse) {
                checkSum = checkSumResponse[0];
              }
            },
            (err) => {
              return reject(err);
            },
            () => {
              if (checkSum) {
                return resolve(this.checkSumResponseNeedSynchro(checkSum));
              } else {
                return resolve(true);
              }
            }
          );
        });
      });
    } else {
      return this.checkSumResponseNeedSynchro(rep);
    }
  }

  public async updateRequestDate(service: BasicSyncService<any, any[] | any>): Promise<any> {
    const newRep = {
      nameRoute: service.getUrl(),
      needSync: false,
      requestDate: moment().format(),
      success: true,
    } as ICheckSumResponse;

    return this.save(newRep);
  }

  private checkSumResponseNeedSynchro(checkSumResponse: ICheckSumResponse): boolean {
    return checkSumResponse && checkSumResponse.needSync;
  }

  private save(checkSumResponse: ICheckSumResponse): Promise<any> {
    return this.localStorageService.setData(this.key(checkSumResponse.nameRoute), JSON.stringify(checkSumResponse), true);
  }

  private async getCheckSumResponse(service: BasicSyncService<any, any[] | any>): Promise<ICheckSumResponse> {
    try {
      if (!service.getUrl()) {
        return null;
      }
      const rep = await this.localStorageService.getData(this.key(service.getUrl()), true);
      return JSON.parse(rep) as ICheckSumResponse;
    } catch (err) {
      return null;
    }
  }

  private key(nameRoute: string): string {
    let route = nameRoute;
    // the day stream observation service's route name change every minute
    // in order not to have an infinite number of checksum saved under a different key for it,
    // we remove the time info from the route name in the key:
    if (route.includes("&day=")) {
      const split = route.split("&");
      const f = split.filter((s) => !s.startsWith("day="));
      route = f.join("&");
    }
    return this.entityPrefix + route;
  }
}

import { Reference } from "src/app/models/sharedInterfaces";
import * as moment from "moment";
import { Tools } from "../tools-helper";
import { FileLogger } from "../fileLogger";

/**
 * Gather observations and utility function for a single component and its historic
 */
export class Observations {
  public _LOINC: Reference; // component loinc code
  public _RAW: SimpleObservation[]; // raw ordered array with components from observations
  public CURRENT: SimpleObservation; // current value entered
  public VALUES: number[]; // ordered array with component value only

  /****************************************************************************************************
   * Collect past days observations
   *
   *  "dayIndex" is the index in the past: '1' means yesterday, '2' means the day before yesterday, ...
   *  as it can exists more than 1 observation in a day, a "filter" can be applied.
   *      "filter" can be:
   *          undefined: return last one in the day
   *          FIRST: return first one
   *          LAST: same as 'undefined'
   *          MAX: return max value
   *          MIN: return min value
   *          AVG: return average value
   *
   */
  public getDay = (dayIndex: number, filter?: string): number => {
    try {
      const arrValues: number[] = [];
      // collect values
      const pastMoment = moment().add(-Math.abs(dayIndex), "days");
      for (const co of this._RAW) {
        if (moment(co.issued).isSame(pastMoment, "day")) {
          arrValues.push(co.value);
        }
      }
      if (!arrValues?.length) return undefined;
      if (!filter?.length) return arrValues[arrValues.length - 1]; // same as 'LAST'
      // compute values
      switch (filter) {
        case "FIRST":
          return arrValues[0]; // first value
        case "MAX":
          return Math.max(...arrValues); // max
        case "MIN":
          return Math.min(...arrValues); // min
        case "AVG":
          return Tools.mean(arrValues); // average
        case "LAST":
        default:
          return arrValues[arrValues.length - 1]; // same as empty
      }
    } catch (err) {
      FileLogger.error("Observations", "getDay", err);
      return undefined;
    }
  };

  /**
   * Collect past days observations for a period of time
   */
  public getPeriod = (dayIndexStart: number, dayIndexEnd: number, filter?: string): number => {
    try {
      const arrValues: number[] = [];
      // collect values
      const pastStartMoment = moment().add(-Math.abs(dayIndexStart), "days");
      const pastEndMoment = moment().add(-Math.abs(dayIndexEnd), "days");
      for (const co of this._RAW) {
        if (moment(co.issued).isSameOrBefore(pastStartMoment, "day") && moment(co.issued).isSameOrAfter(pastEndMoment, "day")) {
          arrValues.push(co.value);
        }
      }
      if (!arrValues?.length) return null as number;
      if (!filter?.length) return arrValues[arrValues.length - 1]; // same as 'LAST'
      // compute values
      switch (filter) {
        case "FIRST":
          return arrValues[0]; // first value
        case "MAX":
          return Math.max(...arrValues); // max
        case "MIN":
          return Math.min(...arrValues); // min
        case "AVG":
          return Tools.mean(arrValues); // average
        case "LAST":
        default:
          return arrValues[arrValues.length - 1]; // same as empty
      }
    } catch (err) {
      FileLogger.error("Observations", "getPeriod", err);
      return null;
    }
  };

  /**
   * Constructor
   *
   */
  constructor(loinc: Reference) {
    this._LOINC = loinc;
    this._RAW = [];
    this.VALUES = [];
  }
}

/**
 * Simple IObservation with only useful fields
 */
export interface SimpleObservation {
  code: string; // loinc component code
  issued: string; // observation date
  value: number; // value entered by patient
  effectiveTiming?: string; // code of when the observation was taken (optionnal)
}

/**
 * A class to handle Observations object in a dictionnary
 */
export class DictionnaryObservations {
  [key: string]: Observations;
}

import { Injectable } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { HtmlAlertModalComponent } from "src/app/components/modals/html-alert-modal/html-alert-modal.component";
import { ComputeDrugsService } from "src/app/services/compute-drugs.service";
import { IRule, IRuleDefinition, IRuleResult, RESULT_TYPE, RULE_CATEGORY, RULE_TYPE_TARGET } from "../../models/rule";
import { Reference, RULE_TARGET_TYPE } from "../../models/sharedInterfaces";
import { AccountService } from "../../services/globalDataProvider/account.service";
import { ConfigurationService } from "../../services/globalDataProvider/configuration.service";
import { DrugService } from "../../services/globalDataProvider/drug.service";
import { LanguagesService } from "../../services/globalDataProvider/languagesService";
import { NotificationsDrugsIntakeService } from "../../services/globalDataProvider/notifications-drugs-intake.service";
import { RulesAlertService } from "../../services/globalDataProvider/rules-alert.service";
import { RulesService } from "../../services/globalDataProvider/rules.service";
import { VitalProfileDefinitionsService } from "../../services/globalDataProvider/vital-profile-definitions.service";
import { InfoAppService } from "../../services/info-app.service";
import { Account } from "../account-helper";
import { ArrayHelper } from "../array-helper";
import { FileLogger } from "../fileLogger";
import { IObservation, IObservationDefinition, IObservationDefinitionComponent } from "../observation-helper";
import { Tools } from "../tools-helper";
import { IRuleAlert, RuleAlertHelper } from "../trigger-rule-helper-engine/ruleAlert-helper";
import { TriggerRuleHelperObservationService } from "../trigger-rule-helper/trigger-rule-helper-observation.service";
@Injectable({
  providedIn: "root",
})
export class RuleHelperObservationService {
  private SOURCE = "RuleHelperObservationService";
  private allObservations: IObservation[];
  private ruleHelper: TriggerRuleHelperObservationService;
  public alertModal: HTMLIonAlertElement;

  /**
   * Constructor
   */
  constructor(
    protected settingsService: InfoAppService,
    protected translateSvc: TranslateService,
    protected alertCtrl: AlertController,
    private configService: ConfigurationService,
    private accountService: AccountService,
    private rulesService: RulesService,
    private rulesAlertService: RulesAlertService,
    private languagesService: LanguagesService,
    private vitalSignDefService: VitalProfileDefinitionsService,
    private drugsService: DrugService,
    private drugIntakeService: NotificationsDrugsIntakeService,
    private modalCtrl: ModalController,
    private computeDrugService: ComputeDrugsService
  ) {}

  /**
   * Check if a Rule is an observation alert
   * @param rule
   */
  private isObservationAlertRule(rule: IRule): boolean {
    try {
      return rule.meta.category === RULE_CATEGORY.ALERT && rule.meta.typeTarget === RULE_TYPE_TARGET.OBSERVATION;
    } catch (err) {
      return false;
    }
  }

  /**
   * Check of rule contains observation component as target
   */
  private isRuleCanBeAppliedOn(rule: IRule, observation: IObservation): boolean {
    try {
      for (const c of observation.component) {
        for (const target of rule.meta.targets) {
          if (c.code.coding[0].code === target.reference) return true;
        }
      }
    } catch (err) {
      FileLogger.log(this.SOURCE, "isRuleCanBeAppliedOn", err);
    }
    return false;
  }

  /**
   * Check of rule contains drugs component as target
   */
  private isRuleCanBeAppliedOnDrugs(targets: Reference[]): boolean {
    try {
      if (!Array.isArray(targets)) {
        return false;
      }
      return targets.findIndex((target) => target.type === RULE_TARGET_TYPE.DRUGS) >= 0;
    } catch (err) {
      FileLogger.log(this.SOURCE, "isRuleCanBeAppliedOn", err);
    }
    return false;
  }

  /**
   * Check if rules applies to this component value entered
   */
  public async checkObservationAlertRules(currentObs: IObservation, allObservations: IObservation[]): Promise<IRuleAlert[]> {
    const ruleAlerts = new Array<IRuleAlert>();
    this.allObservations = allObservations;
    FileLogger.log(this.SOURCE, "checkObservationAlertRules for observation", currentObs);
    FileLogger.log(this.SOURCE, "checkObservationAlertRules", "service initialized");
    // process rules
    for (const rule of this.rulesService.peekData()) {
      if (this.isObservationAlertRule(rule) && this.isRuleCanBeAppliedOn(rule, currentObs)) {
        FileLogger.log(this.SOURCE, "Check new rule", (rule as any)._description);

        const isDrugs = this.isRuleCanBeAppliedOnDrugs(rule?.meta?.targets);

        const drugsIntake = isDrugs
          ? // we will only look for drugIntake if the rule is associated with a drug
            (this.drugIntakeService.peekData()?.length > 0
              ? this.drugIntakeService.peekData()
              : await this.drugIntakeService.getFirstDataAvailable()) ?? []
          : [];

        const drugs = isDrugs
          ? // we will only look for drugIntake if the rule is associated with a drug
            (this.drugsService.peekData()?.length > 0 ? this.drugsService.peekData() : await this.drugsService.getFirstDataAvailable()) ??
            []
          : [];

        this.ruleHelper = new TriggerRuleHelperObservationService(
          this.accountService.cachedAccount,
          rule.meta.targets,
          this.allObservations,
          currentObs,
          this.vitalSignDefService.peekData(),
          drugsIntake,
          drugs,
          this.computeDrugService
        );
        const ruleTriggered = this.isAlertTriggered(rule /*, currentObs*/);
        // build Rule Alert based on Rule Definition
        if (ruleTriggered) {
          const ruleAlert = RuleAlertHelper.asRuleAlertForObservation(rule, ruleTriggered, currentObs, this.accountService.cachedAccount);
          FileLogger.log(this.SOURCE, "Rule Alert generated", ruleAlert);
          await this.ruleHelper.transformMessageTemplateIfNeeded(ruleAlert, await this.languagesService.listOfKeys());
          ruleAlerts.push(ruleAlert);
        }
      }
    }
    return ruleAlerts;
  }

  /**
   * Check if a rule need user profile physiological data to be computed AND is this data is present
   *  If not, request user to fill data
   * Return LOINC code missing in user vital profile
   */
  public checkUserProfile(currentObs: IObservation): Promise<string[]> {
    let loincCodes = new Array<string>();
    for (const rule of this.rulesService.peekData()) {
      if (this.isObservationAlertRule(rule) && this.isRuleCanBeAppliedOn(rule, currentObs)) {
        for (const ruleDefinition of rule.definitions) {
          if (ruleDefinition.triggerFormula) {
            loincCodes = loincCodes.concat(this.lsLoincCodes(ruleDefinition.triggerFormula));
          }
          if (ruleDefinition.results?.length) {
            ruleDefinition.results.forEach((r) => {
              if (r.variableToInterpolate) {
                loincCodes = loincCodes.concat(this.lsLoincCodes(r.variableToInterpolate));
              }
            });
          }
          if (ruleDefinition.resultsPractitionner?.length) {
            ruleDefinition.resultsPractitionner.forEach((r) => {
              if (r.variableToInterpolate) {
                loincCodes = loincCodes.concat(this.lsLoincCodes(r.variableToInterpolate));
              }
            });
          }
        }
      }
    }

    loincCodes = loincCodes.filter(ArrayHelper.onlyUnique);

    const loincCodes2Request: string[] = [];
    for (const code of loincCodes) {
      const vitalQuantity = Account.getVital(this.accountService.cachedAccount, code);
      FileLogger.log(this.SOURCE, "checkUserProfile - vitalQuantity", `${this.accountService.cachedAccount}, ${vitalQuantity}`);
      // is it filled or not ?
      if (!vitalQuantity || (!vitalQuantity.value && !vitalQuantity.valueArray)) {
        loincCodes2Request.push(code);
      }
    }
    return Promise.resolve(loincCodes2Request);
  }

  /**
   *
   * @param formula
   * @returns the list of loinc code related to the variable PATIENT
   */
  private lsLoincCodes(formula: string): string[] {
    const loincCodes = new Array<string>();
    if (formula && formula.indexOf("PATIENT") >= 0) {
      // let formula = "OBSERVED['3141-9'].CURRENT.value / (PATIENT['LOINC_TAILLE'] * PATIENT['LOINC_TAILLE']) > 40";
      // look for Loinc code
      const words = formula.split("PATIENT");
      for (const word of words) {
        if (word && word[0] && word[0] === "[") {
          const endIndex = word.indexOf("]");
          if (endIndex >= 0) {
            const loincCode = word.substring(2, endIndex - 1);
            if (loincCode) {
              loincCodes.push(loincCode);
            }
          }
        }
      }
    }
    return loincCodes;
  }

  /**
   * Display info message for missing physiological data in user vital profile
   */
  /*public getMissingProfileDataMessage(loincCodes2Request: string[]): string {
      let message = this.translateSvc.instant("myprofile.missingData") + ": ";
      for (let code of loincCodes2Request) {
          message += this.translateSvc.instant("myobservations.codes." + code) + "\r\n";
      }
      return message;
  }*/

  /**
   * Check if this rule is trigger by value entered
   */
  private isAlertTriggered(rule: IRule /*, currentObs: IObservation*/): IRuleDefinition {
    const triggeredRules = new Array<IRuleDefinition>();
    // let currentValue = component.valueQuantity.value;
    // loop on rule definition (LOW,MEDIUM,HIGH)
    for (const ruleDefinition of rule.definitions) {
      // loop on triggers for each definitions
      // all triggers must be "activated" for the definition to be engaged
      let allTriggered = true;
      if (ruleDefinition.triggerFormula) {
        // trigger is a "simple" javascript code
        allTriggered = this.ruleHelper.triggerFormulaEvaluation(ruleDefinition.triggerFormula);
      }
      /*else {  // trigger contains parameters for algorithm
          for (let trigger of ruleDefinition.triggers) {
              let triggered = false;
              // which kind of computation ?
              switch (trigger.computation) {
                  case TRIGGER_COMPUTATION.VALUE: {       // **** trigger directly on value entered ****
                      if (!_.isNil(trigger.bounds)) { // check bounds limit
                          let lowBound = false;
                          let highBound = false;
                          if (currentValue >= _.toNumber(trigger.bounds.low)) lowBound = true;
                          if (!_.isNil(trigger.bounds.high)) {
                              if (currentValue < _.toNumber(trigger.bounds.high)) highBound = true;
                          }
                          else highBound = true;    // high bound is not set
                          triggered = lowBound && highBound; // is it triggered ?
                      }
                      break;
                  }
                  case TRIGGER_COMPUTATION.COMPUTED: {    // **** trigger on computed values ****
                      if (!_.isNil(trigger.formula) && !_.isEmpty(trigger.formula.fields)) {
                          // loop on formula fields
                          let formulaResult = 0;
                          let inapplicableFormula = false;
                          for (let field of trigger.formula.fields) {
                              switch (field.fieldType) {
                                  case FIELD_TYPE.ABSOLUTE_VALUE: {     // field is an absolute value
                                      formulaResult = this.computeFormula(formulaResult, field.operator, field.value);
                                      break;
                                  }
                                  case FIELD_TYPE.PAST_VALUE: {   // field is based on past value
                                      let pastComponentValue = this.getPastComponentValue(component.code.coding[0].code, field.value);
                                      if (_.isNil(pastComponentValue)) inapplicableFormula = true; // no past value
                                      else formulaResult = this.computeFormula(formulaResult, field.operator, pastComponentValue);
                                      break;
                                  }
                                  case FIELD_TYPE.REFERENCE_VALUE: {   // field is base on a reference value
                                      // TODO
                                      break;
                                  }
                                  case FIELD_TYPE.CURRENT_VALUE: {    //  field is the current entered value
                                      formulaResult = this.computeFormula(formulaResult, field.operator, currentValue);
                                      break;
                                  }
                              }
                          }
                          // compare formula result to trigger bounds
                          if (!_.isNil(trigger.bounds) && !inapplicableFormula) { // check bounds limit (if formula is applicable)
                              let lowBound = false;
                              let highBound = false;
                              if (formulaResult >= _.toNumber(trigger.bounds.low)) lowBound = true;
                              if (!_.isNil(trigger.bounds.high)) {
                                  if (formulaResult < _.toNumber(trigger.bounds.high)) highBound = true;
                              }
                              else highBound = true;    // high bound is not set
                              triggered = lowBound && highBound; // is it triggered ?
                          }
                      }
                      break;
                  }
              }
              if (!triggered) {   // all trigger must be activate for a Rule Definition to be engaged
                  allTriggered = false;
                  break;
              }
          }
      }*/
      if (allTriggered) {
        if (!ruleDefinition.repeatOnly) {
          triggeredRules.push(ruleDefinition); // all triggers are activated, add this Rule Definition to be engaged list
        } else {
          // repeatOnly > 0 -> the alert does not necessarily have to be pushed
          // this alert is push only if the last similar alert is enough old
          // we use cache alert to improve speed.
          const lastAlert = this.rulesAlertService.getLastCacheAlert(rule.identifier.value, ruleDefinition);

          const unit = "days"; // "days"  "minutes"
          if (!lastAlert || moment(lastAlert.creation).add(ruleDefinition.repeatOnly, unit).isSameOrBefore(moment(), unit)) {
            triggeredRules.push(ruleDefinition);
          }
        }
      }
    }
    // There can be more than 1 Rule Definition engaged, choose the one with highest level
    let ruleEngaged: IRuleDefinition = null;
    for (const ruleDef of triggeredRules) {
      if (!ruleEngaged) {
        ruleEngaged = ruleDef;
        continue;
      }
      if (ruleDef.level > ruleEngaged.level) ruleEngaged = ruleDef; // set higher level rule
    }
    FileLogger.log(this.SOURCE, "rule triggered", `${triggeredRules}, ${ruleEngaged}`);
    return ruleEngaged; // can be null
  }

  /**
   * Return component value from "pastDays" ago observation
   * @param componentCode
   */
  /*private getPastComponentValue(componentCode: string, pastDays: string): number {
      if (_.isNil(this.allObservations)) return null; // no past observations
      let numberPastDays = _.toNumber(pastDays);
      if (_.isNaN(pastDays) || _.isNil(pastDays)) return null;
      try {
          let pastMoment = moment().add(-Math.abs(numberPastDays), "days");
          for (let observation of this.allObservations) {
              // same day
              if (moment(observation.issued).isSame(pastMoment, "day")) {
                  for (let component of observation.component) {
                      // same component
                      if ((component.code.coding[0].code === componentCode)) {
                          return component.valueQuantity.value;
                      }
                  }
              }
              // went to far, did not found observation for correct past day, take the first one before
              if (moment(observation.issued).isBefore(pastMoment, "day")) {
                  for (let component of observation.component) {
                      // same component
                      if ((component.code.coding[0].code === componentCode)) {
                          return component.valueQuantity.value;
                      }
                  }
              }
          }
      }
      catch (err) {
      }
      return null;
  }*/

  /**
   * Process 2 fields of a formula
   * @param valueX
   * @param operator
   * @param valueY
   */
  /*private computeFormula(valueX: number, operator: RULE_FORMULA_OPERATOR, valueY: any): number {
      try {
          let numValueY = _.toNumber(valueY);
          if (_.isNaN(numValueY)) return valueX; // valueY is not a number
          // compute
          switch (operator) {
              case RULE_FORMULA_OPERATOR.PLUS: return valueX + numValueY;
              case RULE_FORMULA_OPERATOR.MINUS: return valueX - numValueY;
              case RULE_FORMULA_OPERATOR.MULTIPLY: return valueX * numValueY;
              case RULE_FORMULA_OPERATOR.DIVISION: return valueX / numValueY;
              default: return valueX;
          }
      }
      catch (err) {
          return valueX;
      }
  }*/

  /**
   * Display multiple alert in a single popup
   * @param ruleAlerts
   */
  public async displayRuleAlerts(ruleAlerts: IRuleAlert[]): Promise<void> {
    // gather messages
    let messages = "";
    const isHTML: boolean[] = [];
    for (const alert of ruleAlerts) {
      for (const ruleResult of alert.rule.results) {
        const identifierValue = alert.identifier && alert.identifier.length ? alert.identifier[0].value : "";
        const msg = this.ruleHelper.getAlertMessageTranslated(ruleResult, identifierValue, this.configService.getCurrentLanguage());
        if (msg && msg.length) messages += "<br>" + msg;
        if (ruleResult.resultType === RESULT_TYPE.MESSAGE) {
          isHTML.push(false);
        } else if (ruleResult.resultType === RESULT_TYPE.HTML) {
          isHTML.push(true);
        }
      }
    }

    if (isHTML.map((v) => v === true)?.length > 0) {
      const modal = await this.modalCtrl.create({
        component: HtmlAlertModalComponent,
        componentProps: {
          title: this.translateSvc.instant("myobservations.alert"),
          content: messages,
        },
      });
      return modal.present();
    } else {
      this.alertModal = await this.alertCtrl.create({
        header: this.translateSvc.instant("myobservations.alert"),
        message: messages,
        buttons: ["OK"],
      });
      return this.alertModal.present();
    }
  }

  /**
   * Dismiss the alertModal displayed with displayRuleAlerts()
   */
  public dismissRuleAlerts(): Promise<boolean> {
    return this.alertModal?.dismiss();
  }

  /**
   * @param observation
   * @returns true if there exists a rule linked to this observation that needs a reference value ; false otherwise
   */
  public ruleNeedVitalSign(observation: IObservation): boolean {
    const def = this.rulesService
      .peekData()
      ?.filter((r) => this.isObservationAlertRule(r) && this.isRuleCanBeAppliedOn(r, observation))
      .map((r) => r.definitions)
      .reduce((acc, el) => acc.concat(el), []);

    // in the rule, a vital sign is called with the keyword PATIENT
    const inTriggerFormula = def.some((d) => d.triggerFormula?.includes("PATIENT"));
    const inResult = def.some((d) => d.results?.some((r) => r.variableToInterpolate?.includes("PATIENT")));
    const inResultPractitionner = def.some((d) => d.resultsPractitionner?.some((r) => r.variableToInterpolate?.includes("PATIENT")));

    return inTriggerFormula || inResult || inResultPractitionner;
  }

  /**
   * @param obs a definition
   * @returns LOINC code missing in user vital profile in the recommendation
   */
  public checkUserProfileForRecommendation(obs: IObservationDefinition): string[] {
    return obs.components
      .filter((comp) => Tools.isDefined(comp.recommendation))
      .map((comp) => comp.recommendation)
      .reduce((r1, r2) => r1.concat(r2), [])
      .filter((r) => Tools.isDefined(r.message))
      .map((m) => m.message)
      .reduce((r1, r2) => r1.concat(r2), [])
      .filter((r) => Tools.isDefined(r.variableToInterpolate))
      .map((r) => this.lsLoincCodes(r.variableToInterpolate))
      .reduce((r1, r2) => r1.concat(r2), [])
      .filter(ArrayHelper.onlyUnique)
      .filter((code) => {
        const vitalQuantity = Account.getVital(this.accountService.cachedAccount, code);
        return !vitalQuantity || (!vitalQuantity.value && !vitalQuantity.valueArray);
      });
  }

  /**
   *
   * @param obsDef
   * @param currentObs
   * @param allObservations
   * @returns
   */
  public async getMessagesRecommendation(
    obsDef: IObservationDefinitionComponent,
    currentObs: IObservation,
    allObservations: IObservation[]
  ): Promise<IRuleResult[]> {
    if (Tools.isNotDefined(obsDef?.recommendation?.message)) return null;
    const message = Tools.deepCopy(obsDef.recommendation.message);
    const target = obsDef.recommendation.targets ? obsDef.recommendation.targets : [];

    const isDrugs = this.isRuleCanBeAppliedOnDrugs(obsDef.recommendation?.targets);

    const drugsIntake = isDrugs
      ? // we will only look for drugIntake if the rule is associated with a drug
        (this.drugIntakeService.peekData()?.length > 0
          ? this.drugIntakeService.peekData()
          : await this.drugIntakeService.getFirstDataAvailable()) ?? []
      : [];

    const drugs = isDrugs
      ? // we will only look for drugIntake if the rule is associated with a drug
        (this.drugsService.peekData()?.length > 0 ? this.drugsService.peekData() : await this.drugsService.getFirstDataAvailable()) ?? []
      : [];

    this.ruleHelper = new TriggerRuleHelperObservationService(
      this.accountService.cachedAccount,
      target,
      allObservations,
      currentObs,
      this.vitalSignDefService.peekData(),
      drugsIntake,
      drugs,
      this.computeDrugService
    );
    await this.ruleHelper.transformMessageTemplateIfNeededFromResults(message, await this.languagesService.listOfKeys());
    return message;
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AppConstants } from "src/app/appConstants";
import { ICheckSum } from "src/app/models/checksum";
import { DataParameter } from "src/app/models/dataParameters";
import { HashService } from "../../hash.service";
import { DataService } from "./data.service";

export interface INeedRefresh {
  _needRefresh: {
    value: boolean;
  };
}
@Injectable({
  providedIn: "root",
})
export abstract class BasicSyncService<E extends object, T extends Array<E> | E> {
  needNotifGeneration = true;
  synchroTime = AppConstants.REQUIRED_SYNCHRO_TIME;
  public abstract get needRefresh(): {
    value: boolean;
  };

  protected defaultDataParameter: DataParameter;
  protected data$: BehaviorSubject<T>;

  public get entityStoreKey() {
    return this.defaultDataParameter.entityStoreKey;
  }

  public get serviceId() {
    return this.defaultDataParameter.entityPrefix + this.defaultDataParameter.entityStoreKey;
  }

  constructor(protected dataService: DataService) {
    this.setupDataParameters();
    this.clearWatch();
  }

  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
  public watchData(): Observable<T> {
    return this.data$;
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(): T {
    return this.data$.value;
  }
  /**
   * Tell all those that watch the service's data that there's a new version
   * @param data the new data
   */
  public pokeData(data: T): void {
    this.data$.next(data);
  }

  public getUrl() {
    return this.defaultDataParameter.getUrl;
  }

  protected getParamObject(): DataParameter {
    const paramObject = Object.assign({}, this.defaultDataParameter);
    paramObject.getUrl = this.getUrl();
    paramObject.entityStoreKey = this.entityStoreKey;
    return paramObject;
  }

  /**
   * Empty the data contained inside the service
   */
  public async init(): Promise<void> {
    this.needRefresh.value = true;
    this.needNotifGeneration = true;
    this.initWatch();
  }

  /**
   * Put the service back to the state it was in when just constructed
   */
  public clear(): void {
    this.needRefresh.value = true;
    this.needNotifGeneration = true;
    this.clearWatch();
  }

  /**
   * Reinit the data behavior subject
   */
  protected abstract clearWatch(): void;
  /**
   * Empty the data inside the data's behavior subject
   */
  protected abstract initWatch(): void;

  protected abstract setupDataParameters(): void;

  /**
   * This should return all the other services needRefresh (Boolean object) that depend on the current one and
   * need to be refreshed if the current one the current one is.
   * (ex: the knowledgeService depends on the careplan service)
   */
  public getDependentServicesRefresh(): { value: boolean }[] {
    return [];
  }

  /**
   * Return a data reader that will first read try to read the local data
   * then try to get the online data.
   */
  public async *getDataReader(): AsyncGenerator<T, T, T> {
    // Note: every error should have been caught inside the read function,
    // so don't worry even thought there's no try/catch
    const paramObject = this.getParamObject();
    const dataReader = this.dataService.readv2<E, T>(paramObject, false, this);
    let d: T;
    for await (const data of dataReader) {
      d = data;
      yield data;
    }
    return d as Awaited<T>;
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(): Promise<T> {
    const dataReader = this.getDataReader();
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(): Promise<T> {
    const dataReader = this.getDataReader();
    const iterator = await dataReader.next();
    return iterator.value;
  }

  public async checkSum(): Promise<ICheckSum> {
    const paramObject = this.getParamObject();
    const dataReader: AsyncGenerator<T, T, T> = this.dataService.readv2<E, T>(paramObject, true);
    const localDataIt = await dataReader.next();
    const localData = localDataIt.value;
    return {
      checkSum: HashService.getMd5HashOfObject(localData, ["actionStatus"]),
      nameRoute: paramObject.getUrl,
    } as ICheckSum;
  }
}

import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import * as moment from "moment";
import { AppConstants } from "src/app/appConstants";
import { LocalStorageError, LocalStorageErrorType } from "./Local-storag-error.service";
import { Aes256Service } from "./aes256.service";
import { LocalStorageInitService } from "./local-storage-init.service";

export interface StoredWithTime {
  date: moment.Moment;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class LocalStorageService extends LocalStorageInitService {
  private static _hashCaremateIdentifier: string = null;

  public static get hashCaremateIdentifier(): string {
    return LocalStorageService._hashCaremateIdentifier;
  }
  public static set hashCaremateIdentifier(id: string) {
    LocalStorageService._hashCaremateIdentifier = id;
  }

  // list of partials keys not to transform if a key starts with it
  private static specificPartialKeys: string[] = [AppConstants.TRANSLATIONS_KEYS];

  private static specificKey = [
    // list of keys to not transform
    AppConstants.SYS_ACCOUNT,
    AppConstants.PRM_LAST_LOGIN,
    AppConstants.APP_MODE,
    AppConstants.SOMEONE_LOGIN,
    AppConstants.KEY_SECURESTORAGE_NOT_ACTIVE,
    AppConstants.IONIC5CLEAR,
    AppConstants.NEW_INSTALLATION_FROM_STORE,
    AppConstants.LANGUAGES_LIST,
    AppConstants.LAST_LANGUAGE,
  ];

  private static keyTransform(key: string): string {
    const noNeedTransform =
      LocalStorageService.specificKey.includes(key) ||
      LocalStorageService.specificPartialKeys.findIndex((k: string) => key.includes(k)) > -1;
    return noNeedTransform ? key : LocalStorageService.hashCaremateIdentifier + key;
  }

  public static async removeSeveralKeys(keys: string[]) {
    if (LocalStorageInitService._storage !== null) {
      const removePromises = [];
      for (const key of keys) {
        const keyT = LocalStorageService.keyTransform(key);
        removePromises.push(LocalStorageInitService._storage.remove(keyT));
      }
      await Promise.all(removePromises);
    }
  }

  public static async clearAllKeysStartingWithExcept(partialKey: string, exception: string) {
    if (LocalStorageInitService._storage !== null) {
      const keys = await LocalStorageInitService._storage?.keys();
      const partialKeyT = LocalStorageService.keyTransform(partialKey);
      for (const k of keys) {
        if (k !== exception && k.startsWith(partialKeyT)) {
          await LocalStorageInitService._storage?.remove(k);
        }
      }
    }
  }

  public static async getAllKeysStartingWith(partialKey: string): Promise<string[]> {
    const result: string[] = [];
    if (LocalStorageInitService._storage !== null) {
      const keys = await LocalStorageInitService._storage?.keys();
      const partialKeyT = LocalStorageService.keyTransform(partialKey);
      for (const k of keys) {
        if (k.startsWith(partialKeyT)) {
          result.push(k);
        }
      }
    }
    return result;
  }

  constructor(private aes256Service: Aes256Service, storageIonic: Storage) {
    super(storageIonic);
  }

  public async setData(key: string, data: string, encrypted: boolean): Promise<any> {
    const keyT = LocalStorageService.keyTransform(key);

    if (await this.isReady()) {
      if (encrypted) {
        data = await this.aes256Service.encrypt(data);
      }
      return LocalStorageInitService._storage?.set(keyT, data);
    }
  }

  public async getData(key: string, encrypted: boolean, _expirationDays?: number): Promise<any> {
    const keyT = LocalStorageService.keyTransform(key);

    if (await this.isStored(keyT)) {
      let localData: string = await LocalStorageInitService._storage?.get(keyT);
      if (encrypted) {
        localData = await this.aes256Service.decrypt(localData);
      }
      return localData;
    } else {
      throw new LocalStorageError("Data " + key + " is not stored", LocalStorageErrorType.NOT_IN_STORAGE);
    }
  }

  public async isStored(key: string, alreadyTransformed = true) {
    if (await this.isReady()) {
      const keyT = alreadyTransformed ? key : LocalStorageService.keyTransform(key);
      const keys = await LocalStorageInitService._storage?.keys();
      return keys.includes(keyT);
    }
  }

  public async clearStorage() {
    if (await this.isReady()) {
      await LocalStorageInitService._storage?.clear();
    }
  }

  public async remove(key: string) {
    if (await this.isReady()) {
      const keyT = LocalStorageService.keyTransform(key);
      return LocalStorageInitService._storage?.remove(keyT);
    }
  }

  public async storeEntity(key: string, data: any, encrypted: boolean): Promise<any> {
    const dataStr = JSON.stringify({ date: moment(), data } as StoredWithTime);
    return this.setData(key, dataStr, encrypted);
  }

  public async hasLocalData(): Promise<boolean> {
    if (await this.isReady()) {
      const nbKeys = await LocalStorageInitService._storage?.length();
      if (nbKeys === null) {
        return false;
      }
      return nbKeys > 0;
    }
    return false;
  }
}

import { Injectable } from "@angular/core";
import { ChartOptions } from "chart.js";
import { DataChart } from "../components/observation/observation.component";
import { Account } from "../helpers/account-helper";
import { IChartColors } from "../helpers/chart-helper";
import { IGraphExtraInfos } from "../models/externalRessource";
import { AccountService } from "./globalDataProvider/account.service";
import { ConfigurationService } from "./globalDataProvider/configuration.service";
import { VitalProfileDefinitionsService } from "./globalDataProvider/vital-profile-definitions.service";
import { InfoAppService } from "./info-app.service";
@Injectable({
  providedIn: "root",
})
export class GraphService {
  constructor(
    private accountService: AccountService,
    private configService: ConfigurationService,
    private vitalProfileDefinitionsService: VitalProfileDefinitionsService
  ) {}

  public setupAdditionalGraphInfos(
    chartOptions: ChartOptions,
    chartColors: IChartColors[],
    extraInfos: IGraphExtraInfos,
    nbPointsInChart: number
  ): DataChart[] {
    if (!extraInfos) return [];
    if (extraInfos.yAxesMax || extraInfos.yAxesMin) {
      if (!chartOptions.scales) {
        chartOptions.scales = {};
      }
      if (!chartOptions.scales.yAxes) {
        chartOptions.scales.yAxes = [{ ticks: { min: extraInfos.yAxesMin, max: extraInfos.yAxesMax } }];
      } else {
        chartOptions.scales.yAxes[0].ticks = { min: extraInfos.yAxesMin, max: extraInfos.yAxesMax };
      }
    }
    const dataCharts: DataChart[] = [];
    if (extraInfos.yLines) {
      chartOptions.spanGaps = true;
      // If we want to have band of color in the background,
      // we must remove the color below the graph lines:
      if (extraInfos.yLines.colors?.length) {
        for (const c of chartColors) {
          c.backgroundColor = "rgba(255, 255, 255, 0.0)";
        }
      }
      const iMax = extraInfos.yLines.vitalProfileCode?.length || extraInfos.yLines.values?.length;
      for (let i = 0; i < iMax; ++i) {
        dataCharts.push(
          this.addYLine(
            nbPointsInChart,
            extraInfos.yLines.values[i],
            extraInfos.yLines.vitalProfileCode[i],
            extraInfos.yLines.colors[i],
            chartColors
          )
        );
      }
      if (extraInfos.yLines.colors.length > iMax && extraInfos.yAxesMax) {
        dataCharts.push(this.addYLine(nbPointsInChart, extraInfos.yAxesMax, null, extraInfos.yLines.colors[iMax], chartColors));
      }
    }
    return dataCharts;
  }

  private addYLine(nbPointsInChart: number, value?: unknown, loincCode?: string, color?: string, chartColors?: IChartColors[]): DataChart {
    const dataChart = new DataChart();
    const values = [];
    const points = nbPointsInChart > 1 ? nbPointsInChart - 2 : 0;
    const emptyValues = new Array(points).fill(null);
    const codeValue = loincCode ? Account.getVital(this.accountService.cachedAccount, loincCode)?.value : null;
    const v = codeValue ?? value;
    values.push(v);
    values.push(...emptyValues);
    values.push(v);
    if (color) {
      // Create a band of color:
      chartColors?.push({
        backgroundColor: color,
        borderColor: "#FFFFFF",
        pointBackgroundColor: "rgba(0,0,0,0.0)",
        pointBorderColor: "rgba(0,0,0,0.0)",
        pointHoverBackgroundColor: "rgba(0,0,0,0.0)",
        pointHoverBorderColor: "rgba(0,0,0,0.0)",
      });
    } else {
      // Create a single dashed dark grey line:
      chartColors?.push({
        backgroundColor: "rgba(255, 255, 255, 0.0)",
        borderColor: "#888888",
        pointBackgroundColor: "rgba(255, 255, 255, 0.0)",
        pointBorderColor: "rgba(255, 255, 255, 0.0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0.0)",
        pointHoverBorderColor: "rgba(255, 255, 255, 0.0)",
      });
      dataChart.borderDash = [10, 5];
    }
    dataChart.data = values;
    // add the label
    if (loincCode) {
      const vitalDefs = this.vitalProfileDefinitionsService.peekData();
      const def = vitalDefs.find((v) => v.code === loincCode);
      if (def) {
        const translation = InfoAppService.getTranslation(def.nameMobile, this.configService.getCurrentLanguage(), loincCode);
        dataChart.label = translation ?? "";
      }
    } else {
      dataChart.label = String(v);
    }
    return dataChart;
  }
}

import { Component, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { IonContent, IonSlides, ModalController, NavParams, Platform, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BasePage } from "src/app/baseClasses/base-page";
import { Account, IAccount } from "src/app/helpers/account-helper";
import { EnableWhenBehavior, EnableWhenHelper, IEnableWhen } from "src/app/helpers/enableWhen-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Answer, QRQuestion, QuestionnaireResponse } from "src/app/helpers/questionnaireResponse";
import { RuleHelperQuestionnaireService } from "src/app/helpers/rule-helper-service/rule-helper-questionnaire.service";
import { ServerResponse } from "src/app/helpers/server-response-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { NOTIFICATION_STATUS } from "src/app/models/notification";
import { IQuestionnaire, PAGING_MODE, Question } from "src/app/models/questionnaire";
import { REWARD_ACTION, REWARD_PAGE_NAME } from "src/app/models/rewardDefinition";
import { RULE_CATEGORY, RULE_TYPE_TARGET } from "src/app/models/rule";
import { Coding } from "src/app/models/sharedInterfaces";
import { SlideView } from "src/app/models/slideView";
import { DateToIsoPipe } from "src/app/pipes/date-to-iso.pipe";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { KnowledgeService } from "src/app/services/globalDataProvider/knowledge.service";
import { QuestionnaireService } from "src/app/services/globalDataProvider/questionnaire.service";
import { RewardScoreService } from "src/app/services/globalDataProvider/reward-score.service";
import { RulesAlertService } from "src/app/services/globalDataProvider/rules-alert.service";
import { RulesService } from "src/app/services/globalDataProvider/rules.service";
import { StatEventService } from "src/app/services/globalDataProvider/statEvent.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoaderService } from "src/app/services/loader.service";
import { ModalKnowledgeService } from "src/app/services/modal-knowledge.service";
import { NetworkService } from "src/app/services/network.service";
import { NotificationsSaveService } from "src/app/services/notificationsService/notifications-save.service";
import { PopupService } from "src/app/services/popup.service";

/**
 * General Structure for View
 *
 * Slides[] ---- SlideView ----- SlideContentGroupQuestion ------ SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |               |                               |---- ...
 *          |               |
 *          |               |--- SlideContentGroupQuestion ------ SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |               |
 *          |               --- SlideContentGroupQuestion ------ SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |
 *          |
 *          |
 *          |--- SlideView ---- SlideContentGroupQuestion ------ SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |               |                               |---- SlideContentQuestion
 *          |               |                               |---- ...
 *
 */

export interface SlideContentGroupQuestion {
  mainTitle?: string; // only for the first slide and first content
  mainDescription?: string; // only for the first slide and first content
  title: string;
  description: string;
  questionResponses: SlideContentQuestion[];
}
/**
 * Slide content for 1 question + response
 */
export interface SlideContentQuestion {
  question: Question;
  response: QRQuestion;
}

@Component({
  selector: "app-questionnaire-modal",
  templateUrl: "./questionnaire-modal.component.html",
  styleUrls: ["./questionnaire-modal.component.scss"],
})
export class QuestionnaireModalComponent extends BasePage {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild("slides") slides: IonSlides;
  public isLoading = true;
  public questionnaire: IQuestionnaire;
  public questionnaireResponse: QuestionnaireResponse;
  public isReadonly: boolean;
  public slideViews: SlideView[] = [];
  private account: IAccount;
  private action: string;
  public isBeginning = true;
  public isEnd = true;
  public isValidating = false;
  public isMoving = false;
  private sliderSub: Subscription;
  public swipOpts = {
    autoHeight: true,
  };
  private lockSwipe = true;
  private modeCreate: boolean;
  public qForms: UntypedFormGroup[] = [];
  private backButtonSubscription: Subscription;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    private accountService: AccountService,
    protected navParams: NavParams,
    protected modalCtr: ModalController,
    private questionnaireService: QuestionnaireService,
    protected translateSvc: TranslateService,
    protected toastController: ToastController,
    private rewardScoreService: RewardScoreService,
    private notificationSaveService: NotificationsSaveService,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    private rulesService: RulesService,
    private ruleAlertService: RulesAlertService,
    protected configService: ConfigurationService,
    private knowledgeService: KnowledgeService,
    private modalKnService: ModalKnowledgeService,
    private networkService: NetworkService,
    private dateToIsoPipe: DateToIsoPipe,
    private platform: Platform,
    private statEventService: StatEventService,
    private iab: InAppBrowser
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.questionnaire = this.navParams.get("questionnaire");
    this.account = this.accountService.cachedAccount;
    this.action = this.navParams.get("action");
    this.lockSwipe = this.navParams.data.action === "create";
    this.modeCreate = this.navParams.data.action === "create";
  }

  ionViewDidEnter(): void {
    super.ionViewDidEnter();
    this.statEventService.newEvent("Opened 'questionnaire modal'", false);
    this.isLoading = false;
    if (this.networkService.isCurrentOffline() && this.questionnaire.onlyOnline) {
      this.popupService.showToast("myfeelings.cannotSaveOffline", 5000, "bottom");
      this.modalCtr.dismiss();
    }

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(101, () => {
      // default priority for modal handling is 100, priority should be > 100
      this.dismiss();
    });
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    // to activate the cache of the rules
    this.rulesService.getFreshestData();

    if (this.lockSwipe) {
      this.slides.lockSwipes(true);
    }
    // create new response or view existing one
    if (this.action === "create") {
      this.isReadonly = false;
      // build Questionnaire Response based on Questionnaire template
      this.questionnaireResponse = new QuestionnaireResponse();
      this.questionnaireResponse.fromTemplate(this.questionnaire, this.account);
      this.buildSlideViews();
    } else if (this.action === "view") {
      this.isReadonly = true;
      this.questionnaireResponse = this.navParams.get("questionnaireResponse");
      this.buildSlideViews();
    }
    if (this.slides) {
      this.initSlidesBool();
    }
  }

  ionViewWillLeave(): void {
    super.ionViewWillLeave();
    this.backButtonSubscription.unsubscribe();
  }

  private initSlidesBool() {
    this.slides?.isEnd().then((isE) => {
      this.isEnd = this.slideViews.length <= 1 ? true : isE;
    });
    this.sliderSub = this.slides.ionSlideWillChange?.pipe(takeUntil(this.onDestroy$))?.subscribe(() => {
      this.slides.isBeginning().then((isB) => (this.isBeginning = isB));
      this.slides.isEnd().then((isE) => (this.isEnd = isE));
      this.slides.updateAutoHeight();
    });
  }

  /**
   *  cancel edition
   */
  public dismiss(): void {
    if (this.action === "create") {
      this.popupService.showYesNo("myfeelings.alertWarnTitle", "myfeelings.alertWarnSubtitle").then((res) => {
        if (res) {
          this.modalCtr.dismiss();
        }
      });
    } else if (this.action === "view") {
      this.modalCtr.dismiss();
    }
  }

  /**
   * Create all slides based on Questionnaire
   */
  private buildSlideViews() {
    switch (this.questionnaire.paging) {
      default:
      case PAGING_MODE.NONE: {
        // no slides (in fact only 1 slide)
        this.buildSimpleSlideMode();
        break;
      }
      case PAGING_MODE.SINGLE: {
        // 1 slide by question
        this.buildSingleSlideMode();
        break;
      }
      case PAGING_MODE.GROUP: {
        // 1 slide by theme
        this.buildGroupSlideMode();
        break;
      }
    }
    this.addMainQuestionnaireDescriptionOnFirstSlide();
  }

  /**
   * Add, if necessary, the Main Questionnaire Description on the first slide in the case "multi-group"
   */
  private addMainQuestionnaireDescriptionOnFirstSlide() {
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse) && this.slideViews?.length && this.slideViews[0].content?.length) {
      const firstSlideContent = this.slideViews[0].content[0] as SlideContentGroupQuestion;
      firstSlideContent.mainTitle = this.questionnaire?.group?.title ? this.questionnaire?.group?.title : null;
      firstSlideContent.mainDescription = this.questionnaire.group?.text ? this.questionnaire.group?.text : null;
    }
  }

  /**
   * build 1 slide by question
   */
  private buildSingleSlideMode() {
    // multiple groups of questions
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse)) {
      // loop on sub groups
      for (let i = 0; i < this.questionnaire.group.group.length; i++) {
        const subGroup = this.questionnaire.group.group[i];
        // loop on questions for each group
        for (let j = 0; j < subGroup.question.length; j++) {
          const question = subGroup.question[j];
          const response = this.questionnaireResponse.group.group[i].question[j];
          // create the reactive form:
          const form = this.fb.group({});
          // one slide for 1 content group
          const slideView: SlideView = {
            identifier: this.questionnaireResponse.identifier.value,
            content: [],
          };
          // one content group for 1 question
          const slideContentGroup: SlideContentGroupQuestion = {
            title: subGroup.title,
            description: subGroup.text,
            questionResponses: [],
          };
          const slideContent: SlideContentQuestion = {
            question: question,
            response: response,
          };
          // Add form control
          if (!question.enableWhen || question.enableWhen.length === 0 || this.isEnabledByPreviousAnswer(question)) {
            this.addFormControl(question, form, slideContent, this.slideViews.length);
          }
          // set question/response to its Content Group
          slideContentGroup.questionResponses = [slideContent];
          // add content group to its slide
          slideView.content = [slideContentGroup];
          // add the form to the list
          this.qForms.push(form);
          // add slide to the list of slides
          this.slideViews.push(slideView);
        }
      }
    } else {
      // flat questionnaires (only main group with questions)
      // loop on main group questions
      for (let i = 0; i < this.questionnaire.group.question.length; i++) {
        const question = this.questionnaire.group.question[i];
        // create the reactive form:
        const form = this.fb.group({});
        // one slide for 1 content group
        const slideView: SlideView = {
          identifier: this.questionnaireResponse.identifier.value,
          content: [],
        };
        // one content group for 1 question
        const slideContentGroup: SlideContentGroupQuestion = {
          title: this.questionnaire.group.title,
          description: this.questionnaire.group.text,
          questionResponses: [],
        };
        const slideContent: SlideContentQuestion = {
          question: question,
          response: this.questionnaireResponse.group.question[i],
        };
        // Add form control
        if (!question.enableWhen || question.enableWhen.length === 0 || this.isEnabledByPreviousAnswer(question)) {
          this.addFormControl(question, form, slideContent, this.slideViews.length);
        }
        // set question/response to its Content Group
        slideContentGroup.questionResponses = [slideContent];
        // add content group to its slide
        slideView.content = [slideContentGroup];
        // add the form to the list
        this.qForms.push(form);
        // add slide to the list of slides
        this.slideViews.push(slideView);
      }
    }
  }

  /**
   * build 1 slide by theme (by group)
   */
  private buildGroupSlideMode() {
    // multiple groups of questions
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse)) {
      // loop on sub groups
      for (let i = 0; i < this.questionnaire.group.group.length; i++) {
        const subGroup = this.questionnaire.group.group[i];
        // one slide for 1 group
        const slideView: SlideView = {
          identifier: this.questionnaireResponse.identifier.value,
          content: [],
        };
        // one content group for all questions of this group
        const slideContentGroup: SlideContentGroupQuestion = {
          title: subGroup.title,
          description: subGroup.text,
          questionResponses: [],
        };
        // add 1 content group to its slide
        slideView.content = [slideContentGroup];
        // create the reactive form:
        const form = this.fb.group({});
        // loop on questions for each group
        for (let j = 0; j < subGroup.question.length; j++) {
          const question = subGroup.question[j];
          const response = this.questionnaireResponse.group.group[i].question[j];
          const slideContent: SlideContentQuestion = {
            question: question,
            response: response,
          };
          // Add form control
          if (!question.enableWhen || question.enableWhen.length === 0 || this.isEnabledByPreviousAnswer(question)) {
            this.addFormControl(question, form, slideContent, this.slideViews.length);
          }
          // add question/response to its Content Group
          slideContentGroup.questionResponses.push(slideContent);
        }
        // add the form to the list
        this.qForms.push(form);
        // add slide to the list of slides
        this.slideViews.push(slideView);
      }
    } else {
      // flat questionnaires (only main group with questions)
      // ??? not possible to build by group mode if there are no groups (only main) ==> same as simple mode
      this.buildSimpleSlideMode();
    }
  }

  /**
   * No slide ==> build 1 single slide with all questions
   */
  private buildSimpleSlideMode() {
    const slideContentGroups: SlideContentGroupQuestion[] = [];
    // create the reactive form:
    const form = this.fb.group({});
    // multiple groups of questions
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse)) {
      // loop on sub groups
      for (let i = 0; i < this.questionnaire.group.group.length; i++) {
        const subGroup = this.questionnaire.group.group[i];
        const slideContentGroup: SlideContentGroupQuestion = {
          title: subGroup.title,
          description: subGroup.text,
          questionResponses: [],
        };
        // loop on questions for each sub groups
        for (let j = 0; j < subGroup.question.length; j++) {
          const question = subGroup.question[j];
          const response = this.questionnaireResponse.group.group[i].question[j];
          const slideContent: SlideContentQuestion = {
            question: question,
            response: response,
          };
          // Add form control
          if (!question.enableWhen || question.enableWhen.length === 0 || this.isEnabledByPreviousAnswer(question)) {
            this.addFormControl(question, form, slideContent, 0);
          }
          slideContentGroup.questionResponses.push(slideContent);
        }
        slideContentGroups.push(slideContentGroup);
      }
    } else {
      // flat questionnaires (only main group with questions)
      const slideContentGroup: SlideContentGroupQuestion = {
        title: this.questionnaire.group.title,
        description: this.questionnaire.group.text,
        questionResponses: [],
      };
      // loop on main group questions
      for (let i = 0; i < this.questionnaire.group.question.length; i++) {
        const question = this.questionnaire.group.question[i];
        const response = this.questionnaireResponse.group.question[i];
        const slideContent: SlideContentQuestion = {
          question: question,
          response: response,
        };
        // Add form control
        if (!response.enableWhen || this.isEnabledByPreviousAnswer(question)) {
          this.addFormControl(question, form, slideContent, 0);
        }
        // add question/response to the single Content Group
        slideContentGroup.questionResponses.push(slideContent);
      }
      slideContentGroups.push(slideContentGroup);
    }
    // create 1 slide
    const slideView: SlideView = {
      identifier: this.questionnaireResponse.identifier.value,
      content: slideContentGroups,
    };
    // add the form to the list
    this.qForms.push(form);
    // add only 1 slide to the list of slides
    this.slideViews = [slideView];
  }

  /**
   * Create and add a new form control to a reactive form
   * @param question the question the form control is based on
   * @param form the form we want to add the question to
   * @param slideContent the content slide the question will be on
   * @param idx the index of the reactive form in our list of reactive forms
   */
  private addFormControl(question: Question, form: UntypedFormGroup, slideContent: SlideContentQuestion, idx: number) {
    const required = question.required;
    let value = "";
    if (this.isReadonly && slideContent) {
      value = slideContent.response.answer[0]?.valueCoding.code;
    } else {
      value = question.answerDisplay?.default ?? "";

      // transform date value to a format compatible with the mat date input
      if (question.answerDisplay?.type === "date" && value) {
        value = this.dateToIsoPipe.transform(value);
      }
    }
    if (
      question.options &&
      ((question.type === "choice" && question.repeats) || (question.answerDisplay && question.answerDisplay.type === "checkboxes"))
    ) {
      const formArray = required ? new UntypedFormArray([], [Validators.required]) : new UntypedFormArray([], []);
      const choices = this.getValueSetChoices(question.options.reference, slideContent.response, true);
      for (const choice of choices) {
        formArray.push(new UntypedFormControl({ value: choice.checked, disabled: this.isReadonly }));
      }
      formArray.valueChanges?.pipe(takeUntil(this.onDestroy$))?.subscribe((newValues: boolean[]) => {
        const checkedChoicesCodes: string[] = [];
        // Put all codes of checked choices in a string for enableWhen
        for (let i = 0; i < newValues.length; ++i) {
          const checked = newValues[i];
          if (checked === true) {
            checkedChoicesCodes.push(choices[i].code);
          }
        }
        const stringifiedValue = checkedChoicesCodes.join("§");
        this.setupEnabledQuestions(question.linkId, stringifiedValue, idx);
      });
      form.addControl(question.linkId, formArray);
    } else {
      const control = required
        ? new UntypedFormControl({ value: value, disabled: this.isReadonly }, [Validators.required])
        : new UntypedFormControl({ value: value, disabled: this.isReadonly }, []);

      if (question.type === "number" || question.type === "decimal") {
        control.setValidators([Validators.max(+question.answerDisplay.max), Validators.min(+question.answerDisplay.min)]);
        if (question.options?.reference) {
          // number with units
          const controlUnit = new UntypedFormControl(
            { value: slideContent.response?.answer[1]?.valueCoding.code, disabled: this.isReadonly },
            []
          );
          form.addControl(question.linkId + "unit", controlUnit);
          if (!slideContent.response?.answer[1]?.valueCoding.code) {
            controlUnit.setValue(this.getValueSetChoices(question.options.reference, slideContent.response)[0].code);
          }
        }
      }

      if (question.answerDisplay?.type === "date") {
        control.setValidators([this.isValidDate(question.answerDisplay.min, question.answerDisplay.max)]);
      }

      control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((newValue) => {
        this.setupEnabledQuestions(question.linkId, newValue, idx);
      });
      form.addControl(question.linkId, control);
    }
  }

  /**
   * Add or remove questions based on their enableWhens after the user answered a new question
   * @param triggerLinkId the linkId of the question newly answered
   * @param triggerValue the value of the question newly answered
   * @param idx the index of the form (and slide) the question is on
   */
  private async setupEnabledQuestions(triggerLinkId: string, triggerValue: string | number | undefined, idx: number) {
    const previousQuestions: Question[] = [];
    if (QuestionnaireResponse.isMultiGroup(this.questionnaireResponse)) {
      const group = this.questionnaire.group.group;
      for (let i = 0; i < group.length; ++i) {
        for (let k = 0; k < group[i].question.length; ++k) {
          const question = group[i].question[k];
          if (question.enableWhen && question.enableWhen.length > 0) {
            const concernedEnableWhens = this.findEnableWhenAboutQuestion(triggerLinkId, question.enableWhen);
            if (concernedEnableWhens.length > 0) {
              const shouldBeEnabled = this.shouldBeEnabled(concernedEnableWhens, triggerLinkId, triggerValue, question, previousQuestions);
              const hasQuestion = Tools.isDefined(this.qForms[i].get(question.linkId));
              if (shouldBeEnabled && !hasQuestion) {
                this.addFormControl(question, this.qForms[i], this.slideViews[i].content, i);
              } else if (!shouldBeEnabled && hasQuestion) {
                this.qForms[i].removeControl(question.linkId);
              }
            }
          }
          previousQuestions.push(question);
        }
      }
    } else {
      for (let i = 0; i < this.questionnaireResponse.group.question.length; ++i) {
        const question = this.questionnaire.group.question[i];
        if (question.enableWhen && question.enableWhen.length > 0) {
          const concernedEnableWhens = this.findEnableWhenAboutQuestion(triggerLinkId, question.enableWhen);
          if (concernedEnableWhens.length > 0) {
            const shouldBeEnabled = this.shouldBeEnabled(concernedEnableWhens, triggerLinkId, triggerValue, question, previousQuestions);
            const hasQuestion = Tools.isDefined(this.qForms[i].get(question.linkId));
            if (shouldBeEnabled && !hasQuestion) {
              this.addFormControl(question, this.qForms[i], this.slideViews[i].content, i);
            } else if (!shouldBeEnabled && hasQuestion) {
              this.qForms[i].removeControl(question.linkId);
            }
          }
        }
        previousQuestions.push(question);
      }
    }
    this.isEnd = !this.isThereNextPage(idx);
    setTimeout(() => {
      this.slides.updateAutoHeight();
    }, 500);
  }

  /**
   * Finds all the enableWhens that refer to a question based on its linkId
   * @param linkId the linkId of the question we want
   * @param enableWhens the list of enableWhens
   */
  private findEnableWhenAboutQuestion(linkId: string, enableWhens: IEnableWhen[]) {
    const ew: IEnableWhen[] = [];
    for (const e of enableWhens) {
      if (e.question === linkId) {
        ew.push(e);
      }
    }
    return ew;
  }

  /**
   * Finds all the enableWhens that refer to other questions
   * @param linkId the linkId of the question we do not want
   * @param enableWhens the list of enableWhens
   */
  private findEnableWhenNotAboutQuestion(linkId: string, enableWhens: IEnableWhen[]) {
    const ew: IEnableWhen[] = [];
    for (const e of enableWhens) {
      if (e.question !== linkId) {
        ew.push(e);
      }
    }
    return ew;
  }

  /**
   * Check if a question should be enabled according to its enableWhen, after we answered another question
   * @param concernedEnableWhens the enableWhen affected by the newly answered question
   * @param triggerLinkId the linkId of the newly answered question
   * @param triggerValue the value of the newly answered question
   * @param question the question we are wondering about
   * @param previousQuestions all the previous questions
   */
  private shouldBeEnabled(
    concernedEnableWhens: IEnableWhen[],
    triggerLinkId: string,
    triggerValue: string | number | undefined,
    question: Question,
    previousQuestions: Question[]
  ) {
    let shouldBeEnabled = EnableWhenHelper.areEnabledBySame(concernedEnableWhens, triggerValue, question.enableWhenBehavior);
    // If there are other necessary conditions that are not related to the current trigger
    // We need to check if there are filled or not
    if (
      shouldBeEnabled &&
      concernedEnableWhens.length !== question.enableWhen.length &&
      question.enableWhenBehavior === EnableWhenBehavior.AND
    ) {
      const otherEnableWhens = this.findEnableWhenNotAboutQuestion(triggerLinkId, question.enableWhen);
      const answers: string[] = [];
      for (const otherEw of otherEnableWhens) {
        const linkId = otherEw.question;
        answers.push(this.getCurrentAnswerValue(linkId, previousQuestions));
      }
      shouldBeEnabled = EnableWhenHelper.areEnabledByEach(otherEnableWhens, answers, question.enableWhenBehavior);
    }
    return shouldBeEnabled;
  }

  /**
   * return multiple choice values define in "questionnaire"
   */
  private getValueSetChoices(reference: string, question?: QRQuestion, init = false): Coding[] {
    if (this.questionnaire.contained) {
      for (const element of this.questionnaire.contained) {
        if (element.resourceType === "ValueSet" && element.idSet === reference) {
          // This part is basically only for checkbox (mutiple answers) to check if they're checked or not
          if (init && question && question.answer) {
            element.compose.include[0].concept.forEach((a) => {
              const i = question.answer.findIndex((q) => q.valueCoding.code === a.code);
              a.checked = i > -1;
            });
          } else if (init) {
            element.compose.include[0].concept.forEach((a) => (a.checked = false));
          }
          return element.compose.include[0].concept;
        }
      }
    }
    return [];
  }

  /**
   *
   * @returns true if this questionnaire is linked to a rule
   */
  private async managementOfTheRules() {
    const getRules = await this.rulesService.getFirstDataAvailable();
    const rules = Array.isArray(getRules) ? getRules : [];
    const helper = new RuleHelperQuestionnaireService(
      this.accountService.cachedAccount,
      rules.filter((r) => r.meta.category === RULE_CATEGORY.REFERENCE_VALUE && r.meta.typeTarget === RULE_TYPE_TARGET.QUESTIONNAIRE),
      this.questionnaire
    );

    if (!helper.isLinkedToARule) {
      return false;
    } else {
      if (this.networkService.isCurrentOffline()) {
        return true;
      }

      let ruleAlert = helper.getRuleAlert(this.questionnaireResponse);

      if (Tools.isNotDefined(ruleAlert)) {
        return true;
      }

      const vitalProfileCode = helper.getVitalProfileLinked();
      const ruleId = ruleAlert.rule.idRule;

      Account._setVital(this.account, vitalProfileCode, "", ruleId, null); // TODO à améliorer si besoin en allant chercher le vitalProfile
      this.accountService.setAccount(this.account).pipe(takeUntil(this.onDestroy$)).subscribe(); // save vital profile

      const mediaFromRule = await this.knowledgeService.getMediaFromRule(ruleId, vitalProfileCode);
      let messageFromAlert = helper.getMessageForPopup(ruleAlert, this.configService.getCurrentLanguage());

      if (mediaFromRule?.media && mediaFromRule?.publicationDate) {
        messageFromAlert += "<br>";
        messageFromAlert += mediaFromRule.media.description;
        this.questionnaireResponse.mediaId = mediaFromRule.media.identifier.value;
        this.questionnaireResponse.knowledgePublicationDate = mediaFromRule.publicationDate;
        this.modalKnService.showMediaFromRuleAlert(this.translateSvc.instant("myfeelings.newMedia"), messageFromAlert, mediaFromRule.media);
        ruleAlert = helper.getRuleAlert(this.questionnaireResponse);
      }

      this.ruleAlertService.save(ruleAlert); // J'ai pris le parti de ne pas sauvegarder la ruleAlert si on affiche pas de message. A voir si on veut garder ce comportement
      // si on veut changer, il suffit de mettre cette ligne plus haut
    }
    return true;
  }

  /**
   * Save these responses: create a QuestionaireResponse
   */
  public async onValidate(): Promise<void> {
    try {
      this.isValidating = true;
      if (await this.isComplete()) {
        this.putFormsValuesInResponseObject();
        this.questionnaireResponse.modified = moment().format();
        this.questionnaireResponse.questionnairePublicationDate = this.questionnaire.date;
        const isLinkedToARule = await this.managementOfTheRules();
        if (this.networkService.isCurrentOffline() && isLinkedToARule) {
          // in this case, the "save" must be done online
          await this.popupService.showAlert("myfeelings.error", "myfeelings.cannotSaveOffline");
          this.isValidating = false;
          return;
        }
        await this.questionnaireService.save(this.questionnaireResponse);
        const notif = this.navParams.data.notification;
        if (notif) {
          await this.notificationSaveService.updateNotificationStatus(notif, NOTIFICATION_STATUS.ACCEPTED);
        }
        this.modalCtr.dismiss(this.questionnaire);

        this.rewardScoreService.update(REWARD_PAGE_NAME.myquestionnaires, REWARD_ACTION.onAdd);
      } else {
        const trans = this.translateSvc.instant("forms.notCompleted");
        const toast = await this.toastController.create({
          message: trans,
          duration: 1500,
          position: "top",
        });
        toast.present();
      }
    } catch (error) {
      FileLogger.error("QuestionnaireModalComponent", "Error while saving questionnaire: ", error);
      await this.loaderService.showSavingToast(false);
      if (error.code === ServerResponse.SERVER_UNREACHABLE.code) {
        this.popupService.showAlert("error.general", "error.browser.unreachableServer");
      } else {
        await this.popupService.showAlert("menu.feeling", "error.storage");
      }
    } finally {
      this.isValidating = false;
    }
  }

  /**
   * Put all the values in the reactive form in the response object
   */
  private putFormsValuesInResponseObject() {
    for (let i = 0; i < this.qForms.length; ++i) {
      const form = this.qForms[i];
      const slideView = this.slideViews[i];
      const contentGroup: SlideContentGroupQuestion[] = slideView.content;
      for (let j = 0; j < contentGroup.length; ++j) {
        const content: SlideContentQuestion[] = contentGroup[j].questionResponses;
        for (let k = 0; k < content.length; ++k) {
          const question = content[k].question;
          const response = content[k].response;
          // If that particular question is enabled:
          if (Tools.isDefined(form.get(question.linkId))) {
            this.setupAnswer(question, response, form.get(question.linkId).value);
            if (question.type === "number" && question.options?.reference && Tools.isDefined(form.get(question.linkId).value)) {
              // number with units
              this.setupAnswer(question, response, form.get(question.linkId + "unit").value, true);
            }
          }
          // If not:
          else {
            response.answer = [];
          }
        }
      }
    }
  }

  /**
   * Put the value that is an answer to a question in the correct format in response
   * @param question the question we answer
   * @param response the formatted response
   * @param value the value entered by the user
   */
  private setupAnswer(question: Question, response: QRQuestion, value: unknown, isUnit = false) {
    let type = question.answerDisplay ? question.answerDisplay.type : question.type;
    if (type === "choice" && question.repeats) type = "checkboxes";
    const res: Answer[] = [];
    const choices = question.options?.reference ? this.getValueSetChoices(question.options.reference) : [];
    const answer = new Answer();
    let code = "";
    let display = "";
    switch (type) {
      case "choice":
      case "radio":
      case "range": {
        code = String(value);
        const d = choices.find((choice: Coding) => choice.code === code)?.display;
        display = d ? d : code;
        break;
      }
      case "date":
      case "text":
      case "string":
      case "textarea":
      case "textfield":
        code = String(value);
        display = String(value);
        break;
      case "decimal":
      case "number": {
        if (isUnit) {
          const unit = choices.find((c) => c.code === value);
          const resUnit = new Answer();
          resUnit.valueCoding = {
            code: unit.code,
            display: unit.display,
            system: null,
          };
          res.push(response.answer[0], resUnit);
        } else {
          code = String(value);
          display = String(value);
        }
        break;
      }
      case "checkboxes": {
        const values: boolean[] = value as boolean[];
        for (let i = 0; i < values.length; ++i) {
          if (values[i] === true && choices.length > i) {
            const a = new Answer();
            a.valueCoding = {
              code: choices[i].code,
              display: choices[i].display,
              system: null,
            };
            res.push(a);
          }
        }
        break;
      }
    }

    if (type !== "checkboxes" && !isUnit) {
      answer.valueCoding = {
        code: code,
        display: display,
        system: null,
      };
      res.push(answer);
    }
    response.answer = res;
  }

  private isThereNextPage(currentSlideIdx: number): boolean {
    const nbSlides = this.slideViews.length;
    for (let i = currentSlideIdx + 1; i < nbSlides; ++i) {
      const empty = this.isEmpty(i);
      if (!empty) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get the current value in the reactive form for a question based on its linkId
   * @param linkId the linkId of the question
   * @param questions the list of questions our question should be in
   */
  private getCurrentAnswerValue(linkId: string, questions: Question[]): string | undefined {
    for (const form of this.qForms) {
      if (form.get(linkId)) {
        const value = form.get(linkId).value;
        // Let's check if our value came from checkboxes
        if (Array.isArray(value)) {
          if (value.length < 1) {
            return "";
          }
          // Only do all the hard stuff if we don't have any other choice:
          else if (typeof value[0] === "boolean") {
            const correspondingQuestion = questions.find((q) => q.linkId === linkId);
            if (correspondingQuestion) {
              const choices = correspondingQuestion.options?.reference
                ? this.getValueSetChoices(correspondingQuestion.options.reference)
                : [];
              const checkedChoicesCodes: string[] = [];
              for (let i = 0; i < value.length; ++i) {
                const checked = value[i];
                if (checked === true) {
                  checkedChoicesCodes.push(String(choices[i].code));
                }
              }
              return checkedChoicesCodes.join("§");
            }
          }
        }

        return String(value);
      }
    }
    return undefined;
  }

  /**
   * Find the previously given answer to a question based on its linkId and returns it.
   * In case of several answers, we concatene them as a string with a '§' as separator.
   * @param linkId the linkId of the question we want the answer to
   * @param type  the type of input. We need to know if it's an input that generate several answers (like a checkbox)
   */
  private getAnswerValue(linkId: string, type: string): string {
    if (this.questionnaireResponse.group.question && this.questionnaireResponse.group.question.length) {
      for (const question of this.questionnaireResponse.group.question) {
        if (question.linkId === linkId && question.answer.length) {
          if (type === "checkboxes") {
            const answersStringArray: string[] = question.answer.map((a) => String(a.valueCoding.code));
            return answersStringArray.join("§");
          } else {
            return question.answer[0].valueCoding.code;
          }
        }
      }
    } else if (this.questionnaireResponse.group.group && this.questionnaireResponse.group.group.length) {
      for (const subGroup of this.questionnaireResponse.group.group) {
        for (const question of subGroup.question) {
          if (question.linkId === linkId && question.answer.length) {
            if (type === "checkboxes") {
              const answersStringArray: string[] = question.answer.map((a) => String(a.valueCoding.code));
              return answersStringArray.join("§");
            } else {
              return question.answer[0].valueCoding.code;
            }
          }
        }
      }
    }
    return null;
  }

  /**
   * Return true if
   * - enableWhen does not exist (-> the question must be always visible)
   * - the condition describe by the field "enableWhen" is respected (-> the question must be visible)
   * Return false if
   * - the condition describe by the field "enableWhen" is not respected (-> the question must not be visible)
   * - the patient has not yet answered the previous questions (-> the question must not be visible)
   * @param enableWhen
   */
  private isEnabledByPreviousAnswer(question: Question): boolean {
    const enableWhen: IEnableWhen[] = question.enableWhen;
    if (!enableWhen || enableWhen.length < 1 || !EnableWhenHelper.isEnableWhen(enableWhen)) {
      return true;
    }
    const type = question.answerDisplay ? question.answerDisplay.type : question.type;
    const answers: string[] = [];
    for (const ew of enableWhen) {
      answers.push(this.getAnswerValue(ew.question, type));
    }
    return EnableWhenHelper.areEnabledByEach(enableWhen, answers, question.enableWhenBehavior);
  }

  /**
   * Automatic go to next slide if only 1 questions and not at the end
   * @param slide
   */
  public async onRadioSelected(slide: SlideView): Promise<void> {
    if (await this.isSingleQuestionAndHasNextSlide(slide)) {
      await this.nextSlide();
    }
  }

  /**
   * check if current vue is complete
   */
  private async isComplete(): Promise<boolean> {
    // get the view
    const index = Number(await this.slides.getActiveIndex());
    const currentView = this.slideViews[index];
    if (currentView) {
      this.qForms[index].markAllAsTouched();
      return this.qForms[index].valid;
    }
  }

  /**
   * Check if current vue is empty (no visible question)
   */
  private isEmpty(slideIndex: number): boolean {
    // get the view
    if (slideIndex >= this.slideViews.length) {
      return true;
    }
    const currentView = this.slideViews[slideIndex];
    if (currentView) {
      return Object.keys(this.qForms[slideIndex].controls).length < 1;
    }
    return true;
  }

  /**
   * Can we automatic go to next slide ?
   * @param slide
   */
  private async isSingleQuestionAndHasNextSlide(slide: SlideView): Promise<boolean> {
    const slideEnd = await this.slides.isEnd();
    return (
      slide.content?.length &&
      slide.content.length === 1 &&
      (slide.content[0] as SlideContentGroupQuestion).questionResponses.length === 1 &&
      !slideEnd
    );
  }

  /**
   * Click on next button
   */
  public async nextSlide(): Promise<void> {
    this.isMoving = true;
    // Fix for get the top of each slides
    await this.content.scrollToTop(0);
    if ((await this.isComplete()) || !this.modeCreate) {
      await this.slides.lockSwipeToNext(false);
      await this.slides.slideNext();
      if (this.lockSwipe) {
        await this.slides.lockSwipeToNext(true);
      }
      // if the new slide is "empty" (no visible question), then we pass directly to the next slide
      const slideIndex = await this.slides.getActiveIndex();
      const emptySlide = this.isEmpty(slideIndex);
      const slideEnd = await this.slides.isEnd();
      if (emptySlide && !slideEnd) {
        await this.nextSlide();
      } else if (emptySlide && slideEnd) {
        await this.onValidate();
      }
    } else {
      const trans = this.translateSvc.instant("forms.notCompleted");
      const toast = await this.toastController.create({
        message: trans,
        duration: 1500,
        position: "top",
      });
      await toast.present();
    }
    this.isMoving = false;
  }

  /**
   * Click on previous button
   */
  public async prevSlide(): Promise<void> {
    this.isMoving = true;
    await this.content.scrollToTop(0);
    await this.slides.lockSwipeToPrev(false);
    await this.slides.slidePrev();
    if (this.lockSwipe) {
      await this.slides.lockSwipeToPrev(true);
    }
    // if the new slide is "empty" (no visible question), then we pass directly to the previous slide
    const slideIndex = await this.slides.getActiveIndex();
    const emptySlide = this.isEmpty(slideIndex);
    if (emptySlide && !(await this.slides.isBeginning())) {
      await this.prevSlide();
    }
    this.isMoving = false;
  }

  public isValidDate(min: string, max: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      // Ensure correct date format
      min = min ? this.dateToIsoPipe.transform(min) : undefined;
      max = max ? this.dateToIsoPipe.transform(max) : undefined;

      if (min && control.value < min) {
        return { isValidDate: { value: control.value, message: this.translateSvc.instant("error.minValue") + " : " + min } };
      }

      if (max && control.value > max) {
        return { isValidDate: { value: control.value, message: this.translateSvc.instant("error.maxValue") + " : " + max } };
      } else return null;
    };
  }

  openLink(url: string): void {
    if (url) {
      this.iab.create(url, "_blank", "location=yes");
    }
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";
import { FEATURES } from "src/environments/features";

/**
 * Generated class for the ActivatedFeaturesPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: "activatedFeatures",
})
export class ActivatedFeaturesPipe implements PipeTransform {
  /**
   * Check if the value is an activated feature in the app environment.
   */
  transform(value: FEATURES | FEATURES[]) {
    if (Array.isArray(environment.features) && environment.features.length) {
      if (Array.isArray(value) && value.length) {
        return value.every((val) => environment.features.includes(val));
      } else return environment.features.includes(value as FEATURES) ? true : false;
    } else return true;
  }
}

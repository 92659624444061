import { Injectable } from "@angular/core";
import { Network } from "@ionic-native/network/ngx";
import { Platform } from "@ionic/angular";
import { BehaviorSubject, fromEvent, merge, Observable, of } from "rxjs";
import { mapTo } from "rxjs/operators";
import { InfoAppService } from "./info-app.service";
import { FileLogger } from "../helpers/fileLogger";

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

  constructor(private network: Network, private platform: Platform, private infoService: InfoAppService) {
    FileLogger.log("NetworkService", "Initialize networkService");
    this.platform.ready().then(() => {
      this.initializeNetworkEvents();
      const status = this.network.type !== "none" ? ConnectionStatus.Online : ConnectionStatus.Offline;
      this.status.next(status);
    });
  }

  public initializeNetworkEvents(): void {
    if (this.infoService.isCordova()) {
      this.network.onDisconnect().subscribe(() => {
        if (this.status.getValue() === ConnectionStatus.Online) {
          FileLogger.log("NetworkService", "WE ARE OFFLINE");
          this.updateNetworkStatus(ConnectionStatus.Offline);
        }
      });

      this.network.onConnect().subscribe(() => {
        if (this.status.getValue() === ConnectionStatus.Offline) {
          FileLogger.log("NetworkService", "WE ARE ONLINE");
          this.updateNetworkStatus(ConnectionStatus.Online);
        }
      });
    } else {
      // on Browser
      merge(of(navigator.onLine), fromEvent(window, "online").pipe(mapTo(true)), fromEvent(window, "offline").pipe(mapTo(false))).subscribe(
        (connected: boolean) => {
          if (connected && this.status.getValue() === ConnectionStatus.Offline) {
            FileLogger.log("NetworkService", "WE ARE ONLINE");
            this.updateNetworkStatus(ConnectionStatus.Online);
          } else if (!connected && this.status.getValue() === ConnectionStatus.Online) {
            FileLogger.log("NetworkService", "WE ARE OFFLINE");
            this.updateNetworkStatus(ConnectionStatus.Offline);
          }
        }
      );
    }
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  public isCurrentOffline(): boolean {
    return this.getCurrentNetworkStatus() === ConnectionStatus.Offline;
  }

  public isCurrentOnline(): boolean {
    return this.getCurrentNetworkStatus() === ConnectionStatus.Online;
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IQuestionnaire } from "src/app/models/questionnaire";
import { LocalStorageService } from "../storage/local-storage.service";
import { AccountService } from "./account.service";
import { ConfigurationService } from "./configuration.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class QuestionnaireDefinitionService
  extends BasicSyncService<IQuestionnaire, IQuestionnaire[]>
  implements StaticImplements<INeedRefresh, typeof QuestionnaireDefinitionService>
{
  public get needRefresh(): { value: boolean } {
    return QuestionnaireDefinitionService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  private lastValueOfParam = "";
  private storageKey = "QuestionnaireDefinitionLastValue";
  private $langSub: Subscription;

  public get entityStoreKey(): string {
    return super.entityStoreKey + this.lastValueOfParam;
  }

  constructor(
    protected dataService: DataService,
    private accountService: AccountService,
    private configService: ConfigurationService,
    private localStorage: LocalStorageService
  ) {
    super(dataService);
  }

  public getUrl(): string {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IQuestionnaire[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "questionnairesDef_",
      entityStoreKey: "list",
      getUrl: "/questionnaires?enableWhenArray=true&lang=",
      setUrl: "/questionnaires", // not settable from application
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, false);
      this.$langSub?.unsubscribe();
      this.$langSub = this.configService.watchData().subscribe((c) => {
        const lang = c?.settings?.globalSettings?.language;
        if (lang !== this.lastValueOfParam) {
          this.needRefresh.value = true;
          this.getFreshestData();
        }
      });
    } catch (err) {
      this.lastValueOfParam = "";
    }
  }

  public clear(): void {
    super.clear();
    this.lastValueOfParam = "";
  }

  public async *getDataReader(): AsyncGenerator<IQuestionnaire[], IQuestionnaire[], IQuestionnaire[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      this.lastValueOfParam = this.configService.getCurrentLanguage();
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, false);
      const dataReader = super.getDataReader();
      let d: IQuestionnaire[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("QuestionnaireDefinitionService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  public filterOnlyLatestVersions(questionnaires: IQuestionnaire[]): IQuestionnaire[] {
    const filtered: IQuestionnaire[] = [];
    for (const q of questionnaires) {
      const i = filtered.findIndex((f) => f.identifier[0].value === q.identifier[0].value);
      if (i > -1) {
        if (filtered[i].date && moment(filtered[i].date).isBefore(q.date)) {
          filtered[i] = q;
        }
      } else {
        filtered.push(q);
      }
    }
    return filtered;
  }
}

import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BasePage } from "src/app/baseClasses/base-page";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { ArchiveRequestService } from "src/app/services/archive-request.service";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { NetworkService } from "src/app/services/network.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-archive-request-modal",
  templateUrl: "./archive-request-modal.component.html",
  styleUrls: ["./archive-request-modal.component.scss"],
})
export class ArchiveRequestModalComponent extends BasePage {
  public mailAddress: string;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected configService: ConfigurationService,
    protected translateService: TranslateService,
    protected modalCtrl: ModalController,
    protected accountService: AccountService,
    protected networkService: NetworkService,
    protected archiveRequestService: ArchiveRequestService
  ) {
    super(translateService, configService, infoService, popupService);
    this.mailAddress = this.accountService.cachedAccount.mail;
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  public async onArchiveRequest(): Promise<void> {
    if (this.networkService.isCurrentOffline()) {
      this.popupService.showAlert("settings.archiveRequest.title", "login.account.offline");
      return;
    }
    const response = await this.archiveRequestService.archiveRequest(this.mailAddress);
    const responseType = ServerResponse.type(response);
    switch (responseType) {
      case SERVER_RESPONSE_TYPE.SUCCESS:
        this.popupService.showToast("application.success", 3000, "bottom");
        this.modalCtrl.dismiss();
        break;
      case SERVER_RESPONSE_TYPE.ALREADY_EXISTS:
        return this.popupService.showAlert("settings.archiveRequest.title", "settings.archiveRequest.alreadyExists");
      default:
        FileLogger.error("ArchiveRequestModalComponent", "archiveRequest error", response);
        return this.popupService.showAlert("application.title", "login.error.subTitle");
    }
  }
}

import { Component, ViewChild } from "@angular/core";
import { IonMenu, IonSplitPane, PopoverController } from "@ionic/angular";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "src/app/baseClasses/base-component";
import { IAccount } from "src/app/helpers/account-helper";
import { IAppointment } from "src/app/models/appointment";
import { ICareplan } from "src/app/models/careplan";
import { ICommunication } from "src/app/models/communication";
import { IRelatedPerson, RelatedHelper } from "src/app/models/relatedPerson";
import { Reference } from "src/app/models/sharedInterfaces";
import { BadgesService } from "src/app/services/badges.service";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { AppointmentService } from "src/app/services/globalDataProvider/appointment.service";
import { CareplanService } from "src/app/services/globalDataProvider/careplan.service";
import { CommunicationService } from "src/app/services/globalDataProvider/communication.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { RelatedCareplansService } from "src/app/services/globalDataProvider/related-careplans.service";
import { RelatedPatientsService } from "src/app/services/globalDataProvider/related-patients.service";
import { GoToPageService } from "src/app/services/go-to-page.service";
import { DataAndType, HealthAppService } from "src/app/services/health-app.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoginService } from "src/app/services/login.service";
import { ModalService } from "src/app/services/modal.service";
import { PopupService } from "src/app/services/popup.service";
import { SysAccountService } from "src/app/services/sys-account.service";
import { Tools } from "../../helpers/tools-helper";
import { LoginPage } from "src/app/login/login.page";
import { Subscription } from "rxjs";
import { FEATURES } from "src/environments/features";
import { CountryService } from "src/app/services/globalDataProvider/country.service";
import { ICountry } from "src/app/models/country";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent extends BaseComponent {
  public showCarePlanSubmenu = false;
  public userCareplans: ICareplan[];
  public careplanMenuVisible = false;
  public relatedSelected: Reference = null;
  public relatedPersons: IRelatedPerson[];
  public relatedPerson: IRelatedPerson;
  public showDetails = false;
  public relatedCareplans: ICareplan[] = [];
  public account: IAccount = null;
  public appointments = new Array<IAppointment>();
  public FEATURES = FEATURES;
  public splitPlaneVisible: boolean;
  public forceWideMenu: boolean;
  public isCompact: boolean;
  public country: ICountry;

  @ViewChild(IonMenu) menu: IonMenu;
  @ViewChild(IonSplitPane) splitPane: any;

  careplanBadgeCount: any;
  public appointmentBadgeCount = 0;
  public messagesBadgeCount: number = null;
  public messages: ICommunication[] = [];
  public activityBadgeCount: number = null;
  public activityDatas: DataAndType[] = [];

  private subscriptions$: Subscription[] = [];
  public isCollapsed = false;

  public isAuthorizeCareplan: boolean;
  public isAuthorizeDrug: boolean;
  public isAuthorizeAgenda: boolean;

  constructor(
    public infoService: InfoAppService,
    protected popupService: PopupService,
    public goToPageService: GoToPageService,
    public accountService: AccountService,
    protected careplanService: CareplanService,
    protected configService: ConfigurationService,
    protected loginService: LoginService,
    protected modalCtrl: ModalService,
    protected popover: PopoverController,
    protected relatedCareplansService: RelatedCareplansService,
    private sysAccountService: SysAccountService,
    protected relatedPatientsService: RelatedPatientsService,
    private appointmentService: AppointmentService,
    private badgesService: BadgesService,
    private communicationService: CommunicationService,
    private countryService: CountryService,
    protected healthAppService: HealthAppService
  ) {
    super(infoService, popupService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.badgesService.$appointmentBadgeCount
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => (this.appointmentBadgeCount = Tools.isDefined(r) && r > 0 ? r : 0));
    this.loginService
      .watchAuthState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.userCareplans = undefined;
          this.relatedPersons = undefined;
          this.relatedPerson = undefined;
          this.relatedCareplans = undefined;
          this.relatedSelected = null;
          this.account = null;
          this.appointments = new Array<IAppointment>();
          this.careplanBadgeCount = 0;
          this.appointmentBadgeCount = 0;
          this.messagesBadgeCount = 0;
          this.messages = [];
          this.activityBadgeCount = null;
          this.activityDatas = [];
          this.country = undefined;
        } else {
          this.account = this.accountService.peekData();
          this.cleanSubscriptions();
          this.onCareplanUpdated();
          this.onAppointmentUpdate();
          this.computeBadgesCount();
          this.getRelatedPersons();
          this.countryService.getFirstDataAvailable().then((countries) => {
            this.country = countries?.length ? countries[0] : null;
          });
        }
      });
  }

  ngAfterViewInit() {
    this.menu?.ionWillOpen.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.cleanSubscriptions();
      this.onCareplanUpdated();
      this.onAppointmentUpdate();
      this.computeBadgesCount();
      this.getRelatedPersons();
      this.country = this.countryService.peekData()?.length ? this.countryService.peekData()[0] : null;
    });
    this.accountService
      .watchData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((account) => {
        this.account = account;
        if (account) {
          this.countryService.getFirstDataAvailable().then((countries) => {
            this.country = countries?.length ? countries[0] : null;
          });
        } else {
          this.country = undefined;
        }
      });
  }

  private cleanSubscriptions() {
    for (const sub of this.subscriptions$) {
      sub.unsubscribe();
    }
    this.subscriptions$ = [];
  }

  public setRelatedAuthorizations() {
    this.isAuthorizeCareplan = this.relatedPerson ? RelatedHelper.hasReadRightOn(this.relatedPerson, RelatedHelper.SHARE_CAREPLAN) : false;
    this.isAuthorizeDrug = this.relatedPerson ? RelatedHelper.hasReadRightOn(this.relatedPerson, RelatedHelper.SHARE_DRUG) : false;
    this.isAuthorizeAgenda = this.relatedPerson ? RelatedHelper.hasReadRightOn(this.relatedPerson, RelatedHelper.SHARE_AGENDA) : false;
  }

  public gotoCarePlan(careplan?: ICareplan) {
    if (this.userCareplans?.length) {
      const currentCareplan = careplan ? careplan : this.userCareplans[0];
      this.careplanService.pokeCurrentCareplan(currentCareplan);
      this.goToPageService.carePage({
        accountId: this.accountService.cachedCaremateId,
      });
    }
  }

  public goToMyReward() {
    return this.goToPageService.rewardPage();
  }

  public gotoMyEntourage() {
    return this.goToPageService.entouragePage();
  }
  public gotoRecommendation() {
    return this.goToPageService.recommandationPage();
  }

  /**
   * Open/close careplan menu
   */
  public toggleCareplanMenu(close?: boolean) {
    this.showCarePlanSubmenu = close ? false : !this.showCarePlanSubmenu;
    const elem = document.getElementsByClassName("accordionPanel");
    const panel = elem[0] as HTMLElement;
    if (panel === undefined) return;
    if (!this.showCarePlanSubmenu) panel.style.height = null;
    else panel.style.height = panel.scrollHeight + "px";
  }

  /**
   * a Careplan has been modified/created
   */
  private async onCareplanUpdated() {
    this.userCareplans = await this.careplanService.getFreshestData();
    this.toggleCareplanMenu();
  }

  public careplanMenuVisibleInverser(): void {
    this.careplanMenuVisible = !this.careplanMenuVisible;
    this.computeCareplanBadgeCount();
  }

  /**
   * Compute all badges count
   */
  private computeBadgesCount(): Promise<void> {
    return this.computeCareplanBadgeCount()
      .then(() => {
        return this.computeMessagesBadgeCount();
      })
      .then(() => {
        return this.computeActivityBadgeCount();
      })
      .then(() => {
        return this.badgesService.computeAppointmentBadgeCount();
      });
  }

  /**
   * Compute badge for careplans
   */
  private computeCareplanBadgeCount(): Promise<void> {
    let maxDay = 7; // default value
    const config = this.configService.getCacheConfiguration();
    if (config && config.settings.globalSettings) maxDay = config.settings.globalSettings.badgeRecent;
    let count = 0;
    // no careplans
    if (!this.userCareplans || this.userCareplans.length === 0) {
      this.careplanBadgeCount = null;
      return Promise.resolve();
    }
    // last time careplan page has been seen
    return this.infoService.getCareplanSeen().then((arrKve) => {
      for (const careplan of this.userCareplans) {
        // recent careplan modification
        if (moment(careplan.modified).isAfter(moment().add(-maxDay, "days"))) {
          if (!arrKve) {
            // user never go into any page
            (<any>careplan).badge = 1;
            count++;
            continue;
          }
          let cpFound = false;
          for (const kve of arrKve) {
            // found same careplan stored
            if (careplan._id === kve.key) {
              // not same modification date, this careplan has new update
              if (careplan.modified !== kve.value) {
                (<any>careplan).badge = 1;
                count++;
              } else (<any>careplan).badge = null; // same careplan already seen

              cpFound = true;
              break;
            }
          }
          // user never go into that careplan page
          if (!cpFound) {
            (<any>careplan).badge = 1;
            count++;
          }
        }
      }
      this.careplanBadgeCount = count ? count : null;
    });
  }

  public async computeMessagesBadgeCount(newData?: ICommunication[]) {
    if (newData) {
      this.messages = newData;
      this.messagesBadgeCount = this.countMessagesUnread(this.messages);
    } else {
      const dataReader = this.communicationService.getDataReader();
      for await (const data of dataReader) {
        this.messages = data;
        this.messagesBadgeCount = this.countMessagesUnread(data);
      }
    }
  }

  public computeActivityBadgeCount(): void {
    this.infoService.getParamHealth().then((prm) => {
      if (prm) {
        this.healthAppService.prepareAllDatasFromLoinc().then((datas) => {
          const nbr = this.healthAppService.computeNbrOfDatasToSend(datas);
          if (nbr) {
            this.activityBadgeCount = nbr;
            this.activityDatas = datas;
          } else {
            this.activityBadgeCount = null;
            this.activityDatas = undefined;
          }
        });
      } else {
        this.activityBadgeCount = null;
        this.activityDatas = undefined;
      }
    });
  }

  public countMessagesUnread(messages: ICommunication[]): number {
    const count = messages.filter((msg) => msg.received === null).length;
    return count ? count : null;
  }

  /**
   * a Careplan has been modified/created
   */
  private async onAppointmentUpdate() {
    this.appointments = await this.appointmentService.getFreshestData();
  }

  public gotoLogout() {
    this.popupService.showYesNo("application.title", "logout.advert").then((response) => {
      if (response && !LoginPage.onLoginPage) {
        this.loginService.disconnect();
      }
    });
  }

  public gotoMyObservations() {
    this.goToPageService.observationPage();
  }

  public gotoPreferences() {
    this.modalCtrl.presentModalSettingsMenu().then(() => {
      this.popover.dismiss();
    });
  }

  public gotoHelp() {
    this.modalCtrl.presentModalHelp();
  }

  public gotoShare() {
    this.modalCtrl.presentModalShare();
  }

  /**
   * Button from standard User menu: display related custom menu
   * @param related
   */
  public async onRelatedPerson(related: IRelatedPerson) {
    this.relatedPerson = related;
    this.setRelatedAuthorizations();
    this.showDetails = false;
    if (related) {
      this.relatedSelected = related.patient;
      this.relatedPatientsService.setRelatedPersonShown(related);
      // collect related data
      const dataReader = this.relatedCareplansService.getDataReader(related);
      for await (const data of dataReader) {
        this.relatedCareplans = data;
      }
    } else {
      this.relatedSelected = null;
      this.relatedCareplans = [];
      this.relatedPatientsService.setRelatedPersonShown(null);
      // Go back to home to avoid continuing showing ex-related info
      this.goToPageService.homePage({ account: this.sysAccountService.cachedCaremateId });
    }
  }

  /**
   *  click to go to entourage invitation
   */
  public onAddEntourage() {
    this.modalCtrl.presentModalEntourageAdd().then((relatedPerson: IRelatedPerson) => {
      if (relatedPerson) {
        // refresh the menu after modal closes
        this.refreshMenuData();
      }
    });
  }

  /**
   * (re)load some data we need in Menu
   */
  private async getRelatedPersons() {
    // gather the related patient of the current user
    const dataReader = this.relatedPatientsService.getDataReader(true);
    for await (const data of dataReader) {
      this.relatedPersons = data;
    }
  }

  /**
   * (re)load some data we need in Menu
   */
  private async refreshMenuData(): Promise<void> {
    if (!this.relatedPersons) this.relatedPersons = [];

    // gather the related patient of the current user
    const dataReader = this.relatedPatientsService.getDataReader(true);
    for await (const data of dataReader) {
      this.relatedPersons = data;
    }

    // gather all appointments
    this.appointmentService.getFreshestData().then((appointments) => {
      this.appointments = appointments;
      this.computeBadgesCount();
    });

    if (!this.userCareplans) this.userCareplans = [];
    this.userCareplans = await this.careplanService.getFirstDataAvailable();
  }

  /**
   * Click on person selection header
   */
  public onPersonSelection() {
    this.refreshMenuData();
    if (this.relatedPersons && this.relatedPersons.length > 0) this.showDetails = !this.showDetails;
  }

  /**
   * from menu selection, display My Care Plan View
   */
  public gotoRelatedCarePlan(careplan: ICareplan) {
    this.relatedCareplansService.pokeCurrentCareplan(careplan);
    this.goToPageService.carePage({ accountId: this.sysAccountService.cachedCaremateId });
  }

  /**
   * from menu selection, display Related Agenda View
   */
  public gotoRelatedDrugs() {
    this.goToPageService.drugPage({
      careplans: this.relatedCareplans,
      accountId: this.sysAccountService.cachedCaremateId,
      isRelated: true,
    });
  }

  /**
   * from menu selection, display Related Agenda View
   */
  public gotoRelatedAgenda() {
    this.goToPageService.appointmentPage({
      careplans: this.relatedCareplans,
      accountId: this.sysAccountService.cachedCaremateId,
      isRelated: true,
    });
  }

  public gotoMyHealth() {
    this.goToPageService.healthPage();
  }

  /**
   * Triggers when Split Pane display status changes based on the breaking point indicated in template
   * @param event
   */
  public onSplitPaneVisible(event: CustomEvent) {
    this.splitPlaneVisible = event.detail.visible;
    this.isCompact = this.splitPlaneVisible && !this.forceWideMenu;
  }

  /**
   * Force menu to show in full width even if split pane is not visible
   */
  public toggleMenu() {
    this.forceWideMenu = !this.forceWideMenu;
    this.isCompact = this.splitPlaneVisible && !this.forceWideMenu;
  }
}

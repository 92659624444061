import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { LocalStorageService } from "../storage/local-storage.service";
import { ConfigurationService } from "./configuration.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

export enum I18nTarget {
  MOBILE = "mobile",
  APPMOBILE = "appMobile",
  DASHBOARD = "dashboard",
  SERVER = "server",
}

export interface II18nTranslation {
  lang: string;
  target: I18nTarget;
  i18n: any;
}

@Injectable({
  providedIn: "root",
})
export class I18nService
  extends BasicSyncService<II18nTranslation, II18nTranslation[]>
  implements StaticImplements<INeedRefresh, typeof I18nService>
{
  public get needRefresh(): { value: boolean } {
    return I18nService._needRefresh;
  }
  private currentLang = "";
  public static _needRefresh = {
    value: true,
  };

  public get entityStoreKey(): string {
    if (!this.currentLang) {
      this.currentLang = this.configService.getCurrentLanguage();
    }
    return super.entityStoreKey + this.currentLang;
  }

  constructor(protected dataService: DataService, private configService: ConfigurationService, private translateSvc: TranslateService) {
    super(dataService);
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.currentLang = this.configService.getCurrentLanguage();
    } catch (err) {
      this.currentLang = "";
    }
  }
  public clear(): void {
    super.clear();
    this.currentLang = "";
  }

  public getUrl(): string {
    if (!this.currentLang) {
      this.currentLang = this.configService.getCurrentLanguage();
    }
    return this.defaultDataParameter.getUrl + this.currentLang;
  }

  public peekData(lang?: string): II18nTranslation[] {
    const tr = super.peekData();
    if (lang && lang !== this.currentLang) {
      this.currentLang = lang;
    }
    if (!this.currentLang) {
      this.currentLang = this.configService.getCurrentLanguage();
    }
    if (tr && tr.length > 0 && tr[0].lang === this.currentLang) {
      return tr;
    } else {
      return [];
    }
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<II18nTranslation[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "translation_",
      entityStoreKey: "i18n_",
      getUrl: "/i18nTranslation?target=" + I18nTarget.MOBILE + "&langs=",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async *getDataReader(lang?: string): AsyncGenerator<II18nTranslation[], II18nTranslation[], II18nTranslation[]> {
    try {
      if (lang && lang !== this.currentLang) {
        this.currentLang = lang;
      }
      if (!this.currentLang) {
        this.currentLang = this.configService.getCurrentLanguage();
      }
      const paramObject = Object.assign({}, this.defaultDataParameter);
      paramObject.getUrl += this.currentLang;
      paramObject.entityStoreKey += this.currentLang;
      const dataReader = this.dataService.readv2<II18nTranslation, II18nTranslation[]>(paramObject, false, this);
      let d: II18nTranslation[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("I18nService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  public async getFreshestData(lang?: string): Promise<II18nTranslation[]> {
    const dataReader = this.getDataReader(lang);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  public async getFirstDataAvailable(lang?: string): Promise<II18nTranslation[]> {
    const dataReader = this.getDataReader(lang);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  /**
   * Forcibly refresh the translations. Works only if the user is connected.
   * This will badly bug if the user is not connected.
   * @param l (optional) a specific lang for which we want to refresh the translations.
   * It will use the current lang if not mentioned.
   */
  public async refreshTranslations(l?: string): Promise<void> {
    const lang = l ? l : this.configService.getCurrentLanguage();
    const trsl = this.peekData(lang);

    if (trsl && trsl.length > 0 && trsl[0].lang === lang) {
      this.translateSvc.setTranslation(lang, trsl[0].i18n);
    } else {
      await this.getFreshestData(lang).then((translations) => {
        if (translations && translations.length > 0) {
          this.translateSvc.setTranslation(lang, translations[0].i18n);
        }
      });
    }
  }

  /**
   *
   * @returns the keys ("en", "fr"...) of the translations already downloaded
   */
  public async getStoredTranslationsLangsList(): Promise<string[]> {
    const langKeyStart = this.defaultDataParameter.entityPrefix + this.defaultDataParameter.entityStoreKey;
    const keys = await LocalStorageService.getAllKeysStartingWith(langKeyStart);
    return keys.map((k) => k.slice(langKeyStart.length, k.length));
  }

  /**
   * Remove a specific lang translations from storage.
   * It cannot be the current lang.
   * @param langs the languages we want to remove
   */
  public async removeTranslationsFromStorage(langs: string[]): Promise<void> {
    this.currentLang = this.configService.getCurrentLanguage();
    const i = langs.findIndex((l) => l === this.currentLang);
    if (i > -1) {
      langs.splice(i, 1);
    }
    const keysToRemove = langs.map((l) => this.defaultDataParameter.entityPrefix + this.defaultDataParameter.entityStoreKey + l);
    await LocalStorageService.removeSeveralKeys(keysToRemove);
  }

  /**
   * Remove all translations except the one for the current language from the storage
   * @returns
   */
  public async removeAllTranslationsExceptCurrent(): Promise<void> {
    this.currentLang = this.configService.getCurrentLanguage();
    const langKeyStart = this.defaultDataParameter.entityPrefix + this.defaultDataParameter.entityStoreKey;
    const langKey = langKeyStart + this.currentLang;
    await LocalStorageService.clearAllKeysStartingWithExcept(langKeyStart, langKey);
  }
}

import { Component } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from "src/app/baseClasses/base-component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ICountry } from "src/app/models/country";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { CountryService } from "src/app/services/globalDataProvider/country.service";
import { GoToPageService } from "src/app/services/go-to-page.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoaderService } from "src/app/services/loader.service";
import { LoginService } from "src/app/services/login.service";
import { ModalService } from "src/app/services/modal.service";
import { PopupService } from "src/app/services/popup.service";
import { SynchronisationService, SynchronisationServiceStatus } from "src/app/services/synchronisation.service";
import { FEATURES } from "src/environments/features";

@Component({
  selector: "app-extra-menu-pop-over",
  templateUrl: "./extra-menu-pop-over.component.html",
  styleUrls: ["./extra-menu-pop-over.component.scss"],
})
export class ExtraMenuPopOverComponent extends BaseComponent {
  public country: ICountry;
  public FEATURES = FEATURES;
  constructor(
    protected popover: PopoverController,
    protected popupService: PopupService,
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    public loginService: LoginService,
    protected infoService: InfoAppService,
    protected synchronisationService: SynchronisationService,
    protected loaderService: LoaderService,
    protected goToPageService: GoToPageService,
    protected modalCtrl: ModalService,
    private countryService: CountryService
  ) {
    super(infoService, popupService);
    this.translateSvc.setDefaultLang(this.configService.getCurrentLanguage());
    this.country = this.countryService.peekData()?.length ? this.countryService.peekData()[0] : null;
  }

  public async onSync(): Promise<void> {
    try {
      await this.loaderService.showLoading(true);
      await this.popover.dismiss();
      const synchroStatus = await this.synchronisationService.syncApp(true);
      await this.loaderService.showLoading(false);
      switch (synchroStatus) {
        case SynchronisationServiceStatus.alreadyInProgress:
          FileLogger.log("ExtraMenuPopOverComponent", "Sync already in progress");
          break;
        case SynchronisationServiceStatus.noCordovaPlatform:
          FileLogger.log("ExtraMenuPopOverComponent", "No cordova platform, no sync");
          break;
        case SynchronisationServiceStatus.offline:
          this.popupService.showAlert("application.title", "synchro.error.offline_instead_of_online");
          break;
        case SynchronisationServiceStatus.missingAccountOrToken: // TODO bad token :faire de même
          this.goToPageService.loginPage();
          break;
        case SynchronisationServiceStatus.error:
          this.popupService.showAlert("application.title", "synchro.error.subTitle");
          break;
        case SynchronisationServiceStatus.success:
          FileLogger.log("ExtraMenuPopOverComponent", "onSync SUCCESS");
          break;
      }
    } catch (error) {
      FileLogger.error("ExtraMenuPopOverComponent", "onSync", error);
      await this.loaderService.showLoading(false);
      await this.popover.dismiss();
      await this.popupService.showAlert("application.title", "synchro.error.subTitle");
    }
  }

  /**
   * Display about modal
   */
  public onAbout(): void {
    this.modalCtrl.presentModalAbout().then(() => {
      this.popover.dismiss();
    });
  }

  /**
   * Display help modal
   */
  public onHelp(): void {
    this.modalCtrl.presentModalHelp().then(() => {
      this.popover.dismiss();
    });
  }

  /**
   * Display share modal
   */
  public onShare(): void {
    this.modalCtrl.presentModalShare().then(() => {
      this.popover.dismiss();
    });
  }

  /**
   * Display settings
   */
  public onSettingsMenu(): void {
    this.modalCtrl.presentModalSettingsMenu().then(() => {
      this.popover.dismiss();
    });
  }
}

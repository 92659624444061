import { Pipe, PipeTransform } from "@angular/core";
import { IObservationParam } from "../models/configuration";
import { ObservationDefinitionService } from "../services/globalDataProvider/observation-definition.service";

// Add new pipe here because we start from IObservationParam
@Pipe({ name: "translateObservation" })
export class TranslateObservationPipe implements PipeTransform {
  constructor(private obsDefService: ObservationDefinitionService) {}

  transform(param: IObservationParam) {
    return this.obsDefService.getTranslatedName(param.type);
  }
}

import { Component, ViewChild } from "@angular/core";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { AlertController, IonContent, IonSlides, ModalController, NavParams, PopoverController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppConstants } from "src/app/appConstants";
import { BasePage } from "src/app/baseClasses/base-page";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IKnowMedia, IKnowledgeBase, KNOW_DOC_CATEGORY, KNOW_DOC_TYPE, KnowledgeBase } from "src/app/helpers/knowledge-helper";
import { IQuestionnaire } from "src/app/models/questionnaire";
import { SlideView, SummaryPoint } from "src/app/models/slideView";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { QuizDefinitionService } from "src/app/services/globalDataProvider/quiz-definition.service";
import { StatEventService } from "src/app/services/globalDataProvider/statEvent.service";
import { StatSummaryService, StatToIncrement } from "src/app/services/globalDataProvider/statSummary.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";
import { QuizService } from "src/app/services/quiz.service";
import { SummaryComponent } from "../summary/summary.component";

@Component({
  selector: "app-knowledge-modal",
  templateUrl: "./knowledge-modal.component.html",
  styleUrls: ["./knowledge-modal.component.scss"],
})
export class KnowledgeModalComponent extends BasePage {
  @ViewChild("slides") slides: IonSlides;
  /// HTML ion content element (needed for scrolling)
  @ViewChild(IonContent) content: IonContent;
  public knowledges: IKnowledgeBase[];
  public name: string;
  public knowMedias: IKnowMedia[];
  public category: KNOW_DOC_CATEGORY;
  public extraUrl: string;
  public descriptionSlides: SlideView[] = [];
  public summary: SummaryPoint[] = [];
  private isRelated = false;
  public isBeginning = true;
  public isEnd = false;
  private sliderSub: Subscription;
  public isLoading = true;
  public swipOpts = {
    autoHeight: true,
    speed: 200,
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  };
  private toastHasBeenShown = false;
  private quizzes: IQuestionnaire[] = [];

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    public params: NavParams,
    // public viewCtrl: ViewController,
    protected translateSvc: TranslateService,
    protected alertCtrl: AlertController,
    private iab: InAppBrowser,
    protected popoverCtrl: PopoverController,
    public configService: ConfigurationService,
    protected modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private quizService: QuizService,
    private quizDefService: QuizDefinitionService,
    private statSummaryService: StatSummaryService,
    private statEventService: StatEventService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.knowledges = params.get("knowledges");
    this.name = params.get("name");
    this.isRelated = params.get("isRelated");
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.knowMedias = new Array<IKnowMedia>();
    // display all text media (in current language)
    if (this.knowledges?.length) {
      this.knowledges.forEach((knowledge) => {
        this.category = knowledge.documentCategory;
        // dipslay medias text
        this.knowMedias = this.knowMedias.concat(
          KnowledgeBase.getMediaCurrentLang(knowledge, KNOW_DOC_TYPE.TEXT, this.configService.getCurrentLanguage())
        );
      });
      // add extra url
      if (this.knowMedias?.length) {
        this.extraUrl = this.knowMedias[0].extra;
        this.setSummaryPoints();
        this.setDescriptionSlides();
      }
    }
  }

  /**
   *  Ionic Event: enter in view
   */
  ionViewDidEnter(): void {
    super.ionViewDidEnter();
    for (let i = 0; i < this.descriptionSlides.length; i++) {
      this.buildDynamicSlideContent(i, this.descriptionSlides[i].content);
    }
    if (this.slides) {
      this.initSlidesBool();
    }
    this.isLoading = false;

    this.loadQuizzes();
  }

  private async initSlidesBool() {
    this.isEnd = this.descriptionSlides.length <= 1 ? true : await this.slides?.isEnd();
    this.sliderSub = this.slides.ionSlideTransitionEnd?.pipe(takeUntil(this.onDestroy$))?.subscribe(async () => {
      this.isEnd = await this.slides.isEnd();
      this.isBeginning = await this.slides.isBeginning();
      await this.slides.updateAutoHeight(200);
    });
  }

  /**
   * Build content for each slides/pages
   */
  private setDescriptionSlides() {
    if (!this.knowMedias) return;
    if (this.category !== KNOW_DOC_CATEGORY.DESCRIPTION && this.category !== KNOW_DOC_CATEGORY.RECOMMENDATION) return; // only for description et advices
    for (const media of this.knowMedias) {
      // pages can be separated by "<hr>" tag in media "content" field
      if (media.content) {
        const contents = media.content.split('<hr id="null">');
        // add a page for each part
        for (const content of contents) {
          const slide: SlideView = {
            title: media.label,
            content: content,
          };
          this.descriptionSlides.push(slide);
        }
      } else {
        // no (formatted) content, only simple description
        const slide: SlideView = {
          title: media.label,
          content: media.description,
        };
        this.descriptionSlides.push(slide);
      }
    }
  }

  ionViewWillLeave(): void {
    super.ionViewWillLeave();
    this.sliderSub?.unsubscribe();
  }

  /**
   * dynamically build content of a slide
   */
  private buildDynamicSlideContent(index: number, htmlContent: string) {
    try {
      const elem = document.getElementById("htmlDescription" + index);
      if (elem) {
        const domParser = new DOMParser();
        const doc: XMLDocument = domParser.parseFromString(htmlContent, "text/html");
        const abbrElements: NodeListOf<HTMLElement> = doc.querySelectorAll("abbr");
        const imgElements: NodeListOf<HTMLImageElement> = doc.querySelectorAll("img");

        // handle maximize class on click
        imgElements.forEach((el, indexOfImage) => {
          el.id = `${index}${indexOfImage}`;
          el.setAttribute(
            "onclick",
            `var x=document.getElementById('${index}${indexOfImage}');x.classList.toggle('maximizeImage');var btn=getElementById('hiddenBtn'); btn.click();`
          );
        });

        if (!abbrElements) return; // nothing to do
        // loop on ABBR tags
        for (let i = 0; i < abbrElements.length; i++) {
          const abbr = abbrElements.item(i);
          const text = abbr.textContent;
          const title = abbr.getAttribute("title");
          // ABBR set with title or id attribute ?
          if (title) {
            // new content with popup for ABBR tag
            const abbrContent = `<span onclick="var x=document.getElementById('snackbar${i}');x.classList.toggle('snackbarShow');">
                                          <u class="dotted">${text}</u>
                                          <span class="snackbar" id="snackbar${i}">${title}</span>
                                          </span>`;
            abbr.innerHTML = abbrContent;
          }
        }
        htmlContent = doc.documentElement.innerHTML;
        elem.innerHTML = htmlContent;

        /*      const root = parse(htmlContent);
             if (root instanceof HTMLElement) {
                 const abbrElements = root.querySelectorAll('abbr');
                 if (!_.isEmpty(abbrElements)) {
                     const arrPromise = new Array<Promise<void>>();
                     for (let i = 0; i < abbrElements.length; i++) {
                         const text = abbrElements[i].text;
                         // title or id tag ?
                         const title = abbrElements[i].attributes["title"];
                         const idSnomed = abbrElements[i].attributes["id"];
                         if (!_.isNil(title)) {
                             // new content for ABBR tag
                             const abbrContent = `<span onclick="var x=document.getElementById('snackbar${i}');x.classList.toggle('snackbarShow');">
                                                     ${text}
                                                     <span class="snackbar" id="snackbar${i}">${title}</span>
                                                     </span>`;
                             abbrElements[i].set_content(abbrContent);
                             arrPromise.push(Promise.resolve());
                         }
                         else if (!_.isNil(idSnomed)) {
                             // get knowledge references by this ID
                             const p = this.sqlStorageService.getKnowledges([idSnomed])
                                 .then((knowledges) => {
                                     const description = KnowledgeBase.getMainKnowledge(knowledges, KNOW_DOC_CATEGORY.DESCRIPTION);
                                     if (!_.isNil(description)) {
                                         const medias = KnowledgeBase.getMediaCurrentLang(description, KNOW_DOC_TYPE.TEXT, this.configService.getCurrentLanguage());
                                         if (!_.isEmpty(medias)) {
                                             // new content for ABBR tag
                                             const abbrContent = `<span onclick="var x=document.getElementById('snackbar${i}');x.classList.toggle('snackbarShow');">
                                                     <u>${text}</u>
                                                     <span class="snackbar" id="snackbar${i}">${medias[0].description}</span>
                                                     </span>`;
                                             abbrElements[i].set_content(abbrContent);
                                         }
                                     }
                                 });
                             arrPromise.push(p);
                         }
                     }
                     // wait for all update
                     Promise.all(arrPromise)
                         .then((results) => {
                             htmlContent = root.toString();
                             elem.innerHTML = htmlContent;
                         });
                 }
             }
        elem.innerHTML = htmlContent;   // nothing to do */
      }
    } catch (err) {
      FileLogger.error("KnowledgeModalComponent", "buildDynamicSlideContent", err);
    }
  }

  /**
   * clic on "more info" button
   */
  public onMoreInfo(): void {
    if (this.extraUrl) {
      this.iab.create(this.extraUrl, "_blank");
    }
  }

  public async dismiss(): Promise<void> {
    const activeIndex = await this.slides?.getActiveIndex();
    const currentLength = await this.slides?.length();
    const isLastPage = this.descriptionSlides.length === 0 || currentLength === activeIndex + 1;
    this.recordKnowledgeReadStat(isLastPage);
    this.modalCtrl.dismiss({ isLastPage });
  }

  private async recordKnowledgeReadStat(isLastPage: boolean): Promise<void> {
    const knowledgesTitles = this.knowledges?.map((k) => k.snomedReference?.display).join(", ");
    const knString = knowledgesTitles ? " (" + knowledgesTitles + ")" : "";
    if (isLastPage) {
      this.statEventService.newEvent("Fully read knowledge(s)" + knString);
    } else {
      this.statEventService.newEvent("Partially read knowledge(s)" + knString);
    }
    this.statSummaryService.increment(StatToIncrement.READ_KNOWLEDGE);
  }

  /**
   * creation of the summary array
   */
  private setSummaryPoints() {
    for (const media of this.knowMedias) {
      this.getSummaryPoint(media.content).forEach((val) => this.summary.push(val));
    }
  }

  /**
   * @param content
   * we get in the html all the h1 balise and we save the id and the text
   */

  private getSummaryPoint(content: string): SummaryPoint[] {
    // build html structure
    const el = document.createElement("html");
    el.innerHTML = content;
    // get summaryPoint html tag
    const SummaryPoints = el.getElementsByTagName("h1");
    const allSummaryPoints: SummaryPoint[] = [];

    for (let index = 0; index < SummaryPoints.length; index++) {
      const element = SummaryPoints[index];
      const sp: SummaryPoint = {
        page: parseInt(element.id, 10),
        title: element.innerText,
      };
      if (!Number.isNaN(parseInt(element.id, 10))) {
        allSummaryPoints.push(sp);
      }
    }
    return allSummaryPoints;
  }

  public async goToSlide(page: number): Promise<void> {
    await this.handleSlideChange(false, true);
    await this.slides.slideTo(page);
    this.scrollToTop();
  }

  /**
   * Click on summary: display summary menu
   */
  public async openSummary(myEvent): Promise<void> {
    if (this.popoverCtrl) {
      // controller must be passed in constructor
      // first we build the component
      const popover = await this.popoverCtrl.create({
        component: SummaryComponent,
        componentProps: {
          data: this.summary,
          quizzes: this.quizzes,
          mediaId: this.knowMedias[0].identifier.value,
        },
        event: myEvent,
        cssClass: "summary",
      });

      popover.present();

      const data = (await popover.onDidDismiss())?.data;

      if (typeof data?.data === "number") {
        this.goToSlide(data.data);
      }
    }
  }

  public async handleImgClick(): Promise<void> {
    if (await this.isImgZoom()) {
      if (!this.toastHasBeenShown) {
        this.showToast();
        this.toastHasBeenShown = true;
      }
      if (this.slides) {
        await this.slides.lockSwipes(true);
      }
    } else {
      if (this.slides) {
        await this.slides.lockSwipes(false);
      }
    }
  }

  public showToast(): void {
    this.toastCtrl
      .create({
        message: this.translateSvc.instant("mycare.magnifyInfo"),
        duration: 4500,
      })
      .then((toast) => {
        toast.present();
      });
  }

  public async handleSlideChange(isNext = true, isSummary = false): Promise<void> {
    // reset image's zoom
    const index = await this.slides.getActiveIndex();
    const domParser = new DOMParser();
    const doc: XMLDocument = domParser.parseFromString(this.descriptionSlides[index].content, "text/html");
    const imgElements: NodeListOf<HTMLImageElement> = doc.querySelectorAll("img");
    imgElements.forEach((el, i) => {
      const x = document.getElementById(index.toString() + i.toString());
      if (x.classList.contains("maximizeImage")) {
        x.classList.toggle("maximizeImage");
      }
    });
    await this.slides.lockSwipes(false); // unlock the swipes
    if (!isSummary) {
      isNext ? await this.slides.slideNext() : await this.slides.slidePrev();
      this.scrollToTop();
    }
  }

  public onSlideChanged(): void {
    this.scrollToTop();
  }

  // return true if there is minimum one image zoomed
  private async isImgZoom() {
    const index = await this.slides.getActiveIndex();
    const domParser = new DOMParser();
    const doc: XMLDocument = domParser.parseFromString(this.descriptionSlides[index].content, "text/html");
    const imgElements: NodeListOf<HTMLImageElement> = doc.querySelectorAll("img");
    let value = false;
    imgElements.forEach((el, i) => {
      const x = document.getElementById(index.toString() + i.toString());
      if (x.classList.contains("maximizeImage")) {
        value = true;
      }
    });
    return value;
  }

  /**
   *  Check where we are on the page and if we are not near the top, scroll to
   *  the top.
   */
  private async scrollToTop() {
    const scrollElement = await this.content.getScrollElement();
    if (scrollElement.scrollTop > 10) {
      this.content.scrollToTop(500);
    }
  }

  /**
   * Load quizzes linked to knowledges
   */
  public async loadQuizzes(): Promise<void> {
    const q = await this.quizDefService.getFirstDataAvailable(true);
    this.quizzes = q.filter((q) => q.mediaId === this.knowMedias[0]?.identifier.value);
  }

  /**
   * Handle displaying a quiz
   */
  public async onDisplayQuiz(mediaId: string): Promise<void> {
    await this.quizService.onDisplayQuiz(mediaId, AppConstants.PAGE_KNOWLEDGE, this.quizzes);
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IKnowMedia, IKnowledges, IMediaFromRule } from "src/app/helpers/knowledge-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { ICheckSum } from "src/app/models/checksum";
import { ApiService } from "../api.service";
import { HashService } from "../hash.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { AccountService } from "./account.service";
import { CareplanService } from "./careplan.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class KnowledgeService
  extends BasicSyncService<IKnowledges, IKnowledges[]>
  implements StaticImplements<INeedRefresh, typeof KnowledgeService>
{
  public get needRefresh(): { value: boolean } {
    return KnowledgeService._needRefresh;
  }
  public lastGenNotif: string = null;

  private lastValueOfParam = "[]";
  private storageKey = "KnowledgeLastValue";
  public static _needRefresh = {
    value: true,
  };

  constructor(
    protected dataService: DataService,
    private careplanService: CareplanService,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private apiService: ApiService
  ) {
    super(dataService);
  }

  public getUrl(): string {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "knwoledgesCareplans_",
      entityStoreKey: "list",
      getUrl: "/knowledge?reference=",
      setUrl: "/knowledge",
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
    } catch (err) {
      this.lastValueOfParam = "[]";
    }
  }

  public clear(): void {
    super.clear();
    this.lastValueOfParam = "[]";
  }

  public async *getDataReader(parameters?: string[]): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      let params = [];
      if (parameters) {
        params = parameters.map((value) => Tools.deleteAcccentSpecialcharacter(value.toLowerCase()));
      }
      const snomed = await this.careplanService.listSnomedRefActivities();
      const paramObject = Object.assign({}, this.defaultDataParameter);
      const stringifySnomed = JSON.stringify(snomed);
      paramObject.getUrl += stringifySnomed;
      // do not modify entityStoreKey to allow the patient to have access at knowledge even if there are changes in parameters
      // paramObject.entityStoreKey += stringifySnomed;
      this.lastValueOfParam = stringifySnomed;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);

      const dataReader = this.dataService.readv2<IKnowledges, IKnowledges[]>(paramObject, false, this);
      let d: IKnowledges[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, params);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("KnowledgeService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IKnowledges[], params?: string[]) {
    try {
      const kn = dataResult;
      if (!params) {
        return kn;
      }
      const filteredData = kn.filter((kk) => {
        const parsedRef = Tools.deleteAcccentSpecialcharacter(kk.reference.toLowerCase());
        return params.includes(parsedRef);
      });
      return filteredData;
    } catch (err) {
      FileLogger.error("KnowledgeService", "Error while processing knowledgeService data: ", err);
      return dataResult;
    }
  }

  public async getMediaFromRule(ruleId: number, vitalProfileCode: string): Promise<IMediaFromRule> {
    const apiResponse = await this.apiService.getWithPromise(`/mediaFromRule`, undefined, {
      ruleId: ruleId,
      vitalProfileCode: vitalProfileCode,
    });
    return apiResponse.data;
  }

  public async getMedia(mediaId: string, publicationDate: string): Promise<IKnowMedia> {
    const apiResponse = await this.apiService.getWithPromise(`/media`, undefined, {
      identifier: mediaId,
      publicationDate: publicationDate,
    });
    return apiResponse.data;
  }
  public async checkSum(): Promise<ICheckSum> {
    const snomed = await this.careplanService.listSnomedRefActivities();
    const paramObject = Object.assign({}, this.defaultDataParameter);
    const stringifySnomed = JSON.stringify(snomed);
    paramObject.getUrl += stringifySnomed;
    // do not modify entityStoreKey to allow the patient to have access at knowledge even if there are changes in parameters
    // paramObject.entityStoreKey += stringifySnomed;
    this.lastValueOfParam = stringifySnomed;
    const dataReader = this.dataService.readv2<IKnowledges, IKnowledges[]>(paramObject, true);
    const localDataIt = await dataReader.next();
    const localData = localDataIt.value;
    return {
      checkSum: HashService.getMd5HashOfObject(localData, ["actionStatus"]),
      nameRoute: paramObject.getUrl,
    } as ICheckSum;
  }
}

import { Injectable } from "@angular/core";
import { AppConstants } from "src/app/appConstants";
import { SecureStorageEcho, SecureStorageEchoObject } from "@ionic-native/secure-storage-echo/ngx";
import { Platform } from "@ionic/angular";
import { LocalStorageInitService } from "./local-storage-init.service";
import { Tools } from "src/app/helpers/tools-helper";
import { FileLogger } from "src/app/helpers/fileLogger";

@Injectable({
  providedIn: "root",
})
export class SecureStorageService {
  private secureStorage: SecureStorageEchoObject | null = null;

  constructor(private secureStorageEcho: SecureStorageEcho, private platform: Platform, private localStorage: LocalStorageInitService) {}

  public async set(key: string, value: string): Promise<any> {
    if (await this.isReady()) {
      return this.secureStorage.set(key, value);
    }
    return null;
  }

  public async get(key: string): Promise<string | null> {
    if (await this.isReady()) {
      return this.secureStorage.get(key);
    }
    return null;
  }

  public async remove(key: string): Promise<string | null> {
    if (await this.isReady()) {
      return this.secureStorage.remove(key);
    }
    return null;
  }

  private async keys(): Promise<string[]> {
    if (await this.isReady()) {
      return this.secureStorage.keys();
    }
    return [];
  }

  public async isStored(key: string): Promise<boolean> {
    const keys = await this.keys();
    return keys.includes(key);
  }

  public async clear(): Promise<any> {
    if (await this.isReady()) {
      return this.secureStorage.clear();
    }
    return null;
  }

  private async create(storage: string) {
    this.secureStorage = await this.secureStorageEcho.create(storage);
  }

  public async isReady(): Promise<boolean> {
    try {
      if (this.secureStorage === null && this.isCordova()) {
        // On Android, always create secure storage
        if (this.isAndroid()) {
          await this.platform.ready();
          try {
            await this.create(AppConstants.STORAGE_NAME);
          } catch (error) {
            FileLogger.error("SecureStorageService", "ISREADY first Error", JSON.stringify(error));
            // On Android 12, for the first installation, we must try again
            await Tools.wait(1000);
            await this.create(AppConstants.STORAGE_NAME);
          }
        }
        // On IOS, only create the secure storage for a new installation for ionic 5 (not if we migrate ionic 3 -> ionic 5)
        else if (this.isIOS()) {
          const newInstallation = await this.localStorage
            .getDataUnencryptedAndWithoutTransformKey(AppConstants.NEW_INSTALLATION_FROM_STORE)
            .catch(() => AppConstants.FALSE);

          if (newInstallation === AppConstants.TRUE) {
            await this.create(AppConstants.STORAGE_NAME);
          }
        }
      }
      return this.secureStorage !== null;
    } catch (error) {
      FileLogger.error("SecureStorageService", "ISREADY Error", JSON.stringify(error));
      this.secureStorage = null;
      return false;
    }
  }

  /**
   * because of the circular dependencies, I had to add this method
   * @returns true the smartphone is on ios, false otherwise
   */
  private isIOS(): boolean {
    return this.platform ? this.platform.is("ios") : false;
  }

  /**
   * because of the circular dependencies, I had to add this method
   * @returns true the smartphone is on android, false otherwise
   */
  private isCordova(): boolean {
    return this.platform ? this.platform.is("cordova") : false;
  }

  /**
   * because of the circular dependencies, I had to add this method
   * @returns true the smartphone is on android, false otherwise
   */
  private isAndroid(): boolean {
    return this.platform ? this.platform.is("android") : false;
  }
}

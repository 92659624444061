import { MedicalBluetoothService } from "../services/medical-bluetooth/medical-bluetooth.service";
import { AccessLevel, IEntity, ITranslationKv } from "./sharedInterfaces";

export interface IExternalRessourceMeta {
  platform: {
    android: string;
    ios: string;
  };
  availableLoinc: string[];
  obsDefAccess?: AccessLevel; // indicate whether or not the user can encode manually a new observation of this type
  obsAccess?: AccessLevel; // indicate whether the user can see and manually edit an observation encoded via this external ressource
  componentAnswer?: IComponentAnswer[];
  force?: boolean;
  noBonding?: boolean;
  medicalBluetoothService: MedicalBluetoothService;
  onlineDevice?: OnlineDevice;
  onlineAuth?: boolean;
  searchPreviousData?: boolean;
  previousDataNbDaysToCheck?: number;
  previousDataNbMinutesNoChecking?: number;
}

export interface IComponentAnswer {
  loinc: string;
  inputName: string;
  displayMethod?: METHOD_DISPLAY_DEVICE_DATA;
  isHiddenLoinc?: boolean;
  graphExtraInfos?: IGraphExtraInfos;
}

export interface IGraphExtraInfos {
  yAxesMin?: number;
  yAxesMax?: number;
  xAxesMin?: string;
  xAxesMax?: string;
  xLines?: ILines; // any lines to be drawn vertically
  yLines?: ILines; // any lines to be drawn horizontally
}
export interface ILines {
  type: LineType;
  values: unknown[];
  vitalProfileCode?: string[];
  colors?: string[];
}

export enum LineType {
  NUMBER = "number",
  STRING = "string",
  DATE = "date",
}

export enum EXTERNAL_RESSOURCE_TYPE {
  APP = 1,
  BLUETOOTH_HARDWARE = 2,
  URL = 3,
  ONLINE_HARDWARE = 4,
  BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK = 5,
}

export interface IExternalRessource extends IEntity {
  title: string;
  description: string;
  content?: string;
  image?: string;
  categories: string[];
  SCTIDs: string[];
  type: EXTERNAL_RESSOURCE_TYPE;
  reference: string;
  meta?: IExternalRessourceMeta;
  i18n: ITranslationKv[];
}

export enum METHOD_DISPLAY_DEVICE_DATA {
  NUMBER_FIXED_1 = "numberFixed1",
  BOOLEAN = "boolean",
  STREAM = "stream", // This means we cannot show all the data
}
export interface IOnlineDevice {
  deviceSource: OnlineDevice; // enum: dexcom, byteflies
  deviceRef?: string; // for byteflies, it will be <flowId>/<followupId>
  externalRessourceRef?: string;
  deviceState?: DeviceState;
}
export enum OnlineDevice {
  DEXCOM = "DEXCOM",
  BYTEFLIES = "BYTEFLIES",
}

export enum DeviceState {
  ORDERED = "ordered",
  SHIPPED = "shipped",
  STARTED = "started",
  STOPPED = "stopped",
}

import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BasePage } from "src/app/baseClasses/base-page";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-two-famodal",
  templateUrl: "./two-famodal.component.html",
  styleUrls: ["./two-famodal.component.scss"],
})
export class TwoFAModalComponent extends BasePage {
  public code: string;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected modalCtrl: ModalController,
    protected translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
  }

  public dismiss() {
    this.modalCtrl.dismiss(null);
  }

  public onLogIn() {
    this.modalCtrl.dismiss(this.code);
  }
}

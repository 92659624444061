import { Injectable } from "@angular/core";

import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { TranslateService } from "@ngx-translate/core";
import { last } from "rxjs/operators";
import { ConfigurationService } from "./globalDataProvider/configuration.service";

@Injectable({
  providedIn: "root",
})
export class FingerprintService {
  constructor(private faio: FingerprintAIO, protected translateSvc: TranslateService, protected configService: ConfigurationService) {}

  /**
   * Check if the fingerprint is activate on the phone
   */
  public isAvailable(): Promise<boolean> {
    return this.faio.isAvailable().then(
      () => {
        return true;
      },
      (_err) => {
        return false;
      }
    );
  }

  /**
   * Show the popup to ask the fingerprint. Promise return an error if authenticate failed or cancel
   */
  public async show(): Promise<any> {
    const language = this.configService.getCurrentLanguage();
    await this.translateSvc.use(language).pipe(last()).toPromise();
    await this.faio.show({
      cancelButtonTitle: this.translateSvc.instant("fingerprint.cancelButtonTitle"),
      description: this.translateSvc.instant("fingerprint.description"),
      disableBackup: false, // allows to have an alternative
      title: this.translateSvc.instant("application.title"),
      fallbackButtonTitle: this.translateSvc.instant("fingerprint.fallbackButtonTitle"), // only for ios
      // subtitle: 'This SubTitle'
    });
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppConstants } from "src/app/appConstants";
import { ITranslation } from "src/app/models/translation";
import { LocalStorageService } from "../storage/local-storage.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class LanguagesService
  extends BasicSyncService<ITranslation, ITranslation[]>
  implements StaticImplements<INeedRefresh, typeof LanguagesService>
{
  public get needRefresh(): { value: boolean } {
    return LanguagesService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService, private localStorageService: LocalStorageService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<ITranslation[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "languages_",
      entityStoreKey: "list",
      getUrl: "/languages",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }

  /**
   * @returns the list of languages managed by our application (e.g. ["fr", "en", "de", "nl"])
   */
  public async listOfKeys(): Promise<Array<string>> {
    if (this.peekData()?.length > 0) {
      return this.peekData().map((l) => l.term);
    }
    const lang: ITranslation[] = await this.getFreshestData();
    return lang.map((l) => l.term);
  }

  /**
   * @returns the "freshest" list of languages managed by our application (e.g. ["fr", "en", "de", "nl"])
   */
  public async listOfFreshestKeys(): Promise<Array<string>> {
    const lang: ITranslation[] = await this.getFreshestData();
    return lang.map((l) => l.term);
  }

  /**
   * Return the language of the last user's config.
   * If there's none, return null
   */
  public async getLastConfigLanguage() {
    if (await this.localStorageService.isStored(AppConstants.LAST_LANGUAGE)) {
      return await this.localStorageService.getData(AppConstants.LAST_LANGUAGE, false);
    }
    return null;
  }

  /**
   * Save the last user's last config's language in the localStorage
   * @param lang the last language set in the last user's config
   */
  public async setLastConfigLanguage(lang: string) {
    await this.localStorageService.setData(AppConstants.LAST_LANGUAGE, lang, false);
  }
}

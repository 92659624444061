import { Injectable } from "@angular/core";
import { AES256 } from "@ionic-native/aes-256/ngx";
import { Platform } from "@ionic/angular";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { SecureStorageService } from "src/app/services/storage/secure-storage.service";

export enum AES256ErrorType {
  CANNOT_STORE_CREDENTIALS,
  CANNOT_DECRYPT_DATA,
  STORAGE_NOT_INITIALIZED,
}
export class AES256Error extends Error {
  public constructor(message: string, public readonly type: AES256ErrorType) {
    super(message);
  }
}

interface AES256Credentials {
  key: string;
  IV: string;
}

@Injectable({
  providedIn: "root",
})
export class Aes256Service {
  private readonly storageCredentialsField = "AES_256_CREDENTIALS";
  private secureStorageNotActive = false;

  constructor(private secureStorage: SecureStorageService, private aes256: AES256, protected platform: Platform) {}

  public async decrypt(data: string): Promise<string> {
    const credentials = await this.getKeyAndIV();
    if (this.secureStorageNotActive) {
      return Promise.resolve(data);
    }
    if (!credentials) {
      throw new AES256Error("Cannot decrypt data", AES256ErrorType.CANNOT_DECRYPT_DATA);
    }
    return await this.aes256.decrypt(credentials.key, credentials.IV, data);
  }

  public async encrypt(data: string): Promise<string> {
    let credentials = await this.getKeyAndIV();
    if (this.secureStorageNotActive) {
      return Promise.resolve(data);
    }
    if (!credentials) {
      credentials = await this.generateKeyAndIV();
    }
    return await this.aes256.encrypt(credentials.key, credentials.IV, data);
  }

  public async secureStorageEvents(): Promise<boolean> {
    const init = await this.secureStorage.isReady().catch(() => false);
    if (!init || !this.isCordova()) {
      this.secureStorageNotActive = true;
      // this.events.publish(AppConstants.EV_SECURESTORAGENOTACTIVE);
    } else {
      this.secureStorageNotActive = false;
      // this.events.publish(AppConstants.EV_SECURESTORAGEACTIVE);
    }
    return init;
  }

  private async getKeyAndIV(): Promise<AES256Credentials | null> {
    try {
      if (!(await this.secureStorageEvents())) {
        FileLogger.error("Aes256Service", "Secure storage not initialized", AES256ErrorType.STORAGE_NOT_INITIALIZED);
        // throw new AES256Error('Secure storage not initialized', AES256ErrorType.STORAGE_NOT_INITIALIZED);
        return null;
      }
      if (!(await this.isCredentialsStored())) {
        FileLogger.error("Aes256Service", "credentials not stored");
        return null;
      }
      const credentials = await this.secureStorage.get(this.storageCredentialsField);
      return JSON.parse(credentials);
    } catch (e) {
      FileLogger.error("Aes256Service", "Error :", e);
      return null;
    }
  }

  private async generateKeyAndIV(): Promise<AES256Credentials> {
    try {
      const pass = Math.ceil(Tools.random(false) * 0x9999999999999 + 0x10000000000000).toString(16);
      const key = await this.aes256.generateSecureKey(pass);
      const IV = await this.aes256.generateSecureIV(pass);
      const credentials: AES256Credentials = { key, IV };
      await this.secureStorage.set(this.storageCredentialsField, JSON.stringify(credentials));
      return credentials;
    } catch (e) {
      throw new AES256Error(e.toString(), AES256ErrorType.CANNOT_STORE_CREDENTIALS);
    }
  }

  public async isCredentialsStored(): Promise<boolean> {
    return this.secureStorage.isStored(this.storageCredentialsField).catch(() => false);
  }
  public isCordova(): boolean {
    return this.platform ? this.platform.is("cordova") : false;
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: "translateDate" })
export class TranslateDatePipe implements PipeTransform {
  constructor() {}

  /**
   * Returns a date translated into the user's language
   * @param {string} date => The date to translate
   * @returns {string} => The translated date
   */
  transform(date: string): string {
    moment.locale();
    return `${moment(date).format("DD MMMM YYYY")}`;
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IRelatedPerson } from "src/app/models/relatedPerson";
import { ACTION_STATUS_ENTITY, StaticImplements } from "src/app/models/sharedInterfaces";
import { ApiService } from "../api.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

@Injectable({
  providedIn: "root",
})
export class RelatedPatientsService
  extends BasicSyncService<IRelatedPerson, IRelatedPerson[]>
  implements StaticImplements<INeedRefresh, typeof RelatedPatientsService>
{
  public get needRefresh(): { value: boolean } {
    return RelatedPatientsService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  private relatedShown$ = new BehaviorSubject<IRelatedPerson | null>(null);

  constructor(protected dataService: DataService, private apiService: ApiService, private accountService: AccountService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IRelatedPerson[]>([]);
    this.relatedShown$ = new BehaviorSubject<IRelatedPerson | null>(null);
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.relatedShown$.next(null);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "relatedPatients_",
      entityStoreKey: "list",
      getUrl: "/relatedPatients",
      setUrl: null,
      expirationDays: 10,
      encrypted: true,
    };
  }

  public async *getDataReader(
    onlyActiveRelation = false,
    forceApiRequest = false
  ): AsyncGenerator<IRelatedPerson[], IRelatedPerson[], IRelatedPerson[]> {
    try {
      if (this.accountService.isNotRelated()) {
        yield [];
        return [];
      }
      const dataReader = this.dataService.readv2<IRelatedPerson, IRelatedPerson[]>(this.defaultDataParameter, false, this, forceApiRequest);
      let d: IRelatedPerson[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, onlyActiveRelation);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("RelatedPatientsService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IRelatedPerson[], onlyActiveRelation: boolean) {
    try {
      let related: IRelatedPerson[] = dataResult.filter((rel) => {
        return rel.actionStatus !== ACTION_STATUS_ENTITY.DELETED;
      });
      if (onlyActiveRelation) {
        related = related.filter((rel) => {
          return rel.active;
        });
      }
      return related;
    } catch (err) {
      FileLogger.error("RelatedPatientsService", "Error while processing relatedPatientService data: ", err);
      return dataResult;
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(onlyActiveRelation = false, forceApiRequest = false): Promise<IRelatedPerson[]> {
    const dataReader = this.getDataReader(onlyActiveRelation, forceApiRequest);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(onlyActiveRelation = false, forceApiRequest = false): Promise<IRelatedPerson[]> {
    const dataReader = this.getDataReader(onlyActiveRelation, forceApiRequest);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  /**
   * The current user accept or reject the invitation of a patient
   * @param related
   * @param accept
   */
  public acceptInvitation(related: IRelatedPerson, accept: boolean): Observable<boolean> {
    // force to wait the answer of the POST, so I go directly through apiService
    return this.apiService.post("/acceptinvitation", { identifier: related.patient.reference, accept: accept }).pipe(
      map((rep) => {
        if (rep && rep.success) {
          return true;
        } else {
          return false;
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * Set current related person shown (reset if "related" is null)
   * @param related
   */
  public setRelatedPersonShown(related: IRelatedPerson): void {
    this.relatedShown$.next(related);
  }

  /**
   * Get current related person shown
   */
  public getRelatedPersonShown(): IRelatedPerson {
    return this.relatedShown$.value;
  }
  /**
   * Watch the changes in the related shown
   * @return a observable with the related shown
   */
  public watchRelatedShown(): Observable<IRelatedPerson> {
    return this.relatedShown$;
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, Pipe, PipeTransform, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ChartOptions } from "chart.js";
import * as moment from "moment";
import { BaseComponent } from "src/app/baseClasses/base-component";
import { ChartHelper, IChartColors } from "src/app/helpers/chart-helper";
import { ObservationDataHelperService } from "src/app/helpers/observation-data-helper.service";
import { ComponentDisplay, IObservation, IObservationDefinition, OComponent } from "src/app/helpers/observation-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { IObservationParam } from "src/app/models/configuration";
import { DeviceState, EXTERNAL_RESSOURCE_TYPE, IExternalRessource } from "src/app/models/externalRessource";
import { KeyValue } from "src/app/models/keyValue";
import { AccessLevel, IEntity } from "src/app/models/sharedInterfaces";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { DayStreamObservationsService } from "src/app/services/globalDataProvider/dayStreamObservations.service";
import { ExternalRessourceService } from "src/app/services/globalDataProvider/external-ressource.service";
import { LanguagesService } from "src/app/services/globalDataProvider/languagesService";
import { ObservationDefinitionService } from "src/app/services/globalDataProvider/observation-definition.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { NetworkService } from "src/app/services/network.service";
import { PopupService } from "src/app/services/popup.service";
import { IAveragedObservation } from "src/app/services/streamObservation.service";
import { E2E_ID_OBS } from "test/helpers/selectorIdHelper";
import { ObservationGraphsComponent } from "../observation-graphs/observation-graphs.component";
import { Dexcom } from "./dexcom";

@Pipe({ name: "isAnswerNull" })
export class IsAnswerNullPipe implements PipeTransform {
  transform(component: OComponent[], index: number): boolean {
    if (component.length > 1) {
      if (index === 0) {
        if (component[index + 1]?.valueQuantity?.value === null || component[index]?.valueQuantity?.value === null) {
          return true;
        }
        return false;
      }
      if (component[index - 1]?.valueQuantity?.value === null || component[index + 1]?.valueQuantity?.value === null) {
        return true;
      }
      return false;
    }
    return true;
  }
}

/**
 * Container for observation data in Chart
 */
export class DataChart {
  public data: number[] = [];
  public label = "";
  public borderDash?: number[] | undefined;
}

@Component({
  selector: "app-care-observation",
  templateUrl: "./observation.component.html",
  styleUrls: ["./observation.component.scss"],
})
export class ObservationComponent extends BaseComponent implements OnChanges {
  @ViewChild(ObservationGraphsComponent) graphComponent: ObservationGraphsComponent;
  @Input() index: number;
  @Input() definition: IObservationDefinition;
  @Input() param: IObservationParam;
  @Input() view: "datas" | "charts" | "parameters";
  @Input() observations: IObservation[];
  @Input() MAX_CHART_LAST_ITEMS = 999;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() addObservation = new EventEmitter<IObservationDefinition>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() editObservation = new EventEmitter<IObservation>();
  showDetails = false;
  validationErrors: { [key: string]: string[] } = {};
  dayWeekNames = Tools.keyDayNames(true);
  dayMonth: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  freqKv = Array.from({ length: 12 }, (_, i) => i + 1).map((v) => {
    return {
      key: v.toString(),
      value: v,
    };
  });

  timings = {
    rising: new KeyValue(),
    morning: new KeyValue(),
    noon: new KeyValue(),
    evening: new KeyValue(),
    atBed: new KeyValue(),
    when: new Array<string>(),
  };
  public isAddButtonShown = false;
  public chartLabels: Array<string> = [];
  public chartData: DataChart[] = [];
  public chartColors: IChartColors[] = ChartHelper.chartColors;

  public E2E_ID_OBS = E2E_ID_OBS;
  public dateTimeLocaleFormat: string;
  public filterBy = "all";
  public externalRessources: IExternalRessource[];
  public ComponentDisplay = ComponentDisplay;
  // observations graphs parameters
  public chartOptions: ChartOptions;
  public range: string;
  public glucoseTrendText = "";
  public glucoseTrendUnit = "mg/dl";
  public glucoseTrendRate = "flat";
  public glucoseTrendColor = "grey";
  public defaultLabel: string;
  private selectedGraphPoint: any;
  public hasOnlineDevices = false;
  public isGraphComputing = true;

  private dexcomClickFunction = (evt, array: any[]) => {
    if (!array[0]) return;
    const i = array[0]._index; // this is the index of the label of the clicked point (it counts invisible points too)
    const activePoint = this.graphComponent.chart.chart.getElementAtEvent(evt);
    const selectedPoint: any = activePoint[0];
    if (this.selectedGraphPoint) {
      delete this.selectedGraphPoint.custom;
    }
    selectedPoint.custom = selectedPoint.custom || {};
    selectedPoint.custom.backgroundColor = "#fff";
    selectedPoint.custom.radius = 5;
    this.selectedGraphPoint = selectedPoint;
    this.graphComponent.chart.update();

    this.selectDexcomTrend(i);
  };

  private streamObservations: IAveragedObservation[] = [];
  private groupedStreamedObservation: IAveragedObservation[][];
  public onlineDeviceWarning = "";

  constructor(
    protected translateSvc: TranslateService,
    protected infoService: InfoAppService,
    protected obsDefService: ObservationDefinitionService,
    protected popupService: PopupService,
    protected configService: ConfigurationService,
    protected languagesService: LanguagesService,
    protected externalRessourceService: ExternalRessourceService,
    protected obsDataHelper: ObservationDataHelperService,
    private accountService: AccountService,
    private networkService: NetworkService,
    private dayStreamObservationService: DayStreamObservationsService,
    private translateService: TranslateService
  ) {
    super(infoService, popupService);
    this.languagesService.getFirstDataAvailable().then((langs) => {
      this.dateTimeLocaleFormat = Tools.getDateTimeLocaleFormat(langs, this.configService.getCurrentLanguage());
    });
    this.loadExternalRessources();
  }

  /**
   * Change event
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.isAddButtonShown = this.isAddButtonAvailable(this.param);

    if (changes.param && !Tools.isEqual(changes.param.currentValue, changes.param.previousValue)) {
      this.prepareObservationParams();
    }
    if (this.filterBy.startsWith("online_")) {
      this.computeGraphData();
    } else if (changes.observations && !Tools.isEqual(changes.observations.currentValue, changes.observations.previousValue)) {
      this.computeGraphData();
    }
    this.validate();
  }

  public async computeGraphData(): Promise<void> {
    this.isGraphComputing = true;
    ({
      chartData: this.chartData,
      chartLabels: this.chartLabels,
      chartOptions: this.chartOptions,
      chartColors: this.chartColors,
      streamObservations: this.streamObservations,
    } = await this.obsDataHelper.computeGraphData(
      this.definition,
      this.observations,
      this.filterBy,
      this.MAX_CHART_LAST_ITEMS,
      this.dexcomClickFunction
    ));
    this.setupOnlineDevicesWarnings();
    this.defaultLabel = this.findDefaultLabelOfStreamObs(this.streamObservations);
    this.range = this.obsDataHelper.getCachedRange();
    this.groupedStreamedObservation = this.groupAveragedObservations(this.streamObservations);
    if (this.range === "day" && this.filterBy.includes("dexcom") && this.groupedStreamedObservation?.length) {
      const i = this.groupedStreamedObservation[0].length - 1;
      const hours = this.groupedStreamedObservation[0][i].issued;
      const minutes = Tools.hoursToMinutes(hours);
      this.selectDexcomTrend(minutes);
    }
    this.isGraphComputing = false;
  }

  private setupOnlineDevicesWarnings(): void {
    if (this.filterBy.startsWith("online_")) {
      // We need observations from an online device. Those are available only online.
      const externalRessourceRef = this.filterBy.slice(7);
      const isConnected = this.isOnlineDeviceConnected(externalRessourceRef);
      if (isConnected && !this.networkService.isCurrentOnline()) {
        this.onlineDeviceWarning = this.translateService.instant("streamObservations.onlineDeviceLatestDay", {
          day: this.dayStreamObservationService.getLastestSavedDay(),
        });
      } else if (!isConnected) {
        this.onlineDeviceWarning = this.translateService.instant("streamObservations.onlineDeviceNotConnected");
      }
    } else {
      this.onlineDeviceWarning = "";
    }
  }

  private isOnlineDeviceConnected(externalRessourceRef: string): boolean {
    const found = this.accountService.cachedAccount.connectedDevices?.find(
      (d) => d.externalRessourceRef === externalRessourceRef && d.deviceState !== DeviceState.STOPPED
    );
    return !!found;
  }
  private selectDexcomTrend(i: number): void {
    ({
      glucoseTrendText: this.glucoseTrendText,
      glucoseTrendUnit: this.glucoseTrendUnit,
      glucoseTrendRate: this.glucoseTrendRate,
      glucoseTrendColor: this.glucoseTrendColor,
    } = Dexcom.selectDexcomTrend(i, this.groupedStreamedObservation, this.accountService));
  }

  /**
   * Returns the rounded hour of the lastest streamed averaged observation
   * Used to set the scrollable graph at the right position.
   * @param data
   * @returns
   */
  private findDefaultLabelOfStreamObs(data: IAveragedObservation[]): string {
    if (!data) return undefined;
    const lastObs: IAveragedObservation = data[data.length - 1];
    const s = lastObs.issued.split(":");
    const d = s[0] + ":00";
    return d;
  }

  /**
   * The components are split in streamed averaged observations.
   * Here we set them in parallel array in order to find the associated component more easily
   * @param averagedObs
   * @returns
   */
  private groupAveragedObservations(averagedObs: IAveragedObservation[]): IAveragedObservation[][] {
    if (!averagedObs) return [];
    const loincCodes = [...new Set(averagedObs.map((item) => item.componentCode))];
    const grouped: IAveragedObservation[][] = [];
    for (const l of loincCodes) {
      const obs = averagedObs.filter((o) => o.componentCode === l);
      grouped.push(obs);
    }
    return grouped;
  }

  /**
   * Prepare Observation parameters to be displayed
   */
  private prepareObservationParams() {
    // prepare Timing Codes
    const timingCodes = IEntity.toKeyValues(this.param.frequency.timingCode);
    this.timings.rising = timingCodes[0];
    this.timings.morning = timingCodes[1];
    this.timings.noon = timingCodes[2];
    this.timings.evening = timingCodes[3];
    this.timings.atBed = timingCodes[4];
    // prepare timing "when"
    if (this.param.frequency.when?.length) {
      this.timings.when = JSON.parse(this.param.frequency.when);
    } else {
      // entry MUST exists, even if empty!
      this.timings.when = [];
    }
    // add observation categories into the list
  }

  public handleClick(): void {
    if (this.definition.onePerDay && this.observations.length >= 1) {
      const issueDate = moment(this.observations[0].issued);
      if (issueDate.isSame(moment(), "day")) {
        this.editObservation.emit(this.observations[0]);
      } else {
        this.addObservation.emit(this.definition);
      }
    } else {
      this.addObservation.emit(this.definition);
    }
  }

  /**
   * @param observation
   */
  public handleEdit(observation: IObservation): void {
    this.editObservation.emit(observation);
  }

  /**
   * Called on a timing change in the parameters form
   */
  public updateTimings(): void {
    // prepare Timing Code
    let timingCode = "";
    if (this.timings.rising.value) {
      timingCode += IEntity.TIMING_RISING;
    }
    if (this.timings.morning.value) {
      timingCode += IEntity.TIMING_MORNING;
    }
    if (this.timings.noon.value) {
      timingCode += IEntity.TIMING_NOON;
    }
    if (this.timings.evening.value) {
      timingCode += IEntity.TIMING_EVENING;
    }
    if (this.timings.atBed.value) {
      timingCode += IEntity.TIMING_BED;
    }
    this.param.frequency.timingCode = timingCode;

    // prepare timing "when"
    this.param.frequency.when = null;
    switch (this.param.frequency.periodUnits) {
      case "d": // nothing to do
        break;
      case "w":
      case "m":
        this.param.frequency.when = JSON.stringify(this.timings.when);
        break;
      case "y": // TODO
        break;
    }
    this.validate();
  }

  /**
   * Called on a bound period change
   */
  public updateBoundPeriods(): void {
    // prepare bound period (remove hours,minutes, seconds)
    this.param.frequency.boundsPeriod.start = Tools.momentNoHours(this.param.frequency.boundsPeriod.start).format();
    this.param.frequency.boundsPeriod.end = Tools.momentNoHours(this.param.frequency.boundsPeriod.end).format();
    this.validate();
  }

  /**
   * Validate the param
   */
  private validate() {
    const out: { [key: string]: string[] } = {};
    if (["m", "w"].indexOf(this.param.frequency.periodUnits) >= 0) {
      // frequency is not used anymore, replaced by "timingWhen"
      if (!this.timings.when?.length) {
        out.timings_when = [
          this.translateSvc.instant("mydrugs.errorTiming") /*+ (!obsTypeName ? "" : " (" + JSON.stringify(obsTypeName) + ")")*/,
        ];
      }
    } else if (this.param.frequency.periodUnits === "h") {
      // frequency is not used anymore, replaced by "timingWhen"
      if (!this.timings.when?.length || this.timings.when.length < 2 || !this.timings.when[0] || !this.timings.when[1]) {
        out.timings_when = [
          this.translateSvc.instant("mydrugs.errorTiming") /*+ (!obsTypeName ? "" : " (" + JSON.stringify(obsTypeName) + ")")*/,
        ];
      }
    }
    this.validationErrors = out;
  }

  private isAddButtonAvailable(observationParams: IObservationParam) {
    if (this.view !== "datas") return false;
    if (observationParams.limitedCreationByFrequence && new Date(observationParams.frequency.boundsPeriod.end) < new Date()) return false;

    const concernedExtRessources = this.externalRessources?.filter((e) => e.meta?.availableLoinc?.includes(this.definition.loinc));
    if (!concernedExtRessources) return true;
    for (const r of concernedExtRessources) {
      if (Tools.isDefined(r.meta.obsDefAccess) && r.meta.obsAccess < AccessLevel.WRITE) {
        return false;
      }
    }
    return true;
  }

  private async loadExternalRessources(): Promise<void> {
    this.externalRessources = await this.externalRessourceService.getFirstDataAvailable("", [
      EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE,
      EXTERNAL_RESSOURCE_TYPE.ONLINE_HARDWARE,
    ]);
    this.isAddButtonShown = this.isAddButtonAvailable(this.param);
    this.setupHasOnlineDevice();
    this.computeGraphData();
  }

  private setupHasOnlineDevice(): void {
    if (!this.externalRessources?.length && this.definition) {
      return;
    }
    const onlineDevices: IExternalRessource[] = this.externalRessources.filter(
      (ressource) =>
        ressource.type === EXTERNAL_RESSOURCE_TYPE.ONLINE_HARDWARE && ressource?.meta?.availableLoinc?.includes(this.definition.loinc)
    );
    const extRessourcesRefs = onlineDevices.map((o) => o.reference);
    // Look if the user had one connected:
    const patientDevices = this.accountService.cachedAccount.connectedDevices?.filter((d) =>
      extRessourcesRefs.includes(d.externalRessourceRef)
    );

    this.hasOnlineDevices = patientDevices && patientDevices.length > 0;
    if (patientDevices?.length > 0) {
      const connected = patientDevices.find((d) => d.deviceState === DeviceState.STARTED);
      this.filterBy = "online_" + (connected ? connected.externalRessourceRef : patientDevices[0].externalRessourceRef);
    }
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, BehaviorSubject, from, timer, combineLatest } from "rxjs";
import { first, map } from "rxjs/operators";
import { AppConstants } from "src/app/appConstants";
import { Appointment, IAppointment } from "src/app/models/appointment";
import { Month, AgendaCell } from "src/app/models/calendar";
import { NOTIFICATION_TYPE } from "src/app/models/notification";
import { ACTION_STATUS_ENTITY, IEntity, StaticImplements, STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { ApiService } from "../api.service";
import { InfoAppService } from "../info-app.service";
import { NotificationsGeneratedService } from "../notificationsService/notifications-generated.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FHIRHelper } from "src/app/helpers/FHIR-helper";
import { ServerError, ServerResponse } from "src/app/helpers/server-response-helper";
import { IAccount } from "src/app/helpers/account-helper";
import { IEntitylink, PARENT_TYPE } from "src/app/models/entitylink";
import { Tools } from "src/app/helpers/tools-helper";
import { AppointmentModalComponent } from "src/app/components/modals/appointment-modal/appointment-modal.component";
import { ModalController } from "@ionic/angular";
import { PopupService } from "../popup.service";
import { ErrorService } from "../error.service";

@Injectable({
  providedIn: "root",
})
export class AppointmentService
  extends BasicSyncService<IAppointment, IAppointment[]>
  implements StaticImplements<INeedRefresh, typeof AppointmentService>
{
  public get needRefresh(): { value: boolean } {
    return AppointmentService._needRefresh;
  }
  /**
   * For browser mode only, should always be false on mobile
   * BehaviorSubject that tracks the saving state of appointment-related operations.
   * It initializes with a 'false' saving state
   */
  public $appointementSaving = new BehaviorSubject(false);
  public lastGenNotif: string = null;
  public static _needRefresh = {
    value: true,
  };

  public currentMonth = new Month();
  public set setMonth(value: Month) {
    if (value.currentMonth === this.currentMonth.currentMonth) {
      this.setSelectedDay = null;
    }
    this.currentMonth = value;
  }

  public selectedDay: AgendaCell = null;
  public set setSelectedDay(value: AgendaCell) {
    this.selectedDay = value;
  }

  public currentAppointments: BehaviorSubject<IAppointment[]> = new BehaviorSubject<IAppointment[]>([]);

  constructor(
    protected dataService: DataService,
    private apiService: ApiService,
    private notificationsGeneratedService: NotificationsGeneratedService,
    private accountService: AccountService,
    protected infoAppService: InfoAppService,
    private iab: InAppBrowser,
    private modalCtrl: ModalController,
    protected popupService: PopupService,
    protected errorService: ErrorService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IAppointment[]>([]);
    this.currentAppointments = new BehaviorSubject<IAppointment[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.currentAppointments.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "appointment_",
      entityStoreKey: "list",
      getUrl: "/appointments",
      setUrl: "/appointment",
      expirationDays: 1,
      encrypted: true,
    };
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(includeDeleted = true): IAppointment[] {
    return this.processData(super.peekData(), includeDeleted);
  }
  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
  public watchData(includeDeleted = false): Observable<IAppointment[]> {
    return this.data$.pipe(
      map((appointments) => {
        return this.processData(appointments, includeDeleted);
      })
    );
  }

  /**
   * Return a data reader that will first read try to read the local data
   * then try to get the online data.
   */
  public async *getDataReader(
    includeDeleted = false,
    forceGenNotif = false
  ): AsyncGenerator<IAppointment[], IAppointment[], IAppointment[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IAppointment[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, includeDeleted);
        yield d;
      }
      this.dealWithNotif(forceGenNotif, d);
      return d;
    } catch (err) {
      FileLogger.error("AppointmentService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IAppointment[], includeDeleted: boolean) {
    try {
      let appointments: IAppointment[] = dataResult;
      // the appointments for which I accompagn are in relatedAppointmentService
      appointments = appointments.filter((a) => !Appointment.isAccompagnied(a, this.accountService.cachedCaremateId));
      // Default sort
      appointments = appointments.sort((a, b) => moment(a.start).diff(moment(b.start)));
      if (!includeDeleted) {
        return appointments.filter((appointment) => {
          return appointment.actionStatus !== ACTION_STATUS_ENTITY.DELETED && !appointment.entityStatus.includes(STATUS_ENTITY.DELETED);
        });
      }
      return appointments;
    } catch (err) {
      FileLogger.error("AppointmentService", "Error while processing appointmentService data: ", err);
      return dataResult;
    }
  }

  private dealWithNotif(forceGenNotif: boolean, data: IAppointment[]) {
    if (forceGenNotif || this.needNotifGeneration || !this.lastGenNotif || moment(this.lastGenNotif).add(4, "hours").isBefore(moment())) {
      const notifications = data.filter((appointment) => {
        return (
          appointment.actionStatus !== ACTION_STATUS_ENTITY.DELETED &&
          !appointment.entityStatus.includes(STATUS_ENTITY.DELETED) &&
          !appointment.entityStatus.includes(STATUS_ENTITY.CANCELLED)
        );
      });

      this.notificationsGeneratedService.generatedNotifications(notifications, NOTIFICATION_TYPE.APPOINTMENT).then(() => {
        this.lastGenNotif = moment().format();
      });
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(includeDeleted = false, forceGenNotif = false): Promise<IAppointment[]> {
    const dataReader = this.getDataReader(includeDeleted, forceGenNotif);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(includeDeleted = false, forceGenNotif = false): Promise<IAppointment[]> {
    const dataReader = this.getDataReader(includeDeleted, forceGenNotif);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  /**
   * Generate public link to Ical agenda for current user's appointments
   */
  public generateIcalUrl() {
    return this.apiService.get("/appointments/ical-generate-url").pipe(
      map((res) => {
        if (res && res.success) {
          return res.data;
        }
        // TODO return this.apiService.handleError(new Response("Cannot parse JSON"));
      })
    );
    // TODO .catch(this.apiService.handleError);
  }

  /**
   * @param app
   * @returns the url of the teleconsultation
   */
  public searchAddressJitsiMeet(app: IAppointment): string {
    if (this.isCallVideo(app)) {
      for (const part of app.participant) {
        for (const coding of part.participantType.coding) {
          if (coding.system === AppConstants.MEETJITSI) {
            return coding.code;
          }
        }
      }
    }
    return null;
  }

  public isCallVideo(app: IAppointment): boolean {
    // TODO : afficher seulement si le rdv est aujourd'hui (dans l'heure ?)

    if (!app.participant) {
      return false;
    }
    return app.participant.some((part) => {
      if (!part.participantType || !part.participantType.coding) {
        return false;
      }
      return part.participantType.coding.some((coding) => coding.system === AppConstants.MEETJITSI && coding.code?.length > 0);
    });
  }

  public runCallVideo(app: IAppointment): void {
    const address = this.searchAddressJitsiMeet(app);
    if (this.infoAppService.isCordova()) {
      const browser = this.iab.create(address, "_system", "location=yes");
      browser.show();
    } else {
      window.open(address, "_blank", "location=no");
    }
  }

  /**
   * Method for creating appointments, utilizes the shared saveAppointment function.
   *
   * @param appointment - The appointment to be created.
   * @returns An observable that resolves to the created appointment.
   */
  public create(appointment: IAppointment): Observable<IAppointment> {
    const savePromise = this.saveAppointment(appointment, SYNC_HTTP_METHOD.POST);
    return from(savePromise);
  }

  /**
   * Method for updating appointments, utilizes the shared saveAppointment function.
   *
   * @param appointment - The appointment to be updated.
   * @param generateNotif - Whether to generate notifications (default is true).
   * @returns An observable that resolves to the updated appointment.
   */
  public update(appointment: IAppointment, generateNotif = true): Observable<IAppointment> {
    const savePromise = this.saveAppointment(appointment, SYNC_HTTP_METHOD.PUT, generateNotif);
    return from(savePromise);
  }

  /**
   * Method for marking appointments as deleted.
   * Sets the entityStatus of the appointment to [STATUS_ENTITY.DELETED].
   *
   * @param appointment - The appointment to be marked as deleted.
   * @returns An observable that resolves to the updated appointment marked as deleted.
   */
  public delete(appointment: IAppointment): Observable<IAppointment> {
    appointment.entityStatus = [STATUS_ENTITY.DELETED];
    return this.update(appointment);
  }

  /**
   * Common method to handle saving appointments on creation and update, encapsulating shared logic.
   *
   * @param appointment - The appointment to be saved.
   * @param method - The HTTP method for the save operation (PUT or POST).
   * @param generateNotif - Whether to generate notifications.
   * @returns A promise that resolves to the saved appointment or null if an error occurs.
   */
  private saveAppointment(appointment: IAppointment, method: SYNC_HTTP_METHOD, generateNotif = true): Promise<IAppointment | null> {
    // Browser mode only : set saving state to true to show loader while saving
    if (!this.infoAppService.isCordova()) {
      this.$appointementSaving.next(true);
    }

    // Set modification time for the appointment
    appointment.modified = moment().format();

    // Perform the save operation via DataService
    return this.dataService
      .saveInArray(
        appointment,
        // Check for identifier match to determine updates or new creation
        (entity) =>
          entity.identifier.find((id) => id.system === FHIRHelper.SYSTEM_CAREMATE)?.value ===
          appointment.identifier.find((id) => id.system === FHIRHelper.SYSTEM_CAREMATE)?.value,
        {
          ...this.defaultDataParameter,
          method: method, // Specify the HTTP method for save operation (PUT or POST)
        }
      )
      .then((app) => {
        const appointments = this.peekData(true);

        // Handling updates or new creation based on HTTP method
        if (method === SYNC_HTTP_METHOD.PUT) {
          // Update logic
          const i = appointments.findIndex(
            (e) =>
              e.identifier.find((id) => id.system === FHIRHelper.SYSTEM_CAREMATE)?.value ===
              app.identifier.find((id) => id.system === FHIRHelper.SYSTEM_CAREMATE)?.value
          );
          if (i >= 0 && !app.entityStatus.includes(STATUS_ENTITY.DELETED)) {
            appointments[i] = app; // Update existing appointment in local data
          } else if (i >= 0 && app.entityStatus.includes(STATUS_ENTITY.DELETED)) {
            appointments.splice(i, 1); // Remove deleted appointment from local data
          }
        } else if (method === SYNC_HTTP_METHOD.POST) {
          appointments.push(app); // Add newly created appointment to local data
        }

        // Update local data storage and trigger notification creation if specified
        this.pokeData(appointments);
        if (generateNotif) {
          this.notificationsGeneratedService.updateOrCreateNotification(app, NOTIFICATION_TYPE.APPOINTMENT);
        }

        return app;
      })
      .finally(() => {
        // Browser mode only : set loading state to false when saving proccess is completed (success or error)
        if (!this.infoAppService.isCordova()) {
          this.completeSavingAfterDelay();
        }
      })
      .catch((error) => {
        // Error handling for server unreachable and general errors
        if (!this.infoAppService.isCordova() && (error as ServerError).code === ServerResponse.SERVER_UNREACHABLE.code) {
          throw error; // Throw server unreachable error for handling externally
        } else {
          // Log general errors and return null
          FileLogger.error("AppointmentService", "saveAppointment", error);
          return null;
        }
      });
  }

  /**
   * Creates a new appointment and presents a modal to create and store it.
   * @param momentDate - The moment date string.
   * @param account - The account details.
   * @returns A promise that resolves to the created appointment.
   */
  public async createAppointmentAndPresentModal(momentDate: string, account: IAccount): Promise<IAppointment> {
    // Setting an initial moment time for the appointment
    const initialMoment = moment(momentDate).hour(7).minute(0).second(0).format();
    // Creating the appointment object
    const appointment = Appointment.createAppointmentObject(initialMoment, account);
    // Handling the appointment creation
    return await this.handleAppointmentCreation(appointment, account);
  }

  /**
   * Handles the creation and storage of the appointment after presentation in a modal.
   * @param appointment - The appointment object.
   * @param account - The account details.
   * @returns A promise that resolves to the created appointment.
   */
  private async handleAppointmentCreation(appointment: IAppointment, account: IAccount): Promise<IAppointment> {
    // Presenting the modal to create the appointment
    const resultAppointment = await this.presentModalAppointment(appointment, account, false);
    // Handle cancellation
    if (!resultAppointment) return null;
    // Try to create the new appointment
    return await new Promise((resolve) => {
      let newappointment: IAppointment = null;
      // Subscribing to the appointmentService's create() method to handle the newly created appointment
      this.create(resultAppointment).subscribe(
        (app) => {
          newappointment = app;
        },
        (error) => {
          // Handling server unreachable errors on browser mode (portal) and retry handling appointment creation
          this.errorService.handleErrorWithRetry(error, () => {
            return this.handleAppointmentCreation(appointment, account);
          });
        },
        () => {
          // If creation is successfull, resolve the promise and return the appointment
          resolve(newappointment);
        }
      );
    });
  }

  /**
   * Edits an appointment and presents a modal to update it.
   * @param appointment - The appointment to be edited.
   * @param account - The account details.
   * @param isRelated - Boolean indicating if related.
   * @param relatedNotes - Array of related notes.
   * @returns A promise that resolves to the edited Appointment.
   */
  public async editAppointmentAndPresentModal(
    appointment: IAppointment,
    account: IAccount,
    isRelated: boolean,
    relatedNotes: IEntitylink[]
  ): Promise<IAppointment> {
    // if (this.isRelated) return; // not allowed to edit appointment for related
    if (IEntity.isCancelled(appointment)) return; // do not edit a cancelled appointment
    // if (_.isEmpty(appointment.identifier[0].value)) return; // do not edit fake appointments from linear careplan: not important, lock in modalAppointment
    const filteredRelatedNotes = Tools.deepCopy(relatedNotes).filter(
      (note) => note.parentType === PARENT_TYPE.APPOINTEMENT && note.parentId === appointment._id
    );
    // make a copy in case of rollback
    const copyAppointment: IAppointment = Tools.deepCopy(appointment);
    return await this.handleAppointmentEdition(
      copyAppointment,
      account,
      isRelated,
      filteredRelatedNotes?.length ? filteredRelatedNotes[0] : null
    );
  }

  /**
   * Displays a view to edit an Appointment and stores modifications.
   * @param appointment - The appointment to be edited.
   * @param account - The account details.
   * @param isRelated - Indicates if the appointment is related.
   * @param relatedNote - The related note linked to the appointment.
   * @returns A promise that resolves to the updated appointment.
   */
  public async handleAppointmentEdition(
    appointment: IAppointment,
    account: IAccount,
    isRelated: boolean,
    relatedNote: IEntitylink
  ): Promise<IAppointment> {
    // Presenting the modal for appointment editing
    // We use deep copy of the appointment to avoid mutating the object if the update fails.
    const resultAppointment = await this.presentModalAppointment(Tools.deepCopy(appointment), account, isRelated, relatedNote);
    if (resultAppointment) {
      // If the appointment is marked as deleted, initiate deletion process
      if (resultAppointment.actionStatus === ACTION_STATUS_ENTITY.DELETED) {
        return new Promise((resolve) => {
          this.delete(resultAppointment).subscribe(
            () => {
              resolve(resultAppointment);
            },
            (error) => {
              // Handling server unreachable errors on browser mode (portal) and retry handling appointment edition (delete in this case)
              this.errorService.handleErrorWithRetry(error, () => {
                // If the deletion fails, we reopen the original appointment (the one with an unaltered ACTION_STATUS_ENTITY)
                return this.handleAppointmentEdition(appointment, account, isRelated, relatedNote);
              });
            }
          );
        });
      } else {
        // Update the appointment after modifications
        return new Promise((resolve) => {
          let updatedAppointment: IAppointment = null;
          this.update(resultAppointment).subscribe(
            (app) => {
              updatedAppointment = app;
            },
            (error) => {
              // Handling server unreachable errors on browser mode (portal) and retry handling appointment edition
              this.errorService.handleErrorWithRetry(error, () => {
                // If edition fails, we reopen the resultAppointment with the previous modification
                return this.handleAppointmentEdition(resultAppointment, account, isRelated, relatedNote);
              });
            },
            () => {
              resolve(updatedAppointment);
            }
          );
        });
      }
    } else {
      return null;
    }
  }

  /**
   * Opens the appointment modal
   */
  public async presentModalAppointment(
    appointment: IAppointment,
    account: IAccount,
    isRelated: boolean,
    relatedNote?: IEntitylink
  ): Promise<IAppointment> {
    const modal = await this.modalCtrl.create({
      component: AppointmentModalComponent,
      componentProps: {
        appointment: appointment,
        account: account,
        isRelated: isRelated,
        relatedNote: relatedNote,
      },
    });
    modal.present();
    const data = await modal.onDidDismiss();
    return data.data as IAppointment;
  }

  /**
   * Signals the end of a loading state after a specified delay period.
   * This method sets the appointment loading indicator to 'false' after a specified delay duration.
   * It ensures that the loading indicator remains visible for at least the provided delay duration.
   * @param delay - The delay duration in milliseconds before ending the loading state. Default is 500ms (0.5 second).
   */
  public completeSavingAfterDelay(delay = 500): void {
    const loadingDelay$ = timer(delay);
    combineLatest([this.$appointementSaving, loadingDelay$])
      .pipe(first())
      .subscribe(() => {
        this.$appointementSaving.next(false);
      });
  }
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GetParametersPageService {
  constructor() {
    // do nothing.
  }

  /**
   * Get value of a key of active Page' parameters. If this key does not exist, defaultValue is return
   * @param keyParam
   * @param defaultValue
   */
  public getValueOfActivePage<T>(keyParam: string, defaultValue: T): T {
    return (history.state[keyParam] as T) || defaultValue;
  }

  /**
   * Check if a key is present as parameter of active page
   * @param keyParam
   */
  public isExistKeyOfActivePage(keyParam): boolean {
    return history.state[keyParam] !== undefined;
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppConstants } from "src/app/appConstants";
import { BaseComponent } from "src/app/baseClasses/base-component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { EXTERNAL_RESSOURCE_TYPE, IExternalRessource } from "src/app/models/externalRessource";
import { ExternalRessourceService } from "src/app/services/globalDataProvider/external-ressource.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { MedicalBluetoothData } from "src/app/services/medical-bluetooth/medical-bluetooth-data";
import { MedicalBluetoothSDKPillDispenserService } from "src/app/services/medical-bluetooth/medical-bluetooth-sdk-pilldispenser.service";
import { MedicalBluetooth, medicalBluetoothServiceMapping } from "src/app/services/medical-bluetooth/medical-bluetooth.service";
import { PopupService } from "src/app/services/popup.service";

export enum MeasurementBluetooth {
  SUCCESS,
  TOO_OLD,
}

export interface DataBluetooth {
  data: any;
  externalRessource: IExternalRessource;
}

export interface DataMeasurementBluetooth {
  valid: MeasurementBluetooth;
  data: DataBluetooth;
}

@Component({
  selector: "app-bluetooth-device",
  templateUrl: "./bluetooth-device.component.html",
  styleUrls: ["./bluetooth-device.component.scss"],
})
export class BluetoothDeviceComponent extends BaseComponent implements OnChanges {
  public externalRessources: IExternalRessource[];
  public externalRessourcesFiltered: IExternalRessource[];
  public measuring = false;
  public lastMeasure: string;
  public observationTypeCode: string;
  public drugId: string;
  private dataHandler: MedicalBluetoothData;
  private lastExternalRessourcesForRefresh: IExternalRessource;
  public hasData = false;
  public displayRefreshButton = false;
  private timeToDisplayRefreshButton = 30000;
  protected stopScan$: Subject<void>;
  public AppConstants = AppConstants;

  @Output() public moreOptions: EventEmitter<string> = new EventEmitter<string>();
  @Output() public measurement: EventEmitter<DataBluetooth> = new EventEmitter<DataBluetooth>();
  @Output() public validate: EventEmitter<any> = new EventEmitter<any>();

  /**
   * when ObservationModalComponent update this value, we must update BluetoothDeviceComponent
   */
  @Input() public validatedValue: DataMeasurementBluetooth;

  @Input() public set observationType(loincCode: string) {
    if (loincCode) {
      if (loincCode.startsWith(AppConstants.PILLDISPENSER)) {
        // linked to drugs
        this.observationTypeCode = AppConstants.PILLDISPENSER;
        this.drugId = loincCode.substring(13); // AppConstants.PILLDISPENSER has 13 characters
      } else {
        // linked to observations
        this.observationTypeCode = loincCode;
      }
      this.updateRessources();
    }
  }
  @Input() public showMesure = true;

  constructor(
    private medicalBluetoothService: MedicalBluetooth,
    private externalRessourceService: ExternalRessourceService,
    protected translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    protected popupService: PopupService,
    infoService: InfoAppService
  ) {
    super(infoService, popupService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubcribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const validatedValueStatus = changes["validatedValue"];
    if (validatedValueStatus && validatedValueStatus.currentValue) {
      const measurementBluetooth = validatedValueStatus.currentValue as DataMeasurementBluetooth;
      switch (measurementBluetooth.valid) {
        case MeasurementBluetooth.TOO_OLD: // value too old in the bluetooth device
          this.displayBluetoothTooOldDataError();
          this.cancelMeasure();
          this.cdr.detectChanges();
          break;
        default: // new value obtain by the bluetooth device
          if (this.dataHandler) {
            this.measuring = false;
            const humanReadableData = this.dataHandler.getHumanReadableData(measurementBluetooth.data.data);
            if (humanReadableData.trim().toLocaleLowerCase().includes("nan")) {
              this.lastMeasure = this.translateService.instant("myobservations.bluetooth.nodata");
              this.hasData = false;
            } else {
              this.lastMeasure = humanReadableData;
              this.hasData = true;
            }
            this.displayRefreshButton = false;
            this.cdr.detectChanges();
          } else {
            FileLogger.error("BluetoothDeviceComponent", "set validatedValue : this.dataHandler is missing");
          }
          break;
      }
    }
  }

  public refresh() {
    this.cancelMeasure();
    this.updateRessources();
  }

  public async measure(externalRessource: IExternalRessource, initialiseDisplayRefreshButton = true) {
    try {
      await this.initBluetooth();

      /* always hide this button when starting a measurement and display it after a certain time : 
               - 30 seconds when starting the measurement
               - 5 seconds from the first click during measurement
            */
      this.displayRefreshButton = false;
      if (initialiseDisplayRefreshButton) {
        this.timeToDisplayRefreshButton = 30000; // 30 seconds when starting the measurement
      }

      this.lastExternalRessourcesForRefresh = externalRessource;
    } catch (error) {
      return;
    }
    try {
      this.measuring = true;
      this.cdr.detectChanges();

      /* according to owasp, you can't use setTimeout if it comes from user-encoded data. In this case, 
               it is only a modification of a boolean, so it is ok.*/
      // display refresh button during measurement after 30 seconds
      setTimeout(() => {
        if (this.measuring) {
          this.displayRefreshButton = true;
          this.timeToDisplayRefreshButton = 5000; // 5 seconds from the first click during measurement
          this.cdr.detectChanges();
        }
      }, this.timeToDisplayRefreshButton);

      const device = this.getBondedDevice(externalRessource);
      const serviceHandler = medicalBluetoothServiceMapping[device.services[0]];

      this.unsubcribe();
      this.dataHandler = await this.medicalBluetoothService.connect(
        device,
        serviceHandler,
        this.onDestroy$,
        externalRessource.meta?.searchPreviousData
      );
      this.stopScan$ = new Subject<void>();
      this.dataHandler
        .getData()
        .pipe(takeUntil(this.stopScan$), takeUntil(this.onDestroy$))
        .subscribe(
          (data) => {
            if (!data) {
              this.displayBluetoothReadingError();
              this.cancelMeasure();
            } else {
              // launch the method associated to ObservationModalComponent.
              // This method set the variable validatedValue which allow to update this component
              this.measurement.emit({ data, externalRessource });
            }
          },
          (err) => {
            this.manageError(err);
          },
          () => {
            this.medicalBluetoothService.clearPreviousConnection(device);
          }
        );
    } catch (error) {
      this.manageError(error);
    }
  }

  private manageError(error) {
    FileLogger.error("BluetoothDeviceComponent", "manageError", JSON.stringify(error));
    this.displayBluetoothError();
    this.cancelMeasure();
  }

  public cancelMeasure() {
    this.unsubcribe();
    this.measuring = false;
  }

  public relaunchMeasure(initialiseDisplayRefreshButton = true) {
    this.cancelMeasure();
    this.lastMeasure = null;
    if (this.lastExternalRessourcesForRefresh) {
      this.measure(this.lastExternalRessourcesForRefresh, initialiseDisplayRefreshButton);
    } else {
      FileLogger.error("relaunchMeasure", "this.lastExternalRessourcesForRefresh is unknown");
    }
  }

  private unsubcribe() {
    if (Tools.isDefined(this.stopScan$)) {
      this.stopScan$?.next();
      this.stopScan$?.complete();
      this.stopScan$ = null;
    }

    /*if (Tools.isDefined(this.measureSubscription)) {
            this.measureSubscription.unsubscribe();
            this.measureSubscription = null;
        }*/
  }

  /**
   * different treatment if the Bluetooth device is linked to observations or drugs
   * @param checkIsBonded
   * @returns
   */
  public filterExternalRessourcesByType(checkIsBonded: boolean): IExternalRessource[] {
    if (this.observationTypeCode !== AppConstants.PILLDISPENSER) {
      // linked to observations
      return this.externalRessources.filter((ressource) => {
        return (
          ressource.meta.availableLoinc &&
          ressource.meta.availableLoinc.indexOf(this.observationTypeCode) > -1 &&
          (!checkIsBonded || this.isBonded(ressource))
        );
      });
    } else {
      // linked to drugs
      return this.externalRessources.filter((ressource) => {
        const device = MedicalBluetoothSDKPillDispenserService.getBondedDevice(ressource);
        return (
          ressource.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK &&
          (!checkIsBonded || (device?.bonded && device?.drugId === this.drugId))
        );
      });
    }
  }

  public onMoreOptions(): void {
    this.moreOptions.emit(this.observationTypeCode);
  }

  private async updateRessources() {
    let type = [EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE]; // linked to observations
    if (this.observationTypeCode === AppConstants.PILLDISPENSER) {
      // linked to drugs
      type = [EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK];
    }
    const dataReader = this.externalRessourceService.getDataReader("", type);
    for await (const ressources of dataReader) {
      this.externalRessources = ressources;
      this.externalRessourcesFiltered = this.filterExternalRessourcesByType(true);
    }
  }

  private async initBluetooth() {
    try {
      await this.medicalBluetoothService.ready();
    } catch (error) {
      await this.displayBluetoothActivateError();
      throw error;
    }
  }

  private getBondedDevice(externalRessource: IExternalRessource) {
    return this.medicalBluetoothService.getBondedDevice(externalRessource);
  }

  private isBonded(externalRessource: IExternalRessource) {
    return this.getBondedDevice(externalRessource) !== undefined;
  }

  private async displayBluetoothActivateError() {
    await this.popupService.showAlert("myBleDevices.inactiveBluetooth", "myBleDevices.pleaseActivateBluetooth");
  }

  private async displayBluetoothError() {
    await this.popupService.showAlert("myBleDevices.errorBluetooth", "myBleDevices.cannotConnect");
  }

  private async displayBluetoothReadingError() {
    await this.popupService.showAlert("myBleDevices.errorBluetooth", "myBleDevices.cannotRead");
  }

  private async displayBluetoothTooOldDataError() {
    await this.popupService.showAlert("application.title", "myobservations.tooOld");
  }

  public saveFromBt() {
    this.unsubcribe();
    return this.validate.emit("save");
  }
}

import { ChangeDetectorRef, Component, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BasePage } from "../baseClasses/base-page";
import { Account } from "../helpers/account-helper";
import { IObservation, IObservationDefinition, Observation } from "../helpers/observation-helper";
import { Tools } from "../helpers/tools-helper";
import { IExternalRessource } from "../models/externalRessource";
import { ACTION_STATUS_ENTITY } from "../models/sharedInterfaces";
import { ITranslation } from "../models/translation";
import { GetParametersPageService } from "../services/get-parameters-page.service";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";
import { ObservationService } from "../services/globalDataProvider/observation.service";
import { GoToPageService } from "../services/go-to-page.service";
import { InfoAppService } from "../services/info-app.service";
import { ModalObservationService } from "../services/modal-observation.service";
import { PopupService } from "../services/popup.service";

@Pipe({ name: "getEffectiveTiming" })
export class GetEffectiveTimingPipe implements PipeTransform {
  constructor() {}
  transform(obs: IObservation, def: IObservationDefinition): ITranslation {
    const t = def.lsTimingWhen.find((t) => {
      return t.when.code === obs.effectiveTiming.repeat.when.code;
    });
    return t?.name;
  }
}

@Pipe({ name: "dataToShow" })
export class DataToShowPipe implements PipeTransform {
  constructor(protected translateSvc: TranslateService) {}
  transform(obs: IObservation, def: IObservationDefinition): string {
    if (!obs) return "";
    let dataToShow = "";
    switch (def.loinc) {
      case Account.LOINC_GLUCOSE: {
        const mgDlGlucoseConcentration = Observation.getComponent(obs, Account.LOINC_GLUCOSE_CONCENTRATION).valueQuantity.value;
        const fasting = Observation.getComponent(obs, Account.LOINC_GLUCOSE_FASTING).valueQuantity.value;
        const defComponent = def.components.find((d) => d.loinc === Account.LOINC_GLUCOSE_CONCENTRATION);
        dataToShow += "" + mgDlGlucoseConcentration + " " + (defComponent?.unit ? defComponent.unit : "mg/dl");
        if (Tools.isDefined(fasting)) {
          dataToShow +=
            " - " +
            this.translateSvc.instant("myobservations.fasting") +
            " : " +
            this.translateSvc.instant(fasting ? "application.yes" : "application.no");
        }
      }
    }
    return dataToShow;
  }
}

@Component({
  selector: "app-bluetooth-multiple-obs",
  templateUrl: "./bluetooth-multiple-obs.page.html",
  styleUrls: ["./bluetooth-multiple-obs.page.scss"],
})
export class BluetoothMultipleObsPage extends BasePage {
  public data: any;
  public latestObs: IObservation;
  public listObs: {
    observation: IObservation;
    isChecked?: boolean;
  }[] = [];
  public definition: IObservationDefinition;
  private externalRessource: IExternalRessource;
  public isLoading = false;
  constructor(
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected infoAppService: InfoAppService,
    protected popupService: PopupService,
    private gotoPage: GoToPageService,
    private modalObservationService: ModalObservationService,
    protected pageParam: GetParametersPageService,
    private observationService: ObservationService,
    protected cdr: ChangeDetectorRef
  ) {
    super(translateSvc, configService, infoAppService, popupService);
  }

  ngOnInit() {
    this.data = this.pageParam.getValueOfActivePage("data", undefined);
    this.definition = this.pageParam.getValueOfActivePage("definition", undefined);
    this.externalRessource = this.pageParam.getValueOfActivePage("externalRessource", undefined);
    this.convertDataIntoObservations();
    this.separateLatestObs();
  }

  private convertDataIntoObservations() {
    switch (this.definition.loinc) {
      case Account.LOINC_GLUCOSE:
        this.data.forEach((d: { mgDlGlucoseConcentration: number; date: Date; fasting?: boolean }) => {
          const obs: IObservation = Observation.createObservation(this.definition, this.configService.getCurrentLanguage());
          Observation.getComponent(obs, Account.LOINC_GLUCOSE_CONCENTRATION).valueQuantity.value = d.mgDlGlucoseConcentration;
          Observation.getComponent(obs, Account.LOINC_GLUCOSE_FASTING).valueQuantity.value = Tools.isDefined(d.fasting)
            ? d.fasting
              ? 1
              : 0
            : undefined;
          obs.issued = moment(d.date).format();
          if (this.definition.askTimingWhen) {
            obs.effectiveTiming = {
              repeat: {
                count: 1,
                when: this.definition.lsTimingWhen.find(
                  (t) => t.when.code === Observation.defaultEffectiveTime(this.definition, moment(d.date).format(), this.configService)
                ).when,
              },
            };
          }
          obs.device = {
            type: this.externalRessource?.meta?.medicalBluetoothService,
            reference: this.externalRessource?.title,
            componentAnswer: this.externalRessource?.meta?.componentAnswer,
          };
          this.listObs.push({
            observation: obs,
            isChecked: this.isChecked(obs),
          });
        });
        break;
    }
  }

  private separateLatestObs() {
    this.listObs.sort((a, b) => {
      return moment(a.observation.issued).isBefore(moment(b.observation.issued)) ? 1 : -1;
    });
    this.latestObs = this.listObs[0].observation;
    this.listObs.splice(0, 1);
  }

  private isChecked(obs: IObservation): boolean {
    let loincToVerify: string[] = [];
    switch (this.definition.loinc) {
      case Account.LOINC_GLUCOSE:
        loincToVerify = [Account.LOINC_GLUCOSE_CONCENTRATION, Account.LOINC_GLUCOSE_FASTING];
    }
    let isAllLoincsDefined = true;
    loincToVerify.forEach((l) => {
      const c = Observation.getComponent(obs, l);
      if (isAllLoincsDefined) {
        isAllLoincsDefined = Tools.isDefined(c.valueQuantity?.value);
      }
    });
    return isAllLoincsDefined;
  }

  public handleCheckboxClicked(index: number): void {
    const o = this.listObs[index];
    if (!this.isChecked(o.observation)) {
      this.onEdit(index);
    }
  }

  public async onEdit(index: number) {
    const loincDisabled = this.definition.loinc === Account.LOINC_GLUCOSE ? [Account.LOINC_GLUCOSE_CONCENTRATION] : [];
    this.listObs[index].observation.actionStatus = ACTION_STATUS_ENTITY.CREATED;
    const newObs = await this.modalObservationService.editObservation(
      this.definition,
      this.listObs[index].observation,
      [],
      true,
      loincDisabled
    );
    if (newObs) {
      this.listObs[index].observation = newObs;
    }
    this.listObs[index].isChecked = this.isChecked(this.listObs[index].observation);
  }

  public async dismiss() {
    this.isLoading = true;
    this.cdr.detectChanges();
    const allObs = await this.observationService.getFreshestData();
    await this.modalObservationService.editObservation(this.definition, this.latestObs, allObs);
    this.gotoPage.observationPage();
    this.isLoading = false;
  }

  public async save() {
    this.isLoading = true;
    this.cdr.detectChanges();
    for (let i = 0; i < this.listObs.length; i++) {
      const o = this.listObs[i];
      if (o.isChecked) {
        o.observation.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
        await this.observationService.save(o.observation);
      }
    }
    this.dismiss();
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { nanoid } from "nanoid";
import { AppConstants } from "src/app/appConstants";
import { ArrayHelper } from "src/app/helpers/array-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ServerResponse } from "src/app/helpers/server-response-helper";
import { CustomParamKey, DataParameter } from "src/app/models/dataParameters";
import { IApiResponse } from "src/app/models/iapi-response";
import { Mutex } from "src/app/models/mutex";
import { ApiService } from "../../api.service";
import { InfoAppService } from "../../info-app.service";
import { ConnectionStatus, NetworkService } from "../../network.service";
import { Aes256Service } from "../../storage/aes256.service";
import { LocalStorageService } from "../../storage/local-storage.service";
import { Tools } from "src/app/helpers/tools-helper";

export enum SYNC_HTTP_METHOD {
  POST,
  PUT,
  DELETE,
}
export interface QueuedRequest {
  date: moment.Moment;
  params: DataParameter;
  data: any;
  id?: string;
}

enum RequestResult {
  SUCCESS,
  ERROR_NOT_AUTHENTICATED,
  ERROR_OTHER,
  SERVER_UNREACHABLE,
}

export enum RequestSenderServiceSyncStatus {
  offline,
  alreadyInProgress,
  success,
  error,
  authenticationError,
}

@Injectable({
  providedIn: "root",
})
export class RequestSenderService {
  private isSynchronising: Map<string, Promise<RequestSenderServiceSyncStatus>> = new Map<
    string,
    Promise<RequestSenderServiceSyncStatus>
  >();

  // If you need access to the storedRequest, the list of all services'keys
  // or the 'isSynchronising' variable anywhere in the code, you need to
  // do it inside a mutex
  private synchroMutex: Mutex = new Mutex();
  private keysListMutex: Mutex = new Mutex();
  private storageMutexes: Map<string, Mutex> = new Map<string, Mutex>();

  private ongoingPostRequestKey = "ongoing_post_requests";
  private allRequestKeys = "allRequestKeys";
  private postRequestKey = "_post_requests";
  private oldRequestsType = "oldRequests";

  constructor(
    private infoService: InfoAppService,
    private networkService: NetworkService,
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private aes256Service: Aes256Service
  ) {}

  /**
   * Will start synchronizing the data of a particular service (or all data of all service)
   * @param serviceType the service we want to synchronize (if no service is given, we will
   * synchronize all services)
   */
  public async sync(serviceType?: string): Promise<RequestSenderServiceSyncStatus> {
    if (!LocalStorageService.hashCaremateIdentifier) {
      FileLogger.warn("RequestSenderService", "Tried to sync before having caremate id");
      return RequestSenderServiceSyncStatus.error;
    }
    let sType = serviceType;
    // While we still have old requests in storage, we can't synchronize new stuff.
    // We will delete the key once everything is sync and this condition will false.
    if (await this.localStorageService.isStored(this.postRequestKey, false)) {
      FileLogger.log("RequestSenderService", "Old queue requests found ! Synchronizing the old before the new");
      sType = this.oldRequestsType;
    }
    return this.synchroniser(this.synchronize.bind(this), sType).catch((err) => {
      FileLogger.error("RequestSenderService", "Error while trying to sync: ", err);
      return RequestSenderServiceSyncStatus.error;
    });
  }

  /**
   * This method will check if we already have a synchronization of 'serviceType' running.
   * If we do, it will return the promise corresponding to the currently running sync.
   * If we don't, il will create a new synchro promise and return it.
   * Several services can synchronize their data simultaneously.
   * @param syncFunction the function used to synchronize data
   * @param serviceType the service we want to synchronize (if no service is given, we will
   * synchronize all services)
   */
  private async synchroniser(
    syncFunction: (type: string) => Promise<RequestSenderServiceSyncStatus>,
    serviceType?: string
  ): Promise<RequestSenderServiceSyncStatus> {
    // If we want to synchronise everything, we need to make several synchronizations:
    if (!serviceType) {
      // get all stored requests' keys
      const syncPromises: Promise<RequestSenderServiceSyncStatus>[] = [];
      try {
        const allRequestKeysList = await this.getKeysList();
        // synchronise each service separatly
        for (const type of allRequestKeysList) {
          // If there's already a partial synchro running, the synchroniser will
          // return the synchro that's already running and not create another one
          syncPromises.push(this.synchroniser(this.synchronize.bind(this), type));
        }
      } catch (err) {
        FileLogger.error("RequestSenderService", "Error while trying to get the keys of all sync services: ", err);
        return RequestSenderServiceSyncStatus.error;
      }
      // but wait for all to have synchronized before returning
      return await Promise.all(syncPromises).then((allStatus: RequestSenderServiceSyncStatus[]) => {
        let _nbSuccess = 0;
        let nbErrors = 0;
        let nbAuthErrors = 0;
        let nbOffline = 0;
        // Bit of a guess as to which status to send back when there are
        // several synchronisations:
        for (const s of allStatus) {
          if (s === RequestSenderServiceSyncStatus.success) _nbSuccess++;
          else if (s === RequestSenderServiceSyncStatus.error) nbErrors++;
          else if (s === RequestSenderServiceSyncStatus.authenticationError) nbAuthErrors++;
          else if (s === RequestSenderServiceSyncStatus.offline) nbOffline++;
        }
        if (nbOffline > 0) {
          return RequestSenderServiceSyncStatus.offline;
        } else if (nbAuthErrors > 0) {
          return RequestSenderServiceSyncStatus.authenticationError;
        } else if (nbErrors > 0) {
          return RequestSenderServiceSyncStatus.error;
        } else {
          return RequestSenderServiceSyncStatus.success;
        }
      });
    }
    // Synchro setup must be done exclusively.
    // Else, we won't know what has been put in the isSynchronizing map.
    await this.synchroMutex
      .runExclusively(() => {
        // We check that we do not already have a same service synchro running
        if (!this.isSynchronising.has(serviceType) && !this.isSynchronising.has(this.oldRequestsType)) {
          // If no concerned synchro are currently running, we can ask for a new one
          const synchro = (async () => {
            try {
              return await syncFunction(serviceType);
            } catch (err) {
              FileLogger.error("RequestSenderService", "error while trying to sync: ", err);
            } finally {
              this.isSynchronising.delete(serviceType);
            }
          })();
          this.isSynchronising.set(serviceType, synchro);
        }
      })
      .catch((err) => {
        FileLogger.error("RequestSenderService", "Error while trying to setup synchro: ", err);
        return RequestSenderServiceSyncStatus.error;
      });
    if (serviceType !== this.oldRequestsType && this.isSynchronising.has(this.oldRequestsType)) {
      // Just in case a sync of a new type manage to get here (it should not happen,
      // but it could if I made an error somewhere, so...)
      FileLogger.warn(
        "RequestSenderService",
        "Got a sync request for: " + serviceType + ", but we stil have an old requests sync running." + "This should not happen."
      );
      return this.isSynchronising.get(this.oldRequestsType);
    }
    return this.isSynchronising.get(serviceType);
  }

  /**
   * The function that actually synchronize the data.
   * It extracts the requests in the storage and put them in the 'ongoing' storage (in one exclusive operation).
   * Then it starts sending the requests one by one and keep the storage updated.
   * If there's an error, we stop everything, put the storage back in order and return.
   * @param serviceType the service we want to synchronize
   */
  private async synchronize(serviceType: string): Promise<RequestSenderServiceSyncStatus> {
    try {
      if (!this.networkService.isCurrentOnline()) {
        return RequestSenderServiceSyncStatus.offline;
      }
      let requests: QueuedRequest[] = [];
      if (!this.storageMutexes.has(serviceType)) {
        this.storageMutexes.set(serviceType, new Mutex());
      }
      await this.storageMutexes.get(serviceType).runExclusively(async () => {
        requests = await this.getStoredRequests(serviceType, true, false);
        // If the user killed the application while it was synchronizing, we could have some requests still in the
        // ongoing requests list. We take them out and put them back at the front of the requests list:
        const ongoingRequests: QueuedRequest[] = await this.getStoredRequests(serviceType, false, true);
        requests = [...ongoingRequests, ...requests];
        // And we save the current state in storage:
        await this.setStoredRequests(serviceType, true, requests);
        await this.setStoredRequests(serviceType, false, []);
      });
      // send requests to server one by one:
      for (const req of requests) {
        const reqResult = await this.sendRequest(req);
        // If for any reason, the request fail, we stop the synchro
        if (reqResult !== RequestResult.SUCCESS) {
          // And we put the ongoing requests back into the stored requests
          await this.storageMutexes.get(serviceType).runExclusively(async () => {
            let currentRequests: QueuedRequest[] = await this.getStoredRequests(serviceType, true, false);
            const ongoingRequests: QueuedRequest[] = await this.getStoredRequests(serviceType, false, true);
            // in the right order
            currentRequests = [...ongoingRequests, ...currentRequests];
            await this.setStoredRequests(serviceType, false, currentRequests);
            await this.setStoredRequests(serviceType, true, []);
          });
          if (reqResult === RequestResult.ERROR_NOT_AUTHENTICATED) {
            return RequestSenderServiceSyncStatus.authenticationError;
          } else {
            return RequestSenderServiceSyncStatus.error;
          }
        }
        // After each successful request, we update the ongoing requests list in storage.
        // We should not need mutex here since this function is the only one that accesses the ongoing requests
        // and this function only run once per type but... let's not risk it.
        await this.storageMutexes.get(serviceType).runExclusively(async () => {
          let ongoingRequests: QueuedRequest[] = await this.getStoredRequests(serviceType, false, true);
          // since we are sending the requests in order, we could potentially just do a splice
          // but who wants to risk it ? Filter it is.
          ongoingRequests = ongoingRequests.filter((r: QueuedRequest) => !this.isSameRequest(r, req));
          await this.setStoredRequests(serviceType, true, ongoingRequests);
        });
      }
      if (serviceType === this.oldRequestsType) {
        // needed for backward compatibility
        // We delete the old key from storage
        await this.storageMutexes.get(serviceType).runExclusively(async () => {
          await this.localStorageService.remove(this.postRequestKey);
          await this.localStorageService.remove(this.ongoingPostRequestKey + serviceType);
        });
      }
      return RequestSenderServiceSyncStatus.success;
    } catch (err) {
      try {
        // if something fail, we put the ongoing requests back into the stored requests
        await this.storageMutexes.get(serviceType).runExclusively(async () => {
          let currentRequests: QueuedRequest[] = await this.getStoredRequests(serviceType, true, false);
          const ongoingRequests: QueuedRequest[] = await this.getStoredRequests(serviceType, false, true);
          currentRequests = [...ongoingRequests, ...currentRequests];
          await this.setStoredRequests(serviceType, false, currentRequests);
          await this.setStoredRequests(serviceType, true, []);
        });
      } catch (err2) {
        FileLogger.error("RequestSenderService", "could not put stored requests back in queue correctly", err2);
      }
      FileLogger.error("RequestSenderService", "error", err);
      return RequestSenderServiceSyncStatus.error;
    }
  }

  /**
   * Check if two queue requests are identical using the url, the date and the data.
   * @param req1 first request
   * @param req2 second request
   */
  private isSameRequest(req1: QueuedRequest, req2: QueuedRequest): boolean {
    return (
      req1.params.getUrl === req2.params.getUrl &&
      req1.date.toString() === req2.date.toString() &&
      JSON.stringify(Tools.sortObjectByKeys(req1.data)) === JSON.stringify(Tools.sortObjectByKeys(req2.data))
    );
  }

  /**
   * Add a request in the queue in storage (in one exclusive operation) and try to
   * sync the requests if we are online
   * @param params the parameters of the service that generated the request
   * @param data the new data
   * @param waitResponse indicates whether to wait for the route's response (and return the status) to release the promise of this method
   */
  public async queue(params: DataParameter, data: string, waitResponse = false): Promise<RequestSenderServiceSyncStatus | null> {
    if (this.useLocalStorage()) {
      try {
        // Encrypt sensitive data:
        if (params.encrypted) {
          data = await this.aes256Service.encrypt(data);
        }
        const serviceType = params.entityPrefix;
        const newRequest: QueuedRequest = { date: moment(), params, data, id: nanoid() };
        if (!this.storageMutexes.has(serviceType)) {
          this.storageMutexes.set(serviceType, new Mutex());
        }
        // If a function somewhere is modifying this storage, we will need to wait for
        // it to finish before we start doing our own modifications
        await this.storageMutexes.get(serviceType).runExclusively(async () => {
          let requests: QueuedRequest[] = await this.getStoredRequests(serviceType, false, false);
          if (params.customParam) {
            requests = await this.updateListAndFilterMergeRequests(newRequest, requests);
          } else {
            requests.push(newRequest);
            requests = requests.filter(ArrayHelper.onlyUniqueRequestSenderService);
          }
          await this.setStoredRequests(serviceType, false, requests);
        });
        // If we are online, we directly try to sync the data:
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online) {
          if (waitResponse) {
            return this.sync(serviceType).then(async (statusSynchro) => {
              if (statusSynchro !== RequestSenderServiceSyncStatus.success) {
                await this.storageMutexes.get(serviceType).runExclusively(async () => {
                  const requests = await this.getStoredRequests(serviceType);
                  const filterRequests = requests.filter((r) => r.id !== newRequest.id);
                  await this.setStoredRequests(serviceType, false, filterRequests);
                });
              }
              return statusSynchro;
            }); // synchrone code
          } else {
            this.sync(serviceType); // asynchrone code
            return null;
          }
        } else if (waitResponse) {
          return RequestSenderServiceSyncStatus.offline;
        }
      } catch (err) {
        FileLogger.error("RequestSenderService", "Error while queueing: ", err);
        return RequestSenderServiceSyncStatus.error;
      }
    } else {
      if (waitResponse) {
        return this.sendRequest({ date: moment(), params, data, id: nanoid() }).then(
          // synchrone code
          (status) => {
            switch (status) {
              case RequestResult.SUCCESS:
                return RequestSenderServiceSyncStatus.success;
              case RequestResult.SERVER_UNREACHABLE:
                return RequestSenderServiceSyncStatus.offline;
              default:
                return RequestSenderServiceSyncStatus.error;
            }
          },
          (_err) => {
            return RequestSenderServiceSyncStatus.error;
          }
        );
      } else {
        this.sendRequest({ date: moment(), params, data, id: nanoid() }); // asynchrone code
        return null;
      }
    }
  }

  public async needSynchro(serviceType: string): Promise<boolean> {
    // While we still have old requests in storage, absolutely need to synchro
    // We will delete the key once everything is sync and this condition will false.
    if (await this.localStorageService.isStored(this.postRequestKey, false)) {
      FileLogger.log("RequestSenderService", "Old queue requests found ! We need to synchronize.");
      return true;
    }
    const requests = await this.getStoredRequests(serviceType);
    if (!requests || requests.length === 0) {
      return false;
    } else {
      return requests.filter((req: QueuedRequest) => req.params.entityPrefix === serviceType).length > 0;
    }
  }

  /**
   * Sends a particular request to the server
   * @param req the request
   */
  private async sendRequest(req: QueuedRequest): Promise<RequestResult> {
    let data = null;
    if (req.params.encrypted && this.useLocalStorage()) {
      try {
        data = await this.aes256Service.decrypt(req.data);
      } catch (err) {
        FileLogger.error("RequestSenderService", "Send request could not decrypt data: ", JSON.stringify(req));
        return RequestResult.ERROR_OTHER;
      }
    } else {
      data = req.data;
    }
    try {
      // tslint:disable-next-line: deprecation
      let response: IApiResponse;
      switch (req.params.method) {
        case SYNC_HTTP_METHOD.POST:
          response = await this.apiService.postWithPromise(req.params.setUrl, data);
          break;
        case SYNC_HTTP_METHOD.PUT:
          response = await this.apiService.put(req.params.setUrl, data).toPromise();
          break;
        case SYNC_HTTP_METHOD.DELETE:
          response = await this.apiService.delete(req.params.setUrl).toPromise();
          break;
      }

      // API Request failed (success false)
      if (response && !response.success) {
        if (ServerResponse.isServerUnreachableError(response)) {
          return RequestResult.SERVER_UNREACHABLE;
        }

        // Token expired, save request for later
        if (ServerResponse.isAuthenticationError(response) || ServerResponse.isAccessDenied(response)) {
          // TODO this.events.publish(AppConstants.EV_LOGOUT);
          // TODO this.events.publish(AppConstants.EV_GOTOLOGINPAGE, { 'error' : response });
          return RequestResult.ERROR_NOT_AUTHENTICATED;
        } else if (this.useLocalStorage()) {
          // POST with ServerResponse.ALREADY_EXISTS is not blocking -> no need to try again
          if (response.data === ServerResponse.ALREADY_EXISTS.code && req.params.method === SYNC_HTTP_METHOD.POST) {
            return RequestResult.SUCCESS;
          } else {
            return RequestResult.ERROR_OTHER;
          }
        }
      } else if (response && response.success) {
        return RequestResult.SUCCESS;
      } else {
        return RequestResult.ERROR_OTHER;
      }
    } catch (e) {
      if (e.type === 3 && e.ok === false && e.status === 0) {
        return RequestResult.SERVER_UNREACHABLE;
      } else if (ServerResponse.isAuthenticationError(e)) {
        // TODO this.events.publish(AppConstants.EV_LOGOUT);
        // Token expired, save request for later
        // TODO this.events.publish(AppConstants.EV_GOTOLOGINPAGE, { 'error' : e });
        return RequestResult.ERROR_NOT_AUTHENTICATED;
      } else if (e instanceof Response) {
        return RequestResult.ERROR_OTHER;
      } else {
        return RequestResult.ERROR_OTHER;
      }
    }
  }

  /**
   * Note: 'getStoredRequests' must always be used inside the corresponding mutex
   * because we do not want to extract data while another function is currently modifying them.
   * You can eventually get away with it if you just want to look briefly at the data and not do
   * anything else with it. (like, just looking to see if we need to synchro some requests)
   * @param serviceType the service the requests belong to
   * @param filterUnique filter for keeping only unique requests
   */
  private async getStoredRequests(serviceType?: string, filterUnique = false, isOngoing?: boolean): Promise<QueuedRequest[]> {
    try {
      // A getStoredRequests withtout an serviceType (= we want all requests) should happen
      // only in the case we want to check if we need to synchro something.
      // Not for actually synchronizing the requests we find.
      if (!serviceType) {
        // get all stored requests' keys
        const allRequestKeysList = await this.getKeysList();
        const syncPromises: Promise<QueuedRequest[]>[] = [];
        // get each service's requests separatly
        for (const type of allRequestKeysList) {
          syncPromises.push(this.getStoredRequests(type, true, isOngoing));
        }
        // wait for all, then flatten the array of QueuedRequest array:
        return await Promise.all(syncPromises).then((allRequests: QueuedRequest[][]) => {
          return [].concat(...allRequests);
        });
      } else {
        let key = "";
        if (serviceType === this.oldRequestsType) {
          // needed for backward compatibility
          key = isOngoing ? this.ongoingPostRequestKey + serviceType : this.postRequestKey;
        } else {
          key = isOngoing ? this.ongoingPostRequestKey + serviceType : this.postRequestKey + serviceType;
        }

        const isStored = await this.localStorageService.isStored(key, false);
        if (!isStored) {
          return [];
        }
        const storedRequests = await this.localStorageService.getData(key, false);
        if (!storedRequests || storedRequests === undefined) {
          return [];
        } else {
          const queuedRequest: QueuedRequest[] = JSON.parse(storedRequests);
          if (filterUnique) {
            return queuedRequest.filter(ArrayHelper.onlyUniqueRequestSenderService);
          }
          return queuedRequest;
        }
      }
    } catch (e) {
      FileLogger.error("RequestSenderService", "error getStoredRequests " + e);
      return [];
    }
  }

  /**
   * Note: 'setStoredRequests' must always be used inside the corresponding mutex
   * because we do not want to modify data while another function is also modifying them.
   * @param serviceType the service the requests belong to
   * @param isOngoing whether we need to save it in the 'ongoing' storage (storage
   * of requests currently being synchronized)
   * @param requests the requests we want to save (note: this will overrite what
   * is currently in the storage, be sure of what you are doing)
   */
  private async setStoredRequests(serviceType: string, isOngoing: boolean, requests: QueuedRequest[]): Promise<void> {
    try {
      let key = "";
      if (serviceType === this.oldRequestsType) {
        // needed for backward compatibility
        key = isOngoing ? this.ongoingPostRequestKey + serviceType : this.postRequestKey;
      } else {
        key = isOngoing ? this.ongoingPostRequestKey + serviceType : this.postRequestKey + serviceType;
        await this.keepKeysListUpToDate(serviceType);
      }
      await this.localStorageService.setData(key, JSON.stringify(requests), false);
    } catch (err) {
      FileLogger.error("RequestSenderService", "setStoredRequests error: ", err);
    }
  }

  /**
   * Checks if we already have this 'serviceType' in our list of all serviceTypes in storage.
   * If we don't, we add it. And all this is done in one exclusive operation.
   * This list is useful for when we want to synchronize all the service and we need all the keys
   * with which their requests are stored.
   * @param serviceType the serviceType
   */
  private async keepKeysListUpToDate(serviceType: string): Promise<void> {
    await this.keysListMutex.runExclusively(async () => {
      let requestKeys = [];
      // Check if we already have a list
      const isStored = await this.localStorageService.isStored(this.allRequestKeys, false);

      if (!isStored) {
        // if not, we will have to create the list
        requestKeys = [];
      } else {
        const r = await this.localStorageService.getData(this.allRequestKeys, false);
        requestKeys = JSON.parse(r);
      }
      // If the service type is not in the list, we add it and save the list
      if (!requestKeys.includes(serviceType)) {
        requestKeys.push(serviceType);
        // Let's not store this as a string and keep it as an array to gain a bit of time
        const stringifyiedKeys = JSON.stringify(requestKeys);
        await this.localStorageService.setData(this.allRequestKeys, stringifyiedKeys, false);
      }
    });
  }

  /**
   * Get the list of all keys with which the services store their requests.
   */
  private async getKeysList(): Promise<string[]> {
    return await this.keysListMutex.runExclusively(async () => {
      let requestKeys = [];
      const isStored = await this.localStorageService.isStored(this.allRequestKeys, false);

      if (!isStored) {
        requestKeys = [];
      } else {
        const r = await this.localStorageService.getData(this.allRequestKeys, false);
        requestKeys = JSON.parse(r);
      }
      return requestKeys;
    });
  }

  private useLocalStorage(): boolean {
    return this.infoService.isCordova() || AppConstants.LOCAL_DEV_MODE;
  }

  /**
   * Update the stored requests list (if needed) and separate merge set requests from other requests (if needed)
   * for treatment, then return the observable that will do the treatment
   * @param params          the parameters of the service making the modifs request
   * @param newRequest      the new request we want to add to the queue
   * @param storedRequests  the list of requests already stored in local
   */
  private async updateListAndFilterMergeRequests(newRequest: QueuedRequest, storedRequests: QueuedRequest[]): Promise<QueuedRequest[]> {
    let newStoredRequests = storedRequests;
    // case where we must delete the old setUrl before push ;
    if (newRequest.params.customParam[CustomParamKey.overwriteQueue]) {
      newStoredRequests = [newRequest];
    }
    // case we must merge all setRequest in only one (the set route must accept an array !)
    else if (newRequest.params.customParam[CustomParamKey.mergeSetRequest]) {
      newStoredRequests.push(newRequest);
      // all requests to merge
      newStoredRequests = newStoredRequests.filter(ArrayHelper.onlyUniqueRequestSenderService);

      const decryptedMergeRequests: Promise<QueuedRequest>[] = newStoredRequests.map(async (req: QueuedRequest) => {
        const data: string = newRequest.params.encrypted ? await this.aes256Service.decrypt(req.data) : req.data;
        return {
          date: req.date,
          params: req.params,
          data: JSON.parse(data),
        };
      });
      newStoredRequests = await this.mergeSetRequests(decryptedMergeRequests, newRequest.params.encrypted);
    }
    return newStoredRequests;
  }

  /**
   *
   * @param decryptedMergeRequests  the promises that will decrypt the data of the updated request list
   * @param needEncryption          whether or not we need to encrypt the requests data
   * @returns
   */
  private async mergeSetRequests(decryptedMergeRequests: Promise<QueuedRequest>[], needEncryption: boolean): Promise<QueuedRequest[]> {
    // Wait for all stored requests to be decrypted:
    const decyptedNewStoredRequests: QueuedRequest[] = await Promise.all(decryptedMergeRequests);
    // Separate POST and PUT requests:
    const putRequests: QueuedRequest[] = decyptedNewStoredRequests.filter((req) => req.params.method === SYNC_HTTP_METHOD.PUT);
    const postRequests: QueuedRequest[] = decyptedNewStoredRequests.filter((req) => req.params.method === SYNC_HTTP_METHOD.POST);
    // Merge requests data by putting all data of each type of requests into one array:
    const mergedDataPUT: unknown[] = putRequests
      .map((req) => (Array.isArray(req.data) ? req.data : [req.data]))
      .reduce((acc, it) => [...acc, ...it], []);
    // Keep only unique data for POST requests
    // (we can't do this for the put requests cause we can have multiple same requests)
    const mergedDataPOST: unknown[] = postRequests
      .map((req) => (Array.isArray(req.data) ? req.data : [req.data]))
      .reduce((acc, it) => [...acc, ...it], [])
      .filter(ArrayHelper.onlyUniqueData);

    // Encrypt back the data:
    const POSTdataMergedStringified = needEncryption
      ? await this.aes256Service.encrypt(JSON.stringify(mergedDataPOST))
      : JSON.stringify(mergedDataPOST);
    const PUTdataMergedStringified = needEncryption
      ? await this.aes256Service.encrypt(JSON.stringify(mergedDataPUT))
      : JSON.stringify(mergedDataPUT);

    // Put back the merged requests together in the list. POST requests first:
    const requestsToStore = [];
    if (postRequests?.length) {
      requestsToStore.push({
        date: moment(),
        params: postRequests[0].params,
        data: POSTdataMergedStringified,
      } as QueuedRequest);
    }
    if (putRequests?.length) {
      requestsToStore.push({
        date: moment(),
        params: putRequests[0].params,
        data: PUTdataMergedStringified,
      } as QueuedRequest);
    }
    return requestsToStore;
  }

  /**
   * Return a promise which resolves when all current synchronizations are done
   */
  public async promiseResolveWhenReady(): Promise<void> {
    try {
      const keys = this.isSynchronising.keys();
      const synchros = [];
      for (const key of keys) {
        synchros.push(this.isSynchronising.get(key));
      }
      await Promise.all(synchros);
    } catch (err) {
      FileLogger.error("RequestSenderService", "promiseResolveWhenReady() error", err);
    }
  }
}

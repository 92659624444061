import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "truncateFileName" })
export class TruncateFileNamePipe implements PipeTransform {
  constructor() {}

  transform(name: any): any {
    name = name.split(".");
    let filename = name[0];
    const extension = name[1];

    if (filename.length > 15) {
      filename = filename.slice(0, 8) + "... ";
    }

    const result = filename + "." + extension;
    return result;
  }
}

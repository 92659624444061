import { Pipe, PipeTransform } from "@angular/core";
import { DeviceState, EXTERNAL_RESSOURCE_TYPE, IExternalRessource } from "../models/externalRessource";
import { MedicalBluetooth } from "../services/medical-bluetooth/medical-bluetooth.service";
import { AccountService } from "../services/globalDataProvider/account.service";
import {
  MedicalBluetoothPillDispenserDevice,
  MedicalBluetoothSDKPillDispenserService,
} from "../services/medical-bluetooth/medical-bluetooth-sdk-pilldispenser.service";
import { ExternalRessourceService } from "../services/globalDataProvider/external-ressource.service";
import { DrugService } from "../services/globalDataProvider/drug.service";

@Pipe({ name: "getHardwareByCategory" })
export class GetHardwareByCategoryPipe implements PipeTransform {
  public transform(category: string, ressources: IExternalRessource[]): IExternalRessource[] {
    return ressources.filter((ressource) => ressource.categories.indexOf(category) > -1);
  }
}

@Pipe({ name: "linkedDrugExternalRessourcePipe" })
export class LinkedDrugExternalRessourcePipe implements PipeTransform {
  constructor(protected externalRessourceService: ExternalRessourceService, protected drugService: DrugService) {}

  /**
   * @param atcCode to find out if there is a resource external associated with this atc code
   * @param drugId (optional) to find out if an external resource is linked to this specific drug
   * @param bondedDevices (optional) it is used to find the device associatied to the drugId
   * (you need to pass it as an argument so that the pipe updates itself when the device association is updated)
   * @returns true if such an external resource exists, false otherwise
   */
  public async transform(atcCode: string, drugId?: string, bondedDevices?: MedicalBluetoothPillDispenserDevice[]): Promise<boolean> {
    return await this.drugService.islinkedDrugExternalRessource(atcCode, drugId, bondedDevices);
  }
}

@Pipe({ name: "isActiveHardwareBonded" })
export class IsActiveHardwareBondedPipe implements PipeTransform {
  constructor(protected medicalBluetoothService: MedicalBluetooth, protected accountService: AccountService) {}

  public transform(hardware: IExternalRessource): boolean {
    if (!hardware) {
      return false;
    } else {
      if (hardware.type === EXTERNAL_RESSOURCE_TYPE.ONLINE_HARDWARE) {
        const found = this.accountService.cachedAccount.connectedDevices?.find(
          (d) => d.externalRessourceRef === hardware.reference && d.deviceState !== DeviceState.STOPPED
        );
        return !!found;
      }
      if (hardware.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK) {
        // linked to drugs
        return (
          MedicalBluetoothSDKPillDispenserService.bondedDevices.find((device) => {
            return hardware.reference && device.name.indexOf(hardware.reference) > -1 && device.bonded;
          }) !== undefined
        );
      } else {
        // linked to observations
        return (
          this.medicalBluetoothService.bondedDevices.find((device) => {
            return hardware.reference && device.name.indexOf(hardware.reference) > -1 && device.bonded;
          }) !== undefined
        );
      }
    }
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ArrayHelper } from "src/app/helpers/array-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IKnowledges } from "src/app/helpers/knowledge-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { LocalStorageService } from "../storage/local-storage.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { RelatedCareplansService } from "./related-careplans.service";
import { RelatedDrugsService } from "./related-drugs.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class RelatedKnowledgeService
  extends BasicSyncService<IKnowledges, IKnowledges[]>
  implements StaticImplements<INeedRefresh, typeof RelatedKnowledgeService>
{
  public get needRefresh(): { value: boolean } {
    return RelatedKnowledgeService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  private lastValueOfParam = "[]";
  private storageKey = "RelatedKnowledgeLastValue";

  private careplanWatch$: Subscription;

  constructor(
    protected dataService: DataService,
    private relatedDrugsService: RelatedDrugsService,
    private relatedCareplansService: RelatedCareplansService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    super(dataService);
  }

  public getUrl(): string {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
    this.careplanWatch$?.unsubscribe();
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.careplanWatch$?.unsubscribe();
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "related_knwoledges_",
      entityStoreKey: "list",
      getUrl: "/knowledge?reference=",
      setUrl: "/knowledge",
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
    } catch (err) {
      this.lastValueOfParam = "[]";
    }
  }

  public clear(): void {
    super.clear();
    this.lastValueOfParam = "[]";
  }

  public async *getDataReader(parameters?: string[]): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {
    try {
      if (this.accountService.isOnlyPatient()) {
        yield [];
        return [];
      }
      let params = [];
      if (parameters) {
        params = parameters.map((value) => Tools.deleteAcccentSpecialcharacter(value.toLowerCase()));
      }
      const relatedCareplanSnomed = await this.relatedCareplansService.listSnomedRef();
      const relatedDrugSnomed = await this.relatedDrugsService.listSnomedRef();
      const allSnomed = relatedCareplanSnomed.concat(relatedDrugSnomed).filter(ArrayHelper.onlyUnique).sort();

      const paramObject = Object.assign({}, this.defaultDataParameter);
      const stringifySnomed = JSON.stringify(allSnomed);
      paramObject.getUrl += stringifySnomed;
      // do not modify entityStoreKey to allow the patient to have access at knowledge even if there are changes in parameters
      // paramObject.entityStoreKey += stringifySnomed;
      this.lastValueOfParam = stringifySnomed;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);

      const dataReader = this.dataService.readv2<IKnowledges, IKnowledges[]>(paramObject, false, this);
      let d: IKnowledges[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, params);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("RelatedKnowledgeService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IKnowledges[], params?: string[]) {
    try {
      const kn = dataResult;
      if (!params) {
        return kn;
      }
      return kn.filter((kk) => {
        const parsedRef = Tools.deleteAcccentSpecialcharacter(kk.reference.toLowerCase());
        return params.includes(parsedRef);
      });
    } catch (err) {
      FileLogger.error("RelatedKnowledgeService", "Error while processing relatedKnowledgeService data: ", err);
      return [];
    }
  }
}

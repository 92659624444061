import { Codes, IEntity, Identifier, Reference } from "./sharedInterfaces";

export interface IAttachment {
  contentType: string;
  data: string;
  title: string;
  creation: Date;
  url: string;
  size?: number;
  hash: string;
}

export enum IType {
  PDF = "application/pdf",
  JPG = "image/jpeg",
  TIFF = "image/tiff",
  PNG = "image/png",
}

export interface IPayload {
  contentAttachment: IAttachment;
  contentString: string;
  // contentReference: Reference;
}

export interface IAnnotation {
  authorReference: Reference;
  authorString: string;
  time: Date;
  text: string;
}

export enum STATUS_CODE {
  PREPARATION = "preparation", // => draft, we should never see this status here
  IN_PROGRESS = "in-progress", // => not used as far as I can tell
  NOT_DONE = "not-done", // => not used as far as I can tell
  SUSPENDED = "suspended", // => not used as far as I can tell
  ABORTED = "aborted", // => not used as far as I can tell
  COMPLETED = "completed", // => sent but not received, we should never see this status here
  ENTERED_IN_ERROR = "entered-in-error",
  DELIVERED = "delivred", // => received
  OPENED = "opened",
}

export interface ICommunication extends IEntity {
  resourceType: string;
  identifier: Identifier[];
  basedOn?: Reference[];
  deletedByReceiver?: boolean;
  // partOf: Reference[];
  // inResponseTo: Reference[];
  status: STATUS_CODE;
  // statusReason: Codes;
  // category: Codes[];
  // priority: any;
  // medium: Codes[];
  subject: Reference;
  topic: Codes;
  id: number;
  // about: Reference[];
  // encounter: Reference[];
  sent: Date;
  received: Date;
  // recipient: Reference[];
  sender: Reference;
  // reasonCode: Codes[];
  // reasonReference: Reference[];
  payload: IPayload[];
  // note: IAnnotation[];
  records: string[][];
  healthservice?: Reference;
}

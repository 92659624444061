import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { from, Observable } from "rxjs";
import { AboutModalComponent } from "../components/modals/about-modal/about-modal.component";
import { AccountModalComponent } from "../components/modals/account-modal/account-modal.component";
import { AppoitmentSummaryModalComponent } from "../components/modals/appoitment-summary-modal/appoitment-summary-modal.component";
import { DisplayPhotoModalComponent } from "../components/modals/display-photo-modal/display-photo-modal.component";
import { DownloadObservationsModalComponent } from "../components/modals/download-observations-modal/download-observations-modal.component";
import { EntourageAddModalComponent } from "../components/modals/entourage-add-modal/entourage-add-modal.component";
import { EntouragePersonAccessModalComponent } from "../components/modals/entourage-person-access-modal/entourage-person-access-modal.component";
import { FingerprintLoginPwdModalComponent } from "../components/modals/fingerprint-login-pwd-modal/fingerprint-login-pwd-modal.component";
import { HelpModalComponent } from "../components/modals/help-modal/help-modal.component";
import { MessageModalComponent } from "../components/modals/message-modal/message-modal.component";
import { MyProfilModalComponent } from "../components/modals/my-profil-modal/my-profil-modal.component";
import { NoteModalComponent } from "../components/modals/note-modal/note-modal.component";
import { QuestionnaireModalComponent } from "../components/modals/questionnaire-modal/questionnaire-modal.component";
import { ResetPasswordModalComponent } from "../components/modals/reset-password-modal/reset-password-modal.component";
import { RewardHelpComponent } from "../components/modals/reward-help/reward-help.component";
import { RewardHistoryComponent } from "../components/modals/reward-history/reward-history.component";
import { SettingsMenuPageModalComponent } from "../components/modals/settings-menu-page-modal/settings-menu-page-modal.component";
import { ChangeOwnPasswordModalComponent } from "../components/modals/settings-security-page-modal/change-own-password-modal/change-own-password-modal.component";
import { ShareModalComponent } from "../components/modals/share-modal/share-modal.component";
import { TwoFAModalComponent } from "../components/modals/two-famodal/two-famodal.component";
import { IAccount } from "../helpers/account-helper";
import { QuestionnaireResponse } from "../helpers/questionnaireResponse";
import { Tools } from "../helpers/tools-helper";
import { IAppointment } from "../models/appointment";
import { ICommunication } from "../models/communication";
import { IObservationParam } from "../models/configuration";
import { Entitylink, IEntitylink, PARENT_TYPE } from "../models/entitylink";
import { KeyValue } from "../models/keyValue";
import { IQuestionnaire } from "../models/questionnaire";
import { IRelatedPerson } from "../models/relatedPerson";
import { AccountService } from "./globalDataProvider/account.service";
import { ConfigurationService } from "./globalDataProvider/configuration.service";
import { DrugService } from "./globalDataProvider/drug.service";
import { PopupService } from "./popup.service";
import { FileLogger } from "../helpers/fileLogger";
import { AppConstants } from "../appConstants";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(
    private modalCtrl: ModalController,
    private accountService: AccountService,
    private drugService: DrugService,
    private configService: ConfigurationService,
    private popupService: PopupService
  ) {
    moment.locale(this.configService.getCurrentLanguage());
  }

  /**
   *  display view to create a new note and store it
   */
  public createNote(parentId: string, parentType: PARENT_TYPE): Promise<IEntitylink> {
    const caremateId = this.accountService.cachedCaremateId;
    const note = Entitylink.createNote(caremateId, parentId, parentType);
    return this.presentModalNote(note);
  }

  /**
   * display view to edit a note and store modification
   */
  public editNote(note: IEntitylink): Promise<IEntitylink> {
    const copyNote = Tools.deepCopy(note); // make a copy in case of rollback
    return this.presentModalNote(copyNote);
  }

  /**
   * display a note in a modal view
   */
  private presentModalNote(note: IEntitylink): Promise<IEntitylink> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: NoteModalComponent,
          componentProps: {
            note: note,
          },
        })
        .then((modal) => {
          modal.onDidDismiss().then((data) => {
            resolve(data.data as IEntitylink);
          });
          return modal.present();
        });
    });
  }

  /**
   * display 2fa prompt in a modal view
   */
  public presentModal2FA(): Observable<string> {
    return from(
      new Promise<string>((resolve) => {
        this.modalCtrl
          .create({
            component: TwoFAModalComponent,
          })
          .then((modal) => {
            modal.onDidDismiss().then((data) => {
              resolve(data.data as string);
            });
            return modal.present();
          });
      })
    );
  }

  /**
   * display Account update in a modal view
   * Notice : this.accountService.cachedAccount; must be existed for the modal work fine !!
   */
  public presentModalAccount(newPasswordRequired: boolean): Observable<IAccount> {
    return from(
      new Promise<IAccount>((resolve) => {
        this.modalCtrl
          .create({
            component: AccountModalComponent,
            componentProps: {
              newPasswordRequired: newPasswordRequired,
            },
            showBackdrop: false,
            backdropDismiss: !newPasswordRequired,
          })
          .then((modal) => {
            modal.onDidDismiss().then((data) => {
              resolve(data.data as IAccount);
            });
            return modal.present();
          });
      })
    );
  }

  /**
   * display a knowledge in a modal view
   */
  public presentModalMessage(msg: ICommunication): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: MessageModalComponent,
          componentProps: { msg },
        })
        .then((modal) => {
          modal.onDidDismiss().then((data) => {
            resolve(data.data as boolean);
          });
          return modal.present();
        });
    });
  }

  /**
   * display settings menu
   * @returns Returns a promise that resolves when the modal will dismiss.
   */
  public async presentModalSettingsMenu(): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: SettingsMenuPageModalComponent,
      id: AppConstants.SETTINGS_MODAL,
    });
    await modal.present();
    return modal.onWillDismiss(); // IMPORTANT FOR THE TRANSLATION OF HOMEPAGE
  }

  /**
   * display about view
   */
  public async presentModalAbout(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: AboutModalComponent,
    });
    return modal.present();
  }

  /**
   * display about view
   */
  public async presentModalHelp(isFirstLaunch = false): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: HelpModalComponent,
    });
    if (isFirstLaunch) {
      modal.onDidDismiss().then(async () => {
        const config = this.configService.peekData();
        if (config?.settings.globalSettings.askVitalSign) {
          this.popupService.showYesOrLater("application.refValuePopupTitle", "application.refValuePopupText").then(async (res) => {
            if (res) {
              const myProfileModal = await this.modalCtrl.create({
                component: MyProfilModalComponent,
                componentProps: { profileTab: "datas" },
              });
              myProfileModal.present();
            }
          });
        }
      });
    }
    return modal.present();
  }

  /**
   * display share view
   */
  public async presentModalShare(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ShareModalComponent,
    });
    return modal.present();
  }

  /**
   * display share view
   */
  public async presentModalRewardHistory(componentProps: any): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: RewardHistoryComponent,
      componentProps,
    });
    return modal.present();
  }

  /**
   * display share view
   */
  public async presentModalRewardHelp(): Promise<void> {
    const modal = await this.modalCtrl.create({ component: RewardHelpComponent });
    return modal.present();
  }

  /**
   * display Reset Password view
   */
  public async presentModalResetPassword(user: string, lang: string): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ResetPasswordModalComponent,
      componentProps: {
        user: user,
        lang: lang,
      },
    });
    return modal.present();
  }

  /**
   * display a list of appointments
   * return selected appointment or null
   */
  public async presentModalAppointmentSummary(accountId: string, appointments: IAppointment[]): Promise<KeyValue> {
    const modal = await this.modalCtrl.create({
      component: AppoitmentSummaryModalComponent,
      componentProps: {
        accountId: accountId,
        appointments: appointments,
      },
      showBackdrop: false,
      backdropDismiss: false,
    });
    modal.present();
    return modal.onDidDismiss().then((data) => {
      return data.data as KeyValue;
    });
  }

  /**
   * display to ask login pwd associated to fingerprint : first ask or modify
   */
  public async presentModalLoginPwdForFingerprint(): Promise<void> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: FingerprintLoginPwdModalComponent,
          showBackdrop: false,
          backdropDismiss: false,
        })
        .then((modal) => {
          modal.onDidDismiss().then(
            () => {
              resolve();
            },
            (err) => {
              FileLogger.error("ModalService", "Error in modal login pwd fingerprint", err);
              resolve();
            }
          );
          return modal.present();
        });
    });
  }

  /**
   * display Add Entourage modal view
   */
  public async presentModalEntourageAdd(): Promise<IRelatedPerson> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: EntourageAddModalComponent,
        })
        .then((modal) => {
          modal.onDidDismiss().then((data) => {
            resolve(data.data as IRelatedPerson);
          });
          return modal.present();
        });
    });
  }

  /**
   * display Add Person access details
   */
  public async presentModalEntouragePersonAccess(relatedPerson: IRelatedPerson): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: EntouragePersonAccessModalComponent,
      componentProps: {
        relatedPerson: relatedPerson,
      },
    });
    await modal.present();
  }

  /**
   * @param action "create" or "view" mode
   * @param questionnaire the chosen questionnaire
   * @param questionnaireResponse only when viewing already answered questionnaire
   * @description display a questionnaire in a modal view
   */
  public presentModalQuestionnaire(
    action: string,
    questionnaire: IQuestionnaire,
    questionnaireResponse?: QuestionnaireResponse
  ): Promise<IQuestionnaire> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: QuestionnaireModalComponent,
          componentProps: {
            questionnaire: questionnaire,
            questionnaireResponse: questionnaireResponse,
            action: action,
          },
        })
        .then((modal) => {
          modal.onDidDismiss().then((data) => {
            resolve(data.data as IQuestionnaire);
          });
          return modal.present();
        });
    });
  }

  /**
   *
   */
  public async presentModalDownloadObservations(observations: IObservationParam[]): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DownloadObservationsModalComponent,
      componentProps: {
        observations: observations,
      },
    });
    await modal.present();
  }

  /**
   * display an image
   * @param imageUrl
   */
  public async presentModalDisplayPhoto(imageUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: DisplayPhotoModalComponent,
          componentProps: {
            imageUrl: imageUrl,
          },
        })
        .then((modal) => {
          modal.onDidDismiss().then((data) => {
            resolve(data.data as boolean);
          });
          return modal.present();
        });
    });
  }

  public async presentModalChangeOwnPassword(login: string, expiredPassword?: boolean): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ChangeOwnPasswordModalComponent,
      componentProps: {
        login: login,
        expiredPassword: expiredPassword,
      },
    });
    return modal.present();
  }
}

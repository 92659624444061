import { Pipe, PipeTransform } from "@angular/core";
import { ITranslation } from "../models/translation";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";

@Pipe({ name: "getRewardDefName" })
export class GetRewardDefNamePipe implements PipeTransform {
  constructor(protected configService: ConfigurationService) {}

  public transform(rewardDisplayName: ITranslation): string {
    return rewardDisplayName ? rewardDisplayName?.[this.configService.getCurrentLanguage()] : "-";
  }
}

@Pipe({ name: "getRewardDefDescription" })
export class GetRewardDefDescriptionPipe implements PipeTransform {
  constructor(protected configService: ConfigurationService) {}

  public transform(rewardDescription: ITranslation): string {
    return rewardDescription?.[this.configService.getCurrentLanguage()];
  }
}

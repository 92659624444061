import { Pipe, PipeTransform } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";

@Pipe({ name: "includes" })
export class IncludesPipe implements PipeTransform {
  transform(element: any[] | string, el: any): boolean {
    if (element === null || element === undefined) {
      FileLogger.warn("IncludesPipe", 'element from pipe "includes" is ', element);
      return false;
    }
    return element.includes(el);
  }
}

import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { ChartOptions } from "chart.js";
import * as moment from "moment";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { DataChart } from "../components/observation/observation.component";
import { IConfiguration, IObservationParam } from "../models/configuration";
import { METHOD_DISPLAY_DEVICE_DATA } from "../models/externalRessource";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";
import { ExternalRessourceService } from "../services/globalDataProvider/external-ressource.service";
import { ObservationDefinitionService } from "../services/globalDataProvider/observation-definition.service";
import { ObservationService } from "../services/globalDataProvider/observation.service";
import { GraphService } from "../services/graph.service";
import { InfoAppService } from "../services/info-app.service";
import { ModalObservationService } from "../services/modal-observation.service";
import { PopupService } from "../services/popup.service";
import { IAveragedObservation, StreamObservationService } from "../services/streamObservation.service";
import { ChartHelper, IChartColors } from "./chart-helper";
import { FileLogger } from "./fileLogger";
import { ComponentDisplay, IObsByCategory, IObservation, IObservationDefinition, Observation } from "./observation-helper";
import { Tools } from "./tools-helper";

@Injectable({
  providedIn: "root",
})
export class ObservationDataHelperService {
  public dataLoaded: boolean;
  private startDate: string;
  private endDate: string;
  private range: string;
  private basicChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: this.platform.is("desktop") ? false : true, // avoid chart not resizing correctly on desktop
  };

  private chartOptionsBool: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  constructor(
    private obsDefService: ObservationDefinitionService,
    private obsService: ObservationService,
    private configService: ConfigurationService,
    private modalObservationService: ModalObservationService,
    private streamObsService: StreamObservationService,
    private externalRessourceService: ExternalRessourceService,
    protected popupService: PopupService,
    private platform: Platform,
    private graphService: GraphService
  ) {}

  public getCachedRange(): string {
    return this.range;
  }

  public async getInitialData(
    observationRange: "day" | "week" | "month" | "3months",
    selectedDate: string,
    obsByCategories: IObsByCategory[]
  ): Promise<{ obsByCategories: IObsByCategory[]; allObservations: IObservation[]; config: IConfiguration }> {
    // Initialize obsDefService so it can be used by the peakData in getObservationDefinition()
    await this.obsDefService.getFirstDataAvailable();
    const config = await this.getInitialConfig();
    const observations = await this.getInitialObservations();
    this.range = observationRange;
    return {
      obsByCategories: this.setupObsByCategories(observationRange, selectedDate, config, observations, obsByCategories),
      allObservations: observations,
      config: config,
    };
  }

  /**
   * Get the first config we find
   */
  public async getInitialConfig(): Promise<IConfiguration> {
    const data = await this.configService.getFirstDataAvailable();
    return this.prepareConfig(data);
  }

  /**
   * Get the first observations we find
   */
  public async getInitialObservations(): Promise<IObservation[]> {
    const data = await this.obsService.getFirstDataAvailable();
    return this.obsService.sortObservations(data);
  }

  public watchObservations(): Observable<IObservation[]> {
    return this.obsService.watchData().pipe(map((obs: IObservation[]) => this.obsService.sortObservations(obs)));
  }

  public watchConfig(): Observable<IConfiguration> {
    return this.configService.watchData().pipe(
      // avoid jump on first load
      distinctUntilChanged((x, y) => Tools.isEqual(x, y)),
      map((config) => this.prepareConfig(config))
    );
  }

  /**
   * Check the config for missing start and end dates and fix them. Returns the same config
   * @param config the configuration to check
   * @returns
   */
  private prepareConfig(config: IConfiguration): IConfiguration {
    if (!config) {
      FileLogger.error("ObservationDataHelperService", "prepareConfig - could not get configuration", null, "none");
      return;
    }
    // no start/end date, set them to default
    config.parameters.observationParams.forEach((obsParam) => {
      if (!obsParam.frequency.boundsPeriod.start) obsParam.frequency.boundsPeriod.start = Tools.getToday().format();
      if (!obsParam.frequency.boundsPeriod.end) obsParam.frequency.boundsPeriod.end = Tools.getToday().add(1, "year").format();
      if (moment(obsParam.frequency.boundsPeriod.end).year() === 9999) obsParam.frequency.boundsPeriod.end = moment("2100").format();
    });
    return config;
  }
  public setupObsByCategories(
    observationRange: "day" | "week" | "month" | "3months",
    selectedDate: string,
    configuration: IConfiguration,
    allObservations: IObservation[],
    obsByCategories: IObsByCategory[]
  ): IObsByCategory[] {
    const categories: IObservationParam[] = [];
    const config = this.prepareConfig(configuration);
    const obsParams = config?.parameters?.observationParams;

    obsParams.forEach((p: IObservationParam) => {
      if (this.isObservationAvailable(p)) categories.push(p);
    });

    for (const category of categories) {
      const definition = this.obsDefService.getObservationDefinition(category.type);
      const foundObsByCatIdx = obsByCategories.findIndex((obc) => obc.param.type === category.type);
      if (definition) {
        const observations = this.getObservations(definition, observationRange, selectedDate, allObservations);

        if (foundObsByCatIdx > -1) {
          obsByCategories[foundObsByCatIdx].observations = observations;
        } else {
          obsByCategories.push({
            observations,
            definition,
            param: category,
          });
        }
      } else if (foundObsByCatIdx > -1) {
        obsByCategories.splice(foundObsByCatIdx, 1);
      }
    }
    return obsByCategories;
  }

  private isObservationAvailable(observationParams: IObservationParam): boolean {
    if (!observationParams.limitedCreationByFrequence) return true;
    if (new Date(observationParams.frequency.boundsPeriod.start) <= new Date()) return true;
    return false;
  }

  public getObservations(
    definition: IObservationDefinition,
    range: "day" | "week" | "month" | "3months",
    selectedDate: string,
    allObservations: IObservation[]
  ): IObservation[] {
    if (!definition) return [];

    this.range = range;
    let minRange: moment.Moment;
    switch (range) {
      case "3months":
        minRange = moment().add(-3, "months");
        break;
      case "day":
        minRange = moment(selectedDate);
        break;
      default:
        minRange = moment().add(-1, range);
        break;
    }
    this.startDate = minRange.toISOString();
    this.endDate = range === "day" ? null : moment().toISOString();

    return allObservations.filter((obs) => {
      const obstype = Observation.getObservationType(obs);
      if (range === "day") {
        return obstype === definition.loinc && moment(obs.issued).isSame(minRange, "day");
      } else {
        return obstype === definition.loinc && moment(obs.issued).isSameOrAfter(minRange);
      }
    });
  }

  public async computeGraphData(
    definition: IObservationDefinition,
    observations: IObservation[],
    filterBy = "all",
    MAX_CHART_LAST_ITEMS = 999,
    dexcomClickFunction?: (evt, array: any[]) => void,
    context?: string
  ): Promise<{
    chartData: DataChart[];
    chartLabels: string[];
    chartOptions: ChartOptions;
    chartColors: IChartColors[];
    streamObservations?: IAveragedObservation[];
  }> {
    if (!definition) return;

    if (filterBy.startsWith("online_")) {
      // We need observations from an online device. Those are available only online.
      const externalRessourceRef = filterBy.slice(7);
      const externalRessource = this.externalRessourceService.peekData().find((e) => e.reference === externalRessourceRef);
      if (externalRessource.meta.componentAnswer.find((a) => a.displayMethod === METHOD_DISPLAY_DEVICE_DATA.STREAM)) {
        return await this.streamObsService.computeStreamObservationGraph(
          definition,
          externalRessource,
          this.startDate,
          this.endDate,
          dexcomClickFunction
        );
      } else {
        filterBy = externalRessourceRef;
      }
    }

    const chartLabels = new Array<string>();
    const chartData = new Array<DataChart>();

    let filteredObservations: IObservation[] = [];
    if (filterBy === "all") {
      filteredObservations = observations;
    } else if (filterBy === "manual") {
      filteredObservations = observations.filter((obs) => !obs?.device?.reference);
    } else {
      filteredObservations = observations.filter((obs) => obs?.device?.reference === filterBy);
    }

    for (const observ of filteredObservations) {
      chartLabels.push(moment(observ.issued).format("DD/MM"));
    }
    chartLabels.reverse();

    for (const component of definition.components) {
      if (!component?.display?.includes(ComponentDisplay.NO_PATIENT_GRAPH_DISPLAY)) {
        const dataChart = new DataChart();
        // dataChart.label = this.translateSvc.instant(Observation.getLabelFromCode(component.loinc || this.definition.loinc));
        if (component.shortnameTranslation) {
          dataChart.label = InfoAppService.getTranslation(
            component.shortnameTranslation,
            this.configService.getCurrentLanguage(),
            component.loinc
          );
        } else if (component.nameTranslation) {
          dataChart.label = InfoAppService.getTranslation(
            component.nameTranslation,
            this.configService.getCurrentLanguage(),
            component.loinc
          );
        } else {
          dataChart.label = this.obsDefService.getTranslatedString(component.name, component.loinc);
        }

        for (const observ of filteredObservations) {
          dataChart.data.push(Observation.getValue(observ, component.loinc));
          if (MAX_CHART_LAST_ITEMS !== 0 && dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
            break;
          }
        }
        dataChart.data.reverse();
        chartData.push(dataChart);
      }
    }

    const chartOptions =
      definition.components[0].type === "bool" || definition.components[0].type === "range"
        ? Tools.deepCopy(this.chartOptionsBool)
        : Tools.deepCopy(this.basicChartOptions);

    if (context === "homepage") {
      chartOptions.legend = { display: false };
    }

    const colors = Tools.deepCopy(ChartHelper.chartColors).slice(0, chartData.length);
    chartData.push(...this.graphService.setupAdditionalGraphInfos(chartOptions, colors, definition.graphExtraInfos, chartLabels.length));
    return { chartData, chartLabels, chartOptions, chartColors: colors };
  }

  /**
   * Add a new observation
   * @param definition
   */
  public async onAddObservation(definition: IObservationDefinition, allObservations: IObservation[]): Promise<IObservation[]> {
    if (!definition) {
      return;
    }

    // Hardcoded - should be provided by API in future
    const hideLoinc: string[] = [];
    switch (definition.loinc) {
      case "20564-1": // Oxygen saturation
        hideLoinc.push("8867-4"); // Heart Rate
        break;
      case "55284-4": // Blood pressure systolic and diastolic
        hideLoinc.push("8867-4"); // Heart Rate
        break;
      default:
        break;
    }

    try {
      const observations = await this.modalObservationService.createObservation(definition, allObservations, hideLoinc);
      return observations;
    } catch (err) {
      FileLogger.error("ObservationDataHelperService", "onAddObservation", err);
      this.popupService.showAlert("menu.observation", "error.storage");
      throw err;
    }
  }

  /**
   * Edit an observation
   * an observation can be deleted by modifying its status
   *
   * @param observation
   */
  public async onEditObservation(observation: IObservation, allObservations: IObservation[]): Promise<IObservation> {
    const type = Observation.getObservationType(observation);
    const definition = this.obsDefService.getObservationDefinition(type);
    try {
      const obsResult = await this.modalObservationService.editObservation(definition, observation, allObservations);
      return obsResult;
    } catch (err) {
      FileLogger.error("ObservationDataHelperService", "onEditObservation", err);
      this.popupService.showAlert("menu.observation", "error.storage");
      throw err;
    }
  }

  public async getObsParams(): Promise<IObservationParam[]> {
    const config = this.prepareConfig(await this.configService.getFirstDataAvailable());
    return config.parameters.observationParams;
  }
}

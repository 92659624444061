import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IQuestionnaire, QuestionnaireStatus } from "src/app/models/questionnaire";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class QuizDefinitionService
  extends BasicSyncService<IQuestionnaire, IQuestionnaire[]>
  implements StaticImplements<INeedRefresh, typeof QuizDefinitionService>
{
  public get needRefresh(): { value: boolean } {
    return QuizDefinitionService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(private accountService: AccountService, protected dataService: DataService) {
    super(dataService);
  }

  /**
   * Returns the current state of the service's data
   */
  public peekData(onlyActive = false): IQuestionnaire[] {
    return this.processData(super.peekData(), onlyActive);
  }
  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
  public watchData(onlyActive = false): Observable<IQuestionnaire[]> {
    return this.data$.pipe(
      map((q) => {
        return this.processData(q, onlyActive);
      })
    );
  }
  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IQuestionnaire[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "quizDefinitions_",
      entityStoreKey: "list",
      getUrl: "/quizDefinitions",
      setUrl: "/quizDefinitions",
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async *getDataReader(onlyActive = false): AsyncGenerator<IQuestionnaire[], IQuestionnaire[], IQuestionnaire[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IQuestionnaire[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, onlyActive);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("QuizDefinitionService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(quizzes: IQuestionnaire[], onlyActive: boolean): IQuestionnaire[] {
    if (onlyActive) {
      return quizzes.filter((q) => q.status === QuestionnaireStatus.ACTIVE);
    }
    return quizzes;
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(onlyActive = false): Promise<IQuestionnaire[]> {
    const dataReader = this.getDataReader(onlyActive);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(onlyActive = false): Promise<IQuestionnaire[]> {
    const dataReader = this.getDataReader(onlyActive);
    const iterator = await dataReader.next();
    return iterator.value;
  }
}

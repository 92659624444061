import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BasePage } from "src/app/baseClasses/base-page";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-html-alert-modal",
  templateUrl: "./html-alert-modal.component.html",
  styleUrls: ["./html-alert-modal.component.scss"],
})
export class HtmlAlertModalComponent extends BasePage {
  public title: string;
  public content: string;

  constructor(
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected navParams: NavParams,
    protected modalCtrl: ModalController
  ) {
    super(translateSvc, configService, infoService, popupService);
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.title = this.navParams.get("title");
    this.content = this.navParams.get("content");
  }

  public onDismiss(): void {
    this.modalCtrl.dismiss();
  }
}

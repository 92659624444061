import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IDrugInfo, IDrugsInfo } from "../models/drugsInfo";
import { ApiService } from "./api.service";
import { FileLogger } from "../helpers/fileLogger";

@Injectable({
  providedIn: "root",
})
export class SamService {
  constructor(private apiService: ApiService) {}

  public getDrugs(keyword: string, lang: string): Observable<IDrugsInfo> {
    return this.apiService.get("/drugsInfo", undefined, { name: keyword, lang: lang }).pipe(
      map((res) => {
        if (res && res.success) {
          return res.data as IDrugsInfo;
        }
        return null;
      }),
      catchError((err) => {
        FileLogger.error("SamService", "getDrugs", err);
        return of(null);
      })
    );
  }
  public getDrug(keyword: string, lang: string, source?: string): Observable<IDrugInfo> {
    return this.apiService.get("/drugInfo", undefined, { name: keyword, lang: lang, source: source }).pipe(
      map((res) => {
        if (res && res.success) {
          return res.data as IDrugInfo;
        }
        return null;
      }),
      catchError((err) => {
        FileLogger.error("SamService", "getDrug", err);
        return of(null);
      })
    );
  }

  public getDrugViaCnk(cnk: string, source?: string): Observable<IDrugInfo> {
    return this.apiService.get("/drugInfo", undefined, { cnk, source }).pipe(
      map((res) => {
        if (res && res.success) {
          return res.data as IDrugInfo;
        }
        return null;
      }),
      catchError((err) => {
        FileLogger.error("SamService", "getDrugViaCnk", err);
        return of(null);
      })
    );
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { ArrayHelper } from "../helpers/array-helper";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools-helper";
import { DrugScheduled, EntityDrug, IEntitylink, IStepwise } from "../models/entitylink";
import { INotification, NOTIFICATION_STATUS, NOTIFICATION_SYSTEM_STATUS, NOTIFICATION_TYPE, Notification } from "../models/notification";
import { Timing } from "../models/sharedInterfaces";
import { IMomentTime, ScheduledBefore, TIMING_CODES, TimingData, Timings } from "../models/timingData";
import { AccountService } from "./globalDataProvider/account.service";
import { ConfigurationService } from "./globalDataProvider/configuration.service";
import { DrugService } from "./globalDataProvider/drug.service";
import { NotificationsDrugsIntakeService } from "./globalDataProvider/notifications-drugs-intake.service";
import { MedicalBluetoothPillDispenserDevice } from "./medical-bluetooth/medical-bluetooth-sdk-pilldispenser.service";
import { NotificationsSaveService } from "./notificationsService/notifications-save.service";

@Injectable({
  providedIn: "root",
})
export class ComputeDrugsService {
  constructor(
    private configService: ConfigurationService,
    private notificationsSaveService: NotificationsSaveService,
    private accountService: AccountService,
    private notificationDrugsIntake: NotificationsDrugsIntakeService,
    private drugService: DrugService /* TODO CMATE-6292 : ,
    private ruleService: RulesService,
    private languageService: LanguagesService*/
  ) {}

  public numberDosesNeededToFinishToday(drugData: EntityDrug): number {
    const midnightToday = moment().endOf("day");
    try {
      const schedules = this.configService.getNotifsSchedules();
      const userTimings = this.configService.getUserTimings();
      if (!drugData.stepwiseSchema) {
        let timingInstances: IMomentTime[] = [];
        if (drugData.cycle && drugData.cycle.cycle.length > 0) {
          timingInstances = TimingData.getCycleTimingInstances(
            drugData.cycle,
            drugData.frequency,
            userTimings,
            schedules,
            moment(),
            midnightToday
          );
        } else {
          timingInstances = TimingData.getTimingInstances(drugData.frequency, userTimings, schedules, moment(), midnightToday);
        }
        const now = moment();
        const todayIntakes = timingInstances.filter((t) => moment(t.time).isAfter(now) && moment(t.time).isBefore(midnightToday));
        const todayRemainingMoments = todayIntakes.map((t) => t.moment).filter((m) => !!m);
        const nbDosesForToday = todayRemainingMoments?.length ? EntityDrug.getTotalQuantityForMoments(drugData, todayRemainingMoments) : 0;
        return nbDosesForToday;
      }
      return 0;
    } catch (err) {
      FileLogger.error("ComputeDrugsService", "getNbOfDayOfIntakes", err);
      return 0;
    }
  }

  /**
   *
   * @param drugData the drug we needed the doses for
   * @param startDate start of the period (it will only at the start of the day)
   * @param endDate end of the period (it will only count at the end of the day)
   * @param futureOnly (boolean) if true will only count the doses the patient still need to take
   *                   ex: will not count the morning doses if we are currently in the afternoon
   *                   if false, it will count the doses for the whole day
   * @returns
   */
  public numberDosesNeededForDates(drugData: EntityDrug, startDate: string, endDate: string, futureOnly = false): number {
    const from = futureOnly ? moment() : moment(startDate);
    const to = moment(endDate);
    if (from.isAfter(to)) return 0;
    try {
      const schedules = this.configService.getNotifsSchedules();
      const userTimings = this.configService.getUserTimings();
      if (!drugData.stepwiseSchema) {
        let timingInstances: IMomentTime[] = [];
        if (drugData.cycle && drugData.cycle.cycle.length > 0) {
          timingInstances = TimingData.getCycleTimingInstances(drugData.cycle, drugData.frequency, userTimings, schedules, from, to);
        } else {
          timingInstances = TimingData.getTimingInstances(drugData.frequency, userTimings, schedules, from, to);
        }
        if (futureOnly) {
          const now = moment().format();
          const midnightToday = moment().endOf("day").format();
          const todayIntakes = timingInstances.filter((t) => moment(t.time).isAfter(now) && moment(t.time).isBefore(midnightToday));
          const todayRemainingMoments = todayIntakes.map((t) => t.moment).filter((m) => !!m);
          const nbDosesForToday = todayRemainingMoments?.length
            ? EntityDrug.getTotalQuantityForMoments(drugData, todayRemainingMoments)
            : 0;

          const futureDaysIntakes = timingInstances.filter((t) => moment(t.time).isAfter(midnightToday));
          const futureDays = futureDaysIntakes.map((t) => moment(t.time).format("YYYY-MM-DD")).filter(ArrayHelper.onlyUnique);
          const nbFutureDoses = EntityDrug.numberDosesNeeded(drugData, futureDays.length);

          return nbFutureDoses + nbDosesForToday;
        } else {
          const days = timingInstances.map((t) => moment(t.time).format("YYYY-MM-DD")).filter(ArrayHelper.onlyUnique);
          const nbDoses = EntityDrug.numberDosesNeeded(drugData, days.length);
          return nbDoses;
        }
      }
      return 0;
    } catch (err) {
      FileLogger.error("ComputeDrugsService", "getNbOfDayOfIntakes", err);
      return 0;
    }
  }

  /**
   * Create scheduled periods for drugs
   * @param entityDrugs list of drugs
   * @param from start of the period
   * @param to end of the period
   */
  public computeDrugsPeriod(entityDrugs: IEntitylink[], from: moment.Moment, to: moment.Moment): DrugScheduled[] {
    const periods: DrugScheduled[] = [];
    if (!entityDrugs?.length) return periods;

    entityDrugs.forEach((drug) => {
      const drugData = drug.entityData as EntityDrug;
      // compute scheduling based on period kind
      try {
        const schedules = this.configService.getNotifsSchedules();
        const userTimings = this.configService.getUserTimings();
        if (drugData.stepwiseSchema) {
          periods.push(...this.computeStepwiseDrugPeriod(drug, userTimings, schedules, from, to));
        } else {
          let timingInstances: IMomentTime[] = [];
          if (drugData.cycle && drugData.cycle.cycle.length > 0) {
            timingInstances = TimingData.getCycleTimingInstances(drugData.cycle, drugData.frequency, userTimings, schedules, from, to);
          } else {
            timingInstances = TimingData.getTimingInstances(drugData.frequency, userTimings, schedules, from, to);
          }

          for (const instance of timingInstances) {
            const period: DrugScheduled = {
              drugId: drug._id,
              start: instance.time,
              end: instance.time,
              name: drugData.name,
              prescriptionName: drugData.prescriptionName,
              description: drugData.comment,
              reference: drugData.reference,
              moment: instance.moment,
              quantity: DrugService.getMomentsQuantity(instance.moment, drugData),
              quantityTaken: undefined,
            };
            periods.push(period);
          }
        }
      } catch (err) {
        // error may happens when invalid data in DrugData. Example: "JSON.parse" with empty string
        // there is nothing more to do except processing next drug
        FileLogger.error("ComputeDrugsService", "Unable to computeDrugsPeriod", err);
      }
    });
    // sort period by date
    return periods.sort((a: DrugScheduled, b: DrugScheduled) => {
      if (!a) return 1;
      if (!b) return -1;
      return moment(a.start).isSameOrBefore(moment(b.start)) ? -1 : 1;
    });
  }

  private computeStepwiseDrugPeriod(
    entitylink: IEntitylink,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment
  ): DrugScheduled[] {
    const periods: DrugScheduled[] = [];
    const drugData = entitylink.entityData as EntityDrug;
    const stepwises: IStepwise[] = drugData.stepwiseSchema.stepwises;
    stepwises.forEach((stepwise) => {
      const startDay = moment(drugData.frequency.boundsPeriod.start).add(stepwise.startDay, "d");
      stepwise.days.forEach((d, i) => {
        const frequency: Timing = {
          boundsPeriod: {
            start: moment(Tools.deepCopy(startDay)).add(i, "d").format(),
          },
          period: 1,
          periodUnits: "d",
        };
        if (
          !drugData.frequency.boundsPeriod.end ||
          moment(frequency.boundsPeriod.start).isSameOrBefore(moment(drugData.frequency.boundsPeriod.end))
        ) {
          frequency.boundsPeriod.end = frequency.boundsPeriod.start;
          d.moment.forEach((m, iMoment) => {
            frequency.timingCode = TIMING_CODES.find((t) => t.display === m.timingCode).value;
            const instance = TimingData.getTimingInstances(frequency, userTimings, schedules, from, to)[0]; // there will always only be one instance in the stepwise case
            let name = drugData.name + " :";
            let pName = drugData.prescriptionName ? drugData.prescriptionName + " :" : null;
            m.drugs.forEach((drug) => {
              name += " " + (drug.quantity ? drug.quantity + " " : "") + drug.name + ",";
              if (pName) {
                pName += " " + (drug.quantity ? drug.quantity + " " : "") + (drug.prescriptionName ?? drug.name) + ",";
              }
            });
            periods.push({
              drugId: entitylink._id + iMoment,
              start: instance.time,
              end: instance.time,
              name: name.substring(0, name.length - 1),
              prescriptionName: pName,
              description: drugData.comment,
              reference: drugData.reference,
              moment: instance.moment,
              quantity: undefined, // TODO CMATE-6361
              quantityTaken: undefined,
            });
          });
        }
      });
    });
    return periods;
  }

  /**
   * Try to find matching notification for a drug
   * If found, update drug's status
   * @param drug
   */
  public updateWithNotification(drug: DrugScheduled, drugNotifications: INotification[]): void {
    // do nothing with drug for the future
    if (moment(drug.start).isAfter(moment())) {
      drug.status = NOTIFICATION_STATUS.MYSTERY; // Do not use this enum value, we can't remember why the -1 was used here
      return;
    } else {
      drug.status = NOTIFICATION_STATUS.NONE;
    }

    if (!drugNotifications || drugNotifications === undefined || drugNotifications.length === 0) {
      return;
    }
    for (const notif of drugNotifications) {
      if (moment(drug.start).isSame(moment(notif.time)) && drug.drugId === notif.appId) {
        drug.status = notif.status;
        drug.notificationComment = !notif.comment ? "" : notif.comment;
        drug.device = notif.device;
        drug.author = notif.author;
        drug.moment = notif.moment;
        drug.quantity = notif.quantity;
        drug.quantityTaken = notif.quantityTaken;
        break;
      }
    }
  }

  public async addTakingDrugScheduled(drug: DrugScheduled, status: NOTIFICATION_STATUS, reason?: string): Promise<void> {
    // create a new notification to store info
    let caremateId = this.accountService.cachedCaremateId;
    if (!caremateId) {
      const account = await this.accountService.getFirstDataAvailable();
      caremateId = account.caremateIdentifier;
    }
    const notification = new Notification(caremateId, NOTIFICATION_TYPE.DRUG, drug.start, drug.end, drug.drugId);

    notification.systemStatus = NOTIFICATION_SYSTEM_STATUS.TRIGGERED;
    notification.time = drug.start;
    notification.moment = drug.moment;
    notification.quantity = drug.quantity;
    notification.quantityTaken = drug.quantityTaken;

    // Due to the overlapping of drug intakes and notifications,
    // it is necessary to use notificationsSaveService (it uses notificationsDrugsIntakeService)
    await this.notificationsSaveService.updateNotificationStatus(notification, status, reason);
    if (notification.quantityTaken && !isNaN(Number(notification.quantityTaken))) {
      // Reduce stock:
      await this.drugService.reduceDrugStocks(drug.drugId, Number(notification.quantityTaken));
    }

    /* TODO CMATE-6292 : Decide what you want to do with it
    const rules = this.ruleService.peekData()?.length > 0 ? this.ruleService.peekData() : await this.ruleService.getFirstDataAvailable();
    const entityDrug = (await this.drugService.getOne(drug.drugId))?.entityData;
    const ruleHelperDrugService = new RuleHelperDrugService(
      this.accountService.peekData(),
      rules,
      entityDrug,
      await this.languageService.listOfKeys(),
      this
    );
    if (ruleHelperDrugService.isLinkedToSomeRules) {
      const allDrugs = await this.drugService.getFirstDataAvailable();
      const allNotifications = this.notificationDrugsIntake.peekData();
      const rulesAlert = await ruleHelperDrugService.getRulesAlert(allNotifications, allDrugs ?? []);
      console.log(
        "!!!!!!!!!!!!!",
        rulesAlert?.map((rule) => rule?.rule?.results[0]?.value?.fr)
      );
    }
      */
  }

  /**
   * If intervalInMinutesConsideredSameTime !== 0, we look to see if the drug was scheduled to be taken such that
   * time € ["scheduled time" - intervalInMinutesConsideredSameTime, "scheduled time" + intervalInMinutesConsideredSameTime] ;
   * If yes, time is replaced by "scheduled time", otherwise time is kept.
   *
   * @param entityDrug
   * @param time
   * @param status
   * @param intervalInMinutesConsideredSameTime
   * @param reason
   */
  public async addTakingEntityDrugWithDevice(
    entityDrug: IEntitylink,
    device: MedicalBluetoothPillDispenserDevice,
    deviceUsageId: string,
    time: string,
    status: NOTIFICATION_STATUS,
    intervalInMinutesConsideredSameTime: number,
    reason?: string
  ): Promise<void> {
    const allNotifications = (
      this.notificationDrugsIntake.peekData()?.length > 0
        ? this.notificationDrugsIntake.peekData()
        : await this.notificationDrugsIntake.getFirstDataAvailable()
    ).filter((n) => n.appId === entityDrug._id);

    if (allNotifications.find((n) => n.deviceUsageIds?.find((id) => id.usageId === deviceUsageId && id.address === device.address))) {
      FileLogger.log("ComputeDrugsService", `addTakingEntityDrugWithDevice - usage (${deviceUsageId}) already saved`, "", "none");
      return;
    }

    const periods = this.computeDrugsPeriod([entityDrug], moment(time).subtract(1, "day"), moment(time).add(1, "day"));
    // try to find a scheduled dose of medication
    const period = periods.find((p) =>
      moment(time).isBetween(
        moment(p.start).subtract(intervalInMinutesConsideredSameTime, "minutes"),
        moment(p.start).add(intervalInMinutesConsideredSameTime, "minutes"),
        undefined,
        "[]"
      )
    );

    let notification: INotification;

    if (period) {
      // if found, take the time of this scheduled intake
      const notificationAlreadyExists = allNotifications.find((n) => n.appId === entityDrug._id && n.time === period.start);
      if (notificationAlreadyExists) {
        let quantityTakenNumber: number;
        if (
          notificationAlreadyExists.status === NOTIFICATION_STATUS.NONE &&
          (Tools.isNotDefined(notificationAlreadyExists.quantityTaken) || notificationAlreadyExists.quantityTaken === "0")
        ) {
          quantityTakenNumber = 0;
        } else if (
          Tools.isDefined(notificationAlreadyExists.quantityTaken) &&
          Number.isFinite(Number(notificationAlreadyExists.quantityTaken)) &&
          notificationAlreadyExists.status === NOTIFICATION_STATUS.ACCEPTED
        ) {
          quantityTakenNumber = Number(notificationAlreadyExists.quantityTaken);
        }

        let quantityNumber: number;
        if (Number.isFinite(Number(notificationAlreadyExists.quantity ?? "1"))) {
          quantityNumber = Number(notificationAlreadyExists.quantity ?? "1");
        }

        if (Tools.isDefined(quantityTakenNumber) && Tools.isDefined(quantityNumber)) {
          const surplus = quantityTakenNumber + 1 - quantityNumber;
          // if usage = quantity, the maximum dose has already been reached, the notification must not be changed
          if (quantityTakenNumber !== quantityNumber) {
            notificationAlreadyExists.unscheduledIntake = false;
            notificationAlreadyExists.quantityTaken = (quantityTakenNumber + 1 - (surplus > 0 ? surplus : 0)).toString();
            notificationAlreadyExists.device = {
              reference: device.title,
              type: device.services[0],
              address: device.address,
            };
            if (!notificationAlreadyExists.deviceUsageIds) {
              notificationAlreadyExists.deviceUsageIds = [];
            }
            notificationAlreadyExists.deviceUsageIds.push({
              usageId: deviceUsageId,
              address: device.address,
            });
            await this.editDrugIntake(notificationAlreadyExists, status, reason);
          }
          if (surplus > 0) {
            notification = await this.createUnscheduledIntakeWithDevice(
              entityDrug,
              allNotifications,
              device,
              surplus.toString(),
              time,
              deviceUsageId
            );
          }
          // if we are here, then this usage is never associated to this drug (deviceDate field)
        } else {
          // the quantity fields cannot be used to determine the dose (or has been rejected), so a new drugIntake is created for this device
          notification = await this.createScheduledIntakeWithDevice(
            entityDrug,
            allNotifications,
            device,
            "1",
            notificationAlreadyExists.quantity,
            period.start,
            deviceUsageId
          );
        }
      } else {
        // first indicated dose
        notification = await this.createScheduledIntakeWithDevice(
          entityDrug,
          allNotifications,
          device,
          "1",
          period.quantity,
          period.start,
          deviceUsageId
        );
      }
    } else {
      notification = await this.createUnscheduledIntakeWithDevice(entityDrug, allNotifications, device, "1", time, deviceUsageId);
    }
    // Due to the overlapping of drug intakes and notifications,
    // it is necessary to use notificationsSaveService (it uses notificationsDrugsIntakeService)
    if (notification) {
      // if notification exists, then this usage is never associated to this drug (deviceDate field)
      await this.notificationsSaveService.updateNotificationStatus(notification, status, reason);
    }
    await this.drugService.reduceDrugStocks(entityDrug._id, 1);
  }

  /**
   *
   * @param entityDrug
   * @param allNotifications
   * @param device
   * @param quantityTaken
   * @param quantity
   * @param time
   * @param deviceTime
   * @returns undefined if there already exists a notification associated with this drug whose deviceDate contains deviceTime
   */
  private async createScheduledIntakeWithDevice(
    entityDrug: IEntitylink,
    allNotifications: INotification[],
    device: MedicalBluetoothPillDispenserDevice,
    quantityTaken: string,
    quantity: string | null,
    time: string,
    deviceUsageId: string
  ): Promise<INotification> {
    const drugData = entityDrug.entityData as EntityDrug;
    let caremateId = this.accountService.cachedCaremateId;
    if (!caremateId) {
      const account = await this.accountService.getFirstDataAvailable();
      caremateId = account.caremateIdentifier;
    }
    const notification = new Notification(
      caremateId,
      NOTIFICATION_TYPE.DRUG,
      drugData.frequency?.boundsPeriod?.start,
      drugData.frequency?.boundsPeriod?.end,
      entityDrug._id
    );

    notification.systemStatus = NOTIFICATION_SYSTEM_STATUS.TRIGGERED;
    notification.device = {
      reference: device.title,
      type: device.services[0],
      address: device.address,
    };
    notification.deviceUsageIds = [
      {
        usageId: deviceUsageId,
        address: device.address,
      },
    ];
    notification.unscheduledIntake = false;
    notification.quantityTaken = quantityTaken;
    notification.quantity = quantity;
    let newTime = time;
    // for technical reasons, the time must be different
    while (allNotifications.find((n) => n.appId === entityDrug._id && n.time === newTime)) {
      newTime = moment(newTime).add(1, "second").format();
    }
    notification.time = newTime;
    return notification;
  }

  /**
   *
   * @param entityDrug
   * @param allNotifications
   * @param device
   * @param quantityTaken
   * @param time
   * @returns undefined if there already exists a notification associated with this drug whose deviceDate contains time
   */
  private async createUnscheduledIntakeWithDevice(
    entityDrug: IEntitylink,
    allNotifications: INotification[],
    device: MedicalBluetoothPillDispenserDevice,
    quantityTaken: string,
    time: string,
    deviceUsageId: string
  ): Promise<INotification> {
    const drugData = entityDrug.entityData as EntityDrug;
    let caremateId = this.accountService.cachedCaremateId;
    if (!caremateId) {
      const account = await this.accountService.getFirstDataAvailable();
      caremateId = account.caremateIdentifier;
    }
    const notification = new Notification(
      caremateId,
      NOTIFICATION_TYPE.DRUG,
      drugData.frequency?.boundsPeriod?.start,
      drugData.frequency?.boundsPeriod?.end,
      entityDrug._id
    );

    notification.systemStatus = NOTIFICATION_SYSTEM_STATUS.TRIGGERED;
    notification.device = {
      reference: device.title,
      type: device.services[0],
      address: device.address,
    };
    notification.deviceUsageIds = [
      {
        usageId: deviceUsageId,
        address: device.address,
      },
    ];
    notification.unscheduledIntake = true;
    notification.quantityTaken = quantityTaken;
    notification.quantity = null;
    let newTime = time;
    // for technical reasons, the time must be different
    while (allNotifications.find((n) => n.appId === entityDrug._id && n.time === newTime)) {
      newTime = moment(newTime).add(1, "second").format();
    }
    notification.time = newTime;
    return notification;
  }

  public async editDrugIntake(
    notif: INotification,
    status: NOTIFICATION_STATUS,
    reason?: string,
    previousQuantityTaken?: string
  ): Promise<void> {
    if (notif) {
      const newQuantityTaken = notif.quantityTaken;
      if (previousQuantityTaken || newQuantityTaken) {
        if (previousQuantityTaken && !isNaN(Number(previousQuantityTaken))) {
          // Put back the stock previously decreased:
          await this.drugService.increaseDrugStocks(notif.appId, Number(previousQuantityTaken));
        }
        if (newQuantityTaken && !isNaN(Number(newQuantityTaken))) {
          // Reduce stock:
          await this.drugService.reduceDrugStocks(notif.appId, Number(newQuantityTaken));
        }
      }
      // Due to the overlapping of drug intakes and notifications,
      // it is necessary to use notificationsSaveService (it uses notificationsDrugsIntakeService)
      await this.notificationsSaveService.updateNotificationStatus(notif, status, reason, true);
    }
  }

  public async addTakingDrugUnscheduled(
    drug: IEntitylink,
    status: NOTIFICATION_STATUS,
    reason?: string,
    quantityTaken?: string,
    time?: string
  ): Promise<void> {
    let caremateId = this.accountService.cachedCaremateId;
    if (!caremateId) {
      const account = await this.accountService.getFirstDataAvailable();
      caremateId = account.caremateIdentifier;
    }
    const drugData = drug.entityData as EntityDrug;
    const notification = new Notification(
      caremateId,
      NOTIFICATION_TYPE.DRUG,
      drugData.frequency?.boundsPeriod?.start,
      drugData.frequency?.boundsPeriod?.end,
      drug._id
    );
    notification.systemStatus = NOTIFICATION_SYSTEM_STATUS.TRIGGERED;
    notification.unscheduledIntake = true;
    notification.time = time ?? moment().format();
    notification.quantity = drugData.quantity;
    notification.quantityTaken = quantityTaken;
    if (notification.quantityTaken && !isNaN(Number(notification.quantityTaken))) {
      // Reduce stock:
      await this.drugService.reduceDrugStocks(drug._id, Number(notification.quantityTaken));
    }

    // Due to the overlapping of drug intakes and notifications,
    // it is necessary to use notificationsSaveService (it uses notificationsDrugsIntakeService)
    await this.notificationsSaveService.updateNotificationStatus(notification, status, reason);
  }
}

import { Identifier } from "./sharedInterfaces";

export interface ICountry {
  countryCode: string; // BE, FR, ...
  countryLangs: string[]; // [fr, nl, de]
  phonePrefix: string; // +32...
  dbDrugs: IDbDrugsInfo[];
  connectionsOptions: CONNECTION_OPTION[];
  healthNetworks: string[];
  uniqueIdentifiersPatients?: Identifier[];
  uniqueIdentifiersPractitioners?: Identifier[];
}

// And we modify the IDbDrugsInfo, because it does not need to contain the country anymore:
export interface IDbDrugsInfo {
  dbName: string;
  modelName: string;
}

enum CONNECTION_OPTION {
  PASSWORD_LOGIN = 1,
  EHEALTH = 2,
  PSC = 3,
}

export enum HEALTH_NETWORK {
  ABRUMET = "abrumet",
  RSW = "RSW",
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { FeatureService } from "./services/featureService";
import { FileLogger } from "./helpers/fileLogger";

@Injectable({
  providedIn: "root",
})
export class FeatureGuard implements CanActivate {
  constructor(private router: Router, private featureService: FeatureService) {}

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
    // Promise<boolean | UrlTree>
    if (this.featureService.canActivate(next.data.feature)) {
      return true;
    }
    FileLogger.warn("FeatureGuard", "no access to this feature");
    return this.goToHomePage();
  }

  private goToHomePage() {
    return this.router.parseUrl("/home");
  }
}

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "../appConstants";
import { PopupService } from "./popup.service";
import { LocalStorageService } from "./storage/local-storage.service";
import { FileLogger } from "../helpers/fileLogger";

@Injectable({
  providedIn: "root",
})
export class HelpService {
  constructor(
    private localStorageService: LocalStorageService,
    private popupService: PopupService,
    private translateService: TranslateService
  ) {}

  /**
   * Show Help note
   */
  public showHelp(pageName: string, helpTextKey: string): void {
    this.setHelpSeenOn(pageName);
    const msg = this.translateService.instant(helpTextKey);
    const m = msg.replace(new RegExp("<br>", "g"), "");
    this.popupService.showToastWithCloseBtn(m, "bottom");
  }

  /**
   * Set page name in the list of page where help has been seen
   */
  public async setHelpSeenOn(pageName: string): Promise<unknown> {
    let pageList = null;
    try {
      pageList = await this.getHelpSeenPages();
    } catch (err) {
      // nothing to do
    }
    if (!pageList) {
      pageList = "";
    }
    pageList += pageName + ";"; // add page name
    return this.setParam(AppConstants.PRM_HELP_SEEN, pageList);
  }

  /**
   * Reset all help buttons
   */
  public resetHelpSeen(): Promise<unknown> {
    return this.setParam(AppConstants.PRM_HELP_SEEN, "");
  }

  /**
   * Check if help has been seen on particular page
   */
  public async isHelpPageSeenOn(pageName: string): Promise<boolean> {
    let pageList = null;
    try {
      pageList = await this.getHelpSeenPages();
    } catch (err) {
      FileLogger.log("HelpService", "Error in isHelpPageSeenOn", err);
    }
    if (!pageList) {
      return false;
    }
    return pageList.indexOf(pageName) >= 0;
  }

  /**
   * Return list of pages where help has been seen
   */
  public getHelpSeenPages(): Promise<string> {
    return this.getParam(AppConstants.PRM_HELP_SEEN);
  }

  private getParam(name: string) {
    return this.localStorageService.getData(name, false);
  }

  private setParam(name: string, value: string) {
    return this.localStorageService.setData(name, value, false);
  }
}

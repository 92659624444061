import { Injectable } from "@angular/core";
import { MedicalBluetooth, MedicalBluetoothDevice, MedicalBluetoothService } from "./medical-bluetooth.service";
import { IExternalRessource } from "src/app/models/externalRessource";
import { MedicalBluetoothFindair } from "./medical-hardware/medical-bluetooth-findair";
import { PopupService } from "../popup.service";
import { filter, first, map, tap, timeout } from "rxjs/operators";
import * as moment from "moment";
import { FileLogger } from "src/app/helpers/fileLogger";
import { TimeoutError } from "rxjs";
import { ComputeDrugsService } from "../compute-drugs.service";
import { DrugService } from "../globalDataProvider/drug.service";
import { MedicalBluetoothSDKDataPillDispenser, Device } from "./medical-bluetooth-sdk-data-pilldispenser";
import { AccountService } from "../globalDataProvider/account.service";
import { AlertInput } from "@ionic/angular";
import { NotificationsDrugsIntakeService } from "../globalDataProvider/notifications-drugs-intake.service";
import { NOTIFICATION_STATUS } from "src/app/models/notification";
import { NotificationsSaveService } from "../notificationsService/notifications-save.service";
import { Tools } from "src/app/helpers/tools-helper";

export interface MedicalBluetoothPillDispenserDevice extends MedicalBluetoothDevice {
  drugId: string; // TODO CMATE-5528 this is _id of the drug, no other id available for the moment... (backwards compatibility for existing products)
  title: string;
  start: string;
  lastSynchro?: string;
  account: string;
}

export const medicalBluetoothPillDispenserSDKMapping = {
  [MedicalBluetoothService.FINDAIR]: MedicalBluetoothFindair,
};

@Injectable({
  providedIn: "root",
})
export class MedicalBluetoothSDKPillDispenserService {
  private SOURCE = "MedicalBluetoothSDKPillDispenserService";
  private static localStorageKey = "bondedDevicesSDKPillDispenser";
  constructor(
    public medicalBluetoothService: MedicalBluetooth,
    protected popupService: PopupService,
    protected computeDrugsService: ComputeDrugsService,
    protected drugService: DrugService,
    private accountService: AccountService,
    private notificationsDrugsIntake: NotificationsDrugsIntakeService,
    private notificationsSaveService: NotificationsSaveService
  ) {}

  public static get bondedDevices(): MedicalBluetoothPillDispenserDevice[] {
    const local = localStorage.getItem(MedicalBluetoothSDKPillDispenserService.localStorageKey);
    return local ? JSON.parse(local) : [];
  }

  public static addBondedDevices(device: MedicalBluetoothPillDispenserDevice): void {
    const bondedDevices = MedicalBluetoothSDKPillDispenserService.bondedDevices;
    const exists = bondedDevices.find((value) => {
      return value.address === device.address;
    });
    if (!exists) {
      bondedDevices.push(device);
    }
    localStorage.setItem(MedicalBluetoothSDKPillDispenserService.localStorageKey, JSON.stringify(bondedDevices));
  }

  public static updateBondedDevices(device: MedicalBluetoothPillDispenserDevice): void {
    const bondedDevices = MedicalBluetoothSDKPillDispenserService.bondedDevices;
    const index = bondedDevices.findIndex((value) => {
      return value.address === device.address;
    });
    if (index >= 0) {
      bondedDevices.splice(index, 1, device);
    }
    localStorage.setItem(MedicalBluetoothSDKPillDispenserService.localStorageKey, JSON.stringify(bondedDevices));
  }

  public removeByDeviceName(deviceName: string): void {
    const device = MedicalBluetoothSDKPillDispenserService.bondedDevices.find((device) => device.name === deviceName);
    if (device) {
      this.removeBondedDevices(device);
    }
  }

  public removeByDrugId(drugId: string): void {
    const device = MedicalBluetoothSDKPillDispenserService.bondedDevices.find((device) => device.drugId === drugId);
    if (device) {
      this.removeBondedDevices(device);
    }
  }

  public removeBondedDevices(device: MedicalBluetoothPillDispenserDevice): void {
    let bondedDevices = MedicalBluetoothSDKPillDispenserService.bondedDevices;
    bondedDevices = bondedDevices.filter((d) => {
      return d.address !== device.address;
    });
    localStorage.setItem(MedicalBluetoothSDKPillDispenserService.localStorageKey, JSON.stringify(bondedDevices));
    const serviceHandler = medicalBluetoothPillDispenserSDKMapping[device.services[0]];
    const service: MedicalBluetoothSDKDataPillDispenser = serviceHandler.Instance(
      this.medicalBluetoothService,
      this.popupService,
      this.computeDrugsService,
      this.drugService,
      this.accountService
    );
    service.makeARequestForDisconnectDevice({ address: device.address });
  }

  /**
   *
   * @param externalRessource
   * @returns the MedicalBluetoothDevice already bounded associated to this externalRessource. If no exist
   * (not already bounded for example), return undefined
   */
  public static getBondedDevice(externalRessource: IExternalRessource): MedicalBluetoothPillDispenserDevice {
    return this.findBondedDevice(externalRessource, MedicalBluetoothSDKPillDispenserService.bondedDevices);
  }

  public static findBondedDevice(
    externalRessource: IExternalRessource,
    bondedDevices: MedicalBluetoothPillDispenserDevice[]
  ): MedicalBluetoothPillDispenserDevice {
    const bleServiceCode = externalRessource.meta?.medicalBluetoothService;
    if (!bleServiceCode) {
      return undefined;
    }
    return bondedDevices.find((device) => {
      return (
        device.services?.includes(bleServiceCode) && externalRessource.reference && device.name.indexOf(externalRessource.reference) > -1
      );
    });
  }

  private async selectAndBondedDevice(
    devices: Device[],
    externalRessource: IExternalRessource,
    drugId: string,
    service: MedicalBluetoothSDKDataPillDispenser
  ): Promise<void> {
    let deviceAddress: string;
    let caremateId = this.accountService.cachedCaremateId;
    let pilldispenserDevice: MedicalBluetoothPillDispenserDevice;
    if (!caremateId) {
      const account = await this.accountService.getFirstDataAvailable();
      caremateId = account.caremateIdentifier;
    }
    try {
      deviceAddress = await this.popupService.showSomeInputs(
        "myBleDevices.chooseDevice",
        devices.map((device, i) => {
          return {
            type: "radio",
            label: device.address,
            value: device.address,
            checked: i === 0 ? true : false, // check the first one
          } as AlertInput;
        })
      );

      // third : get the devices
      const device = devices.find((device) => device.address === deviceAddress);
      if (device) {
        pilldispenserDevice = {
          name: externalRessource.reference,
          address: device.address,
          services: [externalRessource.meta.medicalBluetoothService],
          bonded: true,
          start: moment().format(),
          drugId: drugId,
          title: externalRessource.title,
          account: caremateId,
        };
      }

      if (pilldispenserDevice) {
        MedicalBluetoothSDKPillDispenserService.addBondedDevices(pilldispenserDevice);
        if (externalRessource.meta.searchPreviousData && device.state === 6) {
          await this.managePreviousData(service, pilldispenserDevice, externalRessource);
        }
      } else {
        throw new MedicalBluetoothSDKError("", MedicalBluetoothSDKErrorType.FAILED_watchDevices);
      }
    } catch (error) {
      if (error.type === MedicalBluetoothSDKErrorType.FAILED_watchDevices) {
        throw new MedicalBluetoothSDKError("", MedicalBluetoothSDKErrorType.FAILED_watchDevices);
      }
      // cancel
      throw new MedicalBluetoothSDKError("", MedicalBluetoothSDKErrorType.CANCEL_SELECTION);
    }
  }

  public async bond(externalRessource: IExternalRessource, drugId: string): Promise<void> {
    const serviceHandler = medicalBluetoothPillDispenserSDKMapping[externalRessource.meta.medicalBluetoothService];
    const service: MedicalBluetoothSDKDataPillDispenser = serviceHandler.Instance(
      this.medicalBluetoothService,
      this.popupService,
      this.computeDrugsService,
      this.drugService,
      this.accountService
    );
    const devices = await this.tryBond(externalRessource, service);
    await this.selectAndBondedDevice(devices, externalRessource, drugId, service);
  }

  private tryBond(externalRessource: IExternalRessource, service: MedicalBluetoothSDKDataPillDispenser): Promise<Device[]> {
    return new Promise<Device[]>((resolve, reject) => {
      // you need to reset the list of devices, to retrieve it via the cordova plugin (and not have a cache)
      service.watchDevices().next([]);
      service.onInit().then(
        () => {
          // first : subscribe asynchrone (without await)
          service
            .watchDevices()
            .pipe(
              tap((devices) => {
                FileLogger.log("tryBond - devices findAir", JSON.stringify(devices), "", "none");
              }),
              map((devices) =>
                devices.filter(
                  (device) =>
                    Number.isFinite(Number(externalRessource.reference)) &&
                    [6, 3].includes(device.state) && // 6 = Synchronized, 3 = offline
                    device.type === Number(externalRessource.reference)
                )
              ),
              filter((devices) => devices.length > 0),
              // throttleTime(2000, undefined, { leading: false, trailing: true }),
              timeout(300000), // wait max 5 minutes
              first()
            )
            .toPromise()
            .then(
              (devices) => {
                // third : get devices
                service
                  .stopScan()
                  .then(
                    () => {
                      FileLogger.log("STOP SCAN", "SUCCESS", "", "none");
                    },
                    (err) => {
                      FileLogger.error("STOP SCAN", "FAILED", JSON.stringify(err), "none");
                    }
                  )
                  .finally(() => resolve(devices));
              },
              (err) => {
                FileLogger.error(this.SOURCE, err);
                service
                  .stopScan()
                  .then(
                    () => {
                      FileLogger.log("STOP SCAN - error", "SUCCESS", "", "none");
                    },
                    (err) => {
                      FileLogger.error("STOP SCAN - error", "FAILED", JSON.stringify(err), "none");
                    }
                  )
                  .finally(() => {
                    if (err instanceof TimeoutError) {
                      // after 5 minutes without state synchronized (6) or offline (3), we show to the user all devices with the correct type or 0
                      const devicesFound = service.peekDevices().filter((d) => [0, Number(externalRessource.reference)].includes(d.type));
                      if (devicesFound?.length) {
                        resolve(devicesFound);
                      } else {
                        reject(new MedicalBluetoothSDKError("", MedicalBluetoothSDKErrorType.TIMEOUT_ERROR));
                      }
                    }
                    reject(new MedicalBluetoothSDKError("", MedicalBluetoothSDKErrorType.FAILED_watchDevices));
                  });
              }
            );

          // second : make a request
          service.makeARequestForOnScanAndConnectWithNearbyDevice(true);
        },
        (err) => {
          FileLogger.error(this.SOURCE, err);
          service
            .stopScan()
            .then(
              () => {
                FileLogger.log("STOP SCAN - init error", "SUCCESS", "", "none");
              },
              (err) => {
                FileLogger.error("STOP SCAN - init error", "FAILED", JSON.stringify(err), "none");
              }
            )
            .finally(() => reject(new MedicalBluetoothSDKError("", MedicalBluetoothSDKErrorType.FAILED_INIT)));
        }
      );
    });
  }

  private async managePreviousData(
    service: MedicalBluetoothSDKDataPillDispenser,
    device: MedicalBluetoothPillDispenserDevice,
    externalRessource: IExternalRessource
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const $usages = service.getUsagesSubject().asObservable();
      const usagesSub = $usages
        .pipe(
          filter((u) => u.origin === "history"),
          map((u) => u.usages),
          timeout(60000)
        )
        .subscribe(
          async (usages) => {
            if (usages.length === 0) {
              // no data, try again
              service.makeARequestForGetUsagesHistory();
            }
            const usagesFilteredByAddress = usages?.filter((u) => u.address === device.address);
            const nbMinutesNoCheckingHistory = externalRessource.meta?.previousDataNbMinutesNoChecking ?? 5;
            const nbDaysOfPreviousData = externalRessource.meta?.previousDataNbDaysToCheck ?? 3;
            const hasHistory = !!usagesFilteredByAddress?.filter(
              (u) =>
                moment(u.date).isBefore(moment().subtract(nbMinutesNoCheckingHistory, "minutes")) &&
                moment(u.date).isAfter(moment().startOf("day").subtract(nbDaysOfPreviousData, "days"))
            )?.length;
            if (hasHistory) {
              const needOldData = await this.popupService.showYesNo("myBleDevices.getOldData.title", "myBleDevices.getOldData.text");
              if (needOldData) {
                let previousUsages = usagesFilteredByAddress?.filter((u) =>
                  moment(u.date).isAfter(moment().startOf("day").subtract(nbDaysOfPreviousData, "days"))
                );
                const notifications = await this.notificationsDrugsIntake.getFirstDataAvailable();
                const recentDeviceNotifications = notifications.filter(
                  (n) =>
                    Tools.isDefined(n.deviceUsageIds) &&
                    n.deviceUsageIds.findIndex((d) => d.address === device.address) !== -1 &&
                    moment(n.time).isAfter(moment().startOf("day").subtract(nbDaysOfPreviousData, "days"))
                );
                const notifUsagesIds: string[] = [];
                recentDeviceNotifications?.forEach((n) => {
                  notifUsagesIds.push(...n.deviceUsageIds.filter((d) => d.address === device.address).map((d) => d.usageId));
                });
                if (notifUsagesIds.length) {
                  previousUsages = previousUsages.filter((pu) => notifUsagesIds.includes(pu.counterState.toString()));
                }

                const usagesSelectedString = await this.popupService.showSomeInputs(
                  notifUsagesIds.length ? "myBleDevices.getOldData.chooseIntakes" : "myBleDevices.getOldData.chooseUsages",
                  previousUsages.map((usage) => {
                    return {
                      type: "checkbox",
                      label: moment(usage.date).format("DD/MM/yy HH:mm"),
                      value: usage.date,
                      checked: notifUsagesIds.length ? false : true,
                    } as AlertInput;
                  }),
                  false
                );
                const usagesSelected: string[] = usagesSelectedString ? usagesSelectedString.toString().split(",") : []; // Need the toString() method even if it is already a string otherwise there is an error
                for (const pu of previousUsages.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())) {
                  if (usagesSelected.includes(pu.date.toString())) {
                    // Need the toString() method even if it is already a string otherwise there is an error
                    const existingNotif = recentDeviceNotifications.find(
                      (n) =>
                        n.deviceUsageIds.map((d) => d.usageId).includes(pu.counterState.toString()) &&
                        n.status === NOTIFICATION_STATUS.ACCEPTED
                    );
                    if (existingNotif) {
                      // Delete existing notif
                      const status = existingNotif.unscheduledIntake ? NOTIFICATION_STATUS.DELETED : NOTIFICATION_STATUS.NONE;
                      await this.notificationsSaveService.updateNotificationStatus(existingNotif, status, undefined, true);
                    }
                    await service.addDrugIntake(pu);
                  }
                }
              }
            }
            if (usages.length !== 0) {
              usagesSub.unsubscribe();
              resolve();
            }
          },
          (err) => {
            FileLogger.error(this.SOURCE, err);
            usagesSub.unsubscribe();
            reject(
              new MedicalBluetoothSDKError(
                "manageHistoryDevice",
                err instanceof TimeoutError ? MedicalBluetoothSDKErrorType.TIMEOUT_ERROR : MedicalBluetoothSDKErrorType.FAILED_watchDevices
              )
            );
          }
        );
      service.makeARequestForGetUsagesHistory();
    });
  }
}

export enum MedicalBluetoothSDKErrorType {
  FAILED_INIT,
  FAILED_watchDevices,
  CANCEL_SELECTION,
  TIMEOUT_ERROR,
}

export class MedicalBluetoothSDKError extends Error {
  public constructor(message: string, public readonly type: MedicalBluetoothSDKErrorType) {
    super(message);
  }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MedicalBluetoothStatus {
  public isBleConnectedToIOS$: Subject<boolean> = new Subject();

  public constructor() {}
}

import { IApiResponse } from "../models/iapi-response";
import { FileLogger } from "./fileLogger";

export class ServerError {
  public message: string;
  public code: string;

  constructor(message: string, code: string) {
    this.message = message;
    this.code = code;
  }
}

export enum SERVER_RESPONSE_TYPE {
  SUCCESS,
  AUTHENTIFICATION_FAILED,
  SERVER_UNREACHABLE,
  ACCESS_DENIED,
  OTHER_ERROR,
  INVALID_DATA,
  SERVER_ERROR,
  NOT_FOUND,
  INVALID_REQUEST,
  ALREADY_EXISTS,
  INVALID_PASSWORD,
  TOO_MANY_FAIL_CONNECTION,
  EXPIRED_PASSWORD,
  NEED_PASSWORD_REFRESH,
}

export class ServerResponse {
  public static get AUTHENTICATION_FAILED(): ServerError {
    return new ServerError("AUTHENTICATION FAILED", "0001");
  }
  public static get ACCESS_DENIED(): ServerError {
    return new ServerError("ACCESS DENIED", "0002");
  }
  public static get INVALID_DATA(): ServerError {
    return new ServerError("INVALID DATA", "0003");
  }
  public static get SERVER_ERROR(): ServerError {
    return new ServerError("SERVER ERROR", "0004");
  }
  public static get NOT_FOUND(): ServerError {
    return new ServerError("NOT FOUND", "0005");
  }
  public static get INVALID_REQUEST(): ServerError {
    return new ServerError("INVALID REQUEST", "0006");
  }
  public static get ALREADY_EXISTS(): ServerError {
    return new ServerError("ALREADY EXISTS", "0007");
  }
  public static get SERVER_UNREACHABLE(): ServerError {
    return new ServerError("SERVER UNREACHABLE", "0008");
  }
  public static get INVALID_PASSWORD(): ServerError {
    return new ServerError("INVALID_PASSWORD", "0003");
  }
  public static get TOO_MANY_FAIL_CONNECTION(): ServerError {
    return new ServerError("TOO_MANY_FAIL_CONNECTION", "0002");
  }
  public static get EXPIRED_PASSWORD(): ServerError {
    return new ServerError("EXPIRED_PASSWORD", "0002");
  }
  public static get NEED_PASSWORD_REFRESH(): ServerError {
    return new ServerError("NEED_PASSWORD_REFRESH", "0002");
  }

  public static type(rep: any): SERVER_RESPONSE_TYPE {
    try {
      if (!ServerResponse.isApiResponse(rep)) {
        return SERVER_RESPONSE_TYPE.OTHER_ERROR;
      } else if (rep.success) {
        return SERVER_RESPONSE_TYPE.SUCCESS;
      } else {
        switch (rep.data) {
          case ServerResponse.AUTHENTICATION_FAILED.code:
            return SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED;
          case ServerResponse.ACCESS_DENIED.code:
            switch (rep.message) {
              case ServerResponse.NEED_PASSWORD_REFRESH.message:
                return SERVER_RESPONSE_TYPE.NEED_PASSWORD_REFRESH;
              case ServerResponse.TOO_MANY_FAIL_CONNECTION.message:
                return SERVER_RESPONSE_TYPE.TOO_MANY_FAIL_CONNECTION;
              case ServerResponse.EXPIRED_PASSWORD.message:
                return SERVER_RESPONSE_TYPE.EXPIRED_PASSWORD;
              default:
                return SERVER_RESPONSE_TYPE.ACCESS_DENIED;
            }
          case ServerResponse.INVALID_DATA.code:
            return SERVER_RESPONSE_TYPE.INVALID_DATA;
          case ServerResponse.SERVER_ERROR.code:
            switch (rep.message) {
              case ServerResponse.INVALID_PASSWORD.message:
                return SERVER_RESPONSE_TYPE.INVALID_PASSWORD;
              default:
                return SERVER_RESPONSE_TYPE.SERVER_ERROR;
            }
          case ServerResponse.NOT_FOUND.code:
            return SERVER_RESPONSE_TYPE.NOT_FOUND;
          case ServerResponse.INVALID_REQUEST.code:
            return SERVER_RESPONSE_TYPE.INVALID_REQUEST;
          case ServerResponse.ALREADY_EXISTS.code:
            return SERVER_RESPONSE_TYPE.ALREADY_EXISTS;
          case ServerResponse.SERVER_UNREACHABLE.code:
            return SERVER_RESPONSE_TYPE.SERVER_UNREACHABLE;
          default:
            return SERVER_RESPONSE_TYPE.OTHER_ERROR;
        }
      }
    } catch (err) {
      return SERVER_RESPONSE_TYPE.OTHER_ERROR;
    }
  }

  /**
   * build a ServerResponse
   */
  public static asServerResponse(success: boolean, message: string, data: any, token: string): IApiResponse {
    return {
      success: success,
      message: message,
      data: data,
      refreshToken: token,
    } as IApiResponse;
  }

  /**
   * Build an server error response
   * @param message
   */
  public static asErrorResponse(message: any): IApiResponse {
    return {
      success: false,
      message: JSON.stringify(message),
      data: null,
    } as IApiResponse;
  }

  public static isApiResponse(rep: any): rep is IApiResponse {
    return (
      (rep as IApiResponse).data !== undefined && (rep as IApiResponse).message !== undefined && (rep as IApiResponse).success !== undefined
    );
  }

  /**
   *
   * @param response
   */
  public static isAuthenticationSuccessful(response: any): boolean {
    try {
      if (ServerResponse.isApiResponse(response)) return response.success === true ? true : false;
      return false;
    } catch (err) {
      return false;
    }
  }

  /**
   * Check if response is failed due authentication
   *   check against Error Code in "data"
   */
  public static isAuthenticationError(err: any): boolean {
    try {
      FileLogger.log("ServerResponse", "isAuthenticationError", err);
      if (ServerResponse.isApiResponse(err) && err.data === ServerResponse.AUTHENTICATION_FAILED.code) {
        return true;
      }
      return false;
    } catch (err) {
      FileLogger.error("ServerResponse", "isAuthenticationError", err);
      return false;
    }
  }

  /**
   * Check if response is failed due authentication
   *   check against Error Code in "data"
   */
  public static isAccessDenied(err: any): boolean {
    try {
      FileLogger.log("ServerResponse", "isAccessDenied", err);
      if (ServerResponse.isApiResponse(err) && err.data === ServerResponse.ACCESS_DENIED.code) {
        return true;
      }
      return false;
    } catch (err) {
      FileLogger.error("ServerResponse", "isAccessDenied", err);
      return false;
    }
  }
  /**
   * Check if response is failed due to no response from server
   *   check against Error Code in "data"
   */
  public static isServerUnreachableError(err: any): boolean {
    try {
      if (ServerResponse.isApiResponse(err) && err.data === ServerResponse.SERVER_UNREACHABLE.code) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }

  /**
   * Check if response is failed due authentication
   *   check against Error Code in "data"
   */
  public static isInvalidData(err: any): boolean {
    try {
      if (ServerResponse.isApiResponse(err) && err.data === ServerResponse.INVALID_DATA.code) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }
}

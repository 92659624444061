import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IObservationDefinition } from "src/app/helpers/observation-helper";
import { OBSERVATION_TYPE } from "src/app/models/configuration";
import { InfoAppService } from "../info-app.service";
import { AccountService } from "./account.service";
import { ConfigurationService } from "./configuration.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class ObservationDefinitionService
  extends BasicSyncService<IObservationDefinition, IObservationDefinition[]>
  implements StaticImplements<INeedRefresh, typeof ObservationDefinitionService>
{
  public get needRefresh(): { value: boolean } {
    return ObservationDefinitionService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  private observationLoincConversionMap = Object.freeze({
    "1": "8310-5", // temperature
    "2": "55284-4", // blood pressure
    "3": "8867-4", // heart rate
    "4": "3141-9", // body weight
  });

  constructor(protected dataService: DataService, private configSerice: ConfigurationService, private accountService: AccountService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IObservationDefinition[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "observationsDef_",
      entityStoreKey: "list",
      getUrl: "/observationsDef",
      setUrl: "/observationsDef",
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async *getDataReader(): AsyncGenerator<IObservationDefinition[], IObservationDefinition[], IObservationDefinition[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IObservationDefinition[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("ObservationDefinitionService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  /**
   * Get translation from a specific string array
   * 1st position must contain FR
   * 2nd position must contain EN
   * 3rd position must contain NL
   * @param translatedContent
   */
  public getTranslatedString(translatedContent: string[], txtBackup: string): string {
    try {
      let translation = "";
      switch (this.configSerice.getCurrentLanguage()) {
        case "fr":
          translation = translatedContent[0];
          break;
        case "en":
          translation = translatedContent[1];
          break;
        case "nl":
          translation = translatedContent[2];
          break;
        case "de":
          translation = translatedContent[3];
          break;
        default:
          translation = txtBackup;
      }
      return !translation ? txtBackup : translation;
    } catch (err) {
      FileLogger.error("ObservationDefinitionService", "getTranslatedString", err);
      return txtBackup;
    }
  }

  /**
   * Return translated Observation name
   * @param param
   */
  public getTranslatedName(loinc: OBSERVATION_TYPE | string): string {
    try {
      const oDef = this.getObservationDefinition(loinc);
      // not exists
      if (!oDef) {
        return loinc.toString();
      }

      if (oDef.nameTranslation) {
        return InfoAppService.getTranslation(oDef.nameTranslation, this.configSerice.getCurrentLanguage(), oDef.loinc);
      }

      // keep retro-compatibility for the old backend implementation (string[])
      return this.getTranslatedString(oDef.name, oDef.loinc);
    } catch (err) {
      FileLogger.error("ObservationDefinitionService", "getTranslatedName", err);
      return loinc.toString();
    }
  }

  /**
   * return Observation Definition based on a loinc code
   */
  public getObservationDefinition(loinc: string | number): IObservationDefinition {
    try {
      const loincStr: string = this.observationLoincConversionMap[loinc] || loinc;
      for (const obsdef of this.peekData()) {
        if (obsdef.loinc === loincStr) {
          return obsdef;
        }
      }
      return null;
    } catch (err) {
      FileLogger.error("ObservationDefinitionService", "getObservationDefinition", err);
      return null;
    }
  }
}

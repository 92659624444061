import * as moment from "moment";
import { KeyValue } from "src/app/models/keyValue";
import { IEntity, SCHEDULE_PERIOD, Timing } from "src/app/models/sharedInterfaces";
import { FHIR_ActivityHelper } from "../helpers/fhirActivityHelper";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools-helper";
import { DrugSchemaService } from "../services/globalDataProvider/drug-schema.service";
import { CycleSchema, EntityDrug } from "./entitylink";
import { NOTIFICATION_TYPE } from "./notification";

export interface Timings {
  [index: string]: number;
}
export interface ScheduledBefore {
  [index: number]: number;
}

export interface IMomentTime {
  moment?: string; // rising, morning, noon, evening, beding
  time: string;
}

export const FREQUENCY_OPTIONS: KeyValue[] = [
  new KeyValue("timing.fixedFrequency", "fixedFrequency"),
  new KeyValue("timing.fixedDates", "fixedDates"),
  new KeyValue("timing.asNecessary", "asNecessary"),
];

export const TIMING_OPTIONS: KeyValue[] = [new KeyValue("timing.moments", "moments"), new KeyValue("timing.fixedHours", "fixedHours")];

export const TIMING_CODES = [
  {
    value: IEntity.TIMING_RISING,
    display: "rise",
  },
  {
    value: IEntity.TIMING_MORNING,
    display: "morning",
  },
  {
    value: IEntity.TIMING_NOON,
    display: "noon",
  },
  {
    value: IEntity.TIMING_EVENING,
    display: "evening",
  },
  {
    value: IEntity.TIMING_BED,
    display: "bedtime",
  },
];

export const DAYS_OF_MONTH: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export class TimingData {
  public static fixedFreqOption = FREQUENCY_OPTIONS[0].value;
  public static fixedDaysOption = FREQUENCY_OPTIONS[1].value;
  public static fixedDaysOptionDisplay = FREQUENCY_OPTIONS[1].key;
  public static asNecessaryOption = FREQUENCY_OPTIONS[2].value;
  public static asNecessaryOptionDisplay = FREQUENCY_OPTIONS[2].key;
  public static momentTimingOption = TIMING_OPTIONS[0].value;
  public static fixedHoursTimingOption = TIMING_OPTIONS[1].value;

  public static getFreqOption(entityDrug: EntityDrug) {
    const asNecessary = entityDrug.frequency.asNecessary;
    return asNecessary
      ? this.asNecessaryOption
      : entityDrug.frequency.event && entityDrug.frequency.event.length
      ? this.fixedDaysOption
      : this.fixedFreqOption;
  }

  public static getTimingOption(entityDrug: EntityDrug) {
    return entityDrug.frequency.timeOfDay && entityDrug.frequency.timeOfDay.length ? this.fixedHoursTimingOption : this.momentTimingOption;
  }
  public static getFreqOptionFromTiming(timing: Timing) {
    const asNecessary = timing.asNecessary;
    return asNecessary ? this.asNecessaryOption : timing.event && timing.event.length ? this.fixedDaysOption : this.fixedFreqOption;
  }

  public static getTimingOptionFromTiming(timing: Timing) {
    return timing.timeOfDay && timing.timeOfDay.length ? this.fixedHoursTimingOption : this.momentTimingOption;
  }
  public static hasTiming(timing: Timing, freqOption?: string, timingOption?: string): boolean {
    const timingCode = timing.timingCode;
    freqOption = freqOption ? freqOption : this.getFreqOptionFromTiming(timing);
    timingOption = timingOption ? timingOption : this.getTimingOptionFromTiming(timing);
    switch (freqOption) {
      case this.asNecessaryOption:
        return true;
      case this.fixedFreqOption:
      case this.fixedDaysOption:
        switch (timingOption) {
          case this.momentTimingOption:
            return !!timingCode;
          case this.fixedHoursTimingOption:
            return timing.timeOfDay && timing.timeOfDay.length > 0;
          default:
            return false;
        }
      default:
        return false;
    }
  }
  public static getDateOfXthDayOfTreatmentForCycle(cycle: CycleSchema, nbDays: number): string {
    try {
      const xthDay = moment().startOf("day");
      let dayOfCycle = DrugSchemaService.getDayOfCycleOnDate(cycle, xthDay.toDate());
      let daysOfTreatment = 0;

      while (daysOfTreatment < nbDays) {
        xthDay.add(1, "day");
        const isPaused = DrugSchemaService.isCycleInPauseOnDate(cycle, xthDay.toDate());
        if (dayOfCycle === cycle.cycle.length - 1) {
          // end of cycle, start a new one
          dayOfCycle = 0;
        } else {
          dayOfCycle++;
        }
        // If it's not pause and it's a day we need to take the drug:
        if (!isPaused && cycle.cycle[dayOfCycle]) {
          daysOfTreatment++;
        }
      }
      return xthDay.format("DD-MM-YYYY");
    } catch (err) {
      FileLogger.error("TimingData", "getDateOfXthDayOfTreatmentForCycle", err);
      return null;
    }
  }
  public static getDateOfXthDayOfTreatment(timing: Timing, nbDays: number): string {
    const xthDay = moment().startOf("day");
    const freqOption = TimingData.getFreqOptionFromTiming(timing);
    const timingOption = TimingData.getTimingOptionFromTiming(timing);
    const hasTiming = TimingData.hasTiming(timing, freqOption, timingOption);
    if (!hasTiming || freqOption === TimingData.asNecessaryOption) {
      return null;
    } else if (freqOption === TimingData.fixedFreqOption) {
      switch (FHIR_ActivityHelper.getScheduledPeriod(timing)) {
        case SCHEDULE_PERIOD.DAY:
          xthDay.add(nbDays, "days").format();
          break;
        case SCHEDULE_PERIOD.WEEK:
          this.addDaysOfTreatment(xthDay, nbDays, "weeks", timing.when);
          break;
        case SCHEDULE_PERIOD.MONTH:
          this.addDaysOfTreatment(xthDay, nbDays, "months", timing.when);
          break;
        default:
          return null;
      }
      return xthDay.format("DD-MM-YYYY");
    } else if (freqOption === TimingData.fixedDaysOption) {
      const days = timing.event;
      // remove past days:
      const futureDays = days?.filter((d) => moment(d).isAfter(moment().endOf("day")));
      if (!futureDays || futureDays.length === 0) return null;
      if (nbDays > futureDays.length) return moment(futureDays[futureDays.length - 1]).format("DD-MM-YYYY");
      return moment(futureDays[nbDays - 1]).format("DD-MM-YYYY");
    }
    return null;
  }

  private static addDaysOfTreatment(
    currentDay: moment.Moment,
    nbDays: number,
    periodType: moment.unitOfTime.DurationAs,
    timingWhen: string
  ): void {
    const treatmentDaysInWeek = JSON.parse(timingWhen) as string[];
    const periods = this.getNbPeriodOfTreatment(treatmentDaysInWeek, nbDays);
    if (periods === null) return null;
    currentDay.add(periods[0], periodType);
    // Deal with remaining days of partial period:
    if (periods[1] > 0) {
      this.addRemaingDaysToPeriod(currentDay, periodType, treatmentDaysInWeek, periods[1]);
    }
  }

  private static addRemaingDaysToPeriod(
    currentDay: moment.Moment,
    periodType: moment.unitOfTime.DurationAs,
    treatmentDaysInPeriod: string[],
    remainingDays: number
  ): void {
    const todayNumber = periodType === "weeks" ? moment().weekday() + 1 : moment().date();
    const previousTreatmentDay = treatmentDaysInPeriod.findIndex((d) => Number(d) <= todayNumber);
    let i = previousTreatmentDay + remainingDays;
    if (i >= treatmentDaysInPeriod.length) {
      // If with the remainging days, we go to the middle of the next period:
      currentDay.add(1, periodType);
      i = i - treatmentDaysInPeriod.length;
    }
    // Fix the correct day of the period:
    const lastDayOfTreatmentInThePeriod = Number(treatmentDaysInPeriod[i]);
    if (periodType === "months") {
      currentDay.date(lastDayOfTreatmentInThePeriod);
    } else if (periodType === "weeks") {
      currentDay.isoWeekday(lastDayOfTreatmentInThePeriod);
    }
  }

  /**
   * Return the number of periods (weeks, months...) of treatment the number of days correspond to
   * and the number of treatment days remaining (short of a full period)
   * Only for weekly drugs.
   * @param treatmentDaysInPeriod
   * @param nbDays
   */
  private static getNbPeriodOfTreatment(treatmentDaysInPeriod: string[], nbDays: number): number[] {
    if (!treatmentDaysInPeriod || treatmentDaysInPeriod.length === 0) return null;
    const nbDaysInPeriod = treatmentDaysInPeriod.length;
    const nbPeriods = Math.floor(nbDays / nbDaysInPeriod);
    const remainingDays = nbDays % nbDaysInPeriod;
    return [nbPeriods, remainingDays];
  }

  public static getTimingInstances(
    timing: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE,
    stopAfterFirst?: boolean
  ): IMomentTime[] {
    let instances: IMomentTime[] = [];
    const freqOption = TimingData.getFreqOptionFromTiming(timing);
    const timingOption = TimingData.getTimingOptionFromTiming(timing);
    const hasTiming = TimingData.hasTiming(timing, freqOption, timingOption);
    if (!hasTiming || freqOption === TimingData.asNecessaryOption) {
      return [];
    } else if (freqOption === TimingData.fixedFreqOption) {
      switch (FHIR_ActivityHelper.getScheduledPeriod(timing)) {
        case SCHEDULE_PERIOD.DAY: // *** DAY scheduled ***
          instances = this.getDailyTimingInstances(timing, userTimings, schedules, from, to, type, stopAfterFirst);
          break;
        case SCHEDULE_PERIOD.WEEK: // *** WEEK scheduled ***
          instances = this.getWeeklyTimingInstances(timing, userTimings, schedules, from, to, type, stopAfterFirst);
          break;
        case SCHEDULE_PERIOD.MONTH: // *** MONTH scheduled ***
          instances = this.getMonthlyTimingInstances(timing, userTimings, schedules, from, to, type, stopAfterFirst);
          break;
      }
    } else if (freqOption === TimingData.fixedDaysOption) {
      instances = this.getFixedDaysTimingInstances(timing, userTimings, schedules, from, to, type);
    } else {
      return [];
    }
    return instances;
  }

  private static getDailyTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE,
    stopAfterFirst?: boolean
  ): IMomentTime[] {
    let instances: IMomentTime[] = [];
    try {
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      const startDay = startDate.clone();
      instances = this.computeTimingInstances(
        startDate,
        startDay,
        frequency,
        userTimings,
        schedules,
        "days",
        endDay,
        from,
        to,
        null,
        type,
        stopAfterFirst
      );
    } catch (err) {
      FileLogger.error("TimingData", "scheduleDrugNotificationDaily", err);
    }
    return instances;
  }
  private static getWeeklyTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE,
    stopAfterFirst?: boolean
  ): IMomentTime[] {
    const instances: IMomentTime[] = [];
    try {
      // "when" contains week days index (from 1 to 7) in an array (ex: ["1,"3"] )
      const days = JSON.parse(frequency.when) as string[];
      if (!days || days.length === 0) return []; // nothing to do
      // start of current processing
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      // end of current processing date
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each week day
      days.forEach((dayIdx) => {
        const dayIndex = Number(dayIdx);
        const startDay = startDate.clone();
        // set week day
        startDay.isoWeekday(dayIndex);
        instances.push(
          ...this.computeTimingInstances(
            startDate,
            startDay,
            frequency,
            userTimings,
            schedules,
            "weeks",
            endDay,
            from,
            to,
            null,
            type,
            stopAfterFirst
          )
        );
      });
    } catch (err) {
      FileLogger.error("TimingData", "scheduleDrugNotificationWeekly", err);
    }
    return instances;
  }

  private static getMonthlyTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE,
    stopAfterFirst?: boolean
  ): IMomentTime[] {
    const instances: IMomentTime[] = [];
    try {
      // let entityDrugData = (drug.entityData as EntityDrug);
      // get days (1,2,3,...,31)
      const days = JSON.parse(frequency.when) as number[];
      if (!days || days.length === 0) return []; // nothing to do
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      // let from = startDate;
      // end of current processing date
      // let to = moment(frequency.boundsPeriod.end);
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each day in month to schedule
      days.forEach((day) => {
        const dayMonth = Number(day);
        const startDay = startDate.clone();
        // set day of the month (do not bubble up to next month)
        if (dayMonth === 31) startDay.endOf("month").startOf("day");
        else if (dayMonth > 28 && startDay.daysInMonth() < dayMonth) startDay.endOf("month").startOf("day");
        else startDay.date(dayMonth);
        instances.push(
          ...this.computeTimingInstances(
            startDate,
            startDay,
            frequency,
            userTimings,
            schedules,
            "months",
            endDay,
            from,
            to,
            dayMonth,
            type,
            stopAfterFirst
          )
        );
      });
    } catch (err) {
      FileLogger.error("TimingData", "scheduleDrugNotificationMonthly", err);
    }
    return instances;
  }

  private static computeTimingInstances(
    startDate: any,
    startDay: any,
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    freqType: string,
    endDay: any,
    from?: moment.Moment,
    to?: moment.Moment,
    dayMonth?: number,
    type?: NOTIFICATION_TYPE,
    stopAfterFirst?: boolean
  ) {
    let instances: IMomentTime[] = [];
    while (startDay.isSameOrBefore(endDay)) {
      // add it only if it is not before "From" date
      // if start date defined in Drug is in middle of current month, we may have to ignore some dates at the beginning of that month
      if (startDay.isSameOrAfter(startDate) && (!from || startDay.isSameOrAfter(from))) {
        const timingOption = TimingData.getTimingOptionFromTiming(frequency);
        if (timingOption === TimingData.momentTimingOption) {
          // compute for morning/noon/evening time
          instances = instances.concat(
            this.getMomentTimingInstances(frequency.timingCode, startDay, userTimings, schedules, from, to, type)
          );
        } else if (timingOption === TimingData.fixedHoursTimingOption) {
          instances = instances.concat(this.getFixedHoursTimingInstances(frequency.timeOfDay, startDay, schedules, from, to, type));
        }
      }

      if (stopAfterFirst && instances.length) {
        break;
      }

      // every X weeks
      startDay.add(frequency.period, freqType);
      if (freqType === "month" && dayMonth) {
        if (dayMonth === 31) startDay.endOf("month").startOf("day");
      }
    }
    return instances;
  }
  private static getFixedDaysTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): IMomentTime[] {
    let instances: IMomentTime[] = [];
    try {
      const days = frequency.event;
      if (!days || days.length === 0) return []; // nothing to do
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each day in event
      for (const day of days) {
        if (instances.length > 100) {
          break;
        }
        const startDay = moment(day).startOf("day");
        if (startDay.isSameOrBefore(endDay) && (!from || startDay.isSameOrAfter(from))) {
          const timingOption = TimingData.getTimingOptionFromTiming(frequency);
          if (timingOption === TimingData.momentTimingOption) {
            // compute for morning/noon/evening time
            instances = instances.concat(
              this.getMomentTimingInstances(frequency.timingCode, startDay, userTimings, schedules, from, to, type)
            );
          } else if (timingOption === TimingData.fixedHoursTimingOption) {
            instances = instances.concat(this.getFixedHoursTimingInstances(frequency.timeOfDay, startDay, schedules, from, to, type));
          }
        }
      }
    } catch (err) {
      FileLogger.error("TimingData", "scheduleNotificationOnFixedDays", err);
    }
    return instances;
  }

  public static getCycleTimingInstances(
    cycle: CycleSchema,
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): IMomentTime[] {
    let instances: IMomentTime[] = [];
    try {
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);

      const actualDayOfCycle = from
        ? DrugSchemaService.getDayOfCycleOnDate(cycle, startDate.toDate())
        : DrugSchemaService.getDayOfCycleOnDate(cycle);
      let isPaused = DrugSchemaService.isCycleInPause(cycle);
      let isPausedAndToday = DrugSchemaService.isCycleInPauseOnDate(cycle, new Date());
      const startDay = startDate.clone();
      let i = actualDayOfCycle;

      while (startDay.isSameOrBefore(endDay) && instances.length < 100) {
        if ((!isPaused && cycle.cycle[i]) || (isPaused && !isPausedAndToday && cycle.cycle[i])) {
          const timingOption = TimingData.getTimingOptionFromTiming(frequency);
          if (timingOption === TimingData.momentTimingOption) {
            // compute for morning/noon/evening time
            instances = instances.concat(
              this.getMomentTimingInstances(frequency.timingCode, startDay, userTimings, schedules, from, to, type)
            );
          } else if (timingOption === TimingData.fixedHoursTimingOption) {
            instances = instances.concat(this.getFixedHoursTimingInstances(frequency.timeOfDay, startDay, schedules, from, to, type));
          }
        }
        if (i === cycle.cycle.length - 1) {
          // end of cycle, start a new one
          i = 0;
          isPaused = false;
        } else {
          i++;
        }
        startDay.add(1, "days");
        if (isPaused && !isPausedAndToday) {
          isPausedAndToday = DrugSchemaService.isCycleInPauseOnDate(cycle, startDay.toDate());
        }
      }
    } catch (err) {
      FileLogger.error("TimingData", "scheduleNotificationForCycle", err);
    }
    return instances;
  }

  private static getMomentTimingInstances(
    timingCode: string,
    startDate: moment.Moment,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): IMomentTime[] {
    const instances: IMomentTime[] = [];
    if (
      (!from && moment().isAfter(startDate, "day")) ||
      (from && from.isAfter(startDate, "day")) ||
      (to && to.isBefore(startDate, "day"))
    ) {
      return [];
    }

    if (!timingCode || timingCode.length === 0) {
      FileLogger.warn("TimingData", "Unable to compute timing instances: no timing defined");
      return [];
    }

    // loop over rising / morning / noon / evening / bed
    for (const timing of IEntity.toKeyValues(timingCode)) {
      if (instances.length > 100) {
        break;
      }
      if ((timing.value as boolean) === true) {
        // make a copy of base notification to update it accordingly with Timing
        // get and set morning, noon or evening hour ?
        const key = timing.key + (Tools.isWeekend(startDate) ? "_weekend" : "");
        const time = userTimings[key] ? userTimings[key] : 0;
        const instanceTime = startDate.clone().add(time, "minutes");
        if (Tools.isDefined(type)) {
          const schedBefore = schedules[type] ? schedules[type] : 0;
          const notifAt = instanceTime.subtract(schedBefore, "minutes");
          instances.push({
            moment: timing.key,
            time: notifAt.format(),
          });
        } else {
          instances.push({
            moment: timing.key,
            time: instanceTime.format(),
          });
        }
      }
    }
    return instances;
  }
  private static getFixedHoursTimingInstances(
    hours: string[],
    startDate: moment.Moment,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): IMomentTime[] {
    const instances: IMomentTime[] = [];
    if ((!from && moment().isAfter(startDate, "day")) || (from && from.isAfter(startDate, "day")) || (to && to.isBefore(startDate, "day")))
      return [];

    if (!hours || hours.length === 0) {
      FileLogger.warn("TimingData", "Unable to compute timing instance: no fixed hours defined");
      return [];
    }
    for (const hour of hours) {
      if (instances.length > 100) {
        break;
      }
      // make a copy of base notification to update it accordingly with hour
      const h = moment.duration(hour + ":00");
      const instanceTime = startDate.clone().add(h);
      if (Tools.isDefined(type)) {
        const schedBefore = schedules[type] ? schedules[type] : 0;
        const notifAt = instanceTime.add(-schedBefore, "minutes");
        instances.push({ time: notifAt.format() });
      } else {
        instances.push({ time: instanceTime.format() });
      }
    }
    return instances;
  }

  public static getMomentsQuantity(moment: string, drug: EntityDrug): string {
    let quantity: string;
    switch (moment) {
      case "rising":
        quantity = drug.frequency?.quantities?.rise;
        break;
      case "beding":
        quantity = drug.frequency?.quantities?.bedtime;
        break;
      default:
        quantity = drug.frequency?.quantities?.[moment];
        break;
    }
    return quantity;
  }
}

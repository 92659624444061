import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppConstants } from "../appConstants";
import { ObservationModalComponent } from "../components/modals/observation-modal/observation-modal.component";
import { FileLogger } from "../helpers/fileLogger";
import { IObservation, IObservationDefinition, Observation } from "../helpers/observation-helper";
import { RuleHelperObservationService } from "../helpers/rule-helper-service/rule-helper-observation.service";
import { ServerResponse } from "../helpers/server-response-helper";
import { Tools } from "../helpers/tools-helper";
import { ConfigurationService } from "./globalDataProvider/configuration.service";
import { ObservationService } from "./globalDataProvider/observation.service";
import { InfoAppService } from "./info-app.service";
import { LoaderService } from "./loader.service";
import { PopupService } from "./popup.service";

@Injectable({
  providedIn: "root",
})
export class ModalObservationService {
  constructor(
    private modalCtrl: ModalController,
    private observationService: ObservationService,
    private loaderService: LoaderService,
    private configService: ConfigurationService,
    private popupService: PopupService,
    private infoAppService: InfoAppService,
    private ruleService: RuleHelperObservationService
  ) {}

  /**
   *  display view to create a new observation and store it
   */
  public async createObservation(
    definition: IObservationDefinition,
    allObservations: IObservation[],
    hideLoinc?: string[]
  ): Promise<IObservation[]> {
    const obs = Observation.createObservation(definition, this.configService.getCurrentLanguage());
    try {
      return await this.openObsCreationModalAndSave(obs, definition, allObservations, hideLoinc);
    } catch (err) {
      FileLogger.error("ModalObservationService", "error, createObservation: ", err);
      await this.loaderService.showSavingToast(false);
      return null;
    }
  }

  public async openObsCreationModalAndSave(
    obs: IObservation,
    definition: IObservationDefinition,
    allObservations: IObservation[],
    hideLoinc?: string[]
  ): Promise<IObservation[]> {
    const observation = await this.presentModalObservation(obs, definition, allObservations, hideLoinc);
    let observations: IObservation[] = [];
    // Split values
    if (hideLoinc && hideLoinc.length > 0 && observation) {
      hideLoinc.forEach((code) => {
        const component = observation.component.find((c) => c.code.coding.findIndex((coding) => coding.code === code) >= 0);
        if (component) {
          // Create new observation
          const newObs = Observation.createObservation(definition, this.configService.getCurrentLanguage());
          newObs.code = { ...component.code };
          newObs.component = [component];
          observations.push(newObs);
          if (observation.device?.reference) {
            newObs.device = observation.device;
          }

          // Remove component from main observation
          observation.component = observation.component.filter((c) => c !== component);
        }
      });
      observations.push(observation);
    } else {
      observations = [observation];
    }

    if (observations[0]) {
      await this.loaderService.showSavingToast(true);
      const observationsSavePromises = observations.map((obsToSave) => this.observationService.save(obsToSave, false));
      let savedObservations: IObservation[];

      try {
        savedObservations = await Promise.all(observationsSavePromises);
        await this.loaderService.showSavingToast(false);
        return savedObservations;
      } catch (error) {
        await this.loaderService.showSavingToast(false);

        // Reopen modal with previously encoded data in case the user lose internet while trying to save an observation
        if (!this.infoAppService.isCordova()) {
          await this.ruleService.dismissRuleAlerts();
          // We reopen the modal only if we are sure it's a server_unreachable error
          if (error.code && error.code === ServerResponse.SERVER_UNREACHABLE.code) {
            this.openObsCreationModalAndSave(observations[0], definition, allObservations, hideLoinc);
          }
          this.popupService.showAlert("error.general", "error.browser.unreachableServer");
        }
      }
    }
    // simple cancel, nothing to do
    return null;
  }

  /**
   *  display view to edit a observation and store modification
   */
  public async editObservation(
    definition: IObservationDefinition,
    observation: IObservation,
    allObservations: IObservation[],
    isBluetoothEdit = false,
    loincDisabled: string[] = []
  ): Promise<IObservation> {
    const obs = Tools.deepCopy(observation); // make a copy in case of rollback
    try {
      return await this.openObsEditionModalAndSave(definition, obs, allObservations, isBluetoothEdit, loincDisabled);
    } catch (error) {
      FileLogger.error("DataPage", "editObservation", error);
      return null;
    }
  }

  public async openObsEditionModalAndSave(
    definition: IObservationDefinition,
    observation: IObservation,
    allObservations: IObservation[],
    isBluetoothEdit = false,
    loincDisabled: string[] = []
  ): Promise<IObservation> {
    const observationModified = await this.presentModalObservation(
      observation,
      definition,
      allObservations,
      undefined,
      true,
      isBluetoothEdit,
      loincDisabled
    );
    try {
      if (observationModified) {
        let savedObservation = observationModified;
        if (!isBluetoothEdit) {
          savedObservation = await this.observationService.save(observationModified);
        }
        return savedObservation;
      } else {
        // simple cancel, nothing to do
        return null;
      }
    } catch (error) {
      FileLogger.error("ModalObservationService", "error, editObservation: ", error);
      this.loaderService.showSavingToast(false);
      this.ruleService.dismissRuleAlerts();
      if (!this.infoAppService.isCordova()) {
        // We reopen the modal only if we are sure it's a server_unreachable error
        if (error.code && error.code === ServerResponse.SERVER_UNREACHABLE.code) {
          this.openObsEditionModalAndSave(definition, observationModified, allObservations, isBluetoothEdit, loincDisabled);
        }
        this.popupService.showAlert("error.general", "error.browser.unreachableServer");
      }
    }
  }

  /**
   * display a observation in a modal view
   */
  public async presentModalObservation(
    observation: IObservation,
    observationDefinition: IObservationDefinition,
    allObservations: IObservation[],
    hideLoinc?: string[],
    isEditMode = false,
    isBluetoothEdit = false,
    loincDisabled?: string[]
  ): Promise<IObservation> {
    const modal = await this.modalCtrl.create({
      component: ObservationModalComponent,
      componentProps: {
        observation: observation,
        definition: observationDefinition,
        allObservations: allObservations,
        hideLoinc: hideLoinc,
        isEditMode: isEditMode,
        isBluetoothEdit: isBluetoothEdit,
        loincDisabled: loincDisabled,
      },
      id: AppConstants.OBSERVATION_MODAL, // using id to help dismiss() the correct modal
    });

    modal.present();
    const data = await modal.onDidDismiss();
    return data.data as IObservation;
  }
}

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { last } from "rxjs/operators";
import { AppConstants } from "src/app/appConstants";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IKnowMedia, IKnowledgeBase, IKnowledges, KNOW_DOC_CATEGORY } from "src/app/helpers/knowledge-helper";
import { IObservation } from "src/app/helpers/observation-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { IMomentTime, TIMING_CODES, TimingData } from "src/app/models/timingData";
import { Appointment, IAppointment } from "../../models/appointment";
import { IConfiguration, OBSERVATION_TYPE } from "../../models/configuration";
import { EntityDrug, IEntitylink, IStepwise } from "../../models/entitylink";
import { INotification, NOTIFICATION_TYPE, Notification } from "../../models/notification";
import { IRelatedPerson } from "../../models/relatedPerson";
import { IEntity, Timing } from "../../models/sharedInterfaces";
import { ConfigurationService } from "../globalDataProvider/configuration.service";
import { ObservationDefinitionService } from "../globalDataProvider/observation-definition.service";
import { SysAccountService } from "../sys-account.service";
import { NotificationsPluginService } from "./notifications-plugin.service";
import { NotificationsSaveService } from "./notifications-save.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsGeneratedService {
  constructor(
    private sysAccountService: SysAccountService,
    private configService: ConfigurationService,
    private notificationsPluginService: NotificationsPluginService,
    private notificationsSaveService: NotificationsSaveService,
    private translateSvc: TranslateService,
    private obsDefService: ObservationDefinitionService
  ) {}

  public async generatedNotifications(data: any[], type: NOTIFICATION_TYPE): Promise<void> {
    // Check if the configuration is set in the cache
    const result = this.configService.getCacheConfiguration() ? Promise.resolve() : this.configService.refreshConfiguration();
    await result; // will wait for possible config refresh
    if (this.configService.getCacheConfiguration()) {
      this.translateSvc.setDefaultLang(this.configService.getCurrentLanguage());
      await this.translateSvc.use(this.configService.getCurrentLanguage()).pipe(last()).toPromise();
    } else {
      return;
    }
    let notifs: INotification[] = [];
    switch (type) {
      case NOTIFICATION_TYPE.APPOINTMENT: {
        notifs = this.generateNotificationFromAppointments(data as IAppointment[]);
        break;
      }
      case NOTIFICATION_TYPE.ADVICE: {
        notifs = this.generateNotificationForAdvices(data as IKnowledges[]);
        break;
      }
      case NOTIFICATION_TYPE.DRUG: {
        try {
          notifs = this.getAllDrugNotifications(data as IEntitylink[]);
        } catch (err) {
          FileLogger.error("NotificationsGeneratedService", "generatedNotifications", err);
        }
        break;
      }
      case NOTIFICATION_TYPE.OBSERVATION: {
        // Check if the obs def is set in the cache
        const promiseObsDef: Promise<boolean> = this.obsDefService.peekData().length
          ? Promise.resolve(true)
          : this.obsDefService.getFreshestData().then(() => {
              return true;
            });
        const osb = await promiseObsDef;
        if (osb) {
          notifs = this.getAllObservationNotifications();
        }
        break;
      }
      case NOTIFICATION_TYPE.FEELING: {
        notifs = this.getAllFeelingNotifications();
        break;
      }
      case NOTIFICATION_TYPE.RELATED_APPOINTMENT: {
        const dataCast = data as { app: IAppointment[]; related: IRelatedPerson }[];
        dataCast.forEach((app) => {
          notifs = notifs.concat(this.generateNotificationFromRelatedAppointments(app.app, app.related));
        });
        break;
      }
      case NOTIFICATION_TYPE.RELATED_DRUG: {
        try {
          const dataCast = data as { drugs: IEntitylink[]; related: IRelatedPerson }[];
          dataCast.forEach((drugs) => {
            const newNotifs = this.getAllDrugNotifications(drugs.drugs, drugs.related);
            notifs = notifs.concat(newNotifs);
          });
        } catch (err) {
          FileLogger.error("NotificationsGeneratedService", "generatedNotifications", err);
        }
        break;
      }
      default:
        break;
    }
    notifs.forEach((notif) => {
      notif.language = this.configService.getCurrentLanguage();
    });
    const notifsMerge: INotification[] = await this.notificationsSaveService.mergeData(notifs, type);
    if (notifsMerge.length > 0) {
      await this.notificationsSaveService.setByType(notifsMerge, type, false);
      const n: INotification[] = await this.notificationsPluginService.setLocalNotificationsByType(notifsMerge, type);
      await this.notificationsSaveService.setByType(n, type);
    }
  }

  public async updateOrCreateNotification(data: any, type: NOTIFICATION_TYPE): Promise<void> {
    if (IEntity.isDeleted(data as IEntity) || IEntity.isCancelled(data as IEntity)) {
      await this.deleteNotification(data, type);
      return;
    }
    let notifs: INotification[] = [];
    switch (type) {
      case NOTIFICATION_TYPE.APPOINTMENT: {
        notifs = this.generateNotificationFromAppointments([data as IAppointment]);
        break;
      }
      case NOTIFICATION_TYPE.ALERT_OBSERVATION: {
        const dataCast = data as { obs: IObservation; identifierAlert: string };
        notifs = [this.getAlertObservationNotification(dataCast.obs, dataCast.identifierAlert)];
        break;
      }
      case NOTIFICATION_TYPE.RELATED_APPOINTMENT: {
        const dataCast = data as { app: IAppointment; related: IRelatedPerson };
        // Notifications are only generated for appointments where the current user is a participant
        if (Appointment.isParticipant(dataCast.app, this.sysAccountService.cachedCaremateId)) {
          notifs = this.generateNotificationFromRelatedAppointments([dataCast.app], dataCast.related);
        }
        // delete the notification if the current user is not a participant
        else {
          await this.deleteNotification(data, NOTIFICATION_TYPE.RELATED_APPOINTMENT);
          return;
        }
        break;
      }
      case NOTIFICATION_TYPE.DRUG: {
        const drug = data as IEntitylink;
        await this.notificationsSaveService.deleteDrug(this.sysAccountService.cachedCaremateId, drug._id, false);
        notifs = await this.notificationsPluginService.deleteLocalNotificationsOfDrug(this.sysAccountService.cachedCaremateId, drug._id);
        await this.notificationsSaveService.setByType(notifs, type);

        if ((drug.entityData as EntityDrug)?.notify) {
          notifs = this.generateNotificationFromDrug(data as IEntitylink, null);
        } else {
          notifs = [];
        }
        break;
      }
      default:
        break;
    }
    const notifsMerge: INotification[] = await this.notificationsSaveService.mergeData(notifs, type);
    if (notifsMerge.length > 0) {
      await this.notificationsSaveService.updateOrCreateByType(notifsMerge, type, false);
      const n: INotification[] = await this.notificationsPluginService.updateOrCreateLocalNotificationsByType(notifsMerge, type);
      this.notificationsSaveService.setByType(n, type);
    }
  }

  public async deleteNotification(data: any, type: NOTIFICATION_TYPE): Promise<void> {
    let notifs: INotification[] = [];
    switch (type) {
      case NOTIFICATION_TYPE.APPOINTMENT: {
        notifs = this.generateNotificationFromAppointments([data as IAppointment], true);
        break;
      }
      case NOTIFICATION_TYPE.ALERT_OBSERVATION: {
        const dataCast = data as { obs: IObservation; identifierAlert: string };
        notifs = [this.getAlertObservationNotification(dataCast.obs, dataCast.identifierAlert)];
        break;
      }
      case NOTIFICATION_TYPE.RELATED_APPOINTMENT: {
        const dataCast = data as { app: IAppointment; related: IRelatedPerson };
        notifs = this.generateNotificationFromRelatedAppointments([dataCast.app], dataCast.related, true);
        break;
      }
      case NOTIFICATION_TYPE.DRUG: {
        const drug = data as IEntitylink;
        await this.notificationsSaveService.deleteDrug(this.sysAccountService.cachedCaremateId, drug._id, false);
        notifs = await this.notificationsPluginService.deleteLocalNotificationsOfDrug(this.sysAccountService.cachedCaremateId, drug._id);
        await this.notificationsSaveService.setByType(notifs, type);
        if ((drug.entityData as EntityDrug)?.notify) {
          notifs = this.generateNotificationFromDrug(data as IEntitylink, null);
        } else {
          notifs = [];
        }
        break;
      }
      default:
        break;
    }
    const notifsMerge = await this.notificationsSaveService.mergeData(notifs, type);
    if (notifsMerge.length > 0) {
      await this.notificationsSaveService.deleteByType(notifsMerge, type, false);
      const n: INotification[] = await this.notificationsPluginService.deleteLocalNotificationsByType(notifsMerge, type);
      await this.notificationsSaveService.setByType(n, type);
    }
  }

  public async deleteAllPluginNotifications(): Promise<void> {
    await this.notificationsPluginService.deleteAllLocalNotifs();
  }

  /******************* RELATED **********************/

  /**
   * Notifications are only generated for appointments where the current user is a participant
   * @param appointments
   * @param related
   * @param forceGenerate
   */
  private generateNotificationFromRelatedAppointments(
    appointments: IAppointment[],
    related: IRelatedPerson,
    forceGenerate = false
  ): INotification[] {
    const arrApp = new Array<IAppointment>();

    for (const a of appointments) {
      if (forceGenerate || Appointment.isParticipant(a, this.sysAccountService.cachedCaremateId)) {
        arrApp.push(a);
      }
    }

    if (arrApp.length === 0) {
      return [];
    }

    return this.generateNotificationFromAppointments(appointments, forceGenerate, related);
  }

  /**************** APPOINTMENTS *******************/

  /**
   * Build a locale notification from appointment data
   * @param appointment
   */
  private generateNotificationFromAppointments(
    appointments: IAppointment[],
    forceGenerate = false,
    related?: IRelatedPerson
  ): INotification[] {
    if (!appointments || appointments.length === 0) {
      return [] as INotification[];
    }

    const notifications = new Array<INotification>();
    appointments.forEach((appointment) => {
      // do not generate notification for cancelled appointment and generate notification for today and after
      if ((forceGenerate || !IEntity.isCancelled(appointment)) && moment(appointment.start).isSameOrAfter(moment(), "days")) {
        const type = related ? NOTIFICATION_TYPE.RELATED_APPOINTMENT : NOTIFICATION_TYPE.APPOINTMENT;
        const notif = new Notification(this.sysAccountService.cachedCaremateId, type, appointment.start, appointment.end, appointment._id);
        notif.reference = appointment;
        notif.message = "👥 ";
        if (related) {
          notif.related = related.patient;
          notif.message += this.translateSvc.instant("agenda.relatedAccompany") + " " + related.patient.display + ": ";
        }
        notif.message += appointment.description;
        notif.time = appointment.start;
        const notifAt = moment(appointment.start)
          .add(-this.configService.getCacheConfiguration().settings.notificationSettings.scheduledBefore[3], "minutes")
          .second(0);
        notif.at = notifAt.format();
        notif.trigger = { at: notifAt.toDate() }; // fill trigger field for Local Notification

        notifications.push(notif);
      }
    });

    return notifications;
  }

  /**************** ADVICES *******************/

  private generateNotificationForAdvices(kv: IKnowledges[]): Notification[] {
    const config = this.configService.getCacheConfiguration();
    let freq: number;
    let adviceTime: string;
    let lastGen: Date;
    if (config?.settings.notificationSettings.adviceSettings) {
      adviceTime = config.settings.notificationSettings.adviceSettings.adviceTime;
      freq = config.settings.notificationSettings.adviceSettings.adviceFrequency;
      // if lastGen is in the future, we put it at today
      if (moment().isBefore(moment(config.settings.notificationSettings.adviceSettings.lastGen))) {
        config.settings.notificationSettings.adviceSettings.lastGen = moment().toDate();
      }
      lastGen = config.settings.notificationSettings.adviceSettings.lastGen;
    } else {
      freq = AppConstants.ADVICE_DEFAULT_FREQUENCY;
      adviceTime = IEntity.MORNING;
      lastGen = null;
    }

    // filter knowledges, only KNOW_DOC_CATEGORY.RECOMMENDATION generate a notification
    const temp1 = kv.map((_) => _.knowledges.filter((v) => v.documentCategory === KNOW_DOC_CATEGORY.RECOMMENDATION));
    const temp2 = temp1.length > 0 ? temp1.reduce((acc, it) => [...acc, ...it], []) : ([] as IKnowledgeBase[]);
    const temp3 = temp2.map((_) => _.medias.filter((m) => m.language === config?.settings.globalSettings.language));
    const kvAdvices = temp3.length > 0 ? temp3.reduce((acc, it) => [...acc, ...it], []) : ([] as IKnowMedia[]);

    if (!kvAdvices || kvAdvices.length === 0) return [] as Notification[];

    const notifications = new Array<Notification>();
    // scheduled for x days
    // we increase i by the frequence (it's like we skip days)

    const dayNow = Tools.getToday();
    const notifTimeIfToday = dayNow
      .clone()
      .add(this.configService.getUserTiming(adviceTime, Tools.isWeekend(dayNow)), "minutes")
      .toDate();

    const diff = this.computeDayWithLastGen(lastGen, freq, notifTimeIfToday);

    for (let i = diff; i < NotificationsPluginService.maxDayGeneration; i += freq) {
      // if i < 0, a advice should have been generated in the past
      if (i >= 0) {
        // random advice

        const randomIdx = Tools.getRandomInt(0, kvAdvices.length - 1);
        const advice = kvAdvices[randomIdx];
        let isLong = false;
        if (advice.content && advice.description) {
          isLong = true;
        }
        const dayMoment = Tools.getToday().add(i, "days");
        const notifTime = dayMoment.clone().add(this.configService.getUserTiming(adviceTime, Tools.isWeekend(dayMoment)), "minutes");
        const notif = new Notification(
          this.sysAccountService.cachedCaremateId,
          NOTIFICATION_TYPE.ADVICE,
          notifTime.format(),
          notifTime.format(),
          advice._id
        );

        const adviceMedia: IKnowMedia = advice;
        if (
          !adviceMedia ||
          adviceMedia === undefined ||
          !adviceMedia.label ||
          adviceMedia.label.length === 0 ||
          !adviceMedia.description ||
          adviceMedia.description.length === 0
        ) {
          continue;
        }

        // only adviceMedia with 3 stars are able to generate a notification
        if (adviceMedia.importanceLevel !== 3) {
          continue;
        }
        notif.isLong = isLong;
        notif.message = "💡 " + advice.label + " : " + advice.description;
        notif.time = notifTime.format();
        notif.at = notifTime.format();
        notif.trigger = { at: notifTime.toDate() }; // fill trigger field for Local Notification
        notifications.push(notif);
      }
    }

    // if diff > 0, we are between two generations of advices : stay the same lastGen
    if (diff <= 0 && config) {
      config.settings.notificationSettings.adviceSettings.lastGen = moment().add(diff, "days").toDate();
    }
    this.updateConfigAfterGenAdvice(config); // this will be done asynchronously
    return notifications;
  }

  /**
   * number of days before the next generation of advices.
   * @param lastGen
   * @param freq
   * @param notifTimeIfToday time related to the configuration of advices (morning, evening, ...)
   */
  public computeDayWithLastGen(lastGen: Date, freq: number, notifTimeIfToday: Date): number {
    if (lastGen === null) {
      return 0;
    }

    const now = moment();
    const diffNowLastGen = now.diff(moment(lastGen), "days");

    // not yet generated today
    if (diffNowLastGen === 0 && now.isBefore(notifTimeIfToday)) {
      return 0;
    }

    return freq - diffNowLastGen;
  }

  public async updateConfigAfterGenAdvice(configuration: IConfiguration): Promise<void> {
    await this.configService.saveWithPromise(configuration);
  }

  /******************* DRUG ***************************/
  /**
   * Generate Notifications from Drugs stored in Careplans and in Entitylinks (for modified careplan drugs and user personal drugs)
   */
  private getAllDrugNotifications(entityDrugs: IEntitylink[], related?: IRelatedPerson): INotification[] {
    // cast drugs into notifications format
    let notifications = new Array<INotification>();
    entityDrugs.forEach((drug) => {
      const notifs = this.generateNotificationFromDrug(drug, related);
      if (notifs.length > 0) notifications = notifications.concat(notifs);
    });
    return notifications;
  }

  /**
   * Generate locales notifications based on single drug information
   * @param drug
   */
  private generateNotificationFromDrug(drug: IEntitylink, related?: IRelatedPerson): INotification[] {
    try {
      // copy because we modify some attributs to create a notification (Warning : Object.assign is not a deep copy)
      const entityDrug = Object.assign({}, drug.entityData as EntityDrug);
      entityDrug.frequency = Object.assign({}, entityDrug.frequency);
      entityDrug.frequency.boundsPeriod = Object.assign({}, entityDrug.frequency.boundsPeriod);
      if (!entityDrug.notify) return []; // notifications are disabled for that drug
      if (
        !TimingData.hasTiming(entityDrug.frequency) &&
        !(entityDrug.cycle && entityDrug.cycle.cycle.length > 0) &&
        !entityDrug.stepwiseSchema
      ) {
        return [];
      }

      if (!entityDrug.frequency.boundsPeriod.end) {
        entityDrug.frequency.boundsPeriod.end = AppConstants.NO_END_DATE;
      }

      if (Tools.getToday().isAfter(entityDrug.frequency.boundsPeriod.end, "day")) return [];
      // we limit the end date at today + 5 weeks for drugs without endDate
      if (Number(entityDrug.frequency.boundsPeriod.end[0]) > 2) {
        entityDrug.frequency.boundsPeriod.end = moment().add(5, "weeks").format();
      }
      const notifications: INotification[] = [];

      if (entityDrug.stepwiseSchema) {
        notifications.push(...this.generateNotifFromStepwiseDrug(drug, related));
      } else {
        let message = "💊 ";
        if (related) {
          const t = this.translateSvc.instant("notification.relatedTake") as string;
          message += t.replace("[NAME]", related.patient.display) + " " + (entityDrug.prescriptionName ?? entityDrug.name);
        } else {
          message += this.translateSvc.instant("myobservations.takeDrug") + " " + (entityDrug.prescriptionName ?? entityDrug.name);
        }
        const type = related ? NOTIFICATION_TYPE.RELATED_DRUG : NOTIFICATION_TYPE.DRUG;
        const schedules = this.configService.getNotifsSchedules();
        const userTimings = this.configService.getUserTimings();
        const max = moment().add(NotificationsPluginService.maxDayGeneration, "days");
        // create a base notification object
        const notif = new Notification(
          this.sysAccountService.cachedCaremateId,
          type,
          entityDrug.frequency.boundsPeriod.start,
          entityDrug.frequency.boundsPeriod.end,
          drug._id
        );
        notif.message = message;
        notif.reference = null;
        notif.color = null;
        notif.drugComment = entityDrug.comment ? entityDrug.comment : undefined;

        let timingInstances: IMomentTime[] = [];

        if (entityDrug.cycle && entityDrug.cycle.cycle.length > 0) {
          timingInstances = TimingData.getCycleTimingInstances(
            entityDrug.cycle,
            entityDrug.frequency,
            userTimings,
            schedules,
            null,
            max,
            type
          );
        } else {
          timingInstances = TimingData.getTimingInstances(entityDrug.frequency, userTimings, schedules, null, max, type);
        }
        for (const instance of timingInstances) {
          const n = Object.assign({}, notif);
          const schedBefore = schedules[type] ? schedules[type] : 0;
          const notifTime = moment(instance.time).add(schedBefore, "minutes"); // get back the exact time
          n.moment = instance.moment;
          n.time = notifTime.format();
          n.at = instance.time;
          n.trigger = { at: moment(instance.time).toDate() }; // fill trigger field for Local Notification
          n.hasDisplay = !entityDrug.photo ? false : true;
          notifications.push(n);
        }
      }
      return notifications;
    } catch (err) {
      FileLogger.error("NotificationsGeneratedService", "generateDrugNotification", err);
      return [];
    }
  }

  private generateNotifFromStepwiseDrug(entitylink: IEntitylink, related?: IRelatedPerson): INotification[] {
    const notifications: INotification[] = [];
    const type = related ? NOTIFICATION_TYPE.RELATED_DRUG : NOTIFICATION_TYPE.DRUG;
    const schedules = this.configService.getNotifsSchedules();
    const userTimings = this.configService.getUserTimings();
    const max = moment().add(NotificationsPluginService.maxDayGeneration, "days");
    const drugData = entitylink.entityData as EntityDrug;
    const stepwises: IStepwise[] = drugData.stepwiseSchema.stepwises;
    stepwises.forEach((stepwise) => {
      const startDay = moment(drugData.frequency.boundsPeriod.start).add(stepwise.startDay, "d");
      stepwise.days.forEach((d, i) => {
        const frequency: Timing = {
          boundsPeriod: {
            start: moment(Tools.deepCopy(startDay)).add(i, "d").format(),
          },
          period: 1,
          periodUnits: "d",
        };
        if (
          !drugData.frequency.boundsPeriod.end ||
          moment(frequency.boundsPeriod.start).isSameOrBefore(moment(drugData.frequency.boundsPeriod.end))
        ) {
          frequency.boundsPeriod.end = frequency.boundsPeriod.start;
          d.moment.forEach((m, iMoment) => {
            frequency.timingCode = TIMING_CODES.find((t) => t.display === m.timingCode).value;
            const instance = TimingData.getTimingInstances(frequency, userTimings, schedules, null, max, type)[0]; // there will always only be one instance in the stepwise case
            let message = "💊 ";
            if (related) {
              const t = this.translateSvc.instant("notification.relatedTake") as string;
              message += t.replace("[NAME]", related.patient.display) + " " + (drugData.prescriptionName ?? drugData.name) + " :";
            } else {
              message += this.translateSvc.instant("myobservations.takeDrug") + " " + (drugData.prescriptionName ?? drugData.name) + " :";
            }
            m.drugs.forEach((drug) => {
              message += " " + (drug.quantity ? drug.quantity + " " : "") + (drug.prescriptionName ?? drug.name) + ",";
            });
            const notif = new Notification(
              this.sysAccountService.cachedCaremateId,
              type,
              instance.time,
              instance.time,
              entitylink._id + iMoment
            );
            notif.message = message.substring(0, message.length - 1);
            notif.reference = null;
            notif.color = null;
            notif.drugComment = drugData.comment ? drugData.comment : undefined;
            const schedBefore = schedules[type] ? schedules[type] : 0;
            const notifTime = moment(instance.time).add(schedBefore, "minutes");
            notif.moment = instance.moment;
            notif.time = notifTime.format();
            notif.at = instance.time;
            notif.trigger = { at: moment(instance.time).toDate() };
            notif.hasDisplay = !drugData.photo ? false : true;
            notifications.push(notif);
          });
        }
      });
    });
    return notifications;
  }

  /***************** Observations *********************/

  /**
   * Genetate notifications for Observations
   */
  private getAllObservationNotifications(): INotification[] {
    const notifs = new Array<INotification>();
    this.configService.getCacheConfiguration().parameters.observationParams.forEach((observParam) => {
      if (observParam.notify) {
        // take only active notification
        const color = observParam.notifColor;
        const mStart = moment(observParam.frequency.boundsPeriod.start);
        const mEnd = moment(observParam.frequency.boundsPeriod.end);
        const max = moment().add(NotificationsPluginService.maxDayGeneration, "days");
        if (mStart.isSameOrBefore(moment(), "day") && mEnd.isSameOrAfter(moment(), "day")) {
          // take only notification for today and after
          // set message
          const observationMessage =
            "📉 " + this.translateSvc.instant("myobservations.takeObservation") + " " + this.getTranslatedName(observParam.type);
          const type = NOTIFICATION_TYPE.OBSERVATION;
          const notif = new Notification(
            this.sysAccountService.cachedCaremateId,
            type,
            observParam.frequency.boundsPeriod.start,
            observParam.frequency.boundsPeriod.end,
            String(observParam.type)
          );
          notif.message = observationMessage;
          notif.reference = observParam.type;
          notif.color = color;
          const schedules = this.configService.getNotifsSchedules();
          const userTimings = this.configService.getUserTimings();
          const timingInstances = TimingData.getTimingInstances(observParam.frequency, userTimings, schedules, mStart, max, type);

          for (const instance of timingInstances) {
            const n = Object.assign({}, notif);
            const schedBefore = schedules[type] ? schedules[type] : 0;
            const notifTime = moment(instance.time).add(schedBefore, "minutes"); // get back the exact time
            n.time = notifTime.format();
            n.at = instance.time;
            n.trigger = { at: moment(instance.time).toDate() }; // fill trigger field for Local Notification
            n.moment = instance.moment;
            notifs.push(n);
          }
        }
      }
    });
    return notifs;
  }

  /**
   * Return translated Observation name
   * @param param
   */
  public getTranslatedName(observationParamType: OBSERVATION_TYPE | string): string {
    return this.obsDefService.getTranslatedName(observationParamType);
  }

  /************************ Feelings ********************************/

  /**
   * Genetate notifications for Feelings
   */
  private getAllFeelingNotifications(): INotification[] {
    const notifs = new Array<INotification>();
    this.configService.getCacheConfiguration().parameters.questionnaireParams.forEach((qParam) => {
      if (qParam.notify) {
        // take only active notification
        const mStart = moment(qParam.frequency.boundsPeriod.start);
        const mEnd = moment(qParam.frequency.boundsPeriod.end);
        const max = moment().add(NotificationsPluginService.maxDayGeneration, "days");
        if (mStart.isSameOrBefore(moment(), "day") && mEnd.isSameOrAfter(moment(), "day")) {
          // take only notification for today and after
          // set message
          const feelingMessage = " 📝 " + this.translateSvc.instant("myfeelings.fillQuestionnaire") + " " + qParam.identifier.value;

          const type = NOTIFICATION_TYPE.FEELING;
          const notif = new Notification(
            this.sysAccountService.cachedCaremateId,
            type,
            qParam.frequency.boundsPeriod.start,
            qParam.frequency.boundsPeriod.end,
            qParam.identifier.system
          );
          notif.message = feelingMessage;
          notif.reference = qParam;
          notif.color = null;
          const schedules = this.configService.getNotifsSchedules();
          const userTimings = this.configService.getUserTimings();
          const timingInstances = TimingData.getTimingInstances(qParam.frequency, userTimings, schedules, mStart, max, type);

          for (const instance of timingInstances) {
            const n = Object.assign({}, notif);
            const schedBefore = schedules[type] ? schedules[type] : 0;
            const notifTime = moment(instance.time).add(schedBefore, "minutes"); // get back the exact time
            n.time = notifTime.format();
            n.at = instance.time;
            n.trigger = { at: moment(instance.time).toDate() }; // fill trigger field for Local Notification
            n.moment = instance.moment;
            notifs.push(n);
          }
        }
      }
    });
    return notifs;
  }

  /******************* Alert observation ***************************/

  private getAlertObservationNotification(observation: IObservation, identifierAlert: string): Notification {
    const notification = new Notification(
      this.sysAccountService.cachedCaremateId,
      NOTIFICATION_TYPE.ALERT_OBSERVATION,
      moment().format(),
      "",
      observation.code.coding[0].code,
      AppConstants.NOTIF_SOURCE_ALERT,
      identifierAlert
    );
    // asign the reference with observation
    notification.reference = observation;
    notification.message = "📉 " + `${this.translateSvc.instant("notification.alert")} ${observation.code.coding[0].display}`;
    const notifAt = moment(observation.issued);
    notification.at = notifAt.format();
    // we stocked the trigger in observation.issued before
    notification.trigger = { at: notifAt.toDate() };

    return notification;
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";

@Pipe({ name: "log" })
export class LogPipe implements PipeTransform {
  transform(param1: unknown, param2?: string): void {
    FileLogger.log("LogPipe", param2 ? param2 : "", param1);
    return;
  }
}

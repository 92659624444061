import { Injectable } from "@angular/core";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { OnlineDevice } from "src/app/models/externalRessource";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { AccountService } from "../globalDataProvider/account.service";
import { StatEventService } from "../globalDataProvider/statEvent.service";
import { GoToPageService } from "../go-to-page.service";
import { InfoAppService } from "../info-app.service";
import { PopupService } from "../popup.service";

@Injectable({
  providedIn: "root",
})
export class OnlineDevicesService {
  private mode: "TEST" | "PROD";
  private org = environment.org;

  constructor(
    private apiService: ApiService,
    private popupService: PopupService,
    private goToPageService: GoToPageService,
    private infoAppService: InfoAppService,
    private statEventService: StatEventService,
    private accountService: AccountService,
    private iab: InAppBrowser
  ) {
    this.infoAppService.getCurrentMode().then((mode: string) => {
      this.mode = mode !== "PROD" ? "TEST" : "PROD";
    });
  }

  private async getMode(): Promise<string> {
    this.mode = this.mode ?? (await this.infoAppService.getCurrentMode()) !== "PROD" ? "TEST" : "PROD";
    return this.mode;
  }
  public async onlineDeviceAuthenticate(deviceType: OnlineDevice, externalRessourceRef: string): Promise<void> {
    const params = {
      mode: await this.getMode(),
      deviceType,
      externalRessourceRef,
      org: this.org,
    };
    const rep = await this.apiService.getWithPromise("/mobile/onlineDeviceAuthURL", null, params).catch((err) => {
      FileLogger.error("OnlineDevicesService", "got error while trying to get auth url", JSON.stringify(err), "none");
      return { data: "" };
    });
    const url = rep.data;
    if (url) {
      const browser = this.iab.create(url, "_system", "location=yes");
      browser.show();
    }
  }
  public async removeOnlineDevice(externalRessourceRef: string): Promise<boolean> {
    const params = {
      externalRessourceRef,
    };
    const rep = await this.apiService.deleteWithPromise("/mobile/onlineDeviceToken", params);
    await this.accountService.getFreshestData(true);
    return rep.success;
  }

  public async handleDeviceOAuthRedirect(code: string, state: string): Promise<void> {
    if (!code || code.length < 1) {
      FileLogger.error("OnlineDevicesService", "No auth code returned", null, "none");
      this.popupService.showAlert("OnlineDevicesService", "error.general");
      return;
    }
    try {
      const deviceInfos = state.split("$")[0].split("_");
      const deviceType = deviceInfos[1] as OnlineDevice;
      const externalRessourceRef = deviceInfos[2];
      // Request the server to ask the device api for a token:
      const params = {
        mode: await this.getMode(),
        deviceType,
        externalRessourceRef,
        code,
      };
      const serverResponse = await this.apiService.getWithPromise("/mobile/onlineDeviceToken", null, params).catch((err) => {
        FileLogger.error("OnlineDevicesService", "got error while trying to get device token", JSON.stringify(err), "none");
        return { data: "" };
      });
      const repType = ServerResponse.type(serverResponse);
      switch (repType) {
        case SERVER_RESPONSE_TYPE.SUCCESS: {
          await this.accountService.getFreshestData(true);
          this.statEventService.newEvent("Authenticated for online device (" + deviceType + ")");
          break;
        }
        case SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED: // invalid token
          this.goToPageService.loginPage({ error: SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED });
          break;
        case SERVER_RESPONSE_TYPE.SERVER_UNREACHABLE:
          this.popupService.showToast("error.nonetwork", 1000, "top");
          break;
        case SERVER_RESPONSE_TYPE.INVALID_DATA:
          this.popupService.showAlert("Online Devices", "error.general");
          break;
        default:
          break;
      }
    } catch (err) {
      FileLogger.error("OnlineDevicesService", "No auth code returned", err, "none");
      this.popupService.showAlert("OnlineDevicesService", "error.general");
    }
  }
}

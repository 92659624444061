import { Pipe, PipeTransform } from "@angular/core";
import { EXTERNAL_RESSOURCE_TYPE, IExternalRessource } from "../models/externalRessource";
import { ArrayHelper } from "../helpers/array-helper";

@Pipe({ name: "isObsMeasurable" })
export class IsObsMeasurablePipe implements PipeTransform {
  public transform(observationLoinc: string, externalRessources: IExternalRessource[]): boolean {
    const concat = externalRessources
      ?.map((b) => b.meta?.availableLoinc)
      .reduce((l1, l2) => l1.concat(l2), [])
      .filter(ArrayHelper.onlyUnique);
    return concat?.includes(observationLoinc);
  }
}

@Pipe({ name: "availableBleDevicesPerObs" })
export class AvailableBleDevicesPerObsPipe implements PipeTransform {
  public transform(observationLoinc: string, externalRessources: IExternalRessource[]): IExternalRessource[] {
    const bleDevices: IExternalRessource[] = externalRessources?.filter(
      (ressource) =>
        ressource.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE && ressource?.meta?.availableLoinc?.includes(observationLoinc)
    );
    return bleDevices;
  }
}
@Pipe({ name: "availableOnlineDevicesPerObs" })
export class AvailableOnlineDevicesPerObsPipe implements PipeTransform {
  public transform(observationLoinc: string, externalRessources: IExternalRessource[]): IExternalRessource[] {
    const bleDevices: IExternalRessource[] = externalRessources?.filter(
      (ressource) =>
        ressource.type === EXTERNAL_RESSOURCE_TYPE.ONLINE_HARDWARE && ressource?.meta?.availableLoinc?.includes(observationLoinc)
    );
    return bleDevices;
  }
}

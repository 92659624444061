import { Pipe, PipeTransform } from "@angular/core";
import { IPonderation, IRewardPonderation, REWARD_ACTION } from "../models/rewardDefinition";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";

@Pipe({ name: "getRewardHistoryString" })
export class GetRewardHistoryStringPipe implements PipeTransform {
  constructor(protected configService: ConfigurationService) {}

  public transform(ponderationId: string, rewardPonderation: IRewardPonderation[]): string {
    // ponderationId present like : pageName_action or pageName_onClick_onClickRefence
    const ponderationParsed = ponderationId.split("_");
    const pageName = ponderationParsed[0];
    const action = ponderationParsed[1];
    const onClickRef = action === REWARD_ACTION.onClick ? ponderationParsed[2] : undefined;
    const pagePonderation = rewardPonderation.find((rp) => rp.pageName === pageName);
    if (!onClickRef) {
      return (pagePonderation[action] as IPonderation)?.patientDescription[this.configService.getCurrentLanguage()];
    } else {
      return pagePonderation.onClick.find((pc) => pc.identifier === ponderationId)?.patientDescription[
        this.configService.getCurrentLanguage()
      ];
    }
  }
}

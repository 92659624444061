import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { BasePage } from "src/app/baseClasses/base-page";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IFingerprintData } from "src/app/models/IFingerprintData";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-fingerprint-login-pwd-modal",
  templateUrl: "./fingerprint-login-pwd-modal.component.html",
  styleUrls: ["./fingerprint-login-pwd-modal.component.scss"],
})
export class FingerprintLoginPwdModalComponent extends BasePage {
  public loginFormGroup: UntypedFormGroup;
  public isForceDevMode: boolean;
  public hasSecretDevAccess: boolean;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected modalCtrl: ModalController,
    private translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.loginFormGroup = new UntypedFormGroup({
      user: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required]),
    });

    this.infoService.getCurrentMode().then((appMode) => {
      this.hasSecretDevAccess = appMode === "DEV";
      this.isForceDevMode = appMode === "FORCE_DEV";
    });

    this.infoService.getLastLogin().then((lastLogin) => {
      if (lastLogin) {
        this.loginFormGroup.get("user").setValue(lastLogin);
      }
    });

    this.translateService.use(this.translateService.defaultLang).pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  async onLogin(): Promise<void> {
    // user/password must be set
    const user = (this.loginFormGroup.get("user").value as string).toLowerCase().trim();
    const password = this.loginFormGroup.get("password").value as string;
    if (!user || !password) {
      this.popupService.showAlert("application.title", "login.error.missUser");
      return;
    }

    const data = {
      login: user,
      password: password,
      mode: this.isForceDevMode ? "FORCE_DEV" : this.hasSecretDevAccess ? "DEV" : "PROD",
    } as IFingerprintData;

    await this.infoService.setDataAssociatedToFingerprint(data);

    this.modalCtrl.dismiss();
  }

  async onSecretAccess(): Promise<void> {
    try {
      if (this.hasSecretDevAccess) {
        // demo mode
        const confirmDemoMode = await this.popupService.showYesNo("application.title", "application.devmodeon");
        if (confirmDemoMode) {
          await this.infoService.setCurrentMode("DEV");
          this.popupService.showToast("application.demoModeToaster", 5000, "bottom");
        } else {
          await this.infoService.setCurrentMode("PROD");
          this.hasSecretDevAccess = false;
        }
      } else {
        // prod mode
        await this.infoService.setCurrentMode("PROD");
      }
    } catch (err) {
      FileLogger.error("FingerprintLoginPwdModalComponent", "onSecretAccess", err);
      this.popupService.showAlert("application.title", "error.general");
    }
  }

  public showHelp(): void {
    const msg = this.translateService.instant("help.fingerprintHelpText");
    const m = msg.replace(new RegExp("<br>", "g"), "");
    this.popupService.showToastWithCloseBtn(m, "bottom");
  }
}

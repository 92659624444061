import { Component } from "@angular/core";
import { IonTabs, ViewDidEnter, ViewDidLeave, ViewWillEnter, ViewWillLeave } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { Tools } from "../helpers/tools-helper";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";
import { InfoAppService } from "../services/info-app.service";
import { PopupService } from "../services/popup.service";

@Component({
  template: "",
})
export abstract class BasePage implements ViewWillLeave, ViewWillEnter, ViewDidEnter, ViewDidLeave {
  /**
   * This Subject is used to easily unsubscribe observables that need to be unsubscribed when leaving the view
   */
  protected onDestroy$: Subject<void>;
  /**
   * Allows to know if we are on a cordova device
   */
  public isCordova: boolean;
  /**
   * Variable to facilitate loading management. It is reset to false each time the page (re)becomes active.
   * The page/modal should make it true at the appropriate time.
   */
  public pageLoaded = false;

  /**
   * Internal variable to handle tabs correctly
   */
  private activeTab?: HTMLElement;

  constructor(
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected infoService: InfoAppService,
    protected popupService: PopupService
  ) {
    this.isCordova = this.infoService.isCordova();
    const lang = this.configService.peekData()?.settings?.globalSettings?.language;
    if (Tools.isDefined(lang)) {
      this.translateSvc.setDefaultLang(lang);
      moment.locale(lang);
    } else {
      this.configService.getCurrentLanguageWithCheck().then((language) => {
        this.translateSvc.setDefaultLang(language);
        moment.locale(language);
      });
    }
  }

  /**
   *  It is called every time the view is navigated to (regardless if initialized or not),
   *  it's a good method to load data from services. However, if your data comes back during
   *  the animation, it can start lots of DOM manipulation, which can cause some janky animations.
   *
   *  @note If this method is overridden on the page/modal, do not forget to use "super.ionViewWillEnter()"
   *  at the beginning of the method.
   */
  ionViewWillEnter(): void {
    this.pageLoaded = false;
    this.onDestroy$ = new Subject<void>();
  }

  /**
   * If you see performance problems from using ionViewWillEnter when loading data, you can do your data calls in ionViewDidEnter instead.
   *
   * @note If this method is overridden on the page/modal, do not forget to use "super.ionViewDidEnter()"
   * at the beginning of the method.
   */
  ionViewDidEnter(): void {}

  /**
   * Can be used for cleanup, like unsubscribing from observables.
   *
   * @note If this method is overridden on the page/modal, do not forget to use "super.ionViewWillLeave()"
   * at the beginning of the method.
   */
  ionViewWillLeave(): void {
    this.onDestroy$?.next();
    this.onDestroy$?.complete();
    this.propagateToActiveTab("ionViewWillLeave");
  }

  /**
   * When this event fires, you know the new page has fully transitioned in, so any logic
   * you might not normally do when the view is visible can go here.
   *
   * @note If this method is overridden on the page/modal, do not forget to use "super.ionViewDidLeave()"
   * at the beginning of the method.
   */
  ionViewDidLeave(): void {
    this.propagateToActiveTab("ionViewDidLeave");
  }

  /**
   * Internal method to handle tabs correctly.
   * It allows to dispatch the events of ionic to the tab
   */
  private propagateToActiveTab(eventName: string) {
    if (this.activeTab) {
      this.activeTab.dispatchEvent(new CustomEvent(eventName));
    }
  }

  /**
   * This method must be used in the html of the main page that handles the tabs.
   * <ion-tabs #tabs (ionTabsDidChange)="tabChange(tabs)"> ... </ion-tabs>
   * @param tabsRef
   */
  public tabChange(tabsRef: IonTabs) {
    this.activeTab = tabsRef?.outlet?.activatedView?.element;
  }
}

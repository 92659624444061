import { Account } from "src/app/helpers/account-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { IAveragedObservation } from "src/app/services/streamObservation.service";
export interface IDexcomGlucoseTrend {
  glucoseTrendText: string;
  glucoseTrendUnit: string;
  glucoseTrendRate: string;
  glucoseTrendColor: string;
}
export class Dexcom {
  /**
   * Find the component with the loinc code for "Trend" https://loinc.org/89253-9 and send back
   * data in how to display it.
   * @param i (number) number of minutes after midnight of the observations
   * @param groupedStreamedObservation (IAveragedObservation) contains 2 array, one with the components for the trend and one with the components for the glycemia
   * @param accountService
   * @returns
   */
  public static selectDexcomTrend(
    i: number,
    groupedStreamedObservation: IAveragedObservation[][],
    accountService: AccountService
  ): IDexcomGlucoseTrend {
    let iTrend = 0;
    const hours = Tools.minutesToHours(i);
    const k = groupedStreamedObservation[0].findIndex((o) => o.issued === hours);
    if (groupedStreamedObservation[0][k]?.componentCode === "89253-9") {
      iTrend = 0;
    } else if (groupedStreamedObservation[1][k]?.componentCode === "89253-9") {
      iTrend = 1;
    } else {
      return { glucoseTrendText: "", glucoseTrendUnit: "", glucoseTrendRate: "none", glucoseTrendColor: "grey" };
    }
    const trend = groupedStreamedObservation[iTrend][k];
    const glucoseTrendRate = trend.interpretation ? trend.interpretation : Dexcom.interpretDexcomTrend(trend.avg);
    const lowerGlucose = Account.getVital(accountService.cachedAccount, Account.LOINC_GLUCOSE_LOW).value ?? 70;
    const upperGlucose = Account.getVital(accountService.cachedAccount, Account.LOINC_GLUCOSE_HIGHT).value ?? 200;
    const glycemia = iTrend === 0 ? groupedStreamedObservation[1][k] : groupedStreamedObservation[0][k];
    const glucoseTrendText = glycemia ? Math.round(glycemia.avg).toString() : "";
    const glucoseTrendUnit = glycemia?.unit;
    const glucoseTrendColor = glycemia ? Dexcom.findDexcomTrendColor(glycemia.avg, lowerGlucose, upperGlucose) : "grey";
    return { glucoseTrendText, glucoseTrendUnit, glucoseTrendRate, glucoseTrendColor };
  }

  /**
   * "doubleUp" (+3 ≤ trendRate ≤ +8)
   * "singleUp" (+2 ≤ trendRate < +3)
   * "fortyFiveUp" (+1 ≤ trendRate < +2)
   * "flat" (-1 < trendRate < +1)
   * "fortyFiveDown" (-2 < trendRate ≤ -1)
   * "singleDown" (-3 < trendRate ≤ -2)
   * "doubleDown" (-8 < trendRate ≤ -3)
   * @param trendRate
   * @returns
   */
  private static interpretDexcomTrend(trendRate: number): string {
    if (trendRate <= -3) return "doubleDown";
    else if (trendRate <= -2) return "singleDown";
    else if (trendRate <= -1) return "fortyFiveDown";
    else if (trendRate < 1) return "flat";
    else if (trendRate < 2) return "fortyFiveUp";
    else if (trendRate < 3) return "singleUp";
    else if (trendRate <= 8) return "doubleUp";
    else return "none";
  }

  private static findDexcomTrendColor(glycemia: number, lower: number, upper: number): string {
    if (glycemia <= lower) return "red";
    else if (glycemia < upper) return "grey";
    else if (glycemia >= upper) return "yellow";
    else return "grey";
  }
}

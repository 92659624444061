import { Pipe, PipeTransform } from "@angular/core";
import { IObservationDefinition, IObservationDefinitionComponent } from "../helpers/observation-helper";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";

@Pipe({
  name: "shortname",
})
export class ShortnamePipe implements PipeTransform {
  private currentLang: string;
  constructor(private configService: ConfigurationService) {
    this.currentLang = configService.getCurrentLanguage();
  }
  /**
   * Get shortname of the observationDefinitionComponent if available. If not, returns the name of the component.
   */
  transform(element: IObservationDefinitionComponent | IObservationDefinition, lang?: string): string {
    // this is use to force the refresh of the translation by forcing the pipe to react when changes are not detected due to object by reference
    if (lang) {
      this.currentLang = lang;
    }

    if (element.shortnameTranslation && element.shortnameTranslation[this.currentLang]) {
      return element.shortnameTranslation[this.currentLang];
    } else if (element.nameTranslation && element.nameTranslation[this.currentLang]) {
      return element.nameTranslation[this.currentLang];
    } else {
      return "";
    }
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { DELETE_REQUEST_STATUS, IDeleteRequest } from "src/app/models/deleteRequest";
import { StaticImplements } from "src/app/models/sharedInterfaces";
import { ApiService } from "../api.service";
import { LoaderService } from "../loader.service";
import { SynchronisationService } from "../synchronisation.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

@Injectable({
  providedIn: "root",
})
export class DeleteRequestService
  extends BasicSyncService<IDeleteRequest, IDeleteRequest[]>
  implements StaticImplements<INeedRefresh, typeof DeleteRequestService>
{
  public get needRefresh(): { value: boolean } {
    return DeleteRequestService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(
    protected dataService: DataService,
    private apiService: ApiService,
    private synchronisationService: SynchronisationService,
    private loaderService: LoaderService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IDeleteRequest[]>([]);
  }
  protected initWatch(): void {
    this.data$.next([]);
  }
  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "deleteRequest_",
      entityStoreKey: "list",
      getUrl: "/deleteRequest",
      setUrl: "/deleteRequest",
      expirationDays: 10,
      encrypted: true,
    };
  }

  public peekData(): IDeleteRequest[] {
    return this.processData(super.peekData());
  }

  public async *getDataReader(): AsyncGenerator<IDeleteRequest[], IDeleteRequest[], IDeleteRequest[]> {
    try {
      const dataReader = this.dataService.readv2<IDeleteRequest, IDeleteRequest[]>(this.defaultDataParameter, false, this, true, false);
      let d: IDeleteRequest[] = [];
      for await (const data of dataReader) {
        d = this.processData(data);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("DeleteRequestService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IDeleteRequest[]) {
    try {
      let deleteRequests: IDeleteRequest[] = dataResult;
      deleteRequests = deleteRequests.filter((dr) => {
        return dr.requestStatus !== DELETE_REQUEST_STATUS.CANCELED;
      });
      return deleteRequests;
    } catch (err) {
      FileLogger.error("DeleteRequestService", "Error while processing deleteRequestservice data: ", err);
      return dataResult;
    }
  }

  public async save(body: any, withToast = true) {
    if (withToast) {
      await this.loaderService.showSavingToast(true);
    }
    const res = await this.apiService.postWithPromise(this.defaultDataParameter.setUrl, body);
    const type = ServerResponse.type(res);
    switch (type) {
      case SERVER_RESPONSE_TYPE.SUCCESS: {
        const dRequest = res.data;
        await this.synchronisationService.syncApp(true);
        this.pokeData(dRequest);
        break;
      }
      default:
        FileLogger.error("DeleteRequestService", "save in DeleteRequestService");
        return Promise.reject(ServerResponse.SERVER_ERROR);
    }
    await this.loaderService.showSavingToast(false);
    if (withToast) {
      await this.loaderService.showSavingToast(false);
    }
  }
}

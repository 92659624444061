import { OperationResult, BluetoothLE } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { InfoAppService } from "../../info-app.service";
import { MedicalBluetoothData } from "../medical-bluetooth-data";
import { MedicalBluetoothCharacteristic, MedicalBluetoothDevice, MedicalBluetoothService } from "../medical-bluetooth.service";
import { MedicalBluetoothStatus } from "../medical-bluetooth-status.service";
export interface MedicalBluetoothOximeterData {
  spO2: number;
  pulseRate: number;
  smartPoint: boolean;
}

export class MedicalBluetoothOximeter extends MedicalBluetoothData {
  public serviceType = MedicalBluetoothService.NONIN_OXIMETER;
  public descriptor = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.NONIN_OXIMETRY_MEASUREMENT,
    service: MedicalBluetoothService.NONIN_OXIMETER,
  };

  public constructor(
    device: MedicalBluetoothDevice,
    handler: Observable<string>,
    infoAppService: InfoAppService,
    bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>,
    translateService: TranslateService,
    searchPreviousData: boolean,
    medicalBluetoothStatus: MedicalBluetoothStatus
  ) {
    super(device, handler, infoAppService, bluetoothle, onDestroy$, translateService, searchPreviousData, medicalBluetoothStatus);
  }

  public readData() {
    this.standby = false;
    return this.bluetoothle.subscribe(this.descriptor).pipe(
      map((r) => {
        const data = r as unknown as OperationResult;
        if (data.status === "subscribedResult") {
          const decodedData = this.decodeData(data.value);
          if (decodedData.smartPoint) {
            this._data.next(decodedData);
          }
        }
      }),
      finalize(() => {
        this._data.complete();
      })
    );
  }

  public getData(): Subject<MedicalBluetoothOximeterData> {
    return this._data;
  }

  public getHumanReadableData(data: MedicalBluetoothOximeterData) {
    return `SpO2 ${data.spO2} - ❤️️ ${data.pulseRate}`;
  }

  private decodeData(data: string): MedicalBluetoothOximeterData {
    const bytes = this.bytesFromString(data);
    const view = new DataView(bytes.buffer);

    return {
      spO2: view.getInt8(7),
      pulseRate: view.getUint16(8),
      smartPoint: (view.getUint8(1) & 0x04) > 0,
    };
  }
}

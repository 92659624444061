import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CustomParamKey } from "src/app/models/dataParameters";
import { IStepwiseDrug } from "src/app/models/entitylink";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class StepwiseDrugService
  extends BasicSyncService<IStepwiseDrug, IStepwiseDrug[]>
  implements StaticImplements<INeedRefresh, typeof StepwiseDrugService>
{
  public get needRefresh(): { value: boolean } {
    return StepwiseDrugService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IStepwiseDrug[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "stepwiseDrug",
      entityStoreKey: "list",
      getUrl: "/stepwiseDrug",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
      customParam: {
        [CustomParamKey.noPopupIfNoDataOffline]: true,
      },
    };
  }
}

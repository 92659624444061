import { Pipe, PipeTransform } from "@angular/core";
import { FileLogger } from "../helpers/fileLogger";

@Pipe({
  name: "dateToIso",
})
export class DateToIsoPipe implements PipeTransform {
  /**
   * Convert 'dd/mm/yyyy' to 'yyyy-mm-dd'
   */
  transform(value: string): string {
    if (!value) {
      return;
    }
    const [day, month, year] = value.split("/");

    if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
      FileLogger.warn(
        "DateToIsoPipe",
        `Cannot convert date "${value}", check that input format is 'dd/mm/yyyy' Conversion might not be necessary, returning original value`
      );
      return value;
    }

    return `${year}-${month}-${day}`;
  }
}

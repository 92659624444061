import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { AppConstants } from "src/app/appConstants";
import { Tools } from "src/app/helpers/tools-helper";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";
import { last } from "rxjs/operators";
import { ResetPasswordService } from "src/app/services/modal-reset-password.service";
import { ServerResponse, SERVER_RESPONSE_TYPE } from "src/app/helpers/server-response-helper";
import Validation from "./password-match.validation";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { BasePage } from "src/app/baseClasses/base-page";
import { TranslateService } from "@ngx-translate/core";
import { FileLogger } from "src/app/helpers/fileLogger";

@Component({
  selector: "app-reset-password-modal",
  templateUrl: "./reset-password-modal.component.html",
  styleUrls: ["./reset-password-modal.component.scss"],
})
export class ResetPasswordModalComponent extends BasePage {
  public resetPasswordForm: UntypedFormGroup;
  public code: string;
  public user: string;
  public newpassword: string;
  public confirmPassword: string;

  public codeMandatory = false;
  public userMandatory = false;
  public newpasswordLength = false;
  public newpasswordDifferent = false;
  public submitted1 = false;
  public submitted2 = false;

  public step = 1;

  private lang: string;
  public passwordError: string;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected modalCtrl: ModalController,
    private resetService: ResetPasswordService,
    protected navParams: NavParams,
    private formBuilder: UntypedFormBuilder,
    protected configService: ConfigurationService,
    translateService: TranslateService
  ) {
    super(translateService, configService, infoService, popupService);
    this.user = navParams.get("user");
    this.lang = navParams.get("lang") || this.configService.getCurrentLanguage();
    this.resetPasswordForm = this.formBuilder.group(
      {
        user: [this.user, [Validators.required, Validators.email]],
        code: ["", [Validators.required]],
        newpassword: ["", [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}$/)]],
        confirmPassword: ["", [Validators.required]],
        acceptTerms: [false, Validators.requiredTrue],
      },
      {
        validators: [Validation.match("newpassword", "confirmPassword")],
      }
    );
  }

  /**
   * Close modal
   */
  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  /**
   * First step: request receive code to allow to reset password
   */
  public requestCode(): void {
    this.submitted1 = true;
    this.user = this.resetPasswordForm.get("user").value;
    // check fields validity
    if (!this.user.trim().length) {
      return;
    }
    // send request
    this.resetService
      .resetPassword(this.user)
      .pipe(last())
      .subscribe((response) => {
        const responseType = ServerResponse.type(response);

        switch (responseType) {
          case SERVER_RESPONSE_TYPE.SUCCESS:
            this.step = 2;
            return this.popupService.showAlert("application.title", "login.resetPassword.codesent");
          default:
            FileLogger.log("ResetPasswordModalComponent", "loginService.resetPassword error", response);
            return this.popupService.showAlert("application.title", "login.refused.subTitle");
        }
      });
  }

  /**
   * final step: reset password server side
   */
  public resetPassword(): void {
    this.submitted1 = true;
    this.submitted2 = true;
    this.user = this.resetPasswordForm.get("user").value;
    this.code = this.resetPasswordForm.get("code").value;
    this.newpassword = this.resetPasswordForm.get("newpassword").value;
    this.confirmPassword = this.resetPasswordForm.get("confirmPassword").value;
    // check fields validity
    this.codeMandatory = !!this.code;
    this.userMandatory = !!this.user;
    this.newpasswordLength = this.newpassword.length < AppConstants.PASSWORD_MINLENGTH;
    this.newpasswordDifferent = this.newpassword !== this.confirmPassword;

    if (!this.codeMandatory || !this.userMandatory || this.newpasswordLength || this.newpasswordDifferent) {
      return;
    }

    let passcode = this.code + this.newpassword;

    passcode = Tools.cryptItWithKey(passcode, this.code);

    // send request
    this.resetService
      .postResetPassword(this.user, passcode)
      .pipe(last())
      .subscribe((response) => {
        const responseType = ServerResponse.type(response);
        switch (responseType) {
          case SERVER_RESPONSE_TYPE.SUCCESS:
            this.modalCtrl.dismiss();
            return this.popupService.showAlert("application.title", "login.resetPassword.successfully");
          case SERVER_RESPONSE_TYPE.EXPIRED_PASSWORD:
            return this.popupService.showAlert("login.refused.title", "login.refused.expiredPassword");
          case SERVER_RESPONSE_TYPE.INVALID_PASSWORD:
            if (response.additionalData) {
              this.passwordError = `${response.additionalData}.${this.lang}`;
              return;
            }
            return;
          case SERVER_RESPONSE_TYPE.SERVER_UNREACHABLE:
            return this.popupService.showAlert("application.title", "login.refused.subTitle");
          default:
            FileLogger.log("ResetPasswordModalComponent", "loginService.postResetPassword error", response);
            return this.popupService.showAlert("application.title", "error.invalidRegistration");
        }
      });
  }

  /**
   * User already request a code, go directly to second step
   */
  public gotoStep2(): void {
    this.step = 2;
  }

  public gotoStep1(): void {
    this.step = 1;
  }
}

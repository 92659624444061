import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "mapHas" })
export class MapHasPipe implements PipeTransform {
  constructor() {}

  public transform(object: Map<any, any>, element: any) {
    return object.has(element);
  }
}

@Pipe({ name: "mapGet" })
export class MapGetPipe implements PipeTransform {
  constructor() {}
  /**
   * Map.prototype.get() as a pipe
   * @returns Returns the element associated with the specified key. If no element is associated with the specified key, undefined is returned.
   */
  public transform(object: Map<any, any>, element: any) {
    return object.get(element);
  }
}

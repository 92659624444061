import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { QuestionnaireResponse } from "src/app/helpers/questionnaireResponse";
import { ACTION_STATUS_ENTITY, SPECIFIC_USE, StaticImplements, STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";

@Injectable({
  providedIn: "root",
})
export class QuizResponseService
  extends BasicSyncService<QuestionnaireResponse, QuestionnaireResponse[]>
  implements StaticImplements<INeedRefresh, typeof QuizResponseService>
{
  public get needRefresh(): { value: boolean } {
    return QuizResponseService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(private accountService: AccountService, protected dataService: DataService) {
    super(dataService);
  }

  public peekData(includeDeleted = true): QuestionnaireResponse[] {
    return this.processData(super.peekData(), includeDeleted);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<QuestionnaireResponse[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "quizResponses_",
      entityStoreKey: "list",
      getUrl: `/specificQuestionnaireResponses?specificUse=${SPECIFIC_USE.QUIZ}`,
      setUrl: "/specificQuestionnaireResponse",
      expirationDays: 10,
      encrypted: true,
    };
  }

  public async *getDataReader(): AsyncGenerator<QuestionnaireResponse[], QuestionnaireResponse[], QuestionnaireResponse[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: QuestionnaireResponse[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("QuizResponseService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: QuestionnaireResponse[], includeDeleted: boolean) {
    try {
      let responses: QuestionnaireResponse[] = dataResult;
      if (!includeDeleted) {
        responses = responses.filter((o) => {
          return o.actionStatus !== ACTION_STATUS_ENTITY.DELETED;
        });
      }
      return responses;
    } catch (err) {
      FileLogger.error("QuizResponseService", "Error while processing QuizResponseService data: ", err);
      return dataResult;
    }
  }

  public save(qr: QuestionnaireResponse): Promise<QuestionnaireResponse> {
    const savePromise = this.dataService
      .saveInArray(qr, (entity) => entity._id === qr._id, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST,
      })
      .then((savedQr: QuestionnaireResponse) => {
        const responses = this.peekData(true);
        const i = responses.findIndex((e) => e._id === savedQr._id);
        if (i >= 0 && !savedQr.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          responses[i] = savedQr;
        } else if (i < 0 && !savedQr.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          responses.push(savedQr);
        } else if (i >= 0 && savedQr.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          responses.splice(i, 1);
        }
        this.pokeData(responses);
        return savedQr;
      });
    return savePromise;
  }
}

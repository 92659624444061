import { Injectable } from "@angular/core";
import { AppConstants } from "src/app/appConstants";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { DataParameter } from "src/app/models/dataParameters";
import { IApiResponse } from "src/app/models/iapi-response";
import { ApiService } from "../../api.service";
import { GoToPageService } from "../../go-to-page.service";
import { InfoAppService } from "../../info-app.service";
import { NetworkService } from "../../network.service";
import { PopupService } from "../../popup.service";
import { LocalStorageService } from "../../storage/local-storage.service";
import { BasicSyncService } from "./basic-sync.service";
import { RequestSenderService, RequestSenderServiceSyncStatus } from "./request-sender.service";
import { RequiredSynchroService } from "./required-synchro.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(
    private infoService: InfoAppService,
    private networkService: NetworkService,
    private requestSenderService: RequestSenderService,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private requiredSynchroService: RequiredSynchroService,
    private popupService: PopupService,
    private goToPageService: GoToPageService, // TODO private events: Events // private fileHelper: FileHelper
    private infoAppService: InfoAppService
  ) {}

  /**
   * Update entity and synchronize local array of entity
   *
   * @param data
   * @param where function to find the entity in local array of entity type
   * @param params
   */
  public async saveInArray<E extends object>(data: E, where: (entity: E) => boolean, params: DataParameter): Promise<E> {
    const queueResponse = await this.requestSenderService.queue(
      params,
      JSON.stringify(data),
      !this.infoAppService.isCordova() ? true : false
    );

    // if we are in portal mode, being offline generate an error
    if (!this.infoAppService.isCordova() && queueResponse === RequestSenderServiceSyncStatus.offline) {
      throw ServerResponse.SERVER_UNREACHABLE;
    }
    const useLocalStorage = this.useLocalStorage() && params.entityPrefix && params.entityStoreKey;
    if (useLocalStorage && data) {
      const dataReader = this.readv2<E, E[]>(params, true);
      const iterator = await dataReader.next();
      const dataArray = iterator.value;
      if (Tools.isDefined(dataArray) && Array.isArray(dataArray) && dataArray.length > 0) {
        const toUpdateIndex = dataArray.findIndex(where);
        if (toUpdateIndex === -1) {
          dataArray.push(data);
        } else {
          dataArray[toUpdateIndex] = data;
        }
        await this.saveInStorage(params, dataArray);
        return data;
      } else {
        // Data array empty, create it
        await this.saveInStorage(params, [data]);
        return data;
      }
    } else {
      return data;
    }
  }

  /**
   * Update entities and synchronize local array of entity
   *
   * @param updateDataArray an array of data to update
   * @param where function to find the entity in local array of entity type
   * @param params
   */
  public async saveArrayInArray<E extends object, T extends Array<E>>(
    updateDataArray: T,
    where: (entity: E, updateEntity: E) => boolean,
    params: DataParameter
  ): Promise<T> {
    await this.requestSenderService.queue(params, JSON.stringify(updateDataArray));

    const useLocalStorage = this.useLocalStorage() && params.entityPrefix && params.entityStoreKey;
    if (useLocalStorage && updateDataArray) {
      const dataReader = this.readv2<E, T>(params, true);
      const iterator = await dataReader.next();
      const dataArray = iterator.value;
      if (dataArray && dataArray.length > 0) {
        updateDataArray.forEach((data) => {
          const toUpdateIndex = dataArray.findIndex((entity) => where(entity, data));
          if (toUpdateIndex === -1) {
            dataArray.push(data);
          } else {
            dataArray[toUpdateIndex] = data;
          }
        });
        await this.saveInStorage(params, dataArray);
        return dataArray;
      } else {
        // Data array empty, create it
        await this.saveInStorage(params, updateDataArray);
        return updateDataArray;
      }
    } else {
      return updateDataArray;
    }
  }

  /**
   * Update entity and synchronize local data
   *
   * @param data
   * @param params
   */
  public async save<T>(data: T, params: DataParameter): Promise<T> {
    await this.requestSenderService.queue(params, JSON.stringify(data));

    const useLocalStorage = this.useLocalStorage() && params.entityPrefix && params.entityStoreKey;
    if (useLocalStorage && data) {
      await this.saveInStorage(params, data);
      return data;
    }
    FileLogger.warn("DataService", "saveWithPromise: Data could not be stored", data);
    return data;
  }

  /**
   * Update entity, synchronize local data and wait the route response
   *
   * @param data
   * @param params
   */
  public async saveWaitResponse<T>(data: T, params: DataParameter): Promise<{ data: T; statusSynchro: RequestSenderServiceSyncStatus }> {
    let statusSynchro: RequestSenderServiceSyncStatus;
    try {
      statusSynchro = await this.requestSenderService.queue(params, JSON.stringify(data), true);

      if (statusSynchro === RequestSenderServiceSyncStatus.success) {
        const useLocalStorage = this.useLocalStorage() && params.entityPrefix && params.entityStoreKey;
        if (useLocalStorage && data) {
          await this.saveInStorage(params, data);
          return { data, statusSynchro };
        }
        FileLogger.warn("DataService", "saveWithPromise: Data could not be stored", data);
      }
    } catch (error) {
      statusSynchro = RequestSenderServiceSyncStatus.error;
    }
    return { data, statusSynchro };
  }

  /**
   * Delete entity and synchronize local array of entity
   *
   * @param data
   * @param where function to find the entity in local array of entity type
   * @param params
   */
  public async removeFromArray<T extends object>(data: T, where: (entity: T) => boolean, params: DataParameter): Promise<boolean> {
    const queueResponse = await this.requestSenderService.queue(
      params,
      JSON.stringify(data),
      !this.infoAppService.isCordova() ? true : false
    );

    // if we are in portal mode, being offline generate an error
    if (!this.infoAppService.isCordova() && queueResponse === RequestSenderServiceSyncStatus.offline) {
      throw ServerResponse.SERVER_UNREACHABLE;
    }

    const useLocalStorage = this.useLocalStorage() && params.entityPrefix && params.entityStoreKey;
    if (useLocalStorage && data) {
      const dataReader = this.readv2<T, T[]>(params, true);
      const iterator = await dataReader.next();
      const dataArray = iterator.value;
      if (dataArray && dataArray.length > 0) {
        const toUpdateIndex = dataArray.findIndex(where);
        if (toUpdateIndex !== -1) {
          dataArray.splice(toUpdateIndex, 1);
          await this.saveInStorage(params, dataArray);
          return true;
        }
        return false;
      }
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * @param params the data request parameters
   * @param onlyLocal whether we only want the local data
   * @param service (optional) the service that is making this request
   * @param forceApiRequest whether we want to force the notifications generations (regardless of the data need for synchro)
   * @param dataIsArray whether the data we want to read is an array
   * @param saveInStorage whether we want the data saved in storage
   */
  public async *readv2<E extends object, T extends Array<E> | E>(
    params: DataParameter,
    onlyLocal = false,
    service: BasicSyncService<E, T> = null,
    forceApiRequest = false,
    dataIsArray = true,
    saveInStorage = true
  ): AsyncGenerator<T, T, T> {
    const useLocalStorage = this.useLocalStorage() && params.entityPrefix && params.entityStoreKey;
    if (!this.networkService.isCurrentOnline()) {
      onlyLocal = true;
    }
    let needOnline = false;
    let localData: T = dataIsArray ? ([] as T) : (null as T);
    if (service && service.peekData() && (!dataIsArray || (service.peekData() as E[]).length > 0)) {
      localData = service.peekData();
      yield service.peekData();
      needOnline = await this.needOnlineData(params, onlyLocal, service, forceApiRequest);
      if (!needOnline) return service.peekData() as Awaited<T>; // If we only need local data, we stop here
    } else if (useLocalStorage) {
      const storageKey = params.entityPrefix + params.entityStoreKey;
      // Data could be null, undefined or empty string
      const data = await this.localStorageService.getData(storageKey, params.encrypted, params.expirationDays).catch((_err) => {
        // if we do not find the data in local storage, we send back
        // a default 'empty' value
        needOnline = true;
        if (service) {
          service.needRefresh.value = true;
        }
        return dataIsArray ? '{"data": []}' : '{"data": null }';
      });
      const parsedData = data ? JSON.parse(data) : '{"data": null }';
      localData = parsedData.data;
      // If the local data is empty, we try to get the online if possible:
      if (!onlyLocal && (!localData || (dataIsArray && (localData as E[]).length < 1))) {
        needOnline = true;
      }
      // If not possible, we send back the empty local data
      else {
        service?.pokeData(parsedData.data);
        yield parsedData.data;
      }
      needOnline = needOnline ? true : await this.needOnlineData(params, onlyLocal, service, forceApiRequest);
      if (!needOnline) return parsedData.data; // If we only need local data, we stop here
    } else if (!needOnline && this.networkService.isCurrentOnline()) {
      needOnline = true;
    } else if (!this.networkService.isCurrentOnline()) {
      FileLogger.warn("DataService", "readv2, no local data and no access to online data");
      yield localData;
      return localData as Awaited<T>;
    }

    if (needOnline) {
      const serverResponse: IApiResponse = await this.apiService.getWithPromise(params.getUrl).catch((err) => {
        FileLogger.error("DataService", "read: Error while trying to get data from server: ", err);
        return { success: false, message: "", data: null };
      });
      const repType = ServerResponse.type(serverResponse);
      switch (repType) {
        case SERVER_RESPONSE_TYPE.SUCCESS: {
          const data = serverResponse.data as T;
          if (saveInStorage && useLocalStorage) {
            this.saveInStorage(params, data);
          }
          // If it's a service request, it means the service got potentially new online data
          // It means we need to update the notifications
          // and we need to tell we just synchronized it
          if (service) {
            service.needNotifGeneration = true;
            service.needRefresh.value = false;
            service.pokeData(data);
            if (useLocalStorage) {
              this.requiredSynchroService.updateRequestDate(service).catch((err) => {
                FileLogger.error("DataService", "read: error while trying to update synchro request date", err);
              });
            }
          }
          yield data;
          return data as Awaited<T>;
        }
        case SERVER_RESPONSE_TYPE.ACCESS_DENIED: {
          const emptyData = dataIsArray ? ([] as T) : (null as T);
          if (saveInStorage && useLocalStorage) {
            this.saveInStorage(params, emptyData);
          }
          if (service) {
            service.needNotifGeneration = true;
            service.needRefresh.value = false;
            service.pokeData(emptyData);
          }
          yield emptyData;
          return emptyData as Awaited<T>;
        }
        case SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED: // invalid token
          this.goToPageService.loginPage({ error: SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED });
          return;
        case SERVER_RESPONSE_TYPE.SERVER_UNREACHABLE:
          this.popupService.showToast("error.nonetwork", 1000, "top");
          break;
        default:
          break;
      }
      service?.pokeData(localData);
      yield localData;
      return localData as Awaited<T>;
    }
  }

  private async needOnlineData<E extends object, T extends Array<E> | E>(
    params: DataParameter,
    onlyLocal: boolean,
    service: BasicSyncService<E, T>,
    forceApiRequest: boolean
  ) {
    if (onlyLocal) {
      return false;
    }
    let needSynchro = true;
    if (service) {
      needSynchro =
        service.needRefresh.value ??
        (await this.requiredSynchroService.needSynchroAndRunIfNecessary(service).catch((_err) => {
          FileLogger.error("DataService", "error while trying to determine if synchro is needed");
          return true; // in doubt, let's try to synchro
        }));
      service.needRefresh.value = needSynchro;
      if (service.needRefresh.value) {
        for (const s of service.getDependentServicesRefresh()) {
          s.value = true;
        }
      }
    }
    // If there's no need to refresh a service, but we were asked for a forced api request
    // (or if we are not dealing with a service). We force the synchronization:
    if (!service || (forceApiRequest && !needSynchro)) {
      await this.requestSenderService.sync(params.entityPrefix);
    }

    if (!service || forceApiRequest || service.needRefresh.value) {
      return true;
    }
    return false;
  }

  /**
   * Save the new data in local storage
   * @param params the data parameters
   * @param data  the data
   */
  private async saveInStorage(params: DataParameter, data: any) {
    return await this.localStorageService
      .storeEntity(params.entityPrefix + params.entityStoreKey, data, params.encrypted)
      .then(() => {
        return data;
      })
      .catch((err) => {
        FileLogger.error("DataService", "saveInStorage", err);
        return data;
      });
  }

  public isStored(entityStorePrefix: string, entityStoreKey: string): Promise<boolean> {
    return this.localStorageService.isStored(entityStorePrefix + entityStoreKey);
  }

  private useLocalStorage(): boolean {
    return this.infoService.isCordova() || AppConstants.LOCAL_DEV_MODE;
  }
}

import { Component, Input, ViewChild } from "@angular/core";
import { Platform } from "@ionic/angular";
import { ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BaseComponent } from "src/app/baseClasses/base-component";
import { IChartColors } from "src/app/helpers/chart-helper";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";
import { DataChart } from "../observation/observation.component";

@Component({
  selector: "app-observation-graphs",
  templateUrl: "./observation-graphs.component.html",
  styleUrls: ["./observation-graphs.component.scss"],
})
export class ObservationGraphsComponent extends BaseComponent {
  @Input() chartType: string;
  @Input() chartData: DataChart[];
  @Input() chartLabels: Array<string>;
  @Input() chartOptions: ChartOptions;
  @Input() chartColors: IChartColors;
  @Input() scrollable: boolean;
  @Input() defaultLabel: string;

  @ViewChild("baseChart") chart: BaseChartDirective;

  public isDesktop: boolean;

  constructor(protected infoService: InfoAppService, protected popupService: PopupService, protected platform: Platform) {
    super(infoService, popupService);
    this.isDesktop = this.platform.is("desktop");
  }
}

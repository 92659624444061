import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AutoCompleteService } from "ionic4-auto-complete";

@Injectable({
  providedIn: "root",
})
export class AppointmentTypeAutoCompleteServiceService implements AutoCompleteService {
  public labelAttribute = "officialName";
  private options: string[] = [
    "diabet",
    "general",
    "podiatrist",
    "dietician",
    "cardiologist",
    "dentist",
    "ophthalmologist",
    "nephrologist",
    "pedicure",
    "pharmacist",
  ];

  // formValueAttribute?: any;

  constructor(private translateService: TranslateService) {}

  /**
   *
   * @param keyword
   */
  /* Notices : in general, it can return 
      - an Observable that produces an array
      - a Subject (like an Observable)
      - a Promise that provides an array
      - directly an array of values
  */
  public getResults(keyword: string): Array<string> | boolean {
    if (keyword?.length === 0) {
      return false; // false allows to the plugin do not print the "suggestion"
    }
    const result = this.options
      .map((name) => this.translateService.instant(`agenda.type.${name}`))
      .filter((item) => item.toLowerCase().startsWith(keyword.toLowerCase()));
    return result.length === 0 ? [] : result;
  }
  /*
  getItemLabel?(item: any) {
    throw new Error('Method not implemented.');
  }*/
}

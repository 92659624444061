import { Pipe, PipeTransform } from "@angular/core";
import { FHIRHelper } from "../helpers/FHIR-helper";
import { IRewardDefinition } from "../models/rewardDefinition";
import { IRewardScore } from "../models/rewardScore";

@Pipe({ name: "getTargetPercent" })
export class RewardScorePipe implements PipeTransform {
  constructor() {}

  public transform(score: IRewardScore, rewardsDefs: IRewardDefinition[]): number {
    const targetDef = rewardsDefs?.find((d) => FHIRHelper.getCaremateIdentifier(d)?.value === score.rewardDefinitionReference.reference);
    return targetDef?.rewardScore ? (+score?.currentScore / +targetDef.rewardScore) * 100 : 0;
  }
}

@Pipe({ name: "getTargetValue" })
export class GetTargetValuePipe implements PipeTransform {
  constructor() {}

  public transform(score: IRewardScore, rewardsDefs: IRewardDefinition[]): string {
    const targetDef = rewardsDefs?.find((d) => FHIRHelper.getCaremateIdentifier(d)?.value === score.rewardDefinitionReference.reference);
    return `${score?.currentScore}/${targetDef.rewardScore}`;
  }
}

@Pipe({ name: "getTargetName" })
export class GetTargetNamePipe implements PipeTransform {
  constructor() {}

  public transform(reference: string, rewardsDefs: IRewardDefinition[], lang: string): string {
    const targetDef = rewardsDefs?.find((d) => FHIRHelper.getCaremateIdentifier(d)?.value === reference);
    return targetDef?.rewardDisplayName?.[lang] || "-";
  }
}

@Pipe({ name: "getRewardScorePercent" })
export class GetRewardScorePercentPipe implements PipeTransform {
  constructor() {}

  public transform(rewardScore: number, currentScore: number): number {
    return rewardScore ? (+currentScore / +rewardScore) * 100 : 0;
  }
}

@Pipe({ name: "getRewardScoreValue" })
export class GetRewardScoreValuePipe implements PipeTransform {
  constructor() {}

  public transform(rewardScore: number, currentScore: number): string {
    return `${currentScore}/${rewardScore}`;
  }
}

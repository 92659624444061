import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DataToShowPipe, GetEffectiveTimingPipe } from "../bluetooth-multiple-obs/bluetooth-multiple-obs.page";
import { GetRemainingDosesMaxValuePipe } from "../components/modals/drug-stock-modal/drug-stock-modal.component";
import { IsAnswerNullPipe } from "../components/observation/observation.component";
import { IsNewMsgPipe } from "../mymessages/mymessages.page";
import { HasBeenRemindedPipe, LoincAndValueMeaningPipe, TranslateAlertNamePipe } from "../myobservations/tabs/alerts/alerts.page";
import { ActivatedFeaturesPipe } from "../pipes/activated-features";
import { ActivityColorPipe } from "../pipes/activity-color.pipe";
import { ActivityNamePipe } from "../pipes/activity-name.pipe";
import { GetHardwareByCategoryPipe, IsActiveHardwareBondedPipe, LinkedDrugExternalRessourcePipe } from "../pipes/bluetooth-devices.pipe";
import { ComponentDefinitionPipe } from "../pipes/component-definition.pipe";
import { CustomTranslatePipe } from "../pipes/custom-translate.pipe";
import { DateToIsoPipe } from "../pipes/date-to-iso.pipe";
import {
  DisplayQuantityPipe,
  GetQuantityPipe,
  HasQuantityPipe,
  LastTreatmentDayPipe,
  RemainingTreatmentDaysPipe,
} from "../pipes/drug-quantity.pipe";
import { AvailableBleDevicesPerObsPipe, AvailableOnlineDevicesPerObsPipe, IsObsMeasurablePipe } from "../pipes/external-ressources.pipe";
import { FilterByKeyValuePipe } from "../pipes/filter-by-key-value.pipe";
import { GetComSenderPipe } from "../pipes/getComSender.pipe";
import { GetTranslationPipe } from "../pipes/getTranslation.pipe";
import { IncludesPipe } from "../pipes/includes.pipe";
import { IsValidDatePipe } from "../pipes/is-valid-date.pipe";
import { IsYoutubePipe } from "../pipes/is-youtube.pipe";
import { MapGetPipe, MapHasPipe } from "../pipes/keyValueMap.pipe";
import { GetFirstImagePipe } from "../pipes/knowledge-media.pipe";
import { LogPipe } from "../pipes/log.pipe";
import { MonthNamePipe } from "../pipes/month-name.pipe";
import { ObsValueOrMeaningPipe } from "../pipes/obs-value-or-meaning.pipe";
import {
  GetLabelFromCodePipe,
  GetLabelRangeFromCompDefListPipe,
  GetLabelRangeFromCompDefPipe,
  GetLabelRangeFromValuePipe,
  GetObsComponentPipe,
  GetRangePipe,
  IsBluetoothDisabledPipe,
  IsHiddenPipe,
} from "../pipes/observation-component.pipe";
import { ProductDescriptionPipe } from "../pipes/product-description.pipe";
import { GetRangeDisplayPipe, GetValueSetChoicesPipe, GetValueTxtPipe, QuestGetValueSetChoicesPipe } from "../pipes/questionnaire.pipe";
import { RangeSmileyPipe } from "../pipes/range-smiley.pipe";
import { GetRewardDefDescriptionPipe, GetRewardDefNamePipe } from "../pipes/reward-definition.pipe";
import { GetRewardHistoryStringPipe } from "../pipes/reward-history.pipe";
import {
  GetRewardScorePercentPipe,
  GetRewardScoreValuePipe,
  GetTargetNamePipe,
  GetTargetValuePipe,
  RewardScorePipe,
} from "../pipes/reward-score.pipe";
import { SafePipe } from "../pipes/safe.pipe";
import { GetScheduledFrequencyDescriptionPipe } from "../pipes/scheduled-frequency-description.pipe";
import { ShortnamePipe } from "../pipes/shortname.pipe";
import { GetClassesForStepwisePipe, IsLastOrFirstCellOfStepwisePipe, IsStepwiseSelectedPipe } from "../pipes/stepwise-drug.pipe";
import { TranslateDatePipe } from "../pipes/translate-date.pipe";
import { TranslateObservationPipe } from "../pipes/translate-observation.pipe";
import { TruncateFileNamePipe } from "../pipes/truncate-file-name.pipe";

@NgModule({
  imports: [TranslateModule],
  declarations: [
    CustomTranslatePipe,
    IsAnswerNullPipe,
    GetTranslationPipe,
    TranslateObservationPipe,
    ActivatedFeaturesPipe,
    MapHasPipe,
    MapGetPipe,
    LogPipe,
    GetComSenderPipe,
    GetFirstImagePipe,
    TruncateFileNamePipe,
    ActivityNamePipe,
    ProductDescriptionPipe,
    ActivityColorPipe,
    RewardScorePipe,
    GetTargetValuePipe,
    GetTargetNamePipe,
    GetScheduledFrequencyDescriptionPipe,
    IsValidDatePipe,
    HasQuantityPipe,
    GetQuantityPipe,
    DisplayQuantityPipe,
    RemainingTreatmentDaysPipe,
    LastTreatmentDayPipe,
    TranslateDatePipe,
    SafePipe,
    IsYoutubePipe,
    GetObsComponentPipe,
    GetLabelFromCodePipe,
    IsHiddenPipe,
    GetLabelRangeFromCompDefPipe,
    RangeSmileyPipe,
    GetLabelRangeFromValuePipe,
    GetRangePipe,
    GetLabelRangeFromCompDefListPipe,
    IncludesPipe,
    GetValueSetChoicesPipe,
    GetValueTxtPipe,
    GetRangeDisplayPipe,
    QuestGetValueSetChoicesPipe,
    GetHardwareByCategoryPipe,
    IsActiveHardwareBondedPipe,
    LinkedDrugExternalRessourcePipe,
    IsNewMsgPipe,
    MonthNamePipe,
    GetRewardHistoryStringPipe,
    GetRewardDefNamePipe,
    GetRewardDefDescriptionPipe,
    GetRewardScorePercentPipe,
    GetRewardScoreValuePipe,
    TranslateAlertNamePipe,
    LoincAndValueMeaningPipe,
    HasBeenRemindedPipe,
    DateToIsoPipe,
    IsBluetoothDisabledPipe,
    GetEffectiveTimingPipe,
    DataToShowPipe,
    IsLastOrFirstCellOfStepwisePipe,
    GetClassesForStepwisePipe,
    IsStepwiseSelectedPipe,
    IsObsMeasurablePipe,
    AvailableBleDevicesPerObsPipe,
    AvailableOnlineDevicesPerObsPipe,
    ObsValueOrMeaningPipe,
    GetObsComponentPipe,
    ShortnamePipe,
    ComponentDefinitionPipe,
    FilterByKeyValuePipe,
    GetRemainingDosesMaxValuePipe,
  ],
  providers: [DateToIsoPipe, IsStepwiseSelectedPipe], // add pipe to providers to use them inside component.ts e.g. this.myPipe.transform(value)
  exports: [
    CustomTranslatePipe,
    IsAnswerNullPipe,
    GetTranslationPipe,
    TranslateObservationPipe,
    ActivatedFeaturesPipe,
    MapHasPipe,
    MapGetPipe,
    LogPipe,
    GetComSenderPipe,
    GetFirstImagePipe,
    TruncateFileNamePipe,
    ActivityNamePipe,
    ProductDescriptionPipe,
    ActivityColorPipe,
    RewardScorePipe,
    GetTargetValuePipe,
    GetTargetNamePipe,
    GetScheduledFrequencyDescriptionPipe,
    IsValidDatePipe,
    HasQuantityPipe,
    GetQuantityPipe,
    DisplayQuantityPipe,
    RemainingTreatmentDaysPipe,
    LastTreatmentDayPipe,
    TranslateDatePipe,
    SafePipe,
    IsYoutubePipe,
    GetObsComponentPipe,
    GetLabelFromCodePipe,
    IsHiddenPipe,
    GetLabelRangeFromCompDefPipe,
    RangeSmileyPipe,
    GetLabelRangeFromValuePipe,
    GetRangePipe,
    GetLabelRangeFromCompDefListPipe,
    IncludesPipe,
    GetValueSetChoicesPipe,
    GetValueTxtPipe,
    GetRangeDisplayPipe,
    QuestGetValueSetChoicesPipe,
    GetHardwareByCategoryPipe,
    IsActiveHardwareBondedPipe,
    LinkedDrugExternalRessourcePipe,
    IsNewMsgPipe,
    MonthNamePipe,
    GetRewardHistoryStringPipe,
    GetRewardDefNamePipe,
    GetRewardDefDescriptionPipe,
    GetRewardScorePercentPipe,
    GetRewardScoreValuePipe,
    TranslateAlertNamePipe,
    LoincAndValueMeaningPipe,
    HasBeenRemindedPipe,
    TranslateModule,
    DateToIsoPipe,
    IsBluetoothDisabledPipe,
    GetEffectiveTimingPipe,
    DataToShowPipe,
    IsLastOrFirstCellOfStepwisePipe,
    GetClassesForStepwisePipe,
    IsStepwiseSelectedPipe,
    IsObsMeasurablePipe,
    AvailableBleDevicesPerObsPipe,
    AvailableOnlineDevicesPerObsPipe,
    ObsValueOrMeaningPipe,
    GetObsComponentPipe,
    ShortnamePipe,
    ComponentDefinitionPipe,
    FilterByKeyValuePipe,
    GetRemainingDosesMaxValuePipe,
  ],
})
export class PipesModule {}

export interface IChartColors {
  backgroundColor: string;
  borderColor: string;
  pointBackgroundColor: string;
  pointBorderColor: string;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
}
export class ChartHelper {
  public static chartColors: IChartColors[] = [
    {
      // dark blue
      backgroundColor: "rgba(0,95,179,0.2)",
      borderColor: "rgba(0,95,179,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // orange
      backgroundColor: "rgba(250,151,0,0.2)",
      borderColor: "rgba(250,151,0,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // green
      backgroundColor: "rgba(75, 192, 192,0.2)",
      borderColor: "rgba(75, 192, 192,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    /* {   //  grey
            backgroundColor: 'rgba(95,100,95,0.2)',
            borderColor: 'rgba(95,100,95,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },*/
    {
      // yellow
      backgroundColor: "rgba(245, 240, 30,0.2)",
      borderColor: "rgba(245, 240, 30,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // light blue
      backgroundColor: "rgba(54, 162, 235,0.2)",
      borderColor: "rgba(54, 162, 235,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // red
      backgroundColor: "rgba(255, 99, 132,0.2)",
      borderColor: "rgba(255, 99, 132,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // purple
      backgroundColor: "rgba(205,85,230,0.2)",
      borderColor: "rgba(205,85,230,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
  ];
}

import { Component } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { last } from "rxjs/operators";
import { BasePage } from "src/app/baseClasses/base-page";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { IConfiguration } from "src/app/models/configuration";
import { ACTION_STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { ITranslation } from "src/app/models/translation";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { I18nService } from "src/app/services/globalDataProvider/i18n.service";
import { LanguagesService } from "src/app/services/globalDataProvider/languagesService";
import { RewardDefinitionsService } from "src/app/services/globalDataProvider/reward-definitions.service";
import { GoToPageService } from "src/app/services/go-to-page.service";
import { HelpService } from "src/app/services/help.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoaderService } from "src/app/services/loader.service";
import { NetworkService } from "src/app/services/network.service";
import { NotificationsEventsService } from "src/app/services/notificationsService/notifications-events.service";
import { PopupService } from "src/app/services/popup.service";
import { SynchronisationService, SynchronisationServiceStatus } from "src/app/services/synchronisation.service";

@Component({
  selector: "app-settings-modal",
  templateUrl: "./settings-modal.component.html",
  styleUrls: ["./settings-modal.component.scss"],
})
export class SettingsModalComponent extends BasePage {
  public configuration: IConfiguration;
  public activeToast = false;
  public rewardIsActive = false;
  public language = "fr";
  private originalLanguage = "fr";
  public languages: ITranslation[];
  public downloadedTranslations: ITranslation[] = [];
  public notDownloadedTranslations: ITranslation[] = [];
  public langToDownload: string;
  public langsToDelete: string[] = [];
  public isOnline = false;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    public configService: ConfigurationService,
    private rewardDefinitionService: RewardDefinitionsService,
    protected popover: PopoverController,
    protected loaderService: LoaderService,
    protected translateSvc: TranslateService,
    protected goToPageService: GoToPageService,
    private modalController: ModalController,
    private helpService: HelpService,
    private notificationsEventsService: NotificationsEventsService,
    protected synchronisationService: SynchronisationService,
    protected languagesService: LanguagesService,
    private i18nService: I18nService,
    private networkService: NetworkService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.configuration = this.configService.getCacheConfiguration();
    this.activeToast = this.configService.showToast;
    // only show reward options if definitions are available for careplan
    this.rewardIsActive = this.rewardDefinitionService.peekData().length > 0;
    if (!this.configuration) {
      this.configService.getFreshestData().then((c) => {
        this.configuration = c;
      });
    }
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    Promise.all([this.languagesService.getFreshestData(), this.i18nService.getStoredTranslationsLangsList(), Tools.wait(1000)]).then(
      (l) => {
        this.languages = l[0];
        this.language = this.configService.getCurrentLanguage();
        const downloadedTranslKeys = l[1];
        this.downloadedTranslations = this.languages.filter((lng) => downloadedTranslKeys.includes(lng.term));
        this.notDownloadedTranslations = this.languages.filter((lng) => !downloadedTranslKeys.includes(lng.term));
        this.originalLanguage = this.language;
        this.isOnline = this.networkService.isCurrentOnline();
        this.pageLoaded = true;
      }
    );
  }

  public deleteLang(l: string, idx: number): void {
    this.langsToDelete.push(l);
    this.downloadedTranslations.splice(idx, 1);
  }

  /**
   *  cancel modal
   */
  public dismiss(validate?: boolean): Promise<boolean> {
    return this.modalController.dismiss(validate);
  }

  /**
   * Reset help page
   */
  public async onResetHelp(): Promise<void> {
    await this.helpService.resetHelpSeen();
    this.goToPageService.homePage();
    this.dismiss(false);
  }

  /**
   * save Registration data
   */
  public async save(): Promise<void> {
    // set language
    if (this.langToDownload) {
      this.isOnline = this.networkService.isCurrentOnline();
      if (this.isOnline) {
        this.language = this.langToDownload;
      } else {
        this.langToDownload = undefined;
        this.popupService.showToast("error.nonetwork", 1000, "top");
        return;
      }
    }
    if (!this.configuration) {
      FileLogger.error("SettingsModalComponent", "No configuration", null, "none");
      return;
    }

    const configuration = Tools.deepCopy(this.configuration);

    configuration.settings.globalSettings.language = this.language;
    // set show toast param
    configuration.settings.globalSettings.showRewardToast = this.activeToast;
    // save modifications
    await this.loaderService.showLoading(true);
    // save session check
    configuration.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    // save configuration
    this.configService.save(configuration).subscribe(
      async () => {
        this.configuration = this.configService.getCacheConfiguration();
        // update language dynamically
        if (this.originalLanguage !== this.language) {
          try {
            const success = await this.configService.setCurrentLanguage(this.language);
            if (success) {
              moment.locale(this.language);
              this.translateSvc.setDefaultLang(this.language);
              await this.translateSvc.use(this.language).pipe(last()).toPromise();
              await this.i18nService.refreshTranslations(this.language);
              await this.saveRemoveTranslations();
              this.synchronisationService.syncApp(true).then((synchroStatus: SynchronisationServiceStatus) => {
                if (synchroStatus !== SynchronisationServiceStatus.success) {
                  // No synchronization, so we can only re-gen the notifications
                  // Better than nothing.
                  this.notificationsEventsService.generateAll();
                }
              });
              await this.dismiss(true);
            } else {
              this.popupService.showToastWithCloseBtn("Config not loaded yet, try again", "middle");
            }
          } catch (err) {
            FileLogger.warn("SettingsModalComponent", "save() -> ", err);
          }
        } else {
          await this.saveRemoveTranslations();
          await this.dismiss(false);
        }
        await this.loaderService.showLoading(false);
      },
      async (err) => {
        FileLogger.error("SettingsModalComponent", "save", err, "none");
        await this.loaderService.showLoading(false);
      }
    );
  }

  private async saveRemoveTranslations() {
    if (this.langsToDelete.length > 0) {
      try {
        await this.i18nService.removeTranslationsFromStorage(this.langsToDelete);
      } catch (err) {
        FileLogger.error("SettingsModalComponent", "Could not delete translations: ", this.langsToDelete);
      }
    }
  }
}

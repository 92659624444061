import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { IApiResponse } from "../models/iapi-response";

@Injectable({
  providedIn: "root",
})
export class ArchiveRequestService {
  constructor(private apiService: ApiService) {}

  public archiveRequest(mailAddress?: string): Promise<IApiResponse> {
    return this.apiService.postWithPromise("/archiveRequest", { mailAddress });
  }
}

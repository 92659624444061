import { Component, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { IonSelect, MenuController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { last, takeLast } from "rxjs/operators";
import { LanguagesService } from "src/app/services/globalDataProvider/languagesService";
import { environment } from "src/environments/environment";
import { E2E_ID_LOGIN } from "test/helpers/selectorIdHelper";
import { BasePage } from "../baseClasses/base-page";
import { FileLogger } from "../helpers/fileLogger";
import { SERVER_RESPONSE_TYPE } from "../helpers/server-response-helper";
import { GetParametersPageService } from "../services/get-parameters-page.service";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";
import { StatEventService } from "../services/globalDataProvider/statEvent.service";
import { GoToPageService } from "../services/go-to-page.service";
import { InfoAppService } from "../services/info-app.service";
import { LOGIN_TYPE, LoginService } from "../services/login.service";
import { ModalService } from "../services/modal.service";
import { NetworkService } from "../services/network.service";
import { PopupService } from "../services/popup.service";
import { InfoOrganization } from "../models/sharedInterfaces";
import { ComunicareInfoService } from "../services/globalDataProvider/comunicare-info.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage extends BasePage {
  @ViewChild("langSelector") langSelector: IonSelect;

  public static onLoginPage = false;

  public language = this.configService.defaultLang;
  public isForceDevMode = false;
  public loginFormGroup: UntypedFormGroup;
  public hasSecretDevAccess = false;
  private forceDevClickCounter = 0;
  public version: string;
  public errServer: any = null;
  public isLoggingIn = false;
  public activeFingerprint = false;
  public env;
  public availableFeatures: any;
  public E2E_ID_LOGIN = E2E_ID_LOGIN;
  public langOptions: { value: string; display: string }[];
  public comunicareInfo: InfoOrganization;

  constructor(
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected route: ActivatedRoute,
    protected infoAppVersion: InfoAppService,
    protected popupService: PopupService,
    protected networkService: NetworkService,
    protected loginService: LoginService,
    protected goToPageService: GoToPageService,
    protected modalService: ModalService,
    protected infoAppService: InfoAppService,
    protected getParamsPage: GetParametersPageService,
    protected menuCtrl: MenuController,
    protected languagesService: LanguagesService,
    private statEventService: StatEventService,
    private comunicareInfoService: ComunicareInfoService
  ) {
    super(translateSvc, configService, infoAppVersion, popupService);
    this.env = environment;
    this.loginFormGroup = new UntypedFormGroup({
      user: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required]),
    });
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.setupLanguage();

    // Get comunicare address and telecom
    this.comunicareInfoService.getFirstDataAvailable().then((info) => {
      this.comunicareInfo = info;
    });

    // disable the root left menu when entring the login page
    this.menuCtrl.enable(false);
    this.menuCtrl.swipeGesture(false);

    LoginPage.onLoginPage = true;
    this.isLoggingIn = false;
    this.infoAppVersion.getCurrentMode().then((appMode) => {
      this.hasSecretDevAccess = appMode === "DEV";
      this.isForceDevMode = appMode === "FORCE_DEV";
    });

    this.infoAppService.getVersion().then((_version) => {
      this.version = _version;
    });
    // To be sure, everything is cleared:
    this.loginService.disconnect(false);

    this.errServer = this.getParamsPage.getValueOfActivePage("error", null);

    this.infoAppVersion.getLastLogin().then((lastLogin) => {
      if (lastLogin) {
        this.loginFormGroup.get("user").setValue(lastLogin);
      }
    });

    this.activeFingerprint = this.route.snapshot.data.secureStorageActive;
  }

  ionViewDidEnter(): void {
    super.ionViewDidEnter();
    switch (this.errServer) {
      case SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED:
        this.popupService.showAlert("application.title", "login.refused.tokenExp");
        break;

      default:
        break;
    }
  }

  ionViewWillLeave(): void {
    super.ionViewWillLeave();
    LoginPage.onLoginPage = false;
    this.forceDevClickCounter = 0;
    this.menuCtrl.enable(true);
  }

  ionViewDidLeave(): void {
    super.ionViewDidLeave();
    // enable the root left menu when leaving the login page
    this.menuCtrl.swipeGesture(true);
  }

  private async setupLanguage() {
    this.language = await this.configService.getCurrentLanguageWithCheck(true);

    // set langOptions and select current language in selector
    const languages = await this.languagesService.getFreshestData();
    this.langOptions = languages.map((lang) => ({ value: lang.term, display: lang[lang.term] })); // showing language in the target language seems more logical
    this.langSelector.value = this.language;

    // Actually set the language to be used in the app
    this.translateSvc.setDefaultLang(this.language);
    await this.translateSvc.use(this.language).pipe(last()).toPromise();
  }

  onLogin(): void {
    // user/password must be set
    const user = (this.loginFormGroup.get("user").value as string).toLowerCase().trim();
    const password = this.loginFormGroup.get("password").value as string;

    if (!user || !password) {
      this.popupService.showAlert("application.title", "login.error.missUser");
      return;
    }
    this.isLoggingIn = true;
    this.loginService
      .authenticate(user, password)
      .pipe(takeLast(1))
      .subscribe(
        async (type) => {
          switch (type) {
            case LOGIN_TYPE.SUCCESS:
              this.infoAppVersion.setLastLogin(user);
              this.loginFormGroup.get("password").reset();
              this.statEventService.newEvent("Login success");
              await this.goToPageService.homePage({
                synchro: true,
              });
              break;
            case LOGIN_TYPE.FAILED:
              this.popupService.showAlert("login.refused.title", "login.refused.subTitle");
              break;
            case LOGIN_TYPE.DENIED:
              this.popupService.showAlert("login.refused.title", "login.refused.nonActive");
              break;
            case LOGIN_TYPE.SUCCESS_OFFLINE:
              this.infoAppVersion.setLastLogin(user);
              this.popupService.showAlert("application.title", "login.success.offline");
              this.loginFormGroup.get("password").reset();
              this.statEventService.newEvent("Offline login success");
              await this.goToPageService.homePage({
                synchro: false,
              });
              break;
            case LOGIN_TYPE.FAILED_OFFLINE:
              this.popupService.showAlert("login.refused.title", "login.refused.offline");
              break;
            case LOGIN_TYPE.PRACTITIONER_DENIED:
              this.popupService.showAlert("login.refused.title", "error.notPatientOrRelated");
              break;
            case LOGIN_TYPE.MIGRATION_IONIC5_FAILED:
              FileLogger.error("LoginPage", "MIGRATION_IONIC5_FAILED"); // the popup is already manage in migrationIonic5Service
              break;
            case LOGIN_TYPE.TOO_MANY_ATTEMPTS:
              this.popupService.showAlert("login.refused.title", "login.refused.tooManyAttempts");
              break;
            case LOGIN_TYPE.EXPIRED_PASSWORD:
              this.popupService.showAlert("login.refused.title", "login.refused.expiredPassword");
              break;
            case LOGIN_TYPE.TWO_FA_CANCELLED:
              this.popupService.showAlert("login.refused.title", "login.refused.twoFA");
              break;
            case LOGIN_TYPE.NEED_PASSWORD_REFRESH:
              this.modalService.presentModalChangeOwnPassword(user, true);
              break;
            default:
              this.popupService.showAlert("login.refused.title", "login.error.title");
              break;
          }
          this.isLoggingIn = false;
        },
        (err) => {
          this.isLoggingIn = false;
          FileLogger.error("LoginPage", "Error while trying to log in", err, "none");
          this.popupService.showAlert("login.refused.title", "login.error.title");
        }
      );
  }

  onResetPassword(): void {
    const user = this.loginFormGroup.get("user").value.toLowerCase().trim();
    this.modalService.presentModalResetPassword(user, this.language);
  }

  async onSecretAccess(): Promise<void> {
    try {
      if (!this.hasSecretDevAccess) {
        this.popupService.showYesNo("application.title", "application.devmodeon").then(async (answer) => {
          if (answer) {
            await this.infoAppVersion.setCurrentMode("DEV");
            this.popupService.showToast("application.demoModeToaster", 5000, "bottom");
            this.ionViewWillEnter();
          } else {
            await this.infoAppVersion.setCurrentMode("PROD");
            this.hasSecretDevAccess = false;
            this.ionViewWillEnter();
          }
        });
      } else {
        await this.infoAppVersion.setCurrentMode("PROD");
        this.ionViewWillEnter();
      }
    } catch (err) {
      FileLogger.error("LoginPage", "onSecretAccess", err);
      this.popupService.showAlert("application.title", "error.general");
    }
  }

  async forceDevMode(): Promise<void> {
    try {
      this.forceDevClickCounter++;
      if (this.forceDevClickCounter === 6) {
        this.isForceDevMode = !this.isForceDevMode;
        if (this.isForceDevMode) {
          this.popupService.showYesNo("application.title", "application.forcedevmodeon").then(async (answer) => {
            if (answer) {
              await this.infoAppVersion.setCurrentMode("FORCE_DEV");
              this.popupService.showToast("application.devModeToaster", 5000, "bottom");
              this.forceDevClickCounter = 0;
              this.isForceDevMode = true;
              this.ionViewWillEnter();
            } else {
              await this.infoAppVersion.setCurrentMode(this.hasSecretDevAccess ? "DEV" : "PROD");
              this.popupService.showToast(
                this.hasSecretDevAccess ? "application.demoModeToaster" : "application.devmodeoff",
                5000,
                "bottom"
              );
              this.forceDevClickCounter = 0;
              this.isForceDevMode = false;
              this.ionViewWillEnter();
            }
          });
        } else {
          await this.infoAppVersion.setCurrentMode("PROD");
          this.forceDevClickCounter = 0;
          this.ionViewWillEnter();
        }
      }
    } catch (err) {
      FileLogger.error("LoginPage", "forceDevMode", err);
      this.popupService.showAlert("application.title", "error.general");
    }
  }

  /**
   * User Change language
   */
  public async onChangeLanguage(event: CustomEvent): Promise<void> {
    this.language = event.detail.value;
    // this.settingsService.setFirstLoginLanguage(this.language);
    this.translateSvc.setDefaultLang(this.language);
    await this.translateSvc.use(this.language).pipe(last()).toPromise();
  }

  public async onLoginWithFingerprint(): Promise<void> {
    this.isLoggingIn = true;
    const success = await this.loginService.authenticateWithFingerprint();
    this.isLoggingIn = false;
    switch (success) {
      case LOGIN_TYPE.SUCCESS:
        this.goToPageService.homePage({
          synchro: true,
        });
        break;
      case LOGIN_TYPE.FAILED:
        // login / mdp associé plus valide
        await this.infoAppVersion.setDataAssociatedToFingerprint(null);
        this.popupService.showAlert("login.refused.title", "fingerprint.invalidIdentifier");
        break;
      case LOGIN_TYPE.SUCCESS_OFFLINE:
        this.popupService.showAlert("application.title", "login.success.offline");
        this.goToPageService.homePage({
          synchro: false,
        });
        break;
      case LOGIN_TYPE.FAILED_OFFLINE:
        this.popupService.showAlert("login.refused.title", "login.refused.offline");
        break;
      case LOGIN_TYPE.FINGERPRINT_NOT_AVAILABLE:
        this.popupService.showAlert("application.title", "fingerprint.notAvailable");
        break;
      case LOGIN_TYPE.FINGERPRINT_FAILED:
        this.popupService.showAlert("application.title", "fingerprint.failed");
        break;
      default:
        this.popupService.showAlert("login.refused.title", "login.error.title");
        break;
    }
  }

  /* TODO use in preference later
  public async modifAccountAssociatedFingerprint() {
    const fingerprintSuccess = await this.loginService.askFingerprint();
    switch (fingerprintSuccess) {
      case ASK_FINGERPRINT.SUCCESS:
        await this.modalService.presentModalLoginPwdForFingerprint();
        this.popupService.showAlert("application.title", "fingerprint.login.updateData");
        break;
      case ASK_FINGERPRINT.NOT_AVAILABLE:
        this.popupService.showAlert("application.title", "fingerprint.notAvailable");
        break;
      case ASK_FINGERPRINT.FAILED:
        this.popupService.showAlert("application.title", "fingerprint.failed");
        break;
    }
  }
  */
}

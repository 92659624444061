import * as moment from "moment";
import { EntityDrug, IEntitylink } from "src/app/models/entitylink";
import { INotification, NOTIFICATION_STATUS } from "src/app/models/notification";
import { IQuestionnaire, IValueSet } from "src/app/models/questionnaire";
import { IRuleResult, RESULT_TYPE } from "src/app/models/rule";
import { ACTION_STATUS_ENTITY, IVitalProfileDefinition, Reference, RULE_TARGET_TYPE } from "src/app/models/sharedInterfaces";
import { ITranslation } from "src/app/models/translation";
import { ComputeDrugsService } from "src/app/services/compute-drugs.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { IAccount } from "../account-helper";
import { FileLogger } from "../fileLogger";
import { IObservation, Observation } from "../observation-helper";
import { QRQuestion, QuestionnaireResponse } from "../questionnaireResponse";
import { Tools } from "../tools-helper";
import { Answers, DictionnaryAnswers, SimpleAnswer } from "./answers";
import { DictionnaryDrugIntake, DrugIntakes } from "./drugintake";
import { DictionnaryObservations, Observations, SimpleObservation } from "./observations";
import { IRuleAlert } from "./ruleAlert-helper";
import { DictionnaryVitals, DictionnaryVitalsDef } from "./vitals";

export abstract class TriggerRuleHelperEngineService {
  private SOURCE = "TriggerRuleHelperEngineService";
  //  Object used in trigger formula
  public OBSERVED: DictionnaryObservations = {};
  public PATIENT: DictionnaryVitals = {};
  public VITALSIGNDEF: DictionnaryVitalsDef = {};
  public DRUG_INTAKE: DictionnaryDrugIntake = {};
  public ANSWER: DictionnaryAnswers = {};

  protected createANSWERVariable(
    valueSet: IValueSet[],
    currentQuestionnaire: IQuestionnaire,
    currentQuestionnaireResponse: QuestionnaireResponse
  ): void {
    let qR: QRQuestion[] = [];

    if (Tools.isDefined(currentQuestionnaireResponse.group.group) && Array.isArray(currentQuestionnaireResponse.group.group)) {
      qR = currentQuestionnaireResponse.group.group.map((group) => group.question).reduce((a, b) => a.concat(b), []);
    } else if (Tools.isDefined(currentQuestionnaireResponse.group.question) && Array.isArray(currentQuestionnaireResponse.group.question)) {
      qR = currentQuestionnaireResponse.group.question;
    }

    // creation of the variable this.ANSWER used in the rules.
    // goal : this.ANSWER[linkId].CURRENT.value = the number associated to the answer of the question "linkId"
    if (Tools.isDefined(qR) && qR.length > 0) {
      qR.forEach((question) => {
        const valueSetIdentifier = this.getValueSetFromQuestionnaire(currentQuestionnaire, question.linkId);
        if (Tools.isDefined(valueSetIdentifier) && Tools.isDefined(question.answer) && question.answer.length) {
          this.ANSWER[question.linkId] = new Answers(
            new SimpleAnswer(
              question.answer,
              valueSet.find((v) => v.id === valueSetIdentifier)
            )
          );
        }
      });
    }
  }

  /**
   * Return valueset identifier of a particular question. Can be null !
   */
  private getValueSetFromQuestionnaire(questionnaire: IQuestionnaire, linkIdQuestion: string): string {
    if (Tools.isDefined(questionnaire.group.group) && Array.isArray(questionnaire.group.group)) {
      for (const group of questionnaire.group.group) {
        // groups in group ?
        for (const question of group.question) {
          if (question.linkId === linkIdQuestion && question.options) {
            return question.options.reference;
          }
        }
      }
    } else if (Tools.isDefined(questionnaire.group.question) && Array.isArray(questionnaire.group.question)) {
      // only questions in group
      for (const question of questionnaire.group.question) {
        if (question.linkId === linkIdQuestion && question.options) {
          return question.options.reference;
        }
      }
    }
    return null; // not found
  }

  /**
   *
   * @param targets type = "drugs", reference = cnk code or name of the drug
   * @param allDrugIntake
   * @param allDrugs
   */
  protected createDRUG_INTAKEVariable(
    targets: Reference[],
    allDrugIntake: INotification[],
    allDrugs: IEntitylink[],
    computeDrugService: ComputeDrugsService
  ): void {
    const targetDrugs = targets
      .filter((target) => target.type === RULE_TARGET_TYPE.DRUGS)
      .map((target) => {
        return {
          reference: target.reference,
          drugs:
            allDrugs?.filter(
              (
                drug // find the drugs with this cnk code or name or atccode
              ) =>
                Tools.isDefined(drug._id) &&
                [
                  (drug.entityData as EntityDrug)?.reference,
                  (drug.entityData as EntityDrug)?.name,
                  (drug.entityData as EntityDrug)?.atcCode,
                ].includes(target.reference)
            ) ?? [],
          drugsIntake: [],
        };
      });

    if (targetDrugs.length) {
      allDrugIntake.forEach((drugIntake) => {
        if (drugIntake.status === NOTIFICATION_STATUS.ACCEPTED || drugIntake.status === NOTIFICATION_STATUS.REJECTED) {
          targetDrugs
            ?.find((targetDrug) => targetDrug?.drugs?.map((drug) => drug._id)?.includes(drugIntake?.appId))
            ?.drugsIntake.push(drugIntake);
        }
      });

      for (const target of targetDrugs) {
        this.DRUG_INTAKE[target.reference] = new DrugIntakes(
          target.reference,
          target.drugs?.map((drug) => drug.entityData) ?? [],
          computeDrugService
        );
        this.DRUG_INTAKE[target.reference]._RAW = target.drugsIntake ?? [];
      }
    }
  }

  protected createPATIENTVariable(patient: IAccount): void {
    // build PATIENT vital profile object
    if (patient.vitalProfile) {
      for (const vital of patient.vitalProfile) {
        // if no value in vital.value, then take vital.valueArray. This last one is an array of array of numbers
        this.PATIENT[vital.code] = vital.value ? vital.value : vital.valueArray;
      }
    }
  }

  protected createVITALSIGNDEFVariable(vitalSignDef: IVitalProfileDefinition[]): void {
    vitalSignDef?.forEach((v) => {
      this.VITALSIGNDEF[v.code] = v;
    });
  }

  /**
   *
   * @param targets type = undefined, reference = loinc code
   * @param allObservations
   * @param currentObs
   */
  protected createOBSERVEDVariable(targets: Reference[], allObservations: IObservation[], currentObs?: IObservation): void {
    // filter observation and collect only those with same code as current entered
    let filteredObservations = allObservations.filter((v) => v.actionStatus !== ACTION_STATUS_ENTITY.DELETED);
    // Sort observations from newer to older
    filteredObservations = filteredObservations?.sort((a, b) => moment(b.issued).valueOf() - moment(a.issued).valueOf());

    // create one data object for each target code
    for (const target of targets.filter(
      (target) => target.type !== RULE_TARGET_TYPE.VITAL_PROFILE && target.type !== RULE_TARGET_TYPE.DRUGS
    )) {
      this.OBSERVED[target.reference] = new Observations(target);
      for (const obs of filteredObservations) {
        const compo = Observation.getComponent(obs, target.reference);
        if (compo) {
          const co: SimpleObservation = {
            code: target.reference,
            issued: obs.issued,
            value: compo.valueQuantity.value,
            effectiveTiming: obs.effectiveTiming?.repeat?.when?.code,
          };
          this.OBSERVED[target.reference]._RAW.push(co);
        }
      }
      // build VALUES
      this.OBSERVED[target.reference].VALUES = this.OBSERVED[target.reference]._RAW.map((oc) => oc.value);
      // build CURRENT
      if (Tools.isDefined(currentObs)) {
        const currentCompo = Observation.getComponent(currentObs, target.reference);
        if (currentCompo) {
          this.OBSERVED[target.reference].CURRENT = {
            code: target.reference,
            issued: currentObs.issued,
            value: currentCompo.valueQuantity.value,
            effectiveTiming: currentObs.effectiveTiming?.repeat?.when?.code,
          };
        }
      }
    }
  }

  /**
   * Evaluate formula to trigger rule
   *
   * Example of formula :
   *      "OBSERVED['3141-9'].CURRENT.value>= 90 && OBSERVED['3141-9'].CURRENT.value < 95"
   *      "OBSERVED['3141-9'].getDay(0, 'MAX')"
   */
  public triggerFormulaEvaluation(formula: string): boolean {
    try {
      const rep = this.evalFormula(formula);
      if (typeof rep === "boolean") {
        return rep;
      }
      FileLogger.error(this.SOURCE, "triggerFormulaEvaluation : is not a boolean !", rep);
      return false;
    } catch (err) {
      // probably not well formatted formula or patient reference value not exists
      FileLogger.warn(this.SOURCE, "triggerFormulaEvaluation failed!", err);
      return false;
    }
  }

  /**
   *
   * @param variableToInterpolate a string representing a variable to interpolate
   * @param templateMessage a string which will be evaluated as a template string
   * @returns the result of the message interpolated. Null if an error has occurred.
   */
  public messageTemplateEvaluation(variableToInterpolate: string, templateMessage: string): string | null {
    try {
      if (Tools.isNotDefined(templateMessage) || Tools.isNotDefined(variableToInterpolate)) return null;

      const REP = this.evalFormula(variableToInterpolate);
      if (Tools.isNotDefined(REP)) return null;

      templateMessage = this.configureRule(templateMessage);

      // tslint:disable-next-line: no-eval
      const value = eval("`" + templateMessage + "`"); // Transform to Template strings
      if (typeof value === "string") {
        return value;
      }
      FileLogger.error(this.SOURCE, "messageTemplateEvaluation : is not a string !", value);
      return null;
    } catch (error) {
      FileLogger.error(this.SOURCE, "messageTemplateEvaluation failed!", error);
      return null;
    }
  }

  /**
   * Configure a rule :
   * - replace OBSERVED by this.OBSERVED
   * - replace PATIENT by this.PATIENT
   * - replace VITALSIGNDEF by this.VITALSIGNDEF
   * - replace ANSWER by this.ANSWER
   * - replace DRUG_INTAKE by this.DRUG_INTAKE
   * @param rule
   * @returns
   */
  private configureRule(rule: string): string {
    // OBSERVED object is only accessible through "this"
    rule = rule.replace(new RegExp("OBSERVED", "g"), "this.OBSERVED");
    // PATIENT object is only accessible through "this"
    rule = rule.replace(new RegExp("PATIENT", "g"), "this.PATIENT");
    // VITALSIGNDEF object is only accessible through "this"
    rule = rule.replace(new RegExp("VITALSIGNDEF", "g"), "this.VITALSIGNDEF");
    // DRUG_INTAKE object is only accessible through "this"
    rule = rule.replace(new RegExp("DRUG_INTAKE", "g"), "this.DRUG_INTAKE");
    // ANSWER object is only accessible through "this"
    rule = rule.replace(new RegExp("ANSWER", "g"), "this.ANSWER");

    FileLogger.log(this.SOURCE, "configureRule", rule);
    return rule;
  }

  /**
   * eval a formula
   * @param formula a string representing a typescript code
   * @returns
   */
  private evalFormula(formula: string): unknown | null {
    try {
      if (Tools.isNotDefined(formula)) return null;
      formula = this.configureRule(formula);
      // tslint:disable-next-line: no-eval
      const value = eval(formula); // The force of the war!
      FileLogger.log(this.SOURCE, "evalFormula result", value);
      return value;
    } catch (err) {
      FileLogger.error(this.SOURCE, "evalFormula failed!", err);
      return null;
    }
  }

  /**
   * Transform the fields ruleAlert.rule.results.value and ruleAlert.rule.resultsPractitionner.value for each language
   * and each result if
   * - the result is of the type RESULT_TYPE.MESSAGE or RESULT_TYPE.HTML, and
   * - the field variableToInterpolate is defined
   * @param ruleAlert the rule alert to treat
   */
  public async transformMessageTemplateIfNeeded(ruleAlert: IRuleAlert, languages: string[]): Promise<void> {
    try {
      // for the message on the mobileApp
      await this.transformMessageTemplateIfNeededFromResults(ruleAlert?.rule?.results, languages);
      // for the message on the dashboard
      await this.transformMessageTemplateIfNeededFromResults(ruleAlert?.rule?.resultsPractitionner, languages);
    } catch (error) {
      FileLogger.error(this.SOURCE, "transformMessageTemplateIfNeeded", error);
    }
  }

  /**
   * Transform the field value for each language if
   * - the result is of the type RESULT_TYPE.MESSAGE or RESULT_TYPE.HTML, and
   * - the field variableToInterpolate is defined
   * @param results the rule result to treat
   * @param languages the list of languages managed by our application (e.g. ["fr", "en", "de", "nl"])
   */
  public async transformMessageTemplateIfNeededFromResults(results: IRuleResult[], languages: string[]): Promise<void> {
    if (Tools.isNotDefined(results)) return;
    results
      .filter(
        (r) => (r.resultType === RESULT_TYPE.MESSAGE || r.resultType === RESULT_TYPE.HTML) && Tools.isDefined(r.variableToInterpolate)
      )
      .forEach((ruleResult: IRuleResult) => {
        const message = ruleResult.value;
        if (Tools.isDefined(message)) {
          languages.forEach((lang) => {
            // for each language, evaluate the template message
            if (Tools.isDefined(message[lang])) {
              message[lang] = this.messageTemplateEvaluation(ruleResult.variableToInterpolate, message[lang]);
            }
          });
        }
      });
  }

  /**
   * @param ruleResult rule result
   * @param alertIdentifier identifier of the alert
   * @param currentLang patient's language
   * @returns Return alert message translated to current language
   */
  public getAlertMessageTranslated(ruleResult: IRuleResult, alertIdentifier: string, currentLang: string): string {
    try {
      if (ruleResult.resultType !== RESULT_TYPE.MESSAGE && ruleResult.resultType !== RESULT_TYPE.HTML) return ""; // no message "inside" result
      const msgTranslation = ruleResult.value as ITranslation;
      if (!msgTranslation) return "";
      return InfoAppService.getTranslation(msgTranslation, currentLang, msgTranslation["fr"] ? msgTranslation["fr"] : alertIdentifier);
    } catch (err) {
      FileLogger.error(this.SOURCE, "getAlertMessageTranslated", err);
      return alertIdentifier;
    }
  }
}

import { Component } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BasePage } from "src/app/baseClasses/base-page";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { EntityNote, IEntitylink } from "src/app/models/entitylink";
import { ACTION_STATUS_ENTITY, IEntity } from "src/app/models/sharedInterfaces";
import { CameraService } from "src/app/services/camera.service";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { LanguagesService } from "src/app/services/globalDataProvider/languagesService";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-note-modal",
  templateUrl: "./note-modal.component.html",
  styleUrls: ["./note-modal.component.scss"],
})
export class NoteModalComponent extends BasePage {
  public note: IEntitylink;
  public noteFormGroup: UntypedFormGroup;
  public dateTimeLocaleFormat: string;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected navParams: NavParams,
    protected translateSvc: TranslateService,
    protected modalCtr: ModalController,
    protected configService: ConfigurationService,
    public accountService: AccountService,
    private cameraService: CameraService,
    protected languagesService: LanguagesService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.note = navParams.get("note");
    this.noteFormGroup = new UntypedFormGroup(
      {
        title: new UntypedFormControl(""),
        dateTime: new UntypedFormControl(undefined, [Validators.required]),
        description: new UntypedFormControl(""),
      },
      { validators: this.TitleOrDescription }
    );

    moment.locale(this.configService.getCurrentLanguage());
    this.languagesService.getFirstDataAvailable().then((langs) => {
      this.dateTimeLocaleFormat = Tools.getDateTimeLocaleFormat(langs, this.configService.getCurrentLanguage());
    });

    this.translateSvc.setDefaultLang(this.configService.getCurrentLanguage());

    // set default value to datetime field
    if (this.note.actionStatus === ACTION_STATUS_ENTITY.CREATED) {
      this.note.entityData.datetime = moment().format();
    }
    this.note.entityData.datetime = this.note.entityData?.datetime ? this.note.entityData.datetime : null;
    if (this.note) {
      this.noteFormGroup.get("title").setValue(this.note.entityData?.name);
      this.noteFormGroup.get("dateTime").setValue(this.note.entityData?.datetime);
      this.noteFormGroup.get("description").setValue(this.note.entityData?.data);
    } else {
      this.noteFormGroup.get("dateTime").setValue(this.note.entityData?.datetime);
    }
  }

  /**
   *  cancel edition
   */
  public dismiss(): void {
    this.modalCtr.dismiss();
  }

  /**
   * save modification
   */
  public save(): void {
    if (this.noteFormGroup.errors?.["TitleOrDescription"]) {
      // reassemble title and data into the note
      const entityNote: EntityNote = this.note.entityData;
      if (this.noteFormGroup.get("title").value) {
        entityNote.name = this.noteFormGroup.get("title").value;
      } else {
        entityNote.name = this.noteFormGroup.get("description").value.split(" ", 3).join(" ");
      }
      entityNote.data = this.noteFormGroup.get("description").value;
      // reinit hours
      entityNote.datetime = moment(this.noteFormGroup.get("dateTime").value).format();
      FileLogger.log("NoteModalComponent", "save", this.noteFormGroup.get("dateTime").value);

      if (!moment(entityNote.datetime).isValid()) {
        entityNote.datetime = null;
        FileLogger.error("NoteModalComponent", "saveNote ERR INVALID DATE :", this.noteFormGroup.get("dateTime").value);
      }
      this.note.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
      this.note.modified = moment().format();
      this.modalCtr.dismiss(this.note);
    } else {
      this.popupService.showAlert("application.title", "mynotes.errorEmpty");
      return;
    }
  }

  /**
   * delete note (in fact, set status to delete)
   */
  public delete(): void {
    this.note.actionStatus = ACTION_STATUS_ENTITY.DELETED;
    this.note.modified = moment().format();
    this.modalCtr.dismiss(this.note);
  }

  /**
   *  update private state
   */
  public private(): void {
    if (IEntity.isPublicShared(this.note)) IEntity.setPrivate(this.note);
    else IEntity.setShared(this.note);
    // (this.note.entityData as EntityNote).isPrivate = !(this.note.entityData as EntityNote).isPrivate;
    this.note.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    this.note.modified = moment().format();
  }

  /**
   * Is this note public or private ?
   */
  public isPublic(): boolean {
    return IEntity.isPublicShared(this.note);
  }

  /**
   * For the browser, search a local picture
   * @param e
   */
  public addPhoto(e): void {
    const file = e.target.files[0];
    this.getPhoto(file).then((s) => {
      (this.note.entityData as EntityNote).photo = s;
      // this.ref.detectChanges();
    });
  }

  /**
   * For the browser
   * @param file
   * @returns
   */
  private getPhoto(file): Promise<string> {
    return new Promise((res, _rej) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        res(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  /**
   * For smartphone, take a picture and save in this note
   */
  public async onPhoto(): Promise<void> {
    try {
      const base64Picture = await this.cameraService.createPictureBase64();
      if (base64Picture) {
        (this.note.entityData as EntityNote).photo = base64Picture;
      }
    } catch (error) {
      FileLogger.error("NoteModalComponent", "onPhoto", error);
    }
  }

  /**
   * Erase the drug picture
   */
  public onDeletePhoto(): void {
    (this.note.entityData as EntityNote).photo = null;
  }

  private TitleOrDescription: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const title = control.get("title");
    const description = control.get("description");
    if (title.value !== "" || description.value !== "") {
      return { TitleOrDescription: true };
    }
    return null;
  };
}

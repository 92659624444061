import { Injectable } from "@angular/core";
import { USER_ROLE } from "../helpers/account-helper";
import { KNOW_CATEGORY } from "../helpers/knowledge-helper";
import { QuestionnaireResponse } from "../helpers/questionnaireResponse";
import { SPECIFIC_USE } from "../models/sharedInterfaces";
import { ApiService } from "./api.service";
import { IApiResponse } from "../models/iapi-response";

@Injectable({
  providedIn: "root",
})
export class ConsentService {
  public consentModalIsOpenned;
  constructor(private apiService: ApiService) {}

  public getLastConsent(role: USER_ROLE[], lang: string): Promise<IApiResponse> {
    if (role.includes(USER_ROLE.PATIENT)) {
      return this.apiService.getWithPromise(`/consent`, undefined, {
        category: KNOW_CATEGORY.CONSENT_PATIENT,
        lang: lang,
      });
    } else if (role.includes(USER_ROLE.RELATEDPERSON)) {
      return this.apiService.getWithPromise(`/consent`, undefined, {
        category: KNOW_CATEGORY.CONSENT_RELATED,
        lang: lang,
      });
    }
  }

  public getSpecificConsent(identifier: string, publicationDate: string, role: USER_ROLE[], lang: string): Promise<IApiResponse> {
    if (role.includes(USER_ROLE.PATIENT)) {
      return this.apiService.getWithPromise(`/consent`, undefined, {
        category: KNOW_CATEGORY.CONSENT_PATIENT,
        identifier: identifier,
        publicationDate: publicationDate,
        lang: lang,
      });
    }
    if (role.includes(USER_ROLE.RELATEDPERSON)) {
      return this.apiService.getWithPromise(`/consent`, undefined, {
        category: KNOW_CATEGORY.CONSENT_RELATED,
        identifier: identifier,
        publicationDate: publicationDate,
        lang: lang,
      });
    }
  }

  public save(qr: QuestionnaireResponse): Promise<IApiResponse> {
    return this.apiService.postWithPromise("/specificQuestionnaireResponse", qr);
  }

  public getConsentResponse(): Promise<IApiResponse> {
    return this.apiService.getWithPromise(`/specificQuestionnaireResponses`, undefined, { specificUse: SPECIFIC_USE.CONSENT });
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IRewardDefinition } from "src/app/models/rewardDefinition";
import { LocalStorageService } from "../storage/local-storage.service";
import { AccountService } from "./account.service";
import { CareplanService } from "./careplan.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class RewardDefinitionsService
  extends BasicSyncService<IRewardDefinition, IRewardDefinition[]>
  implements StaticImplements<INeedRefresh, typeof RewardDefinitionsService>
{
  public get needRefresh(): { value: boolean } {
    return RewardDefinitionsService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  private lastValueOfParam = "[]";
  private storageKey = "RewardDefinitionLastValue";

  constructor(
    protected dataService: DataService,
    private careplanService: CareplanService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    super(dataService);
  }

  public getUrl(): string {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IRewardDefinition[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "rewardDefinitions",
      entityStoreKey: "list",
      getUrl: "/rewardDefinitions?identifiers=",
      setUrl: "/rewardDefinitions",
      expirationDays: 10,
      encrypted: false,
    };
  }

  /**
   * Returns the current state of the service's data
   */
  public peekData(careplansIds?: string[]): IRewardDefinition[] {
    return this.processData(super.peekData(), careplansIds);
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
    } catch (err) {
      this.lastValueOfParam = "[]";
    }
  }

  public clear(): void {
    super.clear();
    this.lastValueOfParam = "[]";
  }

  public async *getDataReader(careplansIds?: string[]): AsyncGenerator<IRewardDefinition[], IRewardDefinition[], IRewardDefinition[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const cpIds = await this.careplanService.getCareplansIds();
      const paramObject = Object.assign({}, this.defaultDataParameter);
      const stringifyCpIds = JSON.stringify(cpIds);
      paramObject.getUrl += stringifyCpIds;
      // do not modify entityStoreKey to allow the patient to have access at knowledge even if there are changes in parameters
      // paramObject.entityStoreKey += stringifySnomed;
      this.lastValueOfParam = stringifyCpIds;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);

      const dataReader = this.dataService.readv2<IRewardDefinition, IRewardDefinition[]>(paramObject, false, this);
      let d: IRewardDefinition[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, careplansIds);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("RewardDefinitionsService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IRewardDefinition[], careplansIds?: string[]) {
    try {
      const allDefs = dataResult;
      return careplansIds ? allDefs?.filter((defs) => defs.careplanIds.some((id) => careplansIds.indexOf(id) >= 0)) : allDefs;
    } catch (err) {
      FileLogger.error("RewardDefinitionsService", "Error while processing rewardDefinitionsService data: ", err);
      return [];
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(careplansIds?: string[]): Promise<IRewardDefinition[]> {
    const dataReader = this.getDataReader(careplansIds);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(careplansIds?: string[]): Promise<IRewardDefinition[]> {
    const dataReader = this.getDataReader(careplansIds);
    const iterator = await dataReader.next();
    return iterator.value;
  }
}

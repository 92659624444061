import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ICheckSum } from "src/app/models/checksum";
import { ICountry } from "src/app/models/country";
import { StaticImplements } from "src/app/models/sharedInterfaces";
import { HashService } from "../hash.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

@Injectable({
  providedIn: "root",
})
export class CountryService
  extends BasicSyncService<ICountry, ICountry[]>
  implements StaticImplements<INeedRefresh, typeof CountryService>
{
  public get needRefresh(): { value: boolean } {
    return CountryService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  private lastValueOfParam = "";
  private storageKey = "countryLastValue";

  public getUrl(): string {
    return super.getUrl() + this.lastValueOfParam;
  }
  public get entityStoreKey(): string {
    return super.entityStoreKey + this.lastValueOfParam;
  }
  constructor(protected dataService: DataService, private localStorage: LocalStorageService, private accountService: AccountService) {
    super(dataService);
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, false);
    } catch (err) {
      this.lastValueOfParam = "";
    }
  }

  public clear(): void {
    super.clear();
    this.lastValueOfParam = "";
  }
  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<ICountry[]>([]);
  }
  protected initWatch(): void {
    this.data$.next([]);
  }
  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "country_",
      entityStoreKey: "list",
      getUrl: "/countries?code=",
      setUrl: "/countries/",
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async *getDataReader(): AsyncGenerator<ICountry[], ICountry[], ICountry[]> {
    try {
      this.lastValueOfParam = this.accountService.cachedAccount.referenceCountry;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, false);

      const paramObject = Object.assign({}, this.defaultDataParameter);
      paramObject.getUrl += this.lastValueOfParam;
      paramObject.entityStoreKey += this.lastValueOfParam;
      const dataReader = this.dataService.readv2<ICountry, ICountry[]>(paramObject, false, this);
      let d: ICountry[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("CountryService", "getDataReader()", err);
      yield [];
      return [];
    }
  }
  public async checkSum(): Promise<ICheckSum> {
    const paramObject = Object.assign({}, this.defaultDataParameter);
    paramObject.getUrl += this.lastValueOfParam;
    paramObject.entityStoreKey += this.lastValueOfParam;
    const dataReader = this.dataService.readv2<ICountry, ICountry[]>(paramObject, true);
    const localDataIt = await dataReader.next();
    const localData = localDataIt.value;
    return {
      checkSum: HashService.getMd5HashOfObject(localData, ["actionStatus"]),
      nameRoute: paramObject.getUrl,
    } as ICheckSum;
  }
}

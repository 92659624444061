import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { FHIR_ActivityHelper } from "../helpers/fhirActivityHelper";
import { Activity } from "../models/careplan";
import { EntityDrug, IEntitylink } from "../models/entitylink";
import { ScheduledActivity } from "../models/scheduledActivity";
import { ACTIVITY_CATEGORY, SCHEDULE_PERIOD, Timing } from "../models/sharedInterfaces";
import { FREQUENCY_OPTIONS, TIMING_CODES, TIMING_OPTIONS, TimingData } from "../models/timingData";
import { ConfigurationService } from "./globalDataProvider/configuration.service";
import { DrugService } from "./globalDataProvider/drug.service";
import { FileLogger } from "../helpers/fileLogger";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  private timingCodes = TIMING_CODES;
  private freqOptions = FREQUENCY_OPTIONS;
  private timingOptions = TIMING_OPTIONS;
  private fixedFreqOption = FREQUENCY_OPTIONS[0].value;
  private fixedDaysOption = FREQUENCY_OPTIONS[1].value;
  private asNecessaryOption = FREQUENCY_OPTIONS[2].value;
  private asNecessaryDisplay = FREQUENCY_OPTIONS[2].key;
  private momentTimingOption = TIMING_OPTIONS[0].value;
  private fixedHoursTimingOption = TIMING_OPTIONS[1].value;

  constructor(private translateSvc: TranslateService, protected configService: ConfigurationService, private drugService: DrugService) {}

  /**
   *  compute short/long description for activity
   */
  public async getSchedulingDescription(activity: Activity, withShortLabel: boolean): Promise<string> {
    let description = "";
    // nothing to do
    if (!activity) return description;
    const category = FHIR_ActivityHelper.getCategory(activity);
    // 3 kind of timing
    switch (category) {
      case ACTIVITY_CATEGORY.DRUG:
        try {
          const entity: IEntitylink | undefined = await this.drugService.getOne(activity._id);
          if (entity) {
            const drug = entity.entityData as EntityDrug;
            description = this.getScheduledFrequencyDescription(drug.frequency, withShortLabel);
          }
        } catch (err) {
          FileLogger.error("ActivityService", "getSchedulingDescription", err);
        }
        break;
      case ACTIVITY_CATEGORY.PROCEDURE:
        description = this.getSchedulingAsString(activity, withShortLabel);
        break;
    }
    return description;
  }

  private getSchedulingAsString(activity: Activity, withShortLabel: boolean): string {
    let description = "";
    if (activity.detail.scheduledString) {
      if (activity.detail.status !== "error" && activity.detail.scheduledString) {
        description = moment(activity.detail.scheduledString).format("DD/MM/YYYY");
      }
    } else if (activity.detail.scheduledPeriod) {
      // one shot activity
      if (activity.detail.status !== "error" && activity.detail.scheduledPeriod.start) {
        description = moment(activity.detail.scheduledPeriod.start).format("DD/MM/YY");
        if (activity.detail.scheduledPeriod.start !== activity.detail.scheduledPeriod.end) {
          description += moment(activity.detail.scheduledPeriod.end).format("DD/MM/YY");
        }
      }
    } else if (activity.detail.scheduledTiming) {
      // scheduling
      description = this.getScheduledFrequencyDescription(activity.detail.scheduledTiming.repeat, withShortLabel);
    }
    return description;
  }

  /**
   * return short/long description for an activity
   */
  public getScheduledFrequencyDescription(timing: Timing, withShortLabel: boolean): string {
    if (!timing) {
      return "";
    }
    const freqOption = TimingData.getFreqOptionFromTiming(timing);
    const timingOption = TimingData.getTimingOptionFromTiming(timing);
    const hasTiming = TimingData.hasTiming(timing, freqOption, timingOption);
    // fix when drug have no timingCode (from careplan for now)
    if (!hasTiming) {
      return "";
    }

    if (freqOption === TimingData.asNecessaryOption) {
      return this.translateSvc.instant(TimingData.asNecessaryOptionDisplay);
    } else if (freqOption === TimingData.fixedDaysOption) {
      return this.translateSvc.instant(TimingData.fixedDaysOptionDisplay);
    }
    const period = FHIR_ActivityHelper.getScheduledPeriod(timing);
    let description = "";
    let timingPeriod = 0;
    if (timing.period > 1) {
      timingPeriod = timing.period;
    }
    let timesPerDay = 1;
    if (timingOption === this.momentTimingOption) {
      timesPerDay = timing.timingCode.length;
    } else if (timingOption === this.fixedHoursTimingOption) {
      timesPerDay = timing.timeOfDay.length;
    }
    switch (period) {
      // minute aren't supported yet
      case SCHEDULE_PERIOD.MINUTE:
        description += this.translateSvc.instant(withShortLabel ? "periodUnit.minute.short" : "periodUnit.minute.plural");
        break;
      // HOUR : every X hours (if x=1, don't show)
      case SCHEDULE_PERIOD.HOUR:
        description += this.translateSvc.instant("periodUnit.everyF") + " " + (timingPeriod > 1 ? timingPeriod + " " : "");
        description += this.translateSvc.instant(withShortLabel ? "periodUnit.hour.short" : "periodUnit.hour.plural");
        break;
      // DAY : X x/d,  every Y days (if Y=1, don't show)
      case SCHEDULE_PERIOD.DAY: {
        const lang = this.configService.getCurrentLanguage();
        const timingP = Number(timing.period);
        if (lang === "nl" && timingP === 1) {
          description +=
            timesPerDay + "x/" + this.translateSvc.instant("periodUnit.day.short") + " " + this.translateSvc.instant("periodUnit.everyday");
        } else {
          description +=
            timesPerDay +
            "x/" +
            this.translateSvc.instant("periodUnit.day.short") +
            " " +
            this.translateSvc.instant("periodUnit.everyM") +
            " " +
            (timingPeriod > 1 ? timingPeriod + " " : "");
          description += this.translateSvc.instant(withShortLabel ? "periodUnit.day.short" : "periodUnit.day.plural");
        }
        break;
      }
      // WEEK : X x/d, Yd every Z weeks (if Z=1, don't show)
      case SCHEDULE_PERIOD.WEEK:
        // add number per day
        description += timesPerDay + "x/" + this.translateSvc.instant("periodUnit.day.short") + ", ";
        // add number of days
        description +=
          this.getNbrDays(timing.when) +
          this.translateSvc.instant("periodUnit.day.short") +
          " " +
          this.translateSvc.instant("periodUnit.everyF") +
          " ";
        // add number of weeks
        description +=
          (timingPeriod > 1 ? timingPeriod + " " : "") +
          this.translateSvc.instant(withShortLabel ? "periodUnit.week.short" : "periodUnit.week.plural");
        break;
      // Month : Xx/d, Yd every Z month (if Z=1, don't show)
      case SCHEDULE_PERIOD.MONTH:
        // add number per day
        description += timesPerDay + "x/" + this.translateSvc.instant("periodUnit.day.short") + ", ";
        // add number of days
        description +=
          this.getNbrDays(timing.when) +
          this.translateSvc.instant("periodUnit.day.short") +
          " " +
          this.translateSvc.instant("periodUnit.everyM") +
          " ";
        // add number of months
        description +=
          (timingPeriod > 1 ? timingPeriod + " " : "") +
          this.translateSvc.instant(withShortLabel ? "periodUnit.month.short" : "periodUnit.month.plural");
        break;
      // year aren't supported yet
      case SCHEDULE_PERIOD.YEAR:
        description += this.translateSvc.instant(withShortLabel ? "periodUnit.year.short" : "periodUnit.year.plural");
        break;
    }

    if (timing.count && !withShortLabel) {
      description += " (max " + timing.count + "x)";
    }
    return description;
  }

  /**
   * @param when
   */
  public getNbrDays(when: string): number {
    if (!when) return 0;
    return (JSON.parse(when) as string[]).length;
  }

  /**
   *  compute Care plan agenda activities base on "scheduledTiming" entry in careplan.Activity json data
   *
   * Warning:
   *   1. there are 2 kind of scheduled activity:
   *    one kind "at home"
   *     one kind "at hospital"
   * 2. there are 3 kind of scheduling
   *     one kind "scheduledString", for a one-shot
   *     one kind "scheduledPeriod", for a period or sometimes one-shot
   *     one kind "scheduledTiming", for recurrence
   * 3. There are 7 kind of categories: http://www.hl7.org/FHIR/valueset-care-plan-activity-category.html
   *
   * There are two optional parameters : from and to
   * They restrict the interval of generation of Scheduled activities
   * Note that if they are outside the activity interval, they are ignored.
   */
  public computeScheduledActivities(
    activity: Activity,
    forceRepeat = false,
    from?: moment.Moment,
    to?: moment.Moment
  ): Array<ScheduledActivity> {
    const scheduledActivities: Array<ScheduledActivity> = new Array<ScheduledActivity>();
    try {
      // *** first kind of scheduling: one-shot ***
      if (activity.detail.scheduledPeriod || activity.detail.scheduledString) {
        const scheduledActivity = new ScheduledActivity(activity, FHIR_ActivityHelper.getStartDate(activity));
        scheduledActivities.push(scheduledActivity);
        return scheduledActivities;
      }
      // *** second kind of scheduling: recurrence ***
      if (!activity.detail.scheduledTiming.repeat.frequency) return []; // frequency not set, nothing to do
      const scheduledTiming = activity.detail.scheduledTiming;
      let currentDaytime = moment(scheduledTiming.repeat.boundsPeriod.start);
      let endDay = moment(scheduledTiming.repeat.boundsPeriod.end);
      // Take the optional begin and end in account
      if (from && from.isAfter(currentDaytime)) {
        currentDaytime = from.clone(); // always clone moment to avoid side effects
      }
      if (to && to.isBefore(endDay)) {
        endDay = to;
      }
      // do not generate same type of recurrence for DRUG activity
      if (!forceRepeat && FHIR_ActivityHelper.getCategory(activity) === ACTIVITY_CATEGORY.DRUG) {
        const scheduledActivity = new ScheduledActivity(activity, FHIR_ActivityHelper.getStartDate(activity));
        scheduledActivities.push(scheduledActivity);
        return scheduledActivities;
      }
      // loop on current time to generate recurrence
      while (currentDaytime.isBefore(endDay)) {
        // when "event" is present, it will contains "time" info
        if (activity.detail.scheduledTiming.event && activity.detail.scheduledTiming.event.length > 0) {
          const time = moment.duration(activity.detail.scheduledTiming.event[0]);
          currentDaytime = currentDaytime.hours(time.hours()).minutes(time.minutes()).seconds(0).milliseconds(0);
        }
        // add activity to the list of generated ones
        const scheduledActivity = new ScheduledActivity(activity, currentDaytime.format());
        scheduledActivities.push(scheduledActivity);
        // reach max activities to generate ?
        if (scheduledTiming.repeat.count && scheduledActivities.length >= scheduledTiming.repeat.count) break;
        // compute next activity
        switch (FHIR_ActivityHelper.getScheduledPeriod(scheduledTiming.repeat)) {
          case SCHEDULE_PERIOD.YEAR: // yearly scheduled
            currentDaytime.add(scheduledTiming.repeat.period, "years");
            break;
          case SCHEDULE_PERIOD.MONTH: // monthly scheduled
            currentDaytime.add(scheduledTiming.repeat.period, "months");
            break;
          case SCHEDULE_PERIOD.WEEK: // weekly scheduled
            if (scheduledTiming.repeat.frequency === 1) currentDaytime.add(scheduledTiming.repeat.period, "weeks");
            else if (scheduledTiming.repeat.frequency <= 5) {
              // "5x 4x 3x 2x / week" means "only week days" (not weekend)
              let invalidWeekDay = true;
              do {
                currentDaytime.add(1, "day");
                const day = currentDaytime.isoWeekday();
                // 5x / week ==> monday(1), tuesday(2), wednesday(3), thursday(4), friday(5)
                // 4x / week ==> monday(1), tuesday(2), wednesday(3), thursday(4)
                // 3x / week ==> monday(1), tuesday(2), wednesday(3)
                // 2x / week ==> monday(1), tuesday(2)
                if (day > scheduledTiming.repeat.frequency) continue;
                else invalidWeekDay = false;
              } while (invalidWeekDay);
            } else {
              // default behaviour
              // more than 1 per period, convert week period into days in order to compute frequency
              const days = scheduledTiming.repeat.period * 7;
              const daysInterval = Math.floor(days / (scheduledTiming.repeat.frequency <= 0 ? 1 : scheduledTiming.repeat.frequency));
              currentDaytime.add(daysInterval <= 1 ? 1 : daysInterval, "days");
            }
            break;
          default:
          case SCHEDULE_PERIOD.DAY: // daily scheduled
            if (scheduledTiming.repeat.frequency === 1) currentDaytime.add(scheduledTiming.repeat.period, "days");
            else {
              // more than 1 per period, convert day period into hours in order to compute frequency
              const hours = scheduledTiming.repeat.period * 24;
              const hoursInterval = Math.floor(hours / scheduledTiming.repeat.frequency);
              currentDaytime.add(hoursInterval, "hours");
            }
            break;

          case SCHEDULE_PERIOD.HOUR: // hourly scheduled (really used ?)
            currentDaytime.add(scheduledTiming.repeat.period, "hours");
            break;

          case SCHEDULE_PERIOD.MINUTE: // minutely scheduled (really used ?)
            currentDaytime.add(scheduledTiming.repeat.period, "minutes");
            break;
        }
      }
    } catch (err) {
      FileLogger.error("ActivityService", "Unable to generate scheduled activities:", err);
    }
    return scheduledActivities;
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "isYoutube" })
export class IsYoutubePipe implements PipeTransform {
  constructor() {}

  transform(url: string): any {
    return url.indexOf("youtu") >= 0;
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { FileLogger } from "../helpers/fileLogger";
import { ComputeDrugsService } from "./compute-drugs.service";
import { AccountService } from "./globalDataProvider/account.service";
import { ApiSyncService, ApiSyncServiceStatus } from "./globalDataProvider/core/api-sync.service";
import { RequestSenderService, RequestSenderServiceSyncStatus } from "./globalDataProvider/core/request-sender.service";
import { DrugService } from "./globalDataProvider/drug.service";
import { StatEventService } from "./globalDataProvider/statEvent.service";
import { InfoAppService } from "./info-app.service";
import { MedicalBluetoothSDKPillDispenserService } from "./medical-bluetooth/medical-bluetooth-sdk-pilldispenser.service";
import { MedicalBluetooth } from "./medical-bluetooth/medical-bluetooth.service";
import { MedicalBluetoothFindair } from "./medical-bluetooth/medical-hardware/medical-bluetooth-findair";
import { ConnectionStatus, NetworkService } from "./network.service";
import { NotificationsEventsService } from "./notificationsService/notifications-events.service";
import { PopupService } from "./popup.service";
import { SysAccountService } from "./sys-account.service";

export enum SynchronisationServiceStatus {
  offline,
  alreadyInProgress,
  success,
  noCordovaPlatform,
  missingAccountOrToken,
  error,
}

// TODO gérer lorsque le token est invalide

@Injectable({
  providedIn: "root",
})
export class SynchronisationService {
  private inProgress = false;
  /* 
    BehaviorSubject to control the synchronisation. 
    When it emit true, there is a synchronisation in progress
    When it emit false, there is no synchronisation
  */
  private synchroObs = new BehaviorSubject<boolean>(false);

  private alreadyinitDetectNetworkChange = false;
  private lastNetworkStatus: ConnectionStatus;
  private lastNetworkChange: string;

  constructor(
    private apiSyncService: ApiSyncService,
    private requestSenderService: RequestSenderService,
    private networkService: NetworkService,
    private infoAppService: InfoAppService,
    private accountService: AccountService,
    private sysAccountService: SysAccountService,
    private notificationsEventsService: NotificationsEventsService,
    private statEventService: StatEventService,
    public medicalBluetoothService: MedicalBluetooth,
    protected popupService: PopupService,
    protected computeDrugsService: ComputeDrugsService,
    protected drugService: DrugService
  ) {}

  public initDetectNetworkChange(): void {
    if (!this.alreadyinitDetectNetworkChange) {
      this.lastNetworkStatus = this.networkService.getCurrentNetworkStatus();
      this.lastNetworkChange = moment().format();
      this.alreadyinitDetectNetworkChange = true;
      this.networkService.onNetworkChange().subscribe((connectionStatus) => {
        const timeSinceLastChange = moment(this.lastNetworkChange).diff(moment(), "minute");
        if (connectionStatus === ConnectionStatus.Online && connectionStatus !== this.lastNetworkStatus && timeSinceLastChange > 2) {
          this.lastNetworkStatus = connectionStatus;
          this.lastNetworkChange = moment().format();
          this.syncApp();
        }
      });
    }
  }

  /**
   * Method to launch a synchro and init the detectNetworkChange.
   */
  public async initSynchro(): Promise<SynchronisationServiceStatus> {
    let result: SynchronisationServiceStatus;
    try {
      // launch a synchro
      result = await this.syncApp();
    } catch (error) {
      FileLogger.error("SynchronisationService", "initSynchro", error);
      result = SynchronisationServiceStatus.error;
    }
    return result;
  }

  public async syncApp(forceSynchroCheck = false): Promise<SynchronisationServiceStatus> {
    try {
      if (MedicalBluetoothSDKPillDispenserService.bondedDevices.length > 0) {
        FileLogger.log("SynchronisationService", "makeARequestForGetUsagesHistory");
        MedicalBluetoothFindair.Instance(
          this.medicalBluetoothService,
          this.popupService,
          this.computeDrugsService,
          this.drugService,
          this.accountService
        ).makeARequestForGetUsagesHistory(false);
      }
      if (this.inProgress) {
        return SynchronisationServiceStatus.alreadyInProgress;
      }

      this.inProgress = true;
      this.synchroObs.next(true);

      if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
        this.inProgress = false;
        this.synchroObs.next(false);
        return SynchronisationServiceStatus.offline;
      }
      if (!this.infoAppService.isCordova()) {
        this.inProgress = false;
        this.synchroObs.next(false);
        return SynchronisationServiceStatus.noCordovaPlatform;
      }

      if (
        !this.accountService.cachedAccount ||
        !this.sysAccountService.cachedSysAccount ||
        !this.sysAccountService.cachedSysAccount.token
      ) {
        this.inProgress = false;
        this.synchroObs.next(false);
        return SynchronisationServiceStatus.missingAccountOrToken;
      }

      await this.requestSenderService.promiseResolveWhenReady();
      const requestSenderServiceSyncStatus = await this.requestSenderService.sync();
      FileLogger.log("SynchronisationService", "syncApp -> requestSender sync done");

      switch (requestSenderServiceSyncStatus) {
        case RequestSenderServiceSyncStatus.alreadyInProgress:
          return SynchronisationServiceStatus.alreadyInProgress;
        case RequestSenderServiceSyncStatus.error:
          this.statEventService.newEvent("Sync services unknown error", false, false);
          return SynchronisationServiceStatus.error;
        case RequestSenderServiceSyncStatus.offline:
          return SynchronisationServiceStatus.offline;
        default:
          break;
      }
      // here, we are sure that requestSenderServiceSyncStatus is success

      const apiSyncStatus = await this.apiSyncService.sync(forceSynchroCheck);
      FileLogger.log("SynchronisationService", "syncApp -> api sync done");

      await this.notificationsEventsService.generateAll();
      FileLogger.log("SynchronisationService", "syncApp -> notifications generated");
      this.inProgress = false;
      this.synchroObs.next(false);

      switch (apiSyncStatus) {
        case ApiSyncServiceStatus.success:
          return SynchronisationServiceStatus.success;
        case ApiSyncServiceStatus.alreadyInProgress:
          return SynchronisationServiceStatus.alreadyInProgress;
        default:
          return SynchronisationServiceStatus.error;
      }
    } catch (error) {
      this.inProgress = false;
      this.synchroObs.next(false);
      FileLogger.error("SynchronisationService", "syncApp", error);
      return SynchronisationServiceStatus.error;
    }
  }

  /**
   * Return a promise which is resolve when this service is finish to sync
   */
  public promiseResolveWhenReady(): Promise<void> {
    // this promise is resolve when we can do a requestSender sync
    return new Promise<void>((resolve) => {
      this.synchroObs
        .pipe(
          first((sync) => !sync) // emits only the first time that sync === false
        )
        .subscribe(
          (sync) => {
            FileLogger.log("SynchronisationService", "promiseResolveWhenReady : ", sync);
            resolve();
          },
          (err) => {
            FileLogger.error("SynchronisationService", "sync requestSenderService.synchroObs error", err);
            resolve();
          }
        );
    });
  }
}

import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BasePage } from "src/app/baseClasses/base-page";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-display-photo-modal",
  templateUrl: "./display-photo-modal.component.html",
  styleUrls: ["./display-photo-modal.component.scss"],
})
export class DisplayPhotoModalComponent extends BasePage {
  public imageUrl: string;

  constructor(
    protected navParams: NavParams,
    private modalCtrl: ModalController,
    translateService: TranslateService,
    configService: ConfigurationService,
    infoService: InfoAppService,
    popupService: PopupService
  ) {
    super(translateService, configService, infoService, popupService);
    this.imageUrl = this.navParams.get("imageUrl");
  }

  public dismiss(isDeleted: boolean) {
    this.modalCtrl.dismiss(isDeleted);
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable, forkJoin } from "rxjs";
import { AppConstants } from "../appConstants";
import { Appointment, IAppointment } from "../models/appointment";
import { KeyValueExtra } from "../models/keyValue";
import { AppointmentService } from "./globalDataProvider/appointment.service";
import { LocalStorageService } from "./storage/local-storage.service";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools-helper";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BadgesService {
  public $appointmentBadgeCount = new BehaviorSubject<number>(0 as number);
  public appointments = new Array<IAppointment>();

  constructor(private localStorageService: LocalStorageService, private appointmentService: AppointmentService) {}

  /**
   * Store the date indicating when appointments were seen by patients. And update it in DB
   */
  public setAppointmentSeen(appointments: IAppointment[], date?: string): Promise<IAppointment[]> {
    return new Promise((resolve) => {
      // If no appointments, nothing to do
      if (!appointments.length) return;
      const appointementsToUpdate$: Observable<IAppointment>[] = [];

      for (const app of appointments) {
        if (Appointment.isPersonalAppointment(app)) continue; // ignore user personal appointment
        app.dateSeenByPatient = date ? moment(date).format() : moment().format(); // if a date is passed, use it, else use "now"
        appointementsToUpdate$.push(this.appointmentService.update(app, false).pipe(first()));
      }
      // It is necessary to wait for every observable to finsih before resolving the promise
      forkJoin(appointementsToUpdate$).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        (error) => {
          FileLogger.error("BadgeService", "setAppointmentSeen", error);
        },
        () => {
          // Resolve the promise with the updated appointments
          resolve(appointments);
        }
      );
    });
  }

  /**
   * For retrocompatibility :
   * Retrieves stored data in localstorage representing moments when appointments were seen before dateSeenByPatient was implemented.
   * Returns an array of KeyValueExtra objects for seen appointments,
   * or an empty array if data is missing or invalid.
   * @returns A Promise resolving to an array of KeyValueExtra objects.
   * @throws {Error} If data retrieval or parsing encounters an issue,
   *                it returns an empty array.
   */
  public async getAppointmentSeenInLocalstorage(): Promise<KeyValueExtra[]> {
    try {
      const value: KeyValueExtra[] = JSON.parse(await this.localStorageService.getData(AppConstants.PRM_APPOINTMENT_BADGE, false));
      return !value.length ? [] : value;
    } catch (err) {
      return [];
    }
  }

  /**
   * Retrieves a list of appointments that have either been recently modified or remain unseen since the last patient interaction.
   * @param appointments An array of appointments to be checked for recent modifications or unseen status.
   * @returns An array of appointments that have either not been previously seen or modified.
   */
  public async getNewAppointments(appointments: IAppointment[]): Promise<IAppointment[]> {
    // RETROCOMPATIBILITY : Retrieve appointments that have been seen in local storage and mark them as seen.
    const appointmentSeenInLocalstorage = await this.getAppointmentSeenInLocalstorage();
    appointmentSeenInLocalstorage.forEach((element) => {
      const found = appointments.find((el) => el._id === element.key);
      if (found) {
        this.setAppointmentSeen([found], element.extra);
      }
    });

    // Remove the appointment badge from local storage.
    this.localStorageService.remove(AppConstants.PRM_APPOINTMENT_BADGE);

    // Filter appointments to include only those that have not been seen by the patient,
    // are in the future, and are not personal appointments.
    const newAppointments = appointments.filter(
      (appointment) =>
        // Check if the appointment has not been seen by the patient
        !Tools.isDefined(appointment.dateSeenByPatient) &&
        // Check if the appointment is in the future. /!\ In this case, isBefore != !isAfter
        !moment().isAfter(appointment.start, "day") &&
        // Ignore personal appointments
        !Appointment.isPersonalAppointment(appointment)
    );

    // Return the filtered array of new appointments.
    return newAppointments;
  }

  /**
   * Compute badge for appointment
   */
  public async computeAppointmentBadgeCount(): Promise<void> {
    const dataReader = this.appointmentService.getDataReader();
    for await (const data of dataReader) {
      this.appointments = data;
      // no appointments
      if (!this.appointments || this.appointments.length === 0) {
        this.$appointmentBadgeCount.next(0);
      } else {
        // gather all new appointments
        try {
          const newApps = await this.getNewAppointments(this.appointments);
          this.$appointmentBadgeCount?.next(!newApps || newApps.length === 0 ? 0 : newApps.length);
        } catch (err) {
          FileLogger.error("BadgesService", "computeAppointmentBadgeCount", err);
        }
      }
    }
  }
}

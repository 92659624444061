import { ChangeDetectorRef, Component } from "@angular/core";
import { AlertController, IonSlides, LoadingController, ModalController, NavController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { from, of } from "rxjs";
import { catchError, concatMap, filter, finalize, map, takeUntil } from "rxjs/operators";
import {
  IKnowMedia,
  IKnowledgeBase,
  IKnowledges,
  KNOW_CATEGORY,
  KNOW_DOC_CATEGORY,
  KNOW_DOC_TYPE,
  KnowledgeBase,
} from "src/app/helpers/knowledge-helper";
import { DeviceState, EXTERNAL_RESSOURCE_TYPE, IExternalRessource } from "src/app/models/externalRessource";
import { BluetoothKnowledgeService } from "src/app/services/globalDataProvider/bluetooth-knowledge.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { ExternalRessourceService } from "src/app/services/globalDataProvider/external-ressource.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { MedicalBluetooth } from "src/app/services/medical-bluetooth/medical-bluetooth.service";
import { PopupService } from "src/app/services/popup.service";
import { AppConstants } from "../appConstants";
import { BasePage } from "../baseClasses/base-page";
import { FileLogger } from "../helpers/fileLogger";
import { OnlineDevicesService } from "../services/deeplinks/onlineDevices.service";
import { GetParametersPageService } from "../services/get-parameters-page.service";
import { AccountService } from "../services/globalDataProvider/account.service";
import { StatEventService } from "../services/globalDataProvider/statEvent.service";
import { GoToPageService } from "../services/go-to-page.service";
import { HelpService } from "../services/help.service";
import {
  MedicalBluetoothSDKError,
  MedicalBluetoothSDKErrorType,
  MedicalBluetoothSDKPillDispenserService,
} from "../services/medical-bluetooth/medical-bluetooth-sdk-pilldispenser.service";
import { MedicalBluetoothStatus } from "../services/medical-bluetooth/medical-bluetooth-status.service";
import { ModalKnowledgeService } from "../services/modal-knowledge.service";
import { SLIDE_OPTS } from "./slideOpts";

@Component({
  selector: "app-my-devices",
  templateUrl: "./my-devices.page.html",
  styleUrls: ["./my-devices.page.scss"],
})
export class MyDevicesPage extends BasePage {
  public EXTERNAL_RESSOURCE_TYPE = EXTERNAL_RESSOURCE_TYPE;
  public ressources: IExternalRessource[] = [];
  public activeSnomed: string[] = [];
  public activeCategory = "";
  public activeHardware: IExternalRessource;
  public selectedHardware: IExternalRessource;
  public helpSeen = false;
  public isLoading = true;

  // Bluetooth
  public bonded = false;
  private deviceFound = false;

  public slideOpts = SLIDE_OPTS;
  public isModal: boolean;
  public i18nCategories: string[] = [];
  public categories: string[] = [];

  public drugId = "";
  public code: string; // loinc or atc code

  constructor(
    protected infoService: InfoAppService,
    protected loadingCtrl: LoadingController,
    protected translateSvc: TranslateService,
    protected alertCtrl: AlertController,
    protected externalRessourceService: ExternalRessourceService,
    protected medicalBluetoothService: MedicalBluetooth,
    protected cdr: ChangeDetectorRef,
    protected translateService: TranslateService,
    protected navParams: NavParams,
    protected getParametersPageService: GetParametersPageService,
    protected navCtrl: NavController,
    protected modalKnowledge: ModalKnowledgeService,
    protected modalCtrl: ModalController,
    protected configService: ConfigurationService,
    protected bluetoothKnowledgeService: BluetoothKnowledgeService,
    protected popupService: PopupService,
    protected helpService: HelpService,
    private statEventService: StatEventService,
    private medicalBluetoothStatus: MedicalBluetoothStatus,
    private onlineDevicesService: OnlineDevicesService,
    private accountService: AccountService,
    private medicalBluetoothSDKService: MedicalBluetoothSDKPillDispenserService,
    private goToPage: GoToPageService
  ) {
    super(translateService, configService, infoService, popupService);
    this.isModal = navParams.get("isModal");
    this.drugId = navParams.get("drugId");
    this.code = navParams.get("loinc");
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.isLoading = true;
    this.helpService.isHelpPageSeenOn(AppConstants.PAGE_BLUETOOTH).then((seen) => {
      this.helpSeen = seen;
    });
    this.updateList();
    this.accountService
      .watchData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((a) => {
        if (this.selectedHardware) {
          const found = a.connectedDevices?.find(
            (d) => d.externalRessourceRef === this.selectedHardware.reference && d.deviceState === DeviceState.STARTED
          );
          if (found) {
            this.bonded = true;
            this.cdr.detectChanges();
          }
        }
      });
  }

  private loadI18nCategories() {
    const categories = this.ressources.reduce((acc: string[], next: IExternalRessource) => {
      let ressourceCategories = next.categories;
      // Search category translations in externalRessource object
      if (this.translateService.getDefaultLang() && next.i18n.length > 0) {
        const i18nLang = next.i18n.find((t) => t.lang === this.translateService.getDefaultLang());
        if (i18nLang) {
          ressourceCategories = next.categories.map((category, index) => {
            const i18nCategories = i18nLang.values.find((t) => t.key === "categories_" + index);
            return i18nCategories ? i18nCategories.value : category;
          });
        }
      }
      acc.push(...ressourceCategories);
      return acc;
    }, []);
    this.i18nCategories = Array.from(new Set(categories));
  }

  private loadCategories() {
    const categories = this.ressources.reduce((acc: string[], next: IExternalRessource) => {
      acc.push(...next.categories);
      return acc;
    }, []);
    this.categories = Array.from(new Set(categories));
    if (this.code) {
      this.openCategory(this.categories[0]);
    }
  }

  public dismiss(): void {
    this.externalRessourceService.getFreshestData();
    this.modalCtrl.dismiss();
  }

  private getHardwareByCategory(category: string) {
    return this.ressources.filter((ressource) => ressource.categories.indexOf(category) > -1);
  }

  private async updateList() {
    const ressources = await this.externalRessourceService.getFirstDataAvailable("", [
      EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE,
      EXTERNAL_RESSOURCE_TYPE.ONLINE_HARDWARE,
      EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK,
    ]);
    if (this.code) {
      this.ressources = ressources.filter((ressource) => {
        return ressource.meta.availableLoinc && ressource.meta.availableLoinc.indexOf(this.code) > -1;
      });
    } else {
      this.ressources = ressources;
    }
    if (!this.activeCategory) {
      const navParamCategory = this.isModal
        ? this.navParams.get("category")
        : this.getParametersPageService.getValueOfActivePage("category", undefined);
      if (navParamCategory) {
        this.openCategory(navParamCategory);
      } else if (this.categories.length > 0) {
        this.openCategory(this.categories[0]);
      }
    }
    this.loadCategories();
    this.loadI18nCategories();
    this.isLoading = false;
  }

  public openCategory(category: string): void {
    if (category === this.activeCategory) {
      this.activeCategory = "";
      this.activeHardware = undefined;
    } else {
      this.activeCategory = category;

      const hardwares = this.getHardwareByCategory(this.activeCategory);
      if (hardwares.length > 0) {
        this.activeHardware = hardwares[0];
      } else {
        this.activeHardware = undefined;
      }
    }
  }

  public async updateActiveHardware(slides: CustomEvent): Promise<void> {
    const hardwares = this.getHardwareByCategory(this.activeCategory);
    let slideIndex = await (slides.target as unknown as IonSlides).getActiveIndex();
    if (slideIndex > hardwares.length) {
      slideIndex = hardwares.length;
    } else if (slideIndex < 0) {
      slideIndex = 0;
    }
    if (hardwares.length > slideIndex) {
      this.activeHardware = hardwares[slideIndex];
    }
  }

  public async finish(): Promise<void> {
    this.selectedHardware = undefined;
    if (this.getParametersPageService.getValueOfActivePage("category", undefined)) {
      this.navCtrl.pop();
    }
  }

  private async initBluetooth() {
    try {
      await this.medicalBluetoothService.ready();
    } catch (error) {
      this.translateService
        .get(["myBleDevices.pleaseActivateBluetooth", "myBleDevices.inactiveBluetooth"])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(async (translations) => {
          const alert = await this.alertCtrl.create({
            buttons: ["Ok"],
            subHeader: translations["myBleDevices.pleaseActivateBluetooth"],
            header: translations["myBleDevices.inactiveBluetooth"],
          });
          alert.present();
        });
      this.selectedHardware = undefined;
      throw error;
    }
  }

  public async bondActiveHardware(): Promise<void> {
    if (!this.activeHardware) {
      return;
    }

    this.selectedHardware = this.activeHardware;
    if (this.selectedHardware.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE) {
      await this.bondSelectedBluetoothDevice();
    } else if (this.selectedHardware.type === EXTERNAL_RESSOURCE_TYPE.ONLINE_HARDWARE) {
      await this.onlineDevicesService.onlineDeviceAuthenticate(this.selectedHardware.meta.onlineDevice, this.selectedHardware.reference);
    } else if (this.selectedHardware.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK) {
      try {
        await this.medicalBluetoothSDKService.bond(this.selectedHardware, this.drugId);
        this.bonded = true;
        this.statEventService.newEvent("Bonded bluetooth device sdk", false);
        this.cdr.detectChanges();
      } catch (error) {
        if (error instanceof MedicalBluetoothSDKError) {
          switch (error.type) {
            case MedicalBluetoothSDKErrorType.FAILED_INIT:
              this.selectedHardware = undefined;
              this.bonded = false;
              this.cdr.detectChanges();
              break;
            case MedicalBluetoothSDKErrorType.CANCEL_SELECTION:
              this.selectedHardware = undefined;
              this.bonded = false;
              this.cdr.detectChanges();
              this.displayBluetoothNotInList();
              break;
            default:
              this.selectedHardware = undefined;
              this.bonded = false;
              this.cdr.detectChanges();
              this.displayBluetoothError();
              break;
          }
        } else {
          this.selectedHardware = undefined;
          this.bonded = false;
          this.cdr.detectChanges();
          this.displayBluetoothError();
        }
      }
    }
  }

  private async bondSelectedBluetoothDevice(): Promise<void> {
    try {
      await this.initBluetooth();
    } catch (error) {
      return;
    }

    let scanServices = [];
    if (this.selectedHardware.meta.availableLoinc && this.selectedHardware.meta.availableLoinc.length > 0) {
      scanServices = [this.selectedHardware.meta.medicalBluetoothService];
    }

    this.deviceFound = false;
    this.bonded = false;
    this.medicalBluetoothStatus.isBleConnectedToIOS$.next(undefined);

    try {
      const scanner = await this.medicalBluetoothService.scan();
      scanner
        .pipe(
          takeUntil(this.onDestroy$),
          filter((device) => !this.deviceFound && device.name && device.name.indexOf(this.selectedHardware.reference) > -1),
          concatMap((device) => {
            this.deviceFound = true;
            return from(this.medicalBluetoothService.stopScan()).pipe(
              takeUntil(this.onDestroy$),
              concatMap(() => {
                if (this.selectedHardware.meta.noBonding === true) {
                  this.medicalBluetoothService.addBondedDevices({ ...device, bonded: true, services: scanServices });

                  this.bonded = true;
                  this.statEventService.newEvent("Bonded bluetooth device", false);
                  this.cdr.detectChanges();
                  return of(null);
                } else {
                  return this.medicalBluetoothService.bond({ ...device, services: scanServices }, this.onDestroy$).pipe(
                    takeUntil(this.onDestroy$),
                    map(() => {
                      this.bonded = true;
                      this.statEventService.newEvent("Bonded bluetooth device", false);
                      this.cdr.detectChanges();
                    })
                  );
                }
              })
            );
          }),
          catchError((err) => {
            FileLogger.error("MyBleDevicesPage", "bondActiveHardware", JSON.stringify(err));
            this.displayBluetoothError();
            this.selectedHardware = undefined;
            this.deviceFound = false;
            this.statEventService.newEvent("Got an error while bonding bluetooth device", false);
            this.cdr.detectChanges();
            return of(null);
          }),
          finalize(() => {
            this.medicalBluetoothService.stopScan();
          })
        )
        .subscribe();
    } catch (error) {
      this.statEventService.newEvent("Got an error while bonding bluetooth device", false);
      FileLogger.error("MyBleDevicesPage", "bondActiveHardware try catch", JSON.stringify(error));
      this.displayBluetoothError();
      this.selectedHardware = undefined;
    }
  }

  public async unbondActiveHardware(): Promise<void> {
    if (!this.activeHardware) {
      return;
    }
    if (this.activeHardware.type === EXTERNAL_RESSOURCE_TYPE.ONLINE_HARDWARE) {
      const success = await this.onlineDevicesService.removeOnlineDevice(this.activeHardware.reference);
      if (success) {
        this.isLoading = true;
        this.cdr.detectChanges();
      }
      this.updateList();
    } else if (this.activeHardware.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK) {
      this.medicalBluetoothSDKService.removeByDeviceName(this.activeHardware.reference);
      this.isLoading = true;
      this.cdr.detectChanges();
      this.updateList();
    } else {
      const bondedDevice = this.medicalBluetoothService.bondedDevices.find((device) => {
        return this.activeHardware.reference && device.name.indexOf(this.activeHardware.reference) > -1;
      });

      if (bondedDevice) {
        try {
          await this.initBluetooth();
          const success = await this.medicalBluetoothService.unbond(bondedDevice);
          if (success) {
            this.isLoading = true;
            this.cdr.detectChanges();
          }
          this.updateList();
        } catch (error) {
          //
        }
      }
    }
  }

  private displayBluetoothError() {
    this.translateService
      .get(["myBleDevices.cannotConnect", "myBleDevices.errorBluetooth"])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (translations) => {
        const alert = await this.alertCtrl.create({
          buttons: ["Ok"],
          subHeader: translations["myBleDevices.cannotConnect"],
          header: translations["myBleDevices.errorBluetooth"],
        });
        alert.present();
      });
  }

  private displayBluetoothNotInList() {
    this.translateService
      .get(["myBleDevices.cannotConnect", "myBleDevices.errorBluetooth", "myBleDevices.errorNotVisible"])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (translations) => {
        const alert = await this.alertCtrl.create({
          buttons: ["Ok"],
          subHeader: translations["myBleDevices.errorNotVisible"],
          header: translations["myBleDevices.errorBluetooth"],
        });
        alert.present();
      });
  }

  /**
   * Show help page
   */
  public showHelp(): void {
    this.helpSeen = true;
    this.helpService.showHelp(AppConstants.PAGE_BLUETOOTH, "help.bt");
  }

  /**
   * Display activity long description
   */
  public async onDescriptionDetail(name: string): Promise<void> {
    const kn: IKnowledges[] = await this.bluetoothKnowledgeService.getFreshestData([name]);
    const mainKnowledgeDescription = KnowledgeBase.getMainKnowledge(kn, KNOW_DOC_CATEGORY.DESCRIPTION);
    let medias: IKnowMedia[];
    this.statEventService.newEvent("Opened bluetooth device description", false);

    if (mainKnowledgeDescription) {
      medias = KnowledgeBase.getMediaCurrentLang(mainKnowledgeDescription, KNOW_DOC_TYPE.TEXT, this.configService.getCurrentLanguage());
    }
    if (medias?.length) {
      this.showKnownledgeModal(medias[0]);
    } else {
      this.translateService
        .get(["myBleDevices.noDescription"])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((translations) => {
          const descriptionMedia: IKnowMedia = {
            type: 1,
            category: 0,
            label: name,
            language: this.configService.getCurrentLanguage(),
            content: translations["myBleDevices.noDescription"],
          };
          this.showKnownledgeModal(descriptionMedia);
        });
    }
  }

  /**
   * Show help-device page
   */
  private showKnownledgeModal(media: IKnowMedia) {
    const knowledgeBase: IKnowledgeBase = {
      _id: null,
      modified: null,
      entityStatus: null,
      author: null,
      organization: null,
      healthcareservice: null,
      snomedReference: null,
      category: KNOW_CATEGORY.DEVICE,
      documentCategory: KNOW_DOC_CATEGORY.DESCRIPTION,
      criteria: null,
      medias: [media],
    };

    this.modalKnowledge.presentModalKnowledge(knowledgeBase.medias[0]?.label, [knowledgeBase], null);
  }

  public goToDrugPage(): void {
    this.modalCtrl.dismiss();
    this.modalCtrl.dismiss(undefined, undefined, AppConstants.SETTINGS_MODAL);
    this.goToPage.drugPage();
  }
}

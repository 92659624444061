import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ArrayHelper } from "src/app/helpers/array-helper";
import { FHIR_ActivityHelper } from "src/app/helpers/fhirActivityHelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { Careplan, ICareplan } from "src/app/models/careplan";
import { IRelatedPerson } from "src/app/models/relatedPerson";
import { LocalStorageService } from "../storage/local-storage.service";
import { AccountService } from "./account.service";
import { ConfigurationService } from "./configuration.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { RelatedKnowledgeService } from "./related-knowledge.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class RelatedCareplansService
  extends BasicSyncService<ICareplan, ICareplan[]>
  implements StaticImplements<INeedRefresh, typeof RelatedCareplansService>
{
  public get needRefresh(): { value: boolean } {
    return RelatedCareplansService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  private currentCareplan$ = new BehaviorSubject<ICareplan | null>(null);

  private lastValueOfParam = "";
  private storageKey = "RelatedCareplanLastValue";

  public get entityStoreKey(): string {
    return super.entityStoreKey + this.lastValueOfParam;
  }

  constructor(
    protected dataService: DataService,
    private configService: ConfigurationService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    super(dataService);
  }

  public getUrl(): string {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<ICareplan[]>([]);
    this.currentCareplan$ = new BehaviorSubject<ICareplan | null>(null);
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.currentCareplan$.next(null);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "relatedCareplans_",
      entityStoreKey: "list",
      getUrl: "/relatedCareplans?lang=",
      setUrl: null,
      expirationDays: 10,
      encrypted: true,
    };
  }

  /**
   * This should return all the other services that depend on the current one and
   * need to be refreshed if the current one the current one is.
   * (ex: the knowledgeService depends on the careplan service)
   */
  public getDependentServicesRefresh(): any {
    return [RelatedKnowledgeService];
  }
  /**
   * Watch the changes in the currentCareplan
   * @return a observable with the currentCareplan
   */
  public watchCurrentCareplan(): BehaviorSubject<ICareplan> {
    return this.currentCareplan$;
  }
  /**
   * Returns the current state of the currentCareplan
   */
  public peekCurrentCareplan(): ICareplan {
    return this.currentCareplan$.value;
  }
  /**
   * Tell all those that watch the currentCareplan that there's a new version
   * @param currentCareplan the new currentCareplan
   */
  public pokeCurrentCareplan(careplan: ICareplan): void {
    this.currentCareplan$.next(careplan);
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, false);
    } catch (err) {
      this.lastValueOfParam = "";
    }
  }

  public clear(): void {
    super.clear();
    this.lastValueOfParam = "";
  }

  public async *getDataReader(related: IRelatedPerson = null): AsyncGenerator<ICareplan[], ICareplan[], ICareplan[]> {
    try {
      if (this.accountService.isNotRelated()) {
        yield [];
        return [];
      }

      const paramObject = Object.assign({}, this.defaultDataParameter);
      paramObject.getUrl += this.configService.getCurrentLanguage();
      paramObject.entityStoreKey += this.configService.getCurrentLanguage();
      this.lastValueOfParam = this.configService.getCurrentLanguage();
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, false);

      const dataReader = this.dataService.readv2<ICareplan, ICareplan[]>(paramObject, false, this);
      let d: ICareplan[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, related);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("RelatedCareplansService", "getDataReader()", err);
      yield [];
      return [];
    }
  }
  private processData(dataResult: ICareplan[], related: IRelatedPerson) {
    try {
      const filteredCareplans = dataResult.filter((c) => {
        return c.status !== Careplan.CAREPLAN_STATUS_ARCHIVE;
      });
      if (related) {
        return filteredCareplans.filter((c) => {
          return c.subject.reference === related.patient.reference;
        });
      } else {
        return filteredCareplans;
      }
    } catch (err) {
      FileLogger.error("RelatedCareplansService", "Error while processing relatedCareplansService data: ", err);
      return [];
    }
  }

  public async listSnomedRef(onlyCareplan = false): Promise<string[]> {
    const careplans = await this.getFirstDataAvailable();
    return careplans
      .map((careplan) => {
        const snomedActivities = onlyCareplan
          ? ([] as string[])
          : careplan.activity
              .map((activity) => {
                return FHIR_ActivityHelper.getActivitySnomedRef(activity);
              })
              .reduce((acc, it) => [...acc, ...it], []);
        return snomedActivities.concat(FHIR_ActivityHelper.getCareplanSnomedRef(careplan));
      })
      .reduce((acc, it) => [...acc, ...it], [])
      .filter(ArrayHelper.onlyUnique)
      .map(Tools.deleteAcccentSpecialcharacter);
  }
}

import { BluetoothLE, OperationResult } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { Observable, Subject } from "rxjs";
import { MedicalBluetoothData } from "../medical-bluetooth-data";
import { MedicalBluetoothCharacteristic, MedicalBluetoothDevice, MedicalBluetoothService } from "../medical-bluetooth.service";
import { InfoAppService } from "../../info-app.service";
import { finalize, map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { MedicalBluetoothStatus } from "../medical-bluetooth-status.service";

export interface MedicalBluetoothLungMonitorData {
  fev1: number; // Liters
  fev6: number; // Liters / min
  ratio: number; // FEV1 / FEV6
  fef2575: number; // liters / sec
  fev1BP: number; // FEV 1 Personal Best (Liters)
  valid: boolean;
}

export class MedicalBluetoothLungMonitor extends MedicalBluetoothData {
  public serviceType = MedicalBluetoothService.VITALOGRAPH_LUNG_MONITOR;
  public descriptor = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.VITALOGRAPH_BREATH_MEASUREMENT,
    service: MedicalBluetoothService.VITALOGRAPH_LUNG_MONITOR,
  };

  public constructor(
    device: MedicalBluetoothDevice,
    handler: Observable<string>,
    infoAppService: InfoAppService,
    bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>,
    translateService: TranslateService,
    searchPreviousData: boolean,
    medicalBluetoothStatus: MedicalBluetoothStatus
  ) {
    super(device, handler, infoAppService, bluetoothle, onDestroy$, translateService, searchPreviousData, medicalBluetoothStatus);
  }

  public readData() {
    this.standby = false;
    let partialData = "";
    let timeOut = null;
    return this.bluetoothle.subscribe(this.descriptor).pipe(
      map((r) => {
        const data = r as unknown as OperationResult;
        if (data.status === "subscribedResult") {
          partialData += atob(data.value);
          if (timeOut === null) {
            timeOut = setTimeout(() => {
              const decodedData = this.decodeData(btoa(partialData));
              partialData = "";
              timeOut = null;

              if (decodedData.valid) {
                this._data.next(decodedData);
              } else {
                this._data.next(null);
                this._data.complete();
              }
            }, 1000);
          }
        }
      }),
      finalize(() => {
        this._data.complete();
      })
    );
  }

  public getData(): Subject<MedicalBluetoothLungMonitorData> {
    return this._data;
  }

  public getHumanReadableData(data: MedicalBluetoothLungMonitorData) {
    return `${data.fev1}L - ${data.fev6} L/min.`;
  }

  public decodeData(data: string): MedicalBluetoothLungMonitorData {
    const bytes = this.bytesFromString(data);
    const view = new DataView(bytes.buffer);

    let fev1 = "";
    fev1 += String.fromCharCode(view.getUint8(14));
    fev1 += String.fromCharCode(view.getUint8(15));
    fev1 += String.fromCharCode(view.getUint8(16));

    let fev6 = "";
    fev6 += String.fromCharCode(view.getUint8(17));
    fev6 += String.fromCharCode(view.getUint8(18));
    fev6 += String.fromCharCode(view.getUint8(19));

    let ratio = "";
    ratio += String.fromCharCode(view.getUint8(20));
    ratio += String.fromCharCode(view.getUint8(21));
    ratio += String.fromCharCode(view.getUint8(22));

    let fef2575 = "";
    fef2575 += String.fromCharCode(view.getUint8(23));
    fef2575 += String.fromCharCode(view.getUint8(24));
    fef2575 += String.fromCharCode(view.getUint8(25));

    let fev1BP = "";
    fev1BP += String.fromCharCode(view.getUint8(26));
    fev1BP += String.fromCharCode(view.getUint8(27));
    fev1BP += String.fromCharCode(view.getUint8(28));

    const valid = String.fromCharCode(view.getUint8(65));

    return {
      // tslint:disable-next-line: radix
      fev1: Number.parseInt(fev1) / 100,
      // tslint:disable-next-line: radix
      fev6: Number.parseInt(fev6),
      // tslint:disable-next-line: radix
      ratio: Number.parseInt(ratio) / 100,
      // tslint:disable-next-line: radix
      fef2575: Number.parseInt(fef2575) / 100,
      // tslint:disable-next-line: radix
      fev1BP: Number.parseInt(fev1BP) / 100,
      valid: valid === "0",
    };
  }
}

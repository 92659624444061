import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, concatMap, map, takeLast, tap } from "rxjs/operators";
import { AppConstants } from "../appConstants";
import { InfoAppService } from "./info-app.service";
import { IApiResponse } from "../models/iapi-response";
import { combineLatest, from, Observable, of } from "rxjs";
import { SysAccountService } from "./sys-account.service";
import { ServerResponse, SERVER_RESPONSE_TYPE } from "../helpers/server-response-helper";
import { FileLogger } from "../helpers/fileLogger";
import { SysAccount } from "../models/sysaccount";

export enum ContentType {
  JSON = "application/json",
  FORM_URL_ENCODED = "application/x-www-form-urlencoded",
}
@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient, private infoAppService: InfoAppService, private sysAccountService: SysAccountService) {}

  public getUrlApiObs(): Observable<string> {
    return from(this.infoAppService.getUrlAPI());
  }

  /**
   *  HTTP Get decorated with token
   */
  public get(url: string, forceToken?: string, parameters?: { [k: string]: any }): Observable<IApiResponse> {
    return this.preprocessingHttpRequest(forceToken, parameters).pipe(
      concatMap(([urlApi, options]: [string, { headers: HttpHeaders; params: any }]) => {
        return this.preprocessingResponse(this.http.get(urlApi + url, options));
      })
    );
  }

  public getExternal(
    externalUrl: string,
    contentType: ContentType,
    parameters?: any,
    token?: string,
    _origin?: string
  ): Observable<unknown> {
    const options = {
      headers: this.externalHeadersJson(contentType, token),
    };
    const urlParam = parameters.toString();
    return this.http.get(externalUrl + "?" + urlParam, options);
  }
  public async postExternal(
    externalUrl: string,
    contentType: ContentType,
    body?: unknown,
    token?: string,
    parameters?: { [k: string]: any }
  ): Promise<unknown> {
    const options = {
      headers: this.externalHeadersJson(contentType, token),
      params: parameters,
    };
    return this.http.post(externalUrl, body, options).toPromise();
  }

  private externalHeadersJson(contentType: ContentType, token?: string, _origin?: string): HttpHeaders {
    return new HttpHeaders({
      Authorization: token ? "Bearer " + token : undefined,
    });
  }

  /**
   * Get data from server (promise version)
   * @param url
   * @param forceToken
   * @param parameters
   */
  public async getWithPromise(url: string, forceToken?: string, parameters?: { [k: string]: any }): Promise<IApiResponse> {
    const apiUrl = await this.infoAppService.getUrlAPI();
    const options = await this.getRequestOptions(forceToken, parameters);
    return this.preprocessingResponse(this.http.get(apiUrl + url, options)).toPromise();
  }

  public async postWithPromise(url: string, data: any): Promise<IApiResponse> {
    const apiUrl = await this.infoAppService.getUrlAPI();
    const options = await this.getRequestOptions();
    return this.preprocessingResponse(this.http.post(apiUrl + url, data, options)).toPromise();
  }

  public post(url: string, data: any): Observable<IApiResponse> {
    return this.preprocessingHttpRequest().pipe(
      concatMap(([urlApi, options]: [string, { headers: HttpHeaders; params: any }]) => {
        return this.preprocessingResponse(this.http.post(urlApi + url, data, options));
      })
    );
  }

  public put(url: string, data: any): Observable<IApiResponse> {
    return this.preprocessingHttpRequest().pipe(
      concatMap(([urlApi, options]: [string, { headers: HttpHeaders; params: any }]) => {
        return this.preprocessingResponse(this.http.put(urlApi + url, data, options));
      })
    );
  }

  public delete(url: string): Observable<IApiResponse> {
    return this.preprocessingHttpRequest().pipe(
      concatMap(([urlApi, options]: [string, { headers: HttpHeaders; params: { [k: string]: any } }]) => {
        return this.preprocessingResponse(this.http.delete(urlApi + url, options));
      })
    );
  }
  public async deleteWithPromise(url: string, parameters?: { [k: string]: any }): Promise<IApiResponse> {
    const apiUrl = await this.infoAppService.getUrlAPI();
    const options = await this.getRequestOptions(null, parameters);
    return this.preprocessingResponse(this.http.delete(apiUrl + url, options)).toPromise();
  }

  private preprocessingHttpRequest(forceToken?: string, parameters?: { [k: string]: any }): Observable<unknown[]> {
    return combineLatest([this.getUrlApiObs(), this.getHeaderObs(forceToken)]).pipe(
      takeLast(1),
      map((result) => {
        return [
          result[0], // urlApi
          {
            // options
            headers: result[1],
            params: parameters,
          },
        ];
      })
    );
  }

  private async getRequestOptions(
    forceToken?: string,
    parameters?: { [k: string]: unknown }
  ): Promise<{ headers: HttpHeaders; params: { [k: string]: any } }> {
    const headers = await this.getHeader(forceToken);
    return {
      // options
      headers: headers,
      params: parameters,
    };
  }

  private getHeaderObs(forceToken?: string): Observable<HttpHeaders> {
    return from(this.getHeader(forceToken));
  }

  private async getHeader(forceToken?: string): Promise<HttpHeaders> {
    let header = new HttpHeaders({
      "Content-Type": ContentType.JSON,
      accept: ContentType.JSON,
      "x-api-version": AppConstants.CC_API_VERSION,
      "x-app-version": await this.infoAppService.getVersion(),
    });

    const token = this.sysAccountService.cachedSysAccount?.token;

    if (forceToken) {
      header = header.append("x-access-token", forceToken);
    } else if (token) {
      // add token in header
      header = header.append("x-access-token", token);
    } else {
      // maybe the cache hasn't been made yet, in which case we'll force it.
      const sysAccount: SysAccount = await this.sysAccountService.getSysAccount().catch(() => {
        FileLogger.error("getHeader", "no sysaccount load");
        return undefined;
      });
      if (sysAccount?.token) {
        // add token in header
        header = header.append("x-access-token", sysAccount?.token);
      }
    }

    const platformInfo = this.infoAppService.getPlatformInfo();
    if (platformInfo["x-browser-info"]) {
      header = header.append("x-browser-info", platformInfo["x-browser-info"]);
    } else if (platformInfo["x-phone-info"]) {
      header = header.append("x-phone-info", platformInfo["x-phone-info"]);
    }

    return header;
  }

  private preprocessingResponse(httpObs: Observable<object>): Observable<IApiResponse> {
    return httpObs.pipe(
      catchError((err) => {
        const errorRep = ServerResponse.type(err.error);
        switch (errorRep) {
          case SERVER_RESPONSE_TYPE.OTHER_ERROR:
            if (err.status === 0) {
              // OFFLINE !
              return of({
                success: false,
                message: ServerResponse.SERVER_UNREACHABLE.message,
                data: ServerResponse.SERVER_UNREACHABLE.code,
              } as IApiResponse);
            } else {
              return of({
                success: false,
                message: "ERROR",
                data: null,
                refreshToken: null,
              } as IApiResponse);
            }

          default:
            break;
        }
        return of(err.error);
      }),
      tap((rep: IApiResponse) => {
        if (rep.success && rep.refreshToken && (rep.refreshToken as string).length > 0) {
          this.sysAccountService.setRefreshToken(rep.refreshToken);
        }
      })
    );
  }
}

import { Identifier, IEntity, Reference } from "./sharedInterfaces";

export enum DELETE_REQUEST_STATUS {
  NEW = 0,
  ONGOING = 1,
  CANCELED = 2,
  WAITING_FOR_ARCHIVE_DOWNLOAD = 3,
  ARCHIVE_DOWNLOADED = 4,
  DONE = 5,
}

export interface IDeleteRequest extends IEntity {
  identifier: Identifier;
  patient: {
    caremateIdentifier: string;
    email: string;
    firstname: string;
    lastname: string;
  };
  requestDate: string;
  requestStatus: DELETE_REQUEST_STATUS;
  treatmentDate?: string;
  controller?: Reference;
  wantArchive: boolean;
  archiveFilename?: string;
  reason: string;
  token?: string;
}

import { FileLogger } from "src/app/helpers/fileLogger";
import { PopupService } from "../../popup.service";
import { MedicalBluetooth } from "../medical-bluetooth.service";
import {
  MedicalBluetoothSDKDataPillDispenser,
  Usage,
  Device,
  MedicalBluetoothSDKConstructor,
  PopupBluetooth,
} from "../medical-bluetooth-sdk-data-pilldispenser";
import { ComputeDrugsService } from "../../compute-drugs.service";
import { DrugService } from "../../globalDataProvider/drug.service";
import { AccountService } from "../../globalDataProvider/account.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";
import { BehaviorSubject } from "rxjs";
import { filter, first, timeout } from "rxjs/operators";

declare let cordova: any;

export interface FindAirDevice extends Device {
  address: string;
  state: number;
  type: number;
  maxDoses: number;
  usedDoses: number;
  educationalMode: boolean;
  silentMode: boolean;
  locatingSound: boolean;
  reminders: string;
  batteryLevel: string;
  blindMode: boolean;
  bottleIn: boolean;
  blindTill: string;
  lastSync: string;
}

export interface FindAirUsage extends Usage {
  address: string;
  date: string;
  counterState: number;
  timezone: string;
  environmentalData: unknown;
  faTime: string;
  faUsageTime: string;
  isEducational: boolean;
  isNotMeasured: boolean;
  shaked: boolean;
  tooLate: string;
  usedDoses: number;
  maxDoses: number;
  battery: string;
  appPackage: string;
  flow: string;
  tooEarly: string;
  tooStrong: string;
  tooWeak: string;
  tooShort: string;
}

export class MedicalBluetoothFindair
  extends MedicalBluetoothSDKDataPillDispenser
  implements StaticImplements<MedicalBluetoothSDKConstructor, typeof MedicalBluetoothFindair>
{
  private SOURCE = "MedicalBluetoothFindair";

  private static _instance: MedicalBluetoothFindair;
  private onInitSuccess$ = new BehaviorSubject<boolean | undefined>(undefined);

  public constructor(
    public medicalBluetoothService: MedicalBluetooth,
    protected popupService: PopupService,
    protected computeDrugsService: ComputeDrugsService,
    protected drugService: DrugService,
    accountService: AccountService
  ) {
    super(medicalBluetoothService, popupService, computeDrugsService, drugService, accountService);
  }

  public static Instance(
    medicalBluetoothService: MedicalBluetooth,
    popupService: PopupService,
    computeDrugsService: ComputeDrugsService,
    drugService: DrugService,
    accountService: AccountService
  ): MedicalBluetoothFindair {
    return (
      this._instance || (this._instance = new this(medicalBluetoothService, popupService, computeDrugsService, drugService, accountService))
    );
  }

  async onInit(displayPopupError = true, popup = PopupBluetooth.toConnectADevice): Promise<void> {
    await this.initBluetooth(displayPopupError, popup);
    if (!this.onInitSuccess$.value) {
      const promiseOnInit = new Promise<void>((resolve, reject) => {
        this.onInitSuccess$.next(undefined); // init the Behavior Subject
        this.onInitSuccess$
          .pipe(
            filter((value) => value !== undefined),
            timeout(15000), // backup
            first()
          )
          .toPromise()
          .then(
            (onInitSuccess) => {
              if (onInitSuccess) {
                resolve();
              } else {
                reject();
              }
            },
            (err) => {
              reject(err);
            }
          );
      });
      cordova?.plugins?.FindAirComunicarePlugin?.init(this.initMethodSuccess.bind(this), this.initMethodError.bind(this));
      return promiseOnInit; // we have to wait for the plugin to confirm that everything is OK
    }
  }

  public async makeARequestForGetUsagesHistory(displayPopupError = true, popup = PopupBluetooth.toConnectADevice): Promise<void> {
    try {
      await this.onInit(displayPopupError, popup);
      await this.makeARequestForOnScanAndConnectWithNearbyDevice(false);
      cordova?.plugins?.FindAirComunicarePlugin?.getUsagesHistory(
        (rep) => FileLogger.log(this.SOURCE, "makeARequestForGetUsagesHistory", rep, "none"),
        (err) => {
          FileLogger.error(this.SOURCE, "makeARequestForGetUsagesHistory", err, "none");
          this.$error.next(err);
        }
      );
      setTimeout(() => {
        /* To avoid continuous scanning (and killing the battery), the scan is switched off after 1 minute. 
           If it has detected a device, the stop does not cut the link; it only cuts the search for a new device.
        */
        this.stopScan().then(
          () => {
            FileLogger.log(this.SOURCE, "makeARequestForGetUsagesHistory - stopScan", "success", "none");
          },
          (err) => {
            FileLogger.error(this.SOURCE, "makeARequestForGetUsagesHistory - stopScan", err, "none");
          }
        );
      }, 30000);
    } catch (error) {
      this.$error.next(error);
    }
  }

  public async stopScan(): Promise<void> {
    // otherwise, noting to do
    if (this.onInitSuccess$.value) {
      cordova?.plugins?.FindAirComunicarePlugin?.stopScan(
        (rep) => FileLogger.log(this.SOURCE, "stopScan", rep),
        (err) => {
          FileLogger.error(this.SOURCE, "stopScan", err);
          this.$error.next(err);
        }
      );
    }
  }

  public async makeARequestForOnScanAndConnectWithNearbyDevice(disconnect: boolean): Promise<void> {
    await this.onInit();
    cordova?.plugins?.FindAirComunicarePlugin?.scanAndConnectWithNearbyDevice(
      { disconnect },
      (rep) => FileLogger.log(this.SOURCE, "makeARequestForOnScanAndConnectWithNearbyDevice", rep),
      (err) => {
        FileLogger.error(this.SOURCE, "makeARequestForOnScanAndConnectWithNearbyDevice", err);
        this.$error.next(err);
      }
    );
  }

  public async makeARequestForDisconnectDevice(device: { address: string }): Promise<void> {
    await this.onInit();
    cordova?.plugins?.FindAirComunicarePlugin?.disconnect(
      device,
      (rep) => FileLogger.log(this.SOURCE, "makeARequestForDisconnectDevice", rep),
      (err) => {
        FileLogger.error(this.SOURCE, "makeARequestForDisconnectDevice", err);
        this.$error.next(err);
      }
    );
  }

  public static getStateString(state: number): string {
    switch (state) {
      case 0:
        return "Disconnected";
      case 1:
        return "Connecting";
      case 2:
        return "Connected";
      case 3:
        return "Unauthorized";
      case 4:
        return "Not found";
      case 6:
        return "Synchronized";
      default:
        return "Unknown";
    }
  }

  private initMethodSuccess(rep: { devices?: FindAirDevice[]; usage?: FindAirUsage; usagesHistory?: FindAirUsage[] }) {
    FileLogger.log(this.SOURCE, "initMethodSuccess", "", "none");
    if (rep.devices) {
      this.$devices.next(rep.devices);
    } else if (rep.usage) {
      this.$usages.next({ origin: "init", usages: [rep.usage] });
    } else if (rep.usagesHistory) {
      this.$usages.next({ origin: "history", usages: rep.usagesHistory });
    } else {
      FileLogger.log(this.SOURCE, `initMethodSuccess - init : ${JSON.stringify(rep)}`, "");
    }
    this.onInitSuccess$.next(true);
  }

  private initMethodError(err) {
    this.$error.next(err);
    this.onInitSuccess$.next(false);
  }
}

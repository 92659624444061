import { Injectable } from "@angular/core";

import BackgroundFetch from "cordova-plugin-background-fetch";
import * as moment from "moment";
import { AppConstants } from "../appConstants";
import { FileLogger } from "../helpers/fileLogger";
import { SysAccount } from "../models/sysaccount";
import { ComputeDrugsService } from "./compute-drugs.service";
import { AccountService } from "./globalDataProvider/account.service";
import { DrugService } from "./globalDataProvider/drug.service";
import { InfoAppService } from "./info-app.service";
import { MedicalBluetoothSDKPillDispenserService } from "./medical-bluetooth/medical-bluetooth-sdk-pilldispenser.service";
import { MedicalBluetooth } from "./medical-bluetooth/medical-bluetooth.service";
import { MedicalBluetoothFindair } from "./medical-bluetooth/medical-hardware/medical-bluetooth-findair";
import { PopupService } from "./popup.service";
import { SynchronisationService } from "./synchronisation.service";
import { SysAccountService } from "./sys-account.service";

@Injectable({
  providedIn: "root",
})
export class BackgroundService {
  constructor(
    private infoAppService: InfoAppService,
    private sysAccountService: SysAccountService,
    private synchronisationService: SynchronisationService,
    public medicalBluetoothService: MedicalBluetooth,
    protected popupService: PopupService,
    protected computeDrugsService: ComputeDrugsService,
    protected drugService: DrugService,
    private accountService: AccountService
  ) {}

  public initBackgroundSynchro(): void {
    if (this.infoAppService.isCordova()) {
      const config = {
        stopOnTerminate: false,
        enableHeadless: false,
        startOnBoot: true,
        requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY, // This job requires network connectivity.
      };

      const fetchCallback = (taskId) => {
        try {
          FileLogger.log("BackgroundService", "BackgroundFetch event received: ", taskId);
          if (MedicalBluetoothSDKPillDispenserService.bondedDevices.length > 0) {
            FileLogger.log("BackgroundService", "makeARequestForGetUsagesHistory");
            MedicalBluetoothFindair.Instance(
              this.medicalBluetoothService,
              this.popupService,
              this.computeDrugsService,
              this.drugService,
              this.accountService
            ).makeARequestForGetUsagesHistory(false);
          }

          if (!this.time2BackgroundTaskSynchro(this.sysAccountService.cachedSysAccount)) {
            FileLogger.log("BackgroundService", "BackgroundFetch", "No time to synchro.");
            BackgroundFetch.finish(taskId);
          } else {
            this.synchronisationService.syncApp().then(
              () => {
                FileLogger.log("BackgroundService", "BackgroundFetch SUCCESS");
                BackgroundFetch.finish(taskId);
              },
              (err) => {
                FileLogger.error("BackgroundService", "BackgroundFetch syncApp", err);
                BackgroundFetch.finish(taskId);
              }
            );
          }
        } catch (error) {
          FileLogger.error("BackgroundService", "BackgroundFetch try catch", error);
          BackgroundFetch.finish(taskId);
        }
      };

      const failureCallback = (error) => {
        FileLogger.log("BackgroundService", "BackgroundFetch failed", error);
      };

      BackgroundFetch.configure(config, fetchCallback, failureCallback);
    }
  }

  /**
   * Check if time to synchro a account
   * @param sysAccount
   */
  public time2BackgroundTaskSynchro(sysAccount: SysAccount): boolean {
    if (!sysAccount) {
      return false;
    } else if (!sysAccount.name) {
      return false;
    } else if (!sysAccount.lastSynchro) {
      return true;
    } else if (moment(sysAccount.lastSynchro).add(AppConstants.BACKGROUNDSYNCHROPLUGIN_HOUR, "hours").isSameOrBefore(moment(), "minute")) {
      // do not always sync: only every 3 hours
      return true;
    } else {
      return false;
    }
  }
}

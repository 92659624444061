import { Component, ViewChild } from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { AppConstants } from "src/app/appConstants";
import { DELETE_REQUEST_STATUS } from "src/app/models/deleteRequest";
import { IQuestionnaire } from "src/app/models/questionnaire";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { DeleteRequestService } from "src/app/services/globalDataProvider/delete-request.service";
import { FreeQuestionnaireService } from "src/app/services/free-questionnaire.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { NetworkService } from "src/app/services/network.service";
import { PopupService } from "src/app/services/popup.service";
import { QuestionnaireModalComponent } from "../questionnaire-modal/questionnaire-modal.component";
import { Tools } from "src/app/helpers/tools-helper";
import { BasePage } from "src/app/baseClasses/base-page";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { LoginService } from "src/app/services/login.service";
import { SecureStorageService } from "src/app/services/storage/secure-storage.service";

@Component({
  selector: "app-delete-account",
  templateUrl: "./delete-account.component.html",
  styleUrls: ["./delete-account.component.scss"],
})
export class DeleteAccountComponent extends BasePage {
  private slides: IonSlides;
  @ViewChild(IonSlides) set setSlides(s: IonSlides) {
    this.slides = s;
    if (this.slides && this.slides.length) {
      this.initSlidesBool();
      this.userMail = this.accountService.cachedAccount.mail;
    }
  }
  private sliderSub: Subscription;
  public slideOpts = {
    autoHeight: true,
    allowTouchMove: false,
  };
  public isEnd = false;
  public isBeginning = true;
  public hasRequest = false;
  public isLoading = true;
  public currentStatus: string;
  public deleteAccount = false;
  public receiveMail = false;
  public userMail: string;
  public reasonComment: string;
  public questionnaires: IQuestionnaire;
  public isQuestionnaireAnswered = false;
  public isQuestionnaireClicked = false;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected deleteRequestService: DeleteRequestService,
    protected modalCtrl: ModalController,
    protected accountService: AccountService,
    protected configService: ConfigurationService,
    protected networkService: NetworkService,
    protected freeQuestService: FreeQuestionnaireService,
    protected translateService: TranslateService,
    protected loginService: LoginService,
    protected secureStorageService: SecureStorageService
  ) {
    super(translateService, configService, infoService, popupService);
  }

  ionViewWillLeave(): void {
    super.ionViewWillLeave();
    this.sliderSub?.unsubscribe();
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.getDeleteRequest();
  }

  private initSlidesBool() {
    this.sliderSub = this.slides.ionSlideWillChange?.pipe(takeUntil(this.onDestroy$))?.subscribe(() => {
      this.slides.isBeginning().then((isB) => (this.isBeginning = isB));
      this.slides.isEnd().then((isE) => (this.isEnd = isE));
    });
  }

  public async getDeleteRequest(): Promise<void> {
    const dataReader = this.deleteRequestService.getDataReader();
    for await (const dRequests of dataReader) {
      if (dRequests && dRequests.length) {
        this.hasRequest = true;
        if (dRequests.length === 1) {
          this.currentStatus = DELETE_REQUEST_STATUS[dRequests[0].requestStatus];
        } else {
          const foundDR = dRequests.find((dr) => dr.requestStatus !== DELETE_REQUEST_STATUS.CANCELED);
          if (foundDR) {
            this.currentStatus = DELETE_REQUEST_STATUS[foundDR.requestStatus];
          } else {
            this.currentStatus = DELETE_REQUEST_STATUS[DELETE_REQUEST_STATUS.CANCELED];
          }
        }
        this.isLoading = false;
      } else {
        this.hasRequest = false;
        this.isLoading = false;
      }
    }
  }

  /**
   * Load needed satisfaction questionnaire
   */
  private loadNeededQuestionnaire(): Promise<IQuestionnaire> {
    try {
      return this.freeQuestService.get(this.configService.getCurrentLanguage(), AppConstants.SAT_PATIENT_COMUNICARE);
    } catch (err) {
      FileLogger.error("DeleteAccountComponent", "loadNeededQuestionnaire()", err);
      return Promise.resolve(null);
    }
  }

  public async nextSlide(): Promise<void> {
    await this.slides.slideNext();
  }

  public async prevSlide(): Promise<void> {
    await this.slides.slidePrev();
  }

  public async validate(): Promise<void> {
    if (this.networkService.isCurrentOffline()) {
      this.popupService.showAlert("deleteAccount.title", "login.account.offline");
      return;
    }
    if (!this.reasonComment) {
      this.popupService.showAlert("deleteAccount.title", "deleteAccount.slideThree.missingReason");
      return;
    }
    if (!this.isQuestionnaireAnswered) {
      this.popupService.showAlert("deleteAccount.title", "deleteAccount.slideThree.missingQuestionnaire");
      return;
    }
    let body: unknown;
    if (this.deleteAccount && this.reasonComment && !this.receiveMail) {
      body = {
        reason: this.reasonComment,
        wantArchive: this.receiveMail,
      };
    } else if (this.deleteAccount && this.reasonComment && this.receiveMail) {
      body = {
        reason: this.reasonComment,
        wantArchive: this.receiveMail,
        mailAddress: this.userMail,
      };
    } else {
      return;
    }
    const confirm = await this.popupService.showYesNo("deleteAccount.title", "deleteAccount.confirmDeletion");
    if (confirm) {
      this.isLoading = true;
      await this.deleteRequestService.save(body);
      this.isLoading = false;
      await this.popupService.showToast("deleteAccount.successSent", 3000, "bottom");
      this.modalCtrl.dismiss();
      this.modalCtrl.dismiss(undefined, undefined, AppConstants.SETTINGS_MODAL);
      await this.loginService.disconnect();
      await this.secureStorageService.clear();
    } else {
      return;
    }
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  public async onAnswerQuestionnaire(): Promise<void> {
    this.isQuestionnaireClicked = true;
    if (this.networkService.isCurrentOffline()) {
      this.popupService.showAlert("deleteAccount.title", "login.account.offline");
      this.isQuestionnaireClicked = false;
      return;
    } else {
      this.questionnaires = await this.loadNeededQuestionnaire();
      if (Tools.isNotDefined(this.questionnaires)) {
        this.popupService.showAlert("deleteAccount.title", "login.error.title");
        this.isQuestionnaireClicked = false;
        return;
      }
    }
    const modal = await this.modalCtrl.create({
      component: QuestionnaireModalComponent,
      componentProps: {
        questionnaire: this.questionnaires,
        action: "create",
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.isQuestionnaireAnswered = true;
      }
      this.isQuestionnaireClicked = false;
    });
    await modal.present();
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { IRelatedPerson } from "src/app/models/relatedPerson";
import { ACTION_STATUS_ENTITY, StaticImplements, STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { ApiService } from "../api.service";
import { LoaderService } from "../loader.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";

@Injectable({
  providedIn: "root",
})
export class RelatedPersonsService
  extends BasicSyncService<IRelatedPerson, IRelatedPerson[]>
  implements StaticImplements<INeedRefresh, typeof RelatedPersonsService>
{
  public get needRefresh(): { value: boolean } {
    return RelatedPersonsService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(
    protected dataService: DataService,
    private apiService: ApiService,
    private accountService: AccountService,
    private loaderService: LoaderService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IRelatedPerson[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "relatedPersons_",
      entityStoreKey: "list",
      getUrl: "/relatedPersons",
      setUrl: "/relatedPerson",
      expirationDays: 10,
      encrypted: true,
    };
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(includeDeleted = true): IRelatedPerson[] {
    return this.processData(super.peekData(), includeDeleted);
  }

  /**
   * Get the list of related persons of the current user
   * @param includeDeleted
   */
  public async *getDataReader(
    includeDeleted = false,
    forceApiRequest = false
  ): AsyncGenerator<IRelatedPerson[], IRelatedPerson[], IRelatedPerson[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = this.dataService.readv2<IRelatedPerson, IRelatedPerson[]>(this.defaultDataParameter, false, this, forceApiRequest);
      let d: IRelatedPerson[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, includeDeleted);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("RelatedPersonsService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IRelatedPerson[], includeDeleted: boolean) {
    try {
      if (Tools.isNotDefined(dataResult) || !Array.isArray(dataResult)) {
        return [];
      }
      let related: IRelatedPerson[] = dataResult;
      if (!includeDeleted) {
        related = related.filter((rel) => {
          return rel.actionStatus !== ACTION_STATUS_ENTITY.DELETED;
        });
      }
      return related;
    } catch (err) {
      FileLogger.error("RelatedPersonsService", "Error while processing relatedPersonService data: ", err);
      return dataResult;
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(includeDeleted = false, forceApiRequest = false): Promise<IRelatedPerson[]> {
    const dataReader = this.getDataReader(includeDeleted, forceApiRequest);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(includeDeleted = false, forceApiRequest = false): Promise<IRelatedPerson[]> {
    const dataReader = this.getDataReader(includeDeleted, forceApiRequest);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  /**
   * Update in local and in DB a relatedPerson
   * @param relatedPerson
   */
  public async save(relatedPerson: IRelatedPerson, withToast = true): Promise<IRelatedPerson> {
    const savePromise = this.dataService
      .saveInArray(relatedPerson, (entity) => entity._id === relatedPerson._id, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.PUT,
      })
      .then((rp: IRelatedPerson) => {
        const relatedPersons = this.peekData(true);
        const i = relatedPersons.findIndex((e) => e._id === rp._id);
        if (i >= 0 && !rp.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          relatedPersons[i] = rp;
        } else if (i >= 0 && rp.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          relatedPersons.splice(i, 1);
        } else if (i < 0 && !rp.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          relatedPersons.push(rp);
        }
        this.pokeData(relatedPersons);
        return rp;
      });
    if (withToast) {
      await this.loaderService.showSavingToast(true);
    }
    const result = await savePromise;
    if (withToast) {
      await this.loaderService.showSavingToast(false);
    }
    return result;
  }

  /**
   *  Send Invitation to a person and create a relatedPerson in DB
   */
  public inviteRelatedPerson(relatedPerson: IRelatedPerson): Observable<IRelatedPerson> {
    // force to wait the answer of the POST, so I go directly through apiService
    return this.apiService.post("/relatedinvite", relatedPerson).pipe(
      map((rep) => {
        if (rep && rep.success) {
          return rep.data as IRelatedPerson;
        } else {
          return null;
        }
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { AppConstants } from "../appConstants";
import { Tools } from "../helpers/tools-helper";
import { EntityDrug } from "../models/entitylink";
import { TimingData } from "../models/timingData";
import { ComputeDrugsService } from "../services/compute-drugs.service";

/**
 * Returns true if there's a quantity for the timing
 */
@Pipe({ name: "hasQuantity" })
export class HasQuantityPipe implements PipeTransform {
  constructor() {}

  public transform(timing: string, drugQuantities: any): boolean {
    timing = timing === AppConstants.RISING ? "rise" : timing === AppConstants.BEDING ? "bedtime" : timing;
    if (drugQuantities && drugQuantities[timing]) {
      return true;
    } else {
      return false;
    }
  }
}

/**
 * Returns the right quantity for each timing
 */
@Pipe({ name: "getQuantity" })
export class GetQuantityPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(entityData: EntityDrug, timing: string, withDose = false) {
    let quantity;
    if (timing === AppConstants.RISING) {
      quantity = entityData.frequency.quantities.rise;
    } else if (timing === AppConstants.BEDING) {
      quantity = entityData.frequency.quantities.bedtime;
    } else {
      quantity = entityData.frequency.quantities[timing];
    }
    let s = EntityDrug.computeQuantityDisplay(quantity);
    if (withDose) s += " " + this.translateService.instant("mydrugs.dose");
    return s;
  }
}
@Pipe({ name: "displayQuantity" })
export class DisplayQuantityPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(quantity: string, withDose = false) {
    let s = EntityDrug.computeQuantityDisplay(quantity);
    if (withDose) s += " " + this.translateService.instant("mydrugs.dose");
    return s;
  }
}

@Pipe({ name: "remainingTreatmentDays" })
export class RemainingTreatmentDaysPipe implements PipeTransform {
  constructor() {}

  public transform(drug: EntityDrug, remainingUsages: number) {
    if (!Tools.isDefined(remainingUsages) || !Number.isFinite(remainingUsages)) {
      return undefined;
    }
    if (remainingUsages <= 0) return 0;
    return EntityDrug.numberDaysRemaining(drug, remainingUsages);
  }
}
@Pipe({ name: "lastTreatmentDay" })
export class LastTreatmentDayPipe implements PipeTransform {
  constructor(private computeDrugService: ComputeDrugsService) {}

  public transform(drug: EntityDrug, remainingUsages: number) {
    if (!Tools.isDefined(remainingUsages) || !Number.isFinite(remainingUsages)) {
      return null;
    }
    const todayRemainingDoses = this.computeDrugService.numberDosesNeededToFinishToday(drug);
    const remainingDosesAfterToday = remainingUsages - todayRemainingDoses;
    if (remainingDosesAfterToday <= 0) {
      return moment().format("DD-MM-YYYY");
    }
    const nbDays = Math.floor(EntityDrug.numberDaysRemaining(drug, remainingDosesAfterToday));
    if (nbDays < 1) {
      return moment().format("DD-MM-YYYY");
    }
    let lastDay: string;
    if (drug.cycle && drug.cycle.cycle.length > 0) {
      lastDay = TimingData.getDateOfXthDayOfTreatmentForCycle(drug.cycle, nbDays);
    } else {
      lastDay = TimingData.getDateOfXthDayOfTreatment(drug.frequency, nbDays);
    }
    return lastDay;
  }
}

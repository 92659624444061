import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ICommunication } from "src/app/models/communication";
import { IEntity, StaticImplements } from "src/app/models/sharedInterfaces";
import { LoaderService } from "../loader.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";

@Injectable({
  providedIn: "root",
})
export class CommunicationService
  extends BasicSyncService<ICommunication, ICommunication[]>
  implements StaticImplements<INeedRefresh, typeof CommunicationService>
{
  public get needRefresh(): { value: boolean } {
    return CommunicationService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService, private accountService: AccountService, private loaderService: LoaderService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<ICommunication[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "communication_",
      entityStoreKey: "list",
      getUrl: "/communications",
      setUrl: "/communication",
      expirationDays: 10,
      encrypted: true,
    };
  }

  /**
   * Returns the current state of the service's data
   */
  public peekData(includeDeleted = true): ICommunication[] {
    return this.processData(super.peekData(), includeDeleted);
  }

  public async *getDataReader(includeDeleted = false): AsyncGenerator<ICommunication[], ICommunication[], ICommunication[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader: AsyncGenerator<ICommunication[], ICommunication[], ICommunication[]> = super.getDataReader();
      let d: ICommunication[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, includeDeleted);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.warn("CommunicationService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: ICommunication[], includeDeleted: boolean) {
    try {
      let communications: ICommunication[] = dataResult;
      if (!includeDeleted) {
        communications = communications.filter((entity) => {
          return !IEntity.isDeleted(entity) && !entity.deletedByReceiver;
        });
      }
      return communications;
    } catch (err) {
      FileLogger.error("CommunicationService", "Error while processing communicationService data: ", err);
      return dataResult;
    }
  }

  public async save(data: ICommunication, withToast = true): Promise<ICommunication> {
    const savePromise = this.dataService
      .saveInArray(data, (entity) => entity._id === data._id, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.PUT,
      })
      .then((commu: ICommunication) => {
        // Keep the behavior subject up to date:
        const communications = this.peekData(true);
        const i = communications.findIndex((e) => e._id === commu._id);
        if (i >= 0 && !commu.deletedByReceiver) {
          communications[i] = commu;
        } else if (i < 0 && !commu.deletedByReceiver) {
          communications.push(commu);
        } else if (i >= 0 && commu.deletedByReceiver) {
          communications.splice(i, 1);
        }
        this.pokeData(communications);
        return commu;
      });

    if (withToast) {
      await this.loaderService.showSavingToast(true);
    }
    const savedCommu = await savePromise;
    if (withToast) {
      await this.loaderService.showSavingToast(false);
    }
    return savedCommu;
  }

  public async delete(communication: ICommunication, withToast = true): Promise<ICommunication> {
    communication.deletedByReceiver = true;
    return await this.save(communication, withToast);
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { InfoAppService } from "../services/info-app.service";
import { PopupService } from "../services/popup.service";

@Component({
  template: "",
})
export abstract class BaseComponent implements OnInit, OnDestroy {
  /**
   * Allows to know if we are on a cordova device
   */
  public isCordova: boolean;
  /**
   * This Subject is used to easily unsubscribe observables that need to be unsubscribed when leaving the view
   */
  protected onDestroy$: Subject<void>;

  constructor(protected infoService: InfoAppService, protected popupService: PopupService) {
    this.isCordova = this.infoService.isCordova();
  }

  /**
   * Initialize the directive or component after Angular first displays the data-bound properties
   * and sets the directive or component's input properties.
   * Important : Called once, after the first ngOnChanges() -> so ngOnChanges should never use an
   * observable because onDestroy$ is not available and should not be available because it does not make sense.
   *
   * @note If this method is overridden on the component, do not forget to use "super.ngOnInit()"
   * at the beginning of the method.
   */
  ngOnInit(): void {
    this.onDestroy$ = new Subject<void>();
  }

  /**
   * Cleanup just before Angular destroys the directive or component. Unsubscribe Observables and
   * detach event handlers to avoid memory leaks.
   * @note If this method is overridden on the component, do not forget to use "super.ngOnDestroy()"
   * at the beginning of the method.
   */
  ngOnDestroy(): void {
    this.onDestroy$?.next();
    this.onDestroy$?.complete();
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CustomParamKey } from "src/app/models/dataParameters";
import { INotification, NOTIFICATION_STATUS } from "src/app/models/notification";
import { ACTION_STATUS_ENTITY, StaticImplements, STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsDrugsIntakeService
  extends BasicSyncService<INotification, INotification[]>
  implements StaticImplements<INeedRefresh, typeof NotificationsDrugsIntakeService>
{
  public get needRefresh(): { value: boolean } {
    return NotificationsDrugsIntakeService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<INotification[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "notificationsdrugsIntake_",
      entityStoreKey: "list",
      getUrl: "/drugIntake",
      setUrl: "/drugIntake",
      expirationDays: 10,
      encrypted: true,
      customParam: {
        [CustomParamKey.mergeSetRequest]: true,
      },
    };
  }

  public setDrugsIntake(notif: INotification, isEditing = false): Promise<INotification> {
    if (notif.status === NOTIFICATION_STATUS.NONE && !isEditing) {
      // do not send the notification with status NONE : the dashboard will calculate them
      return null;
    }
    notif.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    const method = isEditing ? SYNC_HTTP_METHOD.PUT : SYNC_HTTP_METHOD.POST;
    const savePromise = this.dataService
      .saveInArray(
        notif,
        (entity) =>
          entity.time === notif.time && entity.account === notif.account && entity.appId === notif.appId && entity.ntype === notif.ntype,
        {
          ...this.defaultDataParameter,
          method: method,
        }
      )
      .then((n: INotification) => {
        const notifications = this.peekData();
        const i = notifications.findIndex(
          (e) => e.time === n.time && e.account === n.account && e.appId === n.appId && e.ntype === n.ntype
        );
        if (i >= 0 && !n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          notifications[i] = n;
        } else if (i < 0 && !n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          notifications.push(n);
        } else if (i >= 0 && n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          notifications.splice(i, 1);
        }
        this.pokeData(notifications);
        return n;
      });
    return savePromise;
  }
}

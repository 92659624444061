import { Pipe, PipeTransform } from "@angular/core";

/**
 * We have 11 smileys from 0 to 10. We're matching a value to an smiley :
 *  if value = min
 *      then the smiley is 0 (resp. 10), if showMethod = 1 (resp. -1)
 *  if value = max
 *      then the smiley is 10 (resp. 0), if showMetohd = -1 (resp. 1)
 *  otherwise
 *      the smiley is chosen propotionally at the value, in relation to its position in the range of [min,max]
 */
@Pipe({ name: "rangeSmiley" })
export class RangeSmileyPipe implements PipeTransform {
  constructor() {}

  public transform(value: number, min: number, max: number, showMethod: number): string {
    let nameSmiley = "";
    value = value ? value : min; // Initially, the value is undefined. It is considered to be equal to min
    switch (showMethod) {
      case 2:
        nameSmiley = "smileyChild" + Math.round(((value - min) / (max - min)) * 3);
        break;
      case 1:
        nameSmiley = "smileyFace" + Math.round(((value - min) / (max - min)) * 10);
        break;
      case -1:
        nameSmiley = "smileyFace" + (10 - Math.round(((value - min) / (max - min)) * 10));
        break;
      case -2:
        nameSmiley = "smileyChild" + (3 - Math.round(((value - min) / (max - min)) * 3));
        break;
      default:
        break;
    }
    return nameSmiley + ".png";
  }
}

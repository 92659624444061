import { Injectable } from "@angular/core";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { InfoAppService } from "../info-app.service";
import { BrusafeService } from "./brusafe.service";
import { OnlineDevicesService } from "./onlineDevices.service";
import { SsoService } from "./sso.service";

@Injectable({
  providedIn: "root",
})
export class ActivateDeeplinksService {
  private alreadyInit = false;
  private urlParams: any;
  private pathName: string;

  constructor(
    private deeplinks: Deeplinks,
    private ssoService: SsoService,
    private brusafeService: BrusafeService,
    private onlineDeviceService: OnlineDevicesService,
    private infoAppService: InfoAppService
  ) {
    // Get route params and pathname before it is overwrite by angular router.
    if (!this.infoAppService.isCordova()) {
      this.urlParams = this.getParamUrl();
      this.pathName = window.location.pathname;
    }
  }

  public init(): void {
    if (!this.alreadyInit) {
      this.alreadyInit = true;

      if (this.infoAppService.isCordova()) {
        const destroy$ = new Subject();

        const obsDeeplinks$ = this.deeplinks
          .route({
            "/abrumet/:callback": {},
            "/abrumet": {},
            "/deeplink": {},
          })
          .pipe(takeUntil(destroy$));

        obsDeeplinks$.subscribe(
          (match) => {
            // match.$route - the route we matched, which is the matched entry from the arguments to route()
            // match.$args - the args passed in the link
            // match.$link - the full link data

            // Warning : the info is in match?.$link?.path except if we use the redirection html page of sso
            const path = match?.$link?.path && match?.$link?.path !== "" ? match?.$link?.path : match?.$link?.host;

            switch (path) {
              case "/deeplink": {
                FileLogger.log("ActivateDeeplinksService", "DEEPLINKS /deeplink");
                const code = match.$args.code ? match.$args.code : undefined;
                const state = match.$args.state ? match.$args.state : undefined;
                if (code && state?.startsWith("onlineDevice")) {
                  // redirect from dexcom after authentication with them
                  this.onlineDeviceService.handleDeviceOAuthRedirect(code, state);
                } else {
                  // cdle sso
                  const org = match.$args.org ? match.$args.org.toLowerCase() : undefined;
                  const mode = match.$args.mode ? match.$args.mode.toLowerCase() : undefined;
                  const token = match.$args.token;
                  FileLogger.log("ActivateDeeplinksService", `DEEPLINKS org : ${org} mode : ${mode} token : ${token}`);
                  this.ssoService.managementSSO(org, mode, token);
                }
                break;
              }
              default: {
                // abrumet
                FileLogger.log("ActivateDeeplinksService", "DEEPLINKS /abrumet", path);
                const dataBrusafe = this.brusafeService.customParseUrl(match.$link);
                if (dataBrusafe) {
                  this.brusafeService.handleBrusafeCallback(dataBrusafe);
                } else {
                  FileLogger.error("ActivateDeeplinksService", `DEEPLINKS /abrumet - bad url ${match.$link}`);
                }
                break;
              }
            }
          },
          (nomatch) => {
            const errMsg = JSON.stringify(nomatch);
            FileLogger.error("ActivateDeeplinksService", "Got a DEEPLINKS that didn't match", JSON.stringify(nomatch));
            destroy$.next();
            destroy$.complete();

            // to recreate the subscription
            this.alreadyInit = false;
            if (errMsg !== '"cordova_not_available"') {
              this.init();
            }
          }
        );
      } else {
        if (this.pathName === "/deeplink" && this.urlParams) {
          const code = this.urlParams.code ? this.urlParams.code : undefined;
          const state = this.urlParams.state ? this.urlParams.state : undefined;
          if (code && state?.startsWith("onlineDevice")) {
            // redirect from dexcom after authentication with them
            this.onlineDeviceService.handleDeviceOAuthRedirect(code, state);
          } else {
            this.ssoService.managementSSO(this.urlParams.org?.toLowerCase(), this.urlParams.mode?.toLowerCase(), this.urlParams.token);
          }
        }
      }
    }
  }

  /**
   * Create an object containing the param of the url
   */
  private getParamUrl(): any {
    const oParametre = {};
    if (window.location.search.length > 1) {
      const aCouples = window.location.search.substr(1).split("&");
      aCouples.forEach((param) => {
        const aItKey = param.split("=");
        oParametre[unescape(aItKey[0])] = aItKey.length > 1 ? unescape(aItKey[1]) : "";
      });
    }
    return oParametre;
  }
}

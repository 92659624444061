import { IQuestionnaire, IValueSet } from "../../models/questionnaire";
import { TriggerRuleHelperEngineService } from "../trigger-rule-helper-engine/trigger-rule-helper-engine.service";
import { QuestionnaireResponse } from "../questionnaireResponse";

/**
 * Management of the rule linked to a questionnaire for some valueSets given
 */
export class TriggerRuleHelperQuestionnaireService extends TriggerRuleHelperEngineService {
  constructor(valueSet: IValueSet[], currentQuestionnaire: IQuestionnaire, currentQuestionnaireResponse: QuestionnaireResponse) {
    super();
    this.createANSWERVariable(valueSet, currentQuestionnaire, currentQuestionnaireResponse);
  }
}

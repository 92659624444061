import { IValueSet } from "src/app/models/questionnaire";
import { Answer } from "../questionnaireResponse";

/**
 * Gather answer and utility functions (in the future)
 */
export class Answers {
  constructor(public CURRENT: SimpleAnswer) {}
}

/**
 * Simple Answer of a questionnaire
 */
export class SimpleAnswer {
  constructor(public answer: Answer[], public valueSet: IValueSet) {}

  /**
   * returns the "number" associated to this answer.
   * Notice : at the moment, we take always this.answer[0], so this method does not manage checkbox
   */
  get value(): number {
    const answerCode = this.answer[0].valueCoding.code;
    return Number(this.valueSet.values.find((v) => v.key === answerCode)?.value);
  }
}

/**
 * A class to handle Answers object in a dictionnary
 */
export class DictionnaryAnswers {
  [key: string]: Answers;
}

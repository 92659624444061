import { Component, Pipe, PipeTransform } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BasePage } from "src/app/baseClasses/base-page";
import { IPackExtended } from "src/app/models/drugsInfo";
import { EntityDrug, IDrugStock, IEntitylink } from "src/app/models/entitylink";
import { ACTION_STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { DrugService } from "src/app/services/globalDataProvider/drug.service";
import { StatEventService } from "src/app/services/globalDataProvider/statEvent.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";

@Pipe({ name: "getRemainingDosesMaxValue" })
export class GetRemainingDosesMaxValuePipe implements PipeTransform {
  public transform(knownPackaging: boolean, allPacks: IPackExtended[], formGroup: FormGroup): number {
    if (knownPackaging && formGroup.get("packCNK").value) {
      return allPacks.find((p) => p.cnk === formGroup.get("packCNK").value).usable;
    } else {
      formGroup.get("totalUsages").value;
    }
  }
}

@Component({
  selector: "app-drug-stock-modal",
  templateUrl: "./drug-stock-modal.component.html",
  styleUrls: ["./drug-stock-modal.component.scss"],
})
export class DrugStockModalComponent extends BasePage {
  public drug: IEntitylink;
  public drugStockForm = new UntypedFormGroup({
    stocks: new UntypedFormArray([]),
  });
  public allPacks: IPackExtended[] = [];
  public currentLang: string;
  public knownPackaging = false;

  get stocks(): FormArray {
    return this.drugStockForm.controls.stocks as FormArray;
  }

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected modalCtrl: ModalController,
    protected navParams: NavParams,
    protected drugService: DrugService,
    protected statEventService: StatEventService,
    protected fb: FormBuilder
  ) {
    super(translateSvc, configService, infoService, popupService);
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.drug = this.navParams.get("drug");
    this.currentLang = this.configService.getCurrentLanguage();
    const entityDrug = this.drug.entityData as EntityDrug;

    if (entityDrug.source && entityDrug?.knownPackaging && entityDrug.allPack?.filter((p) => p.usable)?.length) {
      this.knownPackaging = true;
      this.allPacks = entityDrug.allPack.filter((p) => p.usable);
      const selectedPack = this.allPacks[entityDrug?.lastPackNumber] ? this.allPacks[entityDrug?.lastPackNumber] : this.allPacks[0];
      const stockForm = this.fb.group(
        {
          packCNK: [selectedPack.cnk, Validators.required],
          remainingUsages: [selectedPack.usable, Validators.required],
        },
        { validators: this.stockValidator() }
      );
      this.stocks.push(stockForm);
    } else {
      this.knownPackaging = false;
      const stockForm = this.fb.group(
        {
          totalUsages: [0, Validators.required],
          remainingUsages: [0, Validators.required],
        },
        { validators: this.stockValidator() }
      );
      this.stocks.push(stockForm);
    }
  }

  public onDismiss(): void {
    this.modalCtrl.dismiss();
  }

  public async onSaveStock(): Promise<void> {
    this.stocks.markAllAsTouched();
    let sumUsages = 0;
    let sumRemainingUsages = 0;
    const invalidStock: boolean[] = [];
    for (const stock of this.stocks.value) {
      const min = 0;
      const max = this.knownPackaging ? this.allPacks.find((p) => p.cnk === stock.packCNK).usable : stock.totalUsages;

      if (stock.remainingUsages <= min || stock.remainingUsages > max) {
        invalidStock.push(true);
      }

      sumUsages += max;
      sumRemainingUsages += stock.remainingUsages;
    }

    if (invalidStock?.length > 0) {
      this.popupService.showToast("mydrugs.stock.errorIfInvalid", 3000, "bottom");
      return;
    } else {
      this.drug.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
      const remainingUsages = this.drug.entityData?.stock?.totalRemainingUsages
        ? sumRemainingUsages + this.drug.entityData.stock.totalRemainingUsages
        : sumRemainingUsages;
      this.drug.entityData.stock = {
        totalUsages: this.drug.entityData?.stock?.totalUsages ? sumUsages + this.drug.entityData.stock.totalUsages : sumUsages,
        totalRemainingUsages: remainingUsages,
      } as IDrugStock;
      await this.drugService.saveDrug(this.drug);
      this.onDismiss();
      this.statEventService.newEvent("Setted up and saved a drug stock");
    }
  }

  public updateRemainingUsages(stockForm: FormGroup): void {
    if (this.knownPackaging) {
      stockForm.get("remainingUsages").setValue(this.allPacks.find((p) => p.cnk === stockForm.get("packCNK").value).usable);
    } else {
      stockForm.get("remainingUsages").setValue(stockForm.get("totalUsages").value);
    }
  }

  public removeStock(index: number): void {
    this.stocks.removeAt(index);
  }

  public addMoreStock(): void {
    if (this.knownPackaging) {
      const selectedPack = this.allPacks[this.drug.entityData?.lastPackNumber]
        ? this.allPacks[this.drug.entityData?.lastPackNumber]
        : this.allPacks[0];
      const stockForm = this.fb.group(
        {
          packCNK: [selectedPack.cnk, Validators.required],
          remainingUsages: [selectedPack.usable, Validators.required],
        },
        { validators: this.stockValidator() }
      );
      this.stocks.push(stockForm);
    } else {
      const stockForm = this.fb.group(
        {
          totalUsages: [0, Validators.required],
          remainingUsages: [0, Validators.required],
        },
        { validators: this.stockValidator() }
      );
      this.stocks.push(stockForm);
    }
  }

  public stockValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const totalUsages = formGroup.get("totalUsages")?.value;
      const remainingUsages = formGroup.get("remainingUsages")?.value;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors: any = {};

      // Ensure totalUsages is greater than zero
      if (!this.knownPackaging && totalUsages !== null && totalUsages <= 0) {
        errors.totalUsagesInvalid = true;
      }

      // Ensure remainingUsages is greater than zero
      if (remainingUsages !== null && remainingUsages <= 0) {
        errors.remainingUsagesInvalid = true;
      }

      if (!this.knownPackaging) {
        // Ensure remainingUsages is less than or equal to totalUsages
        if (totalUsages !== null && remainingUsages !== null && remainingUsages > totalUsages) {
          errors.remainingExceedsTotal = true;
        }
      } else if (this.knownPackaging) {
        const total = this.allPacks.find((p) => p.cnk === formGroup.get("packCNK").value).usable;
        // Ensure remainingUsages is less than or equal to pack total
        if (formGroup.get("packCNK")?.value !== null && remainingUsages !== null && remainingUsages > total) {
          errors.remainingExceedsTotal = true;
        }
      }

      return Object.keys(errors).length ? errors : null;
    };
  }
}

import { Component, NgZone, ViewChild } from "@angular/core";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { IonSlides, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppConstants } from "src/app/appConstants";
import { BasePage } from "src/app/baseClasses/base-page";
import { HEALTH_NETWORK, ICountry } from "src/app/models/country";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { CountryService } from "src/app/services/globalDataProvider/country.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";
import { FEATURES } from "src/environments/features";
import { E2E_ID_HELP } from "test/helpers/selectorIdHelper";

@Component({
  selector: "app-help-modal",
  templateUrl: "./help-modal.component.html",
  styleUrls: ["./help-modal.component.scss"],
})
export class HelpModalComponent extends BasePage {
  @ViewChild("slides") slides: IonSlides;
  private sliderSub: Subscription;
  public isBeginning = true;
  public isEnd = false;
  public iabActive = false;
  public isIOS: boolean;
  public isRelated = false;
  public isPatient = false;
  public isPatientAndRelated = false;
  public FEATURES = FEATURES;
  public mode = "";
  public E2E_ID_HELP = E2E_ID_HELP;
  public country: ICountry;
  public healthnetworks = HEALTH_NETWORK;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected modalCtrl: ModalController,
    protected translateSvc: TranslateService,
    private iab: InAppBrowser,
    public zone: NgZone,
    private accountService: AccountService,
    private countryService: CountryService,
    configService: ConfigurationService
  ) {
    super(translateSvc, configService, infoService, popupService);
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.isIOS = this.infoService.isIOS();
    this.isRelated = this.accountService.isOnlyRelated;
    this.isPatient = this.accountService.isOnlyPatient();
    this.isPatientAndRelated = this.accountService.isRelatedAndPatient();
    this.country = this.countryService.peekData()?.length ? this.countryService.peekData()[0] : null;
    this.mode = this.infoService.getImgPrefixAccordingToCordova();
    this.slides.update();
    this.initSlidesBool();
  }

  ionViewWillLeave(): void {
    super.ionViewWillLeave();
    this.sliderSub?.unsubscribe();
  }

  private initSlidesBool() {
    this.sliderSub = this.slides.ionSlideWillChange?.pipe(takeUntil(this.onDestroy$))?.subscribe(() => {
      this.slides.isBeginning().then((isB) => (this.isBeginning = isB));
      this.slides.isEnd().then((isE) => (this.isEnd = isE));
    });
  }

  /**
   *  cancel modal
   */
  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  /**
   * Click on next button
   */
  public nextSlide(): void {
    this.slides.slideNext();
  }

  /**
   * Click on previous button
   */
  public prevSlide(): void {
    this.slides.slidePrev();
  }

  public goTo(url: string): void {
    window.open(url, "_system", "location=no");
  }

  /**
   * clic on "Terms and privacy" button
   */
  public onTerms(): void {
    const TermsCondURL = this.translateSvc.instant("about.TermsCondURL");
    this.iab.create(TermsCondURL, "_blank", "location=yes");
  }

  /**
   * clic on "Help for inscription" button
   */
  public onHelpShareWithHealthnetwork(): void {
    if (this.country?.healthNetworks?.includes(HEALTH_NETWORK.ABRUMET)) {
      this.iabActive = true;
      const TermsCondURL = AppConstants.ABRUMET_HELP;
      const browser = this.iab.create(TermsCondURL, "_blank", "location=yes");
      browser
        .on("exit")
        ?.pipe(takeUntil(this.onDestroy$))
        .subscribe((_event) => {
          this.zone.run(() => {
            this.iabActive = false;
          });
        });
      browser.show();
    }
  }
}

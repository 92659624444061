import * as moment from "moment";
import { EntityDrug } from "src/app/models/entitylink";
import { INotification, NOTIFICATION_STATUS } from "src/app/models/notification";
import { ComputeDrugsService } from "src/app/services/compute-drugs.service";
import { FileLogger } from "../fileLogger";
import { Tools } from "../tools-helper";

export class DrugIntakes {
  public _RAW: INotification[]; // all notifications (ACCEPTED / REJECTED) associated with the indicated reference
  public ENTITY_DRUGS: EntityDrug[]; // all drugs  associated with the indicated reference
  public REFERENCE: string;

  /**
   * A reference can be associated with several drugs (atc code, for example). Notifications of all associated drugs are taken,
   * and the expected doses of all associated drugs are summed; no distinction is made between drugs in the present methods.
   * @param reference
   * @param drugs
   * @param computeDrugService
   */
  constructor(reference: string, drugs: EntityDrug[] | undefined, private computeDrugService: ComputeDrugsService) {
    this._RAW = [];
    this.ENTITY_DRUGS = drugs ?? [];
    this.REFERENCE = reference;
  }

  /**
   * A drugIntake(notification) can contains several "doses" :
   * if drugIntake.quantityTaken is a number, then this drugIntake is considered to represent this number of doses,
   * otherwise, drugIntake is considered to represent 1 dose
   * @param dayIndex '0' means today, '1' means yesterday, '2' means the day before yesterday, and so on
   * @param filter undefined: all drugs intake,
   *               UNSCHEDULED: only the unscheduled doses of medication,
   *               UNSCHEDULED: only the scheduled doses of medication,
   *               DEVICE: only medication taken by a device,
   *               MANUAL: only take the medication indicated manually
   * @returns number of doses (ACCEPTED, REJECTED, SCHEDULED) of the drug complying with the above conditions
   */
  public getDay = (
    dayIndex: number,
    filter?: string
  ): {
    ACCEPTED: number; // number of ‘notifications’ accepted (a notification may contain several doses)
    REJECTED: number; // number of ‘notifications’ rejected (a notification may contain several doses)
    DOSES_ACCEPTED: number; // number of doses accepted (if the dose encoded in a notification is not a number, the notification is considered to represent 1 dose)
    DOSES_REJECTED: number; // number of doses rejected (if the dose encoded in a notification is not a number, the notification is considered to represent 1 dose)
    SCHEDULED_DOSES_ACCEPTED: number; // number of doses scheduled for in accepted notifications (if the dose scheduled in a notification is not a number, the notification is considered to represent 1 dose scheduled)
    SCHEDULED_DOSES_REJECTED: number; // number of doses scheduled for in rejected notifications (if the dose scheduled in a notification is not a number, the notification is considered to represent 1 dose scheduled)
    SCHEDULED_DOSES: number; // number of doses scheduled for the day specified (ACCEPTED + REJECTED + NONE)
  } => {
    try {
      const arrValues: INotification[] = [];
      // collect values
      const pastMoment = moment().add(-Math.abs(dayIndex), "days");
      for (const intake of this._RAW) {
        if (moment(intake.time).isSame(pastMoment, "day")) {
          arrValues.push(intake);
        }
      }
      const startOfDay = pastMoment.startOf("day").format();
      const endOfDay = pastMoment.endOf("day").format();

      const SCHEDULED_DOSES = this.ENTITY_DRUGS.map((entityDrug) =>
        this.computeDrugService.numberDosesNeededForDates(entityDrug, startOfDay, endOfDay)
      ).reduce((count, nbDoses) => count + (nbDoses || 0), 0);

      if (arrValues.length === 0) {
        return {
          ACCEPTED: 0,
          REJECTED: 0,
          DOSES_ACCEPTED: 0,
          DOSES_REJECTED: 0,
          SCHEDULED_DOSES_ACCEPTED: 0,
          SCHEDULED_DOSES_REJECTED: 0,
          SCHEDULED_DOSES,
        };
      }
      return this.filterNotifications(arrValues, SCHEDULED_DOSES, filter);
    } catch (error) {
      FileLogger.error("DrugIntakes", "getDay", error, "none");
    }
  };

  private filterNotifications(
    arrValues: INotification[],
    SCHEDULED_DOSES: number,
    filter?: string
  ): {
    ACCEPTED: number; // number of ‘notifications’ accepted (a notification may contain several doses)
    REJECTED: number; // number of ‘notifications’ rejected (a notification may contain several doses)
    DOSES_ACCEPTED: number; // number of doses accepted (if the dose encoded in a notification is not a number, the notification is considered to represent 1 dose)
    DOSES_REJECTED: number; // number of doses rejected (if the dose encoded in a notification is not a number, the notification is considered to represent 1 dose)
    SCHEDULED_DOSES_ACCEPTED: number; // number of doses scheduled for in accepted notifications (if the dose scheduled in a notification is not a number, the notification is considered to represent 1 dose scheduled)
    SCHEDULED_DOSES_REJECTED: number; // number of doses scheduled for in rejected notifications (if the dose scheduled in a notification is not a number, the notification is considered to represent 1 dose scheduled)
    SCHEDULED_DOSES: number; // number of doses scheduled for the day specified (ACCEPTED + REJECTED + NONE)
    /* à priori, c'est uniquement dans cette méthode qu'il faut faire le traitement 
    (on ne peut pas se baser sur arrValues, car il ne contient pas forcément les NONE ou trop de NONE si on a fait des changements,
     il faut donc utiliser le EntityDrug contenant le paramétrage actuel (quantity, cycle, etc) et disponible dans le constructeur)
     Autre chose : le moteur de règle est pensé comme un plugin à part, donc tout ce qu'il utilise doit être dans le dosser trigger-rule-helper-engine
     car le but est de créer un package npm qui sera importé dans la mobileApp, server, dashboard etc
     ATTENTION : EntityDrug peut être undefined. Dans ce cas, retourner 0*/
  } {
    let ACCEPTED = 0;
    let REJECTED = 0;
    let DOSES_ACCEPTED = 0;
    let DOSES_REJECTED = 0;
    let SCHEDULED_DOSES_ACCEPTED = 0;
    let SCHEDULED_DOSES_REJECTED = 0;
    // eslint-disable-next-line prefer-const
    arrValues.forEach((drugIntake) => {
      let toBeRecorded = false;
      if (Tools.isNotDefined(filter)) {
        toBeRecorded = true;
      } else if (filter === "UNSCHEDULED" && drugIntake.unscheduledIntake) {
        toBeRecorded = true;
      } else if (filter === "SCHEDULED" && !drugIntake.unscheduledIntake) {
        toBeRecorded = true;
      } else if (filter === "DEVICE" && Tools.isDefined(drugIntake.device?.reference)) {
        toBeRecorded = true;
      } else if (filter === "MANUAL" && Tools.isNotDefined(drugIntake.device?.reference)) {
        toBeRecorded = true;
      }
      if (toBeRecorded && drugIntake.status === NOTIFICATION_STATUS.ACCEPTED) {
        ACCEPTED = ACCEPTED + 1;
        DOSES_ACCEPTED = DOSES_ACCEPTED + this.quantityToNumber(drugIntake?.quantityTaken);
        SCHEDULED_DOSES_ACCEPTED = SCHEDULED_DOSES_ACCEPTED + this.quantityToNumber(drugIntake?.quantity);
      } else if (toBeRecorded && drugIntake.status === NOTIFICATION_STATUS.REJECTED) {
        REJECTED = REJECTED + 1;
        DOSES_REJECTED = DOSES_REJECTED + this.quantityToNumber(drugIntake?.quantityTaken);
        SCHEDULED_DOSES_REJECTED = SCHEDULED_DOSES_REJECTED + this.quantityToNumber(drugIntake?.quantity);
      }
    });
    return { ACCEPTED, REJECTED, DOSES_ACCEPTED, DOSES_REJECTED, SCHEDULED_DOSES_ACCEPTED, SCHEDULED_DOSES_REJECTED, SCHEDULED_DOSES };
  }

  private quantityToNumber(quantity: string | undefined): number {
    try {
      if (Tools.isDefined(quantity)) {
        const quantityTakenNumber = Number(quantity);
        if (Number.isFinite(quantityTakenNumber) && quantityTakenNumber >= 0) {
          return quantityTakenNumber;
        }
      }
      return 1;
    } catch (error) {
      FileLogger.error("DrugIntakes", "quantityTakenToNumber - error", JSON.stringify(error), "none");
      return 1;
    }
  }

  public getPeriod = (
    dayIndexStart: number,
    dayIndexEnd: number,
    filter?: string
  ): {
    ACCEPTED: number; // number of ‘notifications’ accepted (a notification may contain several doses)
    REJECTED: number; // number of ‘notifications’ rejected (a notification may contain several doses)
    DOSES_ACCEPTED: number; // number of doses accepted (if the dose encoded in a notification is not a number, the notification is considered to represent 1 dose)
    DOSES_REJECTED: number; // number of doses rejected (if the dose encoded in a notification is not a number, the notification is considered to represent 1 dose)
    SCHEDULED_DOSES_ACCEPTED: number; // number of doses scheduled for in accepted notifications (if the dose scheduled in a notification is not a number, the notification is considered to represent 1 dose scheduled)
    SCHEDULED_DOSES_REJECTED: number; // number of doses scheduled for in rejected notifications (if the dose scheduled in a notification is not a number, the notification is considered to represent 1 dose scheduled)
    SCHEDULED_DOSES: number; // number of doses scheduled for the period specified (ACCEPTED + REJECTED + NONE)
  } => {
    try {
      const arrValues: INotification[] = [];
      // collect values
      const pastStartMoment = moment().add(-Math.abs(dayIndexStart), "days");
      const pastEndMoment = moment().add(-Math.abs(dayIndexEnd), "days");
      for (const intake of this._RAW) {
        if (moment(intake.time).isSameOrBefore(pastStartMoment, "day") && moment(intake.time).isSameOrAfter(pastEndMoment, "day")) {
          arrValues.push(intake);
        }
      }
      const startDay = pastStartMoment.startOf("day").format();
      const endDay = pastEndMoment.endOf("day").format();
      const SCHEDULED_DOSES = this.ENTITY_DRUGS.map((entityDrug) =>
        this.computeDrugService.numberDosesNeededForDates(entityDrug, startDay, endDay)
      ).reduce((count, nbDoses) => count + (nbDoses || 0), 0);

      if (arrValues.length === 0) {
        return {
          ACCEPTED: 0,
          REJECTED: 0,
          DOSES_ACCEPTED: 0,
          DOSES_REJECTED: 0,
          SCHEDULED_DOSES_ACCEPTED: 0,
          SCHEDULED_DOSES_REJECTED: 0,
          SCHEDULED_DOSES,
        };
      }
      return this.filterNotifications(arrValues, SCHEDULED_DOSES, filter);
    } catch (error) {
      FileLogger.error("DrugIntakes", "getPeriod", error, "none");
    }
  };
}

export class DictionnaryDrugIntake {
  [key: string]: DrugIntakes;
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IKnowledges, KNOW_DOC_CATEGORY } from "src/app/helpers/knowledge-helper";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class KnowledgesDescriptionService
  extends BasicSyncService<IKnowledges, IKnowledges[]>
  implements StaticImplements<INeedRefresh, typeof KnowledgesDescriptionService>
{
  public get needRefresh(): { value: boolean } {
    return KnowledgesDescriptionService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  public lastGenNotif: string = null;

  constructor(protected dataService: DataService, private accountService: AccountService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "knwoledges_careplans_descriptions_",
      entityStoreKey: "list",
      getUrl: "/knowledges/careplans?docCategory=" + KNOW_DOC_CATEGORY.DESCRIPTION,
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }
  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
  public watchData(careplanRef?: string): Observable<IKnowledges[]> {
    return this.data$.pipe(
      map((knowledges) => {
        return this.processData(knowledges, careplanRef);
      })
    );
  }

  /**
   * Returns the current state of the service's data
   */
  public peekData(careplanRef?: string): IKnowledges[] {
    return this.processData(super.peekData(), careplanRef);
  }

  public async *getDataReader(careplanRef?: string): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let iterator = await dataReader.next();
      let d: IKnowledges[] = this.processData(iterator.value, careplanRef);
      yield d;
      while (!iterator.done) {
        iterator = await dataReader.next();
        d = this.processData(iterator.value, careplanRef);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("KnowledgesDescriptionService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IKnowledges[], careplanRef?: string) {
    const kn = dataResult;
    if (careplanRef) {
      try {
        const filteredData = kn.filter((know) => know.reference === careplanRef);
        return filteredData;
      } catch (err) {
        FileLogger.error("KnowledgesDescriptionService", "Error while processing knowledgeDescriptionService data: ", err);
      }
    }
    return kn;
  }
}

import { Component, ViewChild } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AutoCompleteComponent, AutoCompleteOptions } from "ionic4-auto-complete";
import * as moment from "moment";
import { BasePage } from "src/app/baseClasses/base-page";
import { IAccount } from "src/app/helpers/account-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { Appointment, IAppointment } from "src/app/models/appointment";
import { IEntitylink } from "src/app/models/entitylink";
import { ACTION_STATUS_ENTITY, IEntity } from "src/app/models/sharedInterfaces";
import { AppointmentTypeAutoCompleteServiceService } from "src/app/services/appointment-type-auto-complete-service.service";
import { BadgesService } from "src/app/services/badges.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { LanguagesService } from "src/app/services/globalDataProvider/languagesService";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";
import { SysAccountService } from "src/app/services/sys-account.service";

@Component({
  selector: "app-appointment-modal",
  templateUrl: "./appointment-modal.component.html",
  styleUrls: ["./appointment-modal.component.scss"],
})
export class AppointmentModalComponent extends BasePage {
  public appointment: IAppointment;
  private account: IAccount;
  public isRelated: boolean;
  private relatedNote: IEntitylink;
  public customLocation: string;
  public options: AutoCompleteOptions;
  public isUserAppointement: boolean;
  public isPersonalAppointment: boolean;
  public isAccompagnied: boolean;
  public isLocked: boolean;
  public dateTimeLocaleFormat: string;

  @ViewChild("searchbar") searchbar: AutoCompleteComponent;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected navParams: NavParams,
    protected modalCtrl: ModalController,
    public appointmentTypeAutoCompleteServiceService: AppointmentTypeAutoCompleteServiceService,
    protected translateService: TranslateService,
    protected configService: ConfigurationService,
    private sysAccountService: SysAccountService,
    protected badgesService: BadgesService,
    protected languagesService: LanguagesService
  ) {
    super(translateService, configService, infoService, popupService);
    this.appointment = this.navParams.get("appointment");
    this.account = this.navParams.get("account");
    this.isRelated = this.navParams.get("isRelated");
    this.relatedNote = this.navParams.get("relatedNote");
    this.languagesService.getFirstDataAvailable().then((langs) => {
      this.dateTimeLocaleFormat = Tools.getDateTimeLocaleFormat(langs, this.configService.getCurrentLanguage());
    });
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    moment.locale(this.configService.getCurrentLanguage());

    this.isUserAppointement = Appointment.isPersonalAppointment(this.appointment);
    this.isPersonalAppointment = Appointment.isPersonalAppointment(this.appointment);
    this.isAccompagnied = Appointment.isAccompagnied(this.appointment, this.sysAccountService.cachedCaremateId);
    this.isLocked =
      IEntity.isLocked(this.appointment) || this.isRelated || Appointment.isAccompagnied(this.appointment, this.account.caremateIdentifier);

    this.options = new AutoCompleteOptions();
    this.options.noItems = "";
    this.options.placeholder = this.translateService.instant("agenda.description");
    this.options.type = "text";
    this.options.clearIcon = "";
    this.options.searchIcon = "";
    this.options.cancelButtonIcon = "";

    const loc = Appointment.getCustomLocation(this.appointment);
    if (loc !== null) this.customLocation = loc.display;
  }

  /**
   *  cancel edition
   */
  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  /**
   * delete (in fact, set status to delete)
   */
  public delete(): void {
    this.appointment.actionStatus = ACTION_STATUS_ENTITY.DELETED;
    this.modalCtrl.dismiss(this.appointment);
  }

  /**
   * save modification
   */
  public save(): void {
    if (this.isRelated) return; // no modification for related
    // ION-DATETIME use ISO 8601 datetime format (YYYY-MM-DDThh:mm:ssTZD), not exactly the same as Moment format
    // so we must convert it before saving
    if (this.appointment.start?.length) this.appointment.start = moment(this.appointment.start, "YYYY-MM-DDTHH:mm:ss").format();
    if (this.appointment.end?.length) this.appointment.end = moment(this.appointment.end, "YYYY-MM-DDTHH:mm:ss").format();
    // update custom location
    if (this.customLocation) {
      Appointment.setCustomLocation(this.appointment, this.customLocation);
    }

    this.appointment.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    this.appointment.description = !this.isLocked && this.isUserAppointement ? this.searchbar.keyword : this.appointment.description;
    this.badgesService.setAppointmentSeen([this.appointment]).then(() => {
      this.modalCtrl.dismiss(this.appointment);
    });
  }

  /**
   *  update private state
   */
  public private(): void {
    // @TODO n'a pas été implémentée dans "dev". Voir ce qu'on veut faire avec ça
  }

  /**
   * End date has been changed, check start date
   */
  public onEndDateChanged(): void {
    if (this.appointment.end) {
      const endDate = moment(this.appointment.end, "YYYY-MM-DDTHH:mm:ss");
      // check if End date is not before Start date (only hour and minute should be lower)
      if (endDate.isBefore(this.appointment.start, "minutes")) {
        this.appointment.start = endDate.add(-1, "hours").format();
      }
    }
  }

  public onStartDateChanged(): void {
    if (this.appointment.end) {
      const startDate = moment(this.appointment.start, "YYYY-MM-DDTHH:mm:ss");
      if (startDate.isSameOrAfter(moment(this.appointment.end), "second")) {
        this.appointment.end = startDate.add(1, "hours").format();
      }
    }
  }

  public clearEndDate(): void {
    this.appointment.end = null;
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { CustomParamKey } from "src/app/models/dataParameters";
import { IDrugSchema } from "src/app/models/drugSchema";
import { CycleSchema } from "src/app/models/entitylink";
import { ApiService } from "../api.service";
import { NetworkService } from "../network.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class DrugSchemaService
  extends BasicSyncService<IDrugSchema, IDrugSchema[]>
  implements StaticImplements<INeedRefresh, typeof DrugSchemaService>
{
  public get needRefresh(): { value: boolean } {
    return DrugSchemaService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService, private networkService: NetworkService, private apiService: ApiService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IDrugSchema[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "drugSchemas",
      entityStoreKey: "list",
      getUrl: "/drugSchema",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
      customParam: {
        [CustomParamKey.noPopupIfNoDataOffline]: true,
      },
    };
  }

  /**
   *
   * @param atcCode
   * @returns all schemas associated to the drugs for the patient : public + organization of the patient + specific drug
   * @note available only online (if offline, return empty array)
   */
  public async listSpecific(atcCode: string): Promise<IDrugSchema[]> {
    if (this.networkService.isCurrentOffline()) {
      return [];
    }
    try {
      const rep = await this.apiService.getWithPromise(`/drugSchema`, undefined, { drug: atcCode });
      const type = ServerResponse.type(rep);
      switch (type) {
        case SERVER_RESPONSE_TYPE.SUCCESS: {
          const t = rep.data as IDrugSchema[];
          if (!t) {
            return [];
          }
          return t;
        }
        default:
          return [];
      }
    } catch (err) {
      FileLogger.error("DrugSchemaService", "listSpecific: ", err);
      return [];
    }
  }

  /**
   * @returns Return a boolean that indicate if the cycle is actualy in pause or not
   */
  public isCycleInPause(cycle: CycleSchema): boolean {
    return DrugSchemaService.isCycleInPause(cycle);
  }
  /**
   * @returns Return a boolean that indicate if the cycle is actualy in pause or not
   */
  public static isCycleInPause(cycle: CycleSchema): boolean {
    if (!cycle.pauseDate || cycle.pauseDate.length === 0) {
      return false;
    }
    // The last pause is the last element of the array of pauses
    const lastPause = new Date(cycle.pauseDate[cycle.pauseDate.length - 1]);
    const now = new Date();
    // the number of days of each cycle, we have it with the length of the cycle schema
    const nbrDaysOfCycle = cycle.cycle.length;
    // determine in wich cycle we are
    // we have it by dividing the number of days since the cycle started by the number of days of each cycle
    // we round up the result
    const actualCycleNbr = Math.ceil(Tools.differenceDate(now, new Date(cycle.startDate)) / nbrDaysOfCycle);
    if (actualCycleNbr <= 0) {
      // if we are in the first cycle we just need to verify if there is a pause
      return cycle.pauseDate && cycle.pauseDate.length === 1;
    }
    // we determine how many days we need to add to the start date to be in the begining of the actual cycle
    const daysToAdd = (actualCycleNbr - 1) * nbrDaysOfCycle + 1;
    // we add this number of days to the start date, and we now have the date of the beginning of the actual cycle
    const startCycle = new Date(new Date(cycle.startDate).getTime() + daysToAdd * 1000 * 60 * 60 * 24);
    // we compare the date of the beginning of the actual cycle with the last pause
    // if the last pause is before the start cycle, the cycle was in pause but not in this cycle
    return lastPause >= startCycle;
  }

  /**
   * @param cycle
   * @param date
   * @returns Return if the cycle is in pause for today (and not tomorrow)
   */
  public isCycleInPauseOnDate(cycle: CycleSchema, date: Date): boolean {
    return DrugSchemaService.isCycleInPauseOnDate(cycle, date);
  }
  /**
   * @param cycle
   * @param date
   * @returns Return if the cycle is in pause for today (and not tomorrow)
   */
  public static isCycleInPauseOnDate(cycle: CycleSchema, date: Date): boolean {
    if (!this.isCycleInPause(cycle)) return false;
    const diff = Tools.differenceDate(new Date(cycle.pauseDate[cycle.pauseDate.length - 1]), new Date(date));
    return diff === 0;
  }

  /**
   * @param cycle
   * @returns Return the index of the current cycle
   */
  public static getDayOfCycleOnDate(cycle: CycleSchema, date = new Date()): number {
    // the number of days of each cycle, we have it with the length of the cycle schema
    const nbrDaysOfCycle = cycle.cycle.length;
    // determine in wich cycle we are
    // we have it by dividing the number of days since the cycle started by the number of days of each cycle
    // we round up the result
    const actualCycleNbr = Math.ceil(Tools.differenceDate(date, new Date(cycle.startDate)) / nbrDaysOfCycle);
    if (actualCycleNbr < 0) {
      // the cycle is in the futur
      return 0;
    }
    if (actualCycleNbr <= 1) {
      // if we are in the first cycle we just need to return the difference of days between now and the beginning of the cycle
      return Tools.differenceDate(date, new Date(cycle.startDate));
    }
    // we determine since how many days the first cycle started from the beginning of the actual cycle
    const nbrDaysFromLastCycle = (actualCycleNbr - 1) * nbrDaysOfCycle;
    // we determine since how many days the first cycle started from now
    const nbrDaysFromNow = Tools.differenceDate(date, new Date(cycle.startDate));
    // we determine the current index of the actual cycle by subtracting these results
    return nbrDaysFromNow - nbrDaysFromLastCycle;
  }
}

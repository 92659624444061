import { Pipe, PipeTransform } from "@angular/core";
import { IKnowMedia } from "../helpers/knowledge-helper";

/**
 * Get in the html all the img balise and return the src/B64 string image of the first
 */
@Pipe({ name: "getFirstImage" })
export class GetFirstImagePipe implements PipeTransform {
  constructor() {}

  public transform(content: IKnowMedia) {
    const reg = /<img[^>]+src="([^">]+)"/;
    const result = content.content.match(reg);
    return result ? result[1] : undefined;
  }
}

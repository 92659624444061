import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Careplan, ICareplan } from "src/app/models/careplan";
import { IServiceTeam } from "src/app/models/healthcareservice";
import { AccountService } from "./account.service";
import { CareplanService } from "./careplan.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class ContactService
  extends BasicSyncService<IServiceTeam, IServiceTeam[]>
  implements StaticImplements<INeedRefresh, typeof ContactService>
{
  public get needRefresh(): { value: boolean } {
    return ContactService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  public lastGenNotif: string = null;
  private lastValueOfParam = "[]";
  private storageKey = "ContactLastValue";

  public get entityStoreKey(): string {
    return super.entityStoreKey + this.lastValueOfParam;
  }

  constructor(
    protected dataService: DataService,
    private careplanService: CareplanService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    super(dataService);
  }

  public getUrl(): string {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IServiceTeam[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "serviceteam_",
      entityStoreKey: "list",
      getUrl: "/serviceteam?identifiers=",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
    } catch (err) {
      this.lastValueOfParam = "[]";
    }
  }

  public clear(): void {
    super.clear();
    this.lastValueOfParam = "[]";
  }

  public async *getDataReader(careplans: ICareplan[] = null): AsyncGenerator<IServiceTeam[], IServiceTeam[], IServiceTeam[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const lsCareplans = await this.careplanService.getFirstDataAvailable();

      const allCareplanIdentifiers = JSON.stringify(lsCareplans.map((cp) => Careplan.getCaremateIdentifier(cp)));

      if (!allCareplanIdentifiers || allCareplanIdentifiers.length === 0) {
        yield [];
        return [];
      }

      const paramObject = Object.assign({}, this.defaultDataParameter);
      paramObject.getUrl += allCareplanIdentifiers;
      paramObject.entityStoreKey += allCareplanIdentifiers;
      this.lastValueOfParam = allCareplanIdentifiers;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);

      const dataReader = this.dataService.readv2<IServiceTeam, IServiceTeam[]>(paramObject, false, this);
      let d: IServiceTeam[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, careplans);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("ContactService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IServiceTeam[], careplans: ICareplan[] | null) {
    if (!careplans || careplans.length === 0) {
      return dataResult;
    }
    try {
      /*
             The problem with Careplan.getCaremateIdentifier is that return the first identifier.value of the careplan but
             the service.healthService.providedBy.reference is not necessarily this one
            */
      const ids = careplans
        .map((cp) => {
          return cp.identifier.map((id) => id.value);
        })
        .reduce((acc, it) => [...acc, ...it], []);

      const filteredData = dataResult.filter((service) => {
        return ids.includes(service.healthService.providedBy.reference);
      });
      return filteredData;
    } catch (err) {
      FileLogger.error("ContactService", "Error while processing contactService data: ", err);
    }
    return dataResult;
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(careplans: ICareplan[] = null): IServiceTeam[] {
    return this.processData(super.peekData(), careplans);
  }
  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
  public watchData(careplans: ICareplan[] = null): Observable<IServiceTeam[]> {
    return this.data$.pipe(
      map((team) => {
        return this.processData(team, careplans);
      })
    );
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(careplans: ICareplan[] = null): Promise<IServiceTeam[]> {
    const dataReader = this.getDataReader(careplans);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(careplans: ICareplan[] = null): Promise<IServiceTeam[]> {
    const dataReader = this.getDataReader(careplans);
    const iterator = await dataReader.next();
    return iterator.value;
  }
}

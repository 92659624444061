import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BasePage } from "src/app/baseClasses/base-page";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";
import Validation from "../../reset-password-modal/password-match.validation";
import { ResetPasswordService } from "src/app/services/modal-reset-password.service";
import { last } from "rxjs/operators";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { NetworkService } from "src/app/services/network.service";
import { FileLogger } from "src/app/helpers/fileLogger";

@Component({
  selector: "app-change-own-password-modal",
  templateUrl: "./change-own-password-modal.component.html",
  styleUrls: ["./change-own-password-modal.component.scss"],
})
export class ChangeOwnPasswordModalComponent extends BasePage {
  public changePasswordForm: UntypedFormGroup;
  private login: string;
  public passwordError: string;
  public expiredPassword = false;

  constructor(
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    protected formBuilder: UntypedFormBuilder,
    protected resetPwdService: ResetPasswordService,
    protected networkService: NetworkService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.login = this.params.get("login");
    if (this.params.get("expiredPassword")) {
      this.expiredPassword = this.params.get("expiredPassword");
    }
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: ["", [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}$/)]],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validators: [Validation.match("newPassword", "confirmPassword")],
      }
    );
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  public changePassword(): Promise<void> {
    const oldPassword = this.changePasswordForm.get("oldPassword").value;
    const newPassword = this.changePasswordForm.get("newPassword").value;
    if (this.login && oldPassword && newPassword && this.changePasswordForm.valid) {
      if (this.networkService.isCurrentOffline()) {
        return this.popupService.showAlert("application.title", "login.account.offline");
      } else {
        this.resetPwdService
          .changeOwnPassword(this.login, oldPassword, newPassword)
          .pipe(last())
          .subscribe((response) => {
            const responseType = ServerResponse.type(response);
            switch (responseType) {
              case SERVER_RESPONSE_TYPE.SUCCESS:
                this.modalCtrl.dismiss();
                return this.popupService.showToast("login.changePwdSuccess", 3000, "bottom");
              case SERVER_RESPONSE_TYPE.INVALID_PASSWORD:
                if (response.additionalData) {
                  this.passwordError = `${response.additionalData}.${this.configService.defaultLang}`;
                  return;
                }
                return;
              case SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED:
                if (this.expiredPassword) {
                  this.passwordError = this.translateSvc.instant("login.account.oldPwdOrLoginInvalid");
                  return;
                } else {
                  this.passwordError = this.translateSvc.instant("login.account.oldPwdInvalid");
                  return;
                }
              case SERVER_RESPONSE_TYPE.TOO_MANY_FAIL_CONNECTION:
                this.passwordError = this.translateSvc.instant("login.refused.tooManyAttempts");
                return;
              default:
                FileLogger.log("ChangeOwnPasswordModalComponent", "resetPwdService.changeOwnPasswrod error", response);
                return this.popupService.showAlert("application.title", "error.invalidRegistration");
            }
          });
      }
    }
  }
}

import { Component } from "@angular/core";
import { Market } from "@ionic-native/market/ngx";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { BasePage } from "src/app/baseClasses/base-page";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SERVER_RESPONSE_TYPE, ServerResponse } from "src/app/helpers/server-response-helper";
import { ApiService } from "src/app/services/api.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-activate2-famodal",
  templateUrl: "./activate2-famodal.component.html",
  styleUrls: ["./activate2-famodal.component.scss"],
})
export class Activate2FAModalComponent extends BasePage {
  public secret2fa: string;
  public code: string;
  public currentStep = 1;

  /**
   * constructor
   */
  constructor(
    private params: NavParams,
    public modalCtrl: ModalController,
    protected translateSvc: TranslateService,
    protected alertCtrl: AlertController,
    private apiService: ApiService,
    private infoAppService: InfoAppService,
    private market: Market,
    public configService: ConfigurationService,
    protected infoService: InfoAppService,
    protected popupService: PopupService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.secret2fa = this.params.get("secret2fa");
  }

  /**
   *  cancel modal
   */
  public dismiss(activated = false): void {
    this.modalCtrl.dismiss(activated);
  }

  /**
   *
   */
  public onSubmit(): void {
    this.apiService
      .post("/mfa/confirm", { code: this.code })
      .pipe(
        map((rep) => {
          const type = ServerResponse.type(rep);
          switch (type) {
            case SERVER_RESPONSE_TYPE.SUCCESS:
              if (rep.data) {
                this.popupService.showAlert("settings.auth.mfa.activate.title", "settings.auth.mfa.activate.success");
                this.dismiss(true);
              } else {
                this.popupService.showAlert("error.general", "login.refused.wrongmfa");
              }
              break;
            default:
              this.popupService.showAlert("error.general", "settings.auth.mfa.enable_error");
              break;
          }
        })
      )
      .subscribe();
  }

  /**
   *
   */
  public openGA(): void {
    window.open("otpauth://totp/Comunicare?secret=" + this.secret2fa, "_system", "location=no");
    this.currentStep = 2;
  }

  /**
   * Open Market to donwload Google Authenticator
   */

  public openMarket(): void {
    if (this.infoAppService.isAndroid()) {
      this.market.open("com.google.android.apps.authenticator2").then(
        (value) => {
          FileLogger.log("Activate2FAModalComponent", "openMarket Android success", value);
        },
        (err) => {
          FileLogger.error("Activate2FAModalComponent", "openMarket Android failed", err);
        }
      );
    } else if (this.infoAppService.isIOS()) {
      this.market.open("id388497605").then(
        (value) => {
          FileLogger.log("Activate2FAModalComponent", "openMarket IOS success", value);
        },
        (err) => {
          FileLogger.error("Activate2FAModalComponent", "openMarket IOS failed", err);
        }
      );
    } else {
      alert("Cannot install app in browser.");
    }
  }
}

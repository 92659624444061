import { BluetoothLE, Device, Services, Characteristics } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InfoAppService } from "../info-app.service";
import { MedicalBluetoothDevice, MedicalBluetoothService } from "./medical-bluetooth.service";
import { FileLogger } from "src/app/helpers/fileLogger";
import { MedicalBluetoothStatus } from "./medical-bluetooth-status.service";

export interface MedicalBluetoothDataConstructor {
  new (
    device: MedicalBluetoothDevice,
    handler: Observable<string>,
    infoAppService: InfoAppService,
    bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>,
    translateService: TranslateService,
    searchPreviousData: boolean,
    medicalBluetoothStatus: MedicalBluetoothStatus
  );
}

export abstract class MedicalBluetoothData {
  protected abstract get serviceType(): MedicalBluetoothService;
  public deviceInfo: Device | undefined;
  public standby = true;
  protected _data: Subject<any> = new Subject();

  protected abstract descriptor;

  public constructor(
    protected device: MedicalBluetoothDevice,
    public handler: Observable<string>,
    private infoAppService: InfoAppService,
    protected bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>, // to manage the unsubscription of observables (see public async connect<T extends MedicalBluetoothData>)
    protected translateService: TranslateService,
    protected searchPreviousData: boolean,
    public medicalBluetoothStatus: MedicalBluetoothStatus
  ) {
    let readData$: Subscription;
    handler.pipe(takeUntil(onDestroy$)).subscribe(
      (status) => {
        switch (status) {
          case "connected":
            this.initDiscover().then(() => {
              if (readData$) {
                readData$.unsubscribe();
              }
              readData$ = this.readData()
                .pipe(takeUntil(onDestroy$))
                .subscribe(
                  () => {
                    FileLogger.log("MedicalBluetoothData", "Reading data...");
                    this.medicalBluetoothStatus.isBleConnectedToIOS$.next(true);
                  },
                  (err) => {
                    FileLogger.error("readData bluetooth", "error", JSON.stringify(err));
                  }
                );
            });
            break;
          case "disconnected":
          default:
            this.medicalBluetoothStatus.isBleConnectedToIOS$.next(false);
            this.disconnect();
            break;
        }
      },
      (error) => {
        FileLogger.error("MedicalBluetoothData", "Error connect subscribe", JSON.stringify(error));
        this.medicalBluetoothStatus.isBleConnectedToIOS$.next(false);
      }
    );
  }

  public abstract getData(): Subject<any>;
  public abstract getHumanReadableData(data: any): string;
  protected abstract readData(): Observable<void>;

  protected disconnect(): void {
    this.bluetoothle.isConnected({ address: this.descriptor.address }).then((connectionStatus) => {
      if (connectionStatus.isConnected) {
        this.bluetoothle.unsubscribe(this.descriptor).then(() => {
          this.bluetoothle.disconnect({ address: this.descriptor.address }).then(() => {
            this._data.complete();
          });
        });
      } else {
        this._data.complete();
      }
    });
  }

  /**
   * Converts a DataView that represents a 4 byte FLOAT (IEEE-11073)
   * to an actual float
   */
  protected floatFromIEEE_11073(value: DataView, offset: number): number {
    const negative = value.getInt8(offset + 2) >>> 31;
    const [b0, b1, b2, exponent] = [
      value.getUint8(offset),
      value.getUint8(offset + 1),
      value.getUint8(offset + 2),
      value.getInt8(offset + 3),
    ];

    let mantissa = b0 | (b1 << 8) | (b2 << 16);
    if (negative) {
      mantissa |= 255 << 24;
    }

    return mantissa * Math.pow(10, exponent);
  }

  /**
   * Converts a DataView that represents a 2 byte SFLOAT (IEEE-11073)
   * to an actual float
   */
  protected sFloatFromIEEE_11073(value: DataView, offset: number): number {
    const ieee11073 = value.getUint8(offset) + value.getUint8(offset + 1) * 0x100;
    let mantissa = ieee11073 & 0x0fff;

    if (mantissa === 0x07fe || mantissa === 0x0802) {
      return Infinity;
    }

    if (mantissa === 0x07ff || mantissa === 0x0800 || mantissa === 0x0801) {
      return NaN;
    }

    if (mantissa >= 0x0800) {
      mantissa = -(0x1000 - mantissa);
    }

    let exponent = ieee11073 >> 12;
    if (exponent >= 0x08) {
      exponent = -(0x10 - exponent);
    }

    return mantissa * Math.pow(10, exponent);
  }

  protected bytesFromString(base64Value: string): Uint8Array {
    const data = atob(base64Value);
    const bytes = new Uint8Array(data.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = data.charCodeAt(i);
    }

    return bytes;
  }

  private async initDiscover() {
    const discoveredStatus = await this.bluetoothle.isDiscovered({ address: this.device.address });
    if (!discoveredStatus.isDiscovered) {
      if (this.infoAppService.isAndroid()) {
        this.deviceInfo = await this.bluetoothle.discover({
          address: this.device.address,
          clearCache: false,
        });
      } else {
        const servicesResult = (await this.bluetoothle.services({
          address: this.device.address,
          services: this.device.services,
        })) as unknown as Services;

        if (servicesResult.status === "services") {
          for (const service of servicesResult.services) {
            const characteristicsResult = (await this.bluetoothle.characteristics({
              address: this.device.address,
              service: service,
            })) as unknown as Characteristics;

            if (characteristicsResult.status === "characteristics") {
              for (const characteristic of characteristicsResult.characteristics) {
                await this.bluetoothle.descriptors({
                  address: this.device.address,
                  service: service,
                  characteristic: characteristic.uuid,
                });
              }
            }
          }
        }
      }
    }
  }
}

import { Injectable } from "@angular/core";
import { BluetoothLE, DeviceInfo, Status } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subject, combineLatest, from, interval, of, throwError, timer } from "rxjs";
import { catchError, concatMap, filter, finalize, first, flatMap, map, skip, timeout } from "rxjs/operators";
import { IExternalRessource } from "src/app/models/externalRessource";
import { InfoAppService } from "../info-app.service";
import { MedicalBluetoothData, MedicalBluetoothDataConstructor } from "./medical-bluetooth-data";
import { MedicalBluetoothError, MedicalBluetoothErrorType } from "./medical-bluetooth-error";
import { FileLogger } from "src/app/helpers/fileLogger";
export { MedicalBluetoothError, MedicalBluetoothErrorType } from "./medical-bluetooth-error";
import { MedicalBluetoothThermometer } from "./medical-hardware/medical-bluetooth-thermometer";
import { MedicalBluetoothWeightScale } from "./medical-hardware/medical-bluetooth-weight-scale";
import { MedicalBluetoothBloodPressure } from "./medical-hardware/medical-bluetooth-blood-pressure";
import { MedicalBluetoothOximeter } from "./medical-hardware/medical-bluetooth-oximeter";
import { MedicalBluetoothLungMonitor } from "./medical-hardware/ medical-bluetooth-lung-monitor";
import { MedicalBluetoothGlucometer } from "./medical-hardware/medical-bluetooth-glucometer";
import { MedicalBluetoothStatus } from "./medical-bluetooth-status.service";

export interface MedicalBluetoothDevice {
  name: string | null;
  address: string;
  services: MedicalBluetoothService[];
  bonded: boolean;
}

export enum MedicalBluetoothService {
  HEALTH_THERMOMETER = "1809",
  BLOOD_PRESSURE = "1810",
  WEIGHT_SCALE = "181D",
  NONIN_OXIMETER = "46A970E0-0D5F-11E2-8B5E-0002A5D5C51B",
  VITALOGRAPH_LUNG_MONITOR = "FEFB",
  GLUCOSE = "1808",
  FINDAIR = "FINDAIR",
}

export enum MedicalBluetoothCharacteristic {
  TEMPERATURE_MEASUREMENT = "2a1c",
  WEIGHT_MEASUREMENT = "2a9d",
  BLOOD_PRESSURE_MEASUREMENT = "2a35",
  NONIN_OXIMETRY_MEASUREMENT = "0AAD7EA0-0D60-11E2-8E3C-0002A5D5C51B",
  VITALOGRAPH_BREATH_MEASUREMENT = "00000002-0000-1000-8000-008025000000",
  GLUCOSE_MEASUREMENT = "2A18",
  GLUCOSE_MEASUREMENT_CONTEXT = "2A34",
  GLUCOSE_RECORD_ACCESS_POINT = "2A52",
  ACCU_CHEK_MEASURE = "00000000-0000-1000-1000-000000000002",
}

export const medicalBluetoothServiceMapping = {
  [MedicalBluetoothService.HEALTH_THERMOMETER]: MedicalBluetoothThermometer,
  [MedicalBluetoothService.WEIGHT_SCALE]: MedicalBluetoothWeightScale,
  [MedicalBluetoothService.BLOOD_PRESSURE]: MedicalBluetoothBloodPressure,
  [MedicalBluetoothService.NONIN_OXIMETER]: MedicalBluetoothOximeter,
  [MedicalBluetoothService.VITALOGRAPH_LUNG_MONITOR]: MedicalBluetoothLungMonitor,
  [MedicalBluetoothService.GLUCOSE]: MedicalBluetoothGlucometer,
};

@Injectable({
  providedIn: "root",
})
export class MedicalBluetooth {
  public constructor(
    private infoAppService: InfoAppService,
    public bluetoothle: BluetoothLE,
    public device: Device,
    private translateService: TranslateService,
    private medicalBluetoothStatus: MedicalBluetoothStatus
  ) {}

  public get bondedDevices(): MedicalBluetoothDevice[] {
    const local = localStorage.getItem("bondedDevices");
    return local ? JSON.parse(local) : [];
  }

  public addBondedDevices(device: MedicalBluetoothDevice): void {
    const bondedDevices = this.bondedDevices;
    const exists = bondedDevices.find((value) => {
      return value.address === device.address;
    });
    if (!exists) {
      bondedDevices.push(device);
    }
    localStorage.setItem("bondedDevices", JSON.stringify(bondedDevices));
  }

  public removeBondedDevices(device: MedicalBluetoothDevice): void {
    let bondedDevices = this.bondedDevices;
    bondedDevices = bondedDevices.filter((d) => {
      return d.address !== device.address;
    });
    localStorage.setItem("bondedDevices", JSON.stringify(bondedDevices));
  }

  /**
   *
   * @param externalRessource
   * @returns the MedicalBluetoothDevice already bounded associated to this externalRessource. If no exist
   * (not already bounded for example), return undefined
   */
  public getBondedDevice(externalRessource: IExternalRessource): MedicalBluetoothDevice {
    const bleServiceCode = externalRessource.meta?.medicalBluetoothService;
    if (!bleServiceCode) {
      return undefined;
    }
    return this.bondedDevices.find((device) => {
      return (
        device.services?.includes(bleServiceCode) && externalRessource.reference && device.name.indexOf(externalRessource.reference) > -1
      );
    });
  }

  public async ready(): Promise<void> {
    try {
      if (this.infoAppService.isAndroid()) {
        const btPermission = await this.bluetoothle.requestPermission();
        const androidVersion = Number(this.device.version?.split(".")[0]);
        let scanPermission = { requestPermission: true };
        let connectPermission = { requestPermission: true };
        if (androidVersion >= 12) {
          // only for android 12+
          scanPermission = await this.bluetoothle.requestPermissionBtScan();
          connectPermission = await this.bluetoothle.requestPermissionBtConnect();
        }
        if (!btPermission.requestPermission || !scanPermission.requestPermission || !connectPermission.requestPermission) {
          throw new MedicalBluetoothError("Permission not granted", MedicalBluetoothErrorType.PERMISSION_NOT_GRANTED);
        }
        const btLocation = await this.bluetoothle.isLocationEnabled();
        if (!btLocation.isLocationEnabled) {
          throw new MedicalBluetoothError("Location is not enabled", MedicalBluetoothErrorType.LOCATION_NOT_ENABLED);
        }
      }

      const status = await this.getStatus();
      if (status === "disabled") {
        throw new MedicalBluetoothError("Bluetooth is not enabled", MedicalBluetoothErrorType.BLUETOOTH_NOT_ENABLED);
      } else if (status === "enabled") {
        return;
      } else {
        throw new MedicalBluetoothError("Unknonw bluetooth status", MedicalBluetoothErrorType.UNKNOWN_STATUS);
      }
    } catch (error) {
      throw new MedicalBluetoothError(error.message || "Unknown", error.type || MedicalBluetoothErrorType.UNKNOWN);
    }
  }

  private $status = new BehaviorSubject<"enabled" | "disabled" | "no-init">("no-init");

  private async getStatus(): Promise<"enabled" | "disabled" | "no-init"> {
    const initialization = await this.bluetoothle.isInitialized();
    if (initialization.isInitialized) {
      return this.$status.value;
    } else {
      const getStatus = new Promise<"enabled" | "disabled" | "no-init">((resolve, reject) => {
        this.$status
          .pipe(
            filter((status) => status !== "no-init"),
            timeout(10000),
            first()
          )
          .subscribe(
            (status) => resolve(status),
            (err) => reject(err)
          );
      });
      this.bluetoothle.initialize({ request: true }).subscribe((state) => {
        this.$status.next(state.status);
      });
      return getStatus;
    }
  }

  public async scan(services?: MedicalBluetoothService[]): Promise<Observable<MedicalBluetoothDevice>> {
    const scanningStatus = await this.bluetoothle.isScanning();
    if (scanningStatus.isScanning) {
      await this.bluetoothle.stopScan();
    }

    return this.bluetoothle
      .startScan({
        allowDuplicates: false,
        services,
      })
      .pipe(
        map((v) => {
          const d = v as unknown as DeviceInfo;
          const device: MedicalBluetoothDevice = {
            address: d.address,
            bonded: false,
            name: d.name,
            services,
          };
          return device;
        })
      );
  }

  public async stopScan() {
    const scanningStatus = await this.bluetoothle.isScanning();
    if (scanningStatus.isScanning) {
      await this.bluetoothle.stopScan();
    }
  }

  public bond(device: MedicalBluetoothDevice, onDestroy$: Subject<void>): Observable<void> {
    return from(this.clearPreviousConnection(device)).pipe(
      concatMap(() => {
        if (this.infoAppService.isAndroid()) {
          return this.bluetoothle.bond({ address: device.address }).pipe(
            skip(1), //  The first success callback should always return with status == bonding (https://github.com/randdusing/cordova-plugin-bluetoothle#bond)
            concatMap((deviceInfo) => {
              switch (deviceInfo.status as unknown as Status) {
                case "bonded":
                  device.bonded = true;
                  this.addBondedDevices(device);
                  break;
                case "unbonded":
                  return throwError(new MedicalBluetoothError("Bonding timed out", MedicalBluetoothErrorType.BONDING_TIMEOUT));
                case "disconnected":
                  return throwError(new MedicalBluetoothError("Device disconnected", MedicalBluetoothErrorType.DEVICE_DISCONNECTED));
                default:
                  break;
              }
              return of(null);
            }),
            catchError((err) => {
              FileLogger.error("MedicalBluetooth", "bond", JSON.stringify(err));
              if (err.message === "Device already bonded") {
                device.bonded = true;
                this.addBondedDevices(device);
                return of(null);
              } else {
                return throwError(
                  new MedicalBluetoothError("Bonding timed out", err?.type ? err.type : MedicalBluetoothErrorType.UNKNOWN_STATUS)
                );
              }
            }),
            finalize(() => {
              FileLogger.log("MedicalBluetooth", "bond - FINISH");
            })
          );
        } else {
          const serviceHandler = medicalBluetoothServiceMapping[device.services[0]];

          return combineLatest([
            from(this.connect(device, serviceHandler, onDestroy$, false)),
            interval(1000).pipe(flatMap(async () => await this.isBonded(device))),
            this.medicalBluetoothStatus.isBleConnectedToIOS$,
          ]).pipe(
            timeout(60000),
            concatMap(([, bonded, isConnected]: [any, boolean, boolean]) => {
              FileLogger.log("MedicalBluetooth", "isConnected vaut ", isConnected);

              if (isConnected === false) {
                FileLogger.log("MedicalBluetooth", "is not connected");

                return throwError(new MedicalBluetoothError("Device disconnected", MedicalBluetoothErrorType.DEVICE_DISCONNECTED));
              } else if (isConnected === true && bonded) {
                FileLogger.log("MedicalBluetooth", "is connected");

                return from(
                  this.bluetoothle
                    .disconnect({
                      address: device.address,
                    })
                    .catch((err) => {
                      FileLogger.error("MedicalBluetooth", "err1", err);

                      return null;
                    })
                    .then(() => {
                      return this.bluetoothle.close({
                        address: device.address,
                      });
                    })
                    .catch((err) => {
                      FileLogger.error("MedicalBluetooth", "err2", err);

                      return null;
                    })
                ).pipe(
                  concatMap(() => {
                    FileLogger.log("MedicalBluetooth", "add bondedDevices", device);

                    device.bonded = true;
                    this.addBondedDevices(device);
                    return timer(6000);
                  })
                );
              }
              return of(null);
            }),
            catchError((error) => {
              FileLogger.error("MedicalBluetooth", "bond error", JSON.stringify(error));
              return throwError(new MedicalBluetoothError("Bonding timed out", MedicalBluetoothErrorType.BONDING_TIMEOUT));
              return of(error);
            })
          );
        }
      })
    );
  }

  public async unbond(device: MedicalBluetoothDevice) {
    let unbonded = true;
    if (this.infoAppService.isAndroid()) {
      try {
        const bondStatus = await this.bluetoothle.isBonded({ address: device.address });
        if (bondStatus.isBonded) {
          const deviceInfo = await this.bluetoothle.unbond({ address: device.address });
          const di = deviceInfo as unknown as DeviceInfo;
          unbonded = di.status === "unbonded";
        }
      } catch (e) {
        FileLogger.error("MedicalBluetooth", "Error unbonding", JSON.stringify(e));
        unbonded = false;
      }
    }

    try {
      await this.clearPreviousConnection(device);
    } catch (e) {
      FileLogger.error("MedicalBluetooth", "clearPreviousConnection", JSON.stringify(e));
    }

    if (unbonded) {
      this.removeBondedDevices(device);
    }

    return unbonded;
  }

  public async connect<T extends MedicalBluetoothData>(
    device: MedicalBluetoothDevice,
    factory: MedicalBluetoothDataConstructor,
    onDestroy$: Subject<void>,
    searchPreviousData: boolean
  ): Promise<T> {
    await this.clearPreviousConnection(device);

    const handler = this.bluetoothle
      .connect({
        address: device.address,
        autoConnect: true,
      })
      .pipe(
        map((v: any) => {
          if (v.status?.status) {
            return v.status as string;
          } else {
            return v.status as unknown as string;
          }
        })
      );

    return new factory(
      device,
      handler,
      this.infoAppService,
      this.bluetoothle,
      onDestroy$,
      this.translateService,
      searchPreviousData,
      this.medicalBluetoothStatus
    );
  }

  public async clearPreviousConnection(device: MedicalBluetoothDevice) {
    try {
      const connectedState = await this.bluetoothle.wasConnected({ address: device.address });
      FileLogger.log("MedicalBluetooth", "clearPreviousConnection", JSON.stringify(connectedState));
      if (connectedState.wasConnected) {
        await this.bluetoothle.close({ address: device.address });
      }
    } catch (error) {
      FileLogger.log("MedicalBluetooth", "clear previous connection error", JSON.stringify(error));
    }
  }

  private async isBonded(device: MedicalBluetoothDevice) {
    if (this.infoAppService.isAndroid()) {
      return (await this.bluetoothle.isBonded({ address: device.address })).isBonded;
    } else {
      try {
        const connectedDevices = (await this.bluetoothle.retrieveConnected({
          services: device.services,
        })) as unknown as DeviceInfo[];
        return connectedDevices.find((d) => d.address === device.address) !== undefined;
      } catch (e) {
        FileLogger.error("MedicalBluetooth", "retrieveConnected error", JSON.stringify(e));
      }
    }
  }
}

import { IQuestionnaire } from "../../models/questionnaire";
import { IRule } from "../../models/rule";
import { RULE_TARGET_TYPE } from "../../models/sharedInterfaces";
import { IAccount } from "../account-helper";
import { QuestionnaireResponse } from "../questionnaireResponse";
import { Tools } from "../tools-helper";
import { IRuleAlert, RuleAlertHelper } from "../trigger-rule-helper-engine/ruleAlert-helper";
import { TriggerRuleHelperQuestionnaireService } from "../trigger-rule-helper/trigger-rule-helper-questionnaire.service";

/**
 * Management general of the rules linked to a questionnaire
 */
export class RuleHelperQuestionnaireService {
  public ruleLinked: IRule;
  private ruleHelper: TriggerRuleHelperQuestionnaireService;

  constructor(public user: IAccount, rules: IRule[], public currentQuestionnaire: IQuestionnaire) {
    this.ruleLinked = rules.find((r) => r.meta.targets.findIndex((t) => t.reference === currentQuestionnaire.identifier[0].value) >= 0);
  }

  /**
   * Indicates if this questionnaire is linked to a rule
   */
  get isLinkedToARule(): boolean {
    return Tools.isDefined(this.ruleLinked);
  }

  /**
   * @param currentQuestionnaireResponse
   * @returns the rule alert associated to the triggered rule definition. It can be undefined if no rule has been triggered
   */
  public getRuleAlert(currentQuestionnaireResponse: QuestionnaireResponse): IRuleAlert {
    this.ruleHelper = new TriggerRuleHelperQuestionnaireService(
      this.ruleLinked.meta.valueSet,
      this.currentQuestionnaire,
      currentQuestionnaireResponse
    );
    const ruleDef = this.ruleLinked.definitions.find((def) => {
      if (Tools.isDefined(def.triggerFormula)) {
        return this.ruleHelper.triggerFormulaEvaluation(def.triggerFormula);
      } else {
        return false;
      }
    });

    if (Tools.isDefined(ruleDef)) {
      return RuleAlertHelper.asRuleAlertForQuestionnaire(this.ruleLinked, ruleDef, currentQuestionnaireResponse, this.user);
    } else {
      return null;
    }
  }

  /**
   * @returns The code of the vital profile associated to this questionnaire (can be undefined)
   */
  public getVitalProfileLinked(): string {
    return this.ruleLinked.meta.targets.find((t) => t.type === RULE_TARGET_TYPE.VITAL_PROFILE)?.reference;
  }

  /**
   * Display multiple alert in a single popup
   * @param ruleAlerts
   */
  public getMessageForPopup(alert: IRuleAlert, lang: string): string {
    // gather messages
    let messages = "";
    for (const ruleResult of alert.rule.results) {
      const identifierValue = alert.identifier && alert.identifier.length ? alert.identifier[0].value : "";
      const msg = this.ruleHelper.getAlertMessageTranslated(ruleResult, identifierValue, lang);
      if (msg && msg.length) messages += "<br>" + msg;
    }
    return messages;
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { ArrayHelper } from "src/app/helpers/array-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ServerError, ServerResponse } from "src/app/helpers/server-response-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { ENTITY_TYPE, EntityDrug, IEntitylink } from "src/app/models/entitylink";
import { EXTERNAL_RESSOURCE_TYPE } from "src/app/models/externalRessource";
import { NOTIFICATION_TYPE } from "src/app/models/notification";
import { ACTION_STATUS_ENTITY, IEntity, StaticImplements } from "src/app/models/sharedInterfaces";
import { InfoAppService } from "../info-app.service";
import { LoaderService } from "../loader.service";
import {
  MedicalBluetoothPillDispenserDevice,
  MedicalBluetoothSDKPillDispenserService,
} from "../medical-bluetooth/medical-bluetooth-sdk-pilldispenser.service";
import { NotificationsGeneratedService } from "../notificationsService/notifications-generated.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";
import { ExternalRessourceService } from "./external-ressource.service";
import { StatEventService } from "./statEvent.service";

@Injectable({
  providedIn: "root",
})
export class DrugService
  extends BasicSyncService<IEntitylink, IEntitylink[]>
  implements StaticImplements<INeedRefresh, typeof DrugService>
{
  public get needRefresh(): { value: boolean } {
    return DrugService._needRefresh;
  }
  public needNotifGeneration = true; // this variable is modified by the method this.dataService.read
  public static _needRefresh = {
    value: true,
  };
  public lastGenNotif: string = null;

  constructor(
    protected dataService: DataService,
    private notificationGeneratedService: NotificationsGeneratedService,
    private loaderService: LoaderService,
    private statEventService: StatEventService,
    private infoAppService: InfoAppService,
    private externalRessourceService: ExternalRessourceService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IEntitylink[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "entitylinks_drug_",
      entityStoreKey: "list",
      getUrl: "/entitylinks?ENTITY_TYPE=" + ENTITY_TYPE.DRUG,
      setUrl: "/entity",
      expirationDays: 10,
      encrypted: true,
    };
  }

  /**
   * Get the list of drugs for the user
   * @param noNotifs (boolean) whether or not we can generate notifications if needed
   */
  public async *getDataReader(noNotifs = false): AsyncGenerator<IEntitylink[], IEntitylink[], IEntitylink[]> {
    try {
      const dataReader = super.getDataReader();
      let d: IEntitylink[] = [];
      for await (const data of dataReader) {
        d = this.processData(data);
        yield d;
      }
      this.dealWithNotif(noNotifs, d);
      return d;
    } catch (err) {
      FileLogger.error("DrugService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IEntitylink[]) {
    try {
      const filteredData = dataResult.filter((entity) => {
        return !IEntity.isDeleted(entity);
      });
      return filteredData;
    } catch (err) {
      FileLogger.error("DrugService", "Error while processing drugService data: ", err);
    }
    return dataResult;
  }

  private dealWithNotif(noNotifs: boolean, data: IEntitylink[]) {
    if (!noNotifs && (this.needNotifGeneration || !this.lastGenNotif || moment(this.lastGenNotif).add(4, "hours").isBefore(moment()))) {
      this.generateNotifsDrugs(data);
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(noNotifs = false): Promise<IEntitylink[]> {
    const dataReader = this.getDataReader(noNotifs);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(noNotifs = false): Promise<IEntitylink[]> {
    const dataReader = this.getDataReader(noNotifs);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  public async listSnomedRef(): Promise<string[]> {
    try {
      const dataReader = this.getDataReader();
      // we don't care if it's local or online, we just need the first we find:
      const iterator = await dataReader.next();
      const drugs: IEntitylink[] = iterator.value;
      return this.getUniqueDrugsNames(drugs);
    } catch (err) {
      FileLogger.error("DrugService", "listSnomedRef error: ", err);
    }
    return [];
  }

  private getUniqueDrugsNames(drugsList: IEntitylink[]): string[] {
    const filteredDrugs = drugsList.filter((drug) => {
      return drug.entityData && (drug.entityData as EntityDrug).name;
    });
    const drugNames = filteredDrugs.map((drug) => {
      return (drug.entityData as EntityDrug).name;
    });
    const uniqueNamesNoSpecialChar = drugNames.filter(ArrayHelper.onlyUnique).map(Tools.deleteAcccentSpecialcharacter);
    return uniqueNamesNoSpecialChar;
  }

  /**
   * Generate the notifications for the drugs passed in parameters.
   * If the drugs list is empty, it will first try to download a new list
   * from the server.
   * @param _drugs the drugs we want to generate notifications for
   */
  public async generateNotifsDrugs(_drugs: IEntitylink[]): Promise<void> {
    if (_drugs && _drugs.length > 0) {
      try {
        await this.notificationGeneratedService.generatedNotifications(_drugs, NOTIFICATION_TYPE.DRUG);
        this.needNotifGeneration = false;
        this.lastGenNotif = moment().format();
        return;
      } catch (err) {
        FileLogger.error("DrugService", "generateNotifsDrugs error: ", err);
        return;
      }
    }
    try {
      const d = await this.getFreshestData(true);
      await this.notificationGeneratedService.generatedNotifications(d, NOTIFICATION_TYPE.DRUG);
      this.needNotifGeneration = false;
      this.lastGenNotif = moment().format();
    } catch (err) {
      FileLogger.error("DrugService", "generateNotifsDrugs error: ", err);
    }
  }

  public async getOne(id: string): Promise<IEntitylink> {
    // we don't care if it's local or online, we just need the first we find:
    const drugs = await this.getFirstDataAvailable();
    return drugs.find((e: IEntitylink) => e._id === id); // TODO CMATE-5528 : the identification of a drug needs to be managed differently, because if we are fully offline, when we stop creating the _id, this will no longer work
  }

  /**
   *
   * @param id id of the drugs (// TODO CMATE-5528 : the identification of a drug needs to be managed differently, because if we are fully offline, when we stop creating the _id, this will no longer work)
   * @param quantities the quantity to be removed from stock
   */
  public async reduceDrugStocks(id: string, quantities: number): Promise<void> {
    await this.changeDrugStock(id, -quantities);
  }
  /**
   *
   * @param id id of the drugs (// TODO CMATE-5528 : the identification of a drug needs to be managed differently, because if we are fully offline, when we stop creating the _id, this will no longer work)
   * @param quantities the quantity to be added to stock
   */
  public async increaseDrugStocks(id: string, quantities: number): Promise<void> {
    await this.changeDrugStock(id, quantities);
  }

  private async changeDrugStock(id: string, quantities: number): Promise<void> {
    const drug = await this.getOne(id);
    if (drug) {
      const entityDrug = drug.entityData as EntityDrug;
      const totalRemainingUsages = entityDrug?.stock?.totalRemainingUsages;
      if (entityDrug.managedStock && Tools.isDefined(totalRemainingUsages) && Number.isFinite(totalRemainingUsages)) {
        drug.entityData.stock.totalRemainingUsages = entityDrug?.stock?.totalRemainingUsages + quantities;
        drug.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
        await this.saveDrug(drug, false, true);
      } else {
        FileLogger.log("DrugService", `reduceDrugStocks - drug ${id} - unmanaged stock`, "", "none");
      }
    } else {
      FileLogger.warn("DrugService", `reduceDrugStocks - drug ${id} not found`, "", "none");
    }
  }

  public async saveDrug(drug: IEntitylink, generateNotif = true, withToast = true): Promise<IEntitylink> {
    if (withToast) {
      await this.loaderService.showSavingToast(true);
    }
    return this.dataService
      .saveInArray(drug, (entity) => entity._id === drug._id, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST,
      })
      .then((d: IEntitylink) => {
        if (d.entityData.stepwiseSchema) {
          this.statEventService.newEvent("Drug with stepwise schema created : " + d.entityData.name);
        }
        const drugs = this.peekData();
        const i = drugs.findIndex((e) => e._id === d._id);
        if (i >= 0 && d.actionStatus === ACTION_STATUS_ENTITY.MODIFIED) {
          drugs[i] = d;
        } else if (i < 0) {
          drugs.push(d);
        }
        this.pokeData(drugs);
        if (generateNotif) {
          this.notificationGeneratedService.updateOrCreateNotification(drug, NOTIFICATION_TYPE.DRUG);
        }
        return d;
      })
      .finally(async () => {
        if (withToast) {
          await this.loaderService.showSavingToast(false);
        }
      })
      .catch((error) => {
        // Error handling for server unreachable and general errors
        if (!this.infoAppService.isCordova() && (error as ServerError).code === ServerResponse.SERVER_UNREACHABLE.code) {
          throw error; // Throw server unreachable error for handling externally
        } else {
          // Log general errors and return null
          FileLogger.error("DrugService", "saveDrug", error);
          return null;
        }
      });
  }

  public async delete(drug: IEntitylink, withToast = true): Promise<boolean> {
    IEntity.setDeleted(drug);
    const savePromise = this.dataService
      .removeFromArray(drug, (entity) => entity._id === drug._id, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST,
      })
      .then((success: boolean) => {
        if (!success) return false;
        const drugs = this.peekData();
        const i = drugs.findIndex((d) => d._id === drug._id);
        if (i >= 0 && drug.actionStatus === ACTION_STATUS_ENTITY.DELETED) {
          drugs.splice(i, 1);
          this.pokeData(drugs);
        }
        return true;
      })
      .catch((error) => {
        // Throw error to handle it in handleDrugEdition()
        throw error;
      });
    if (withToast) {
      await this.loaderService.showSavingToast(true);
    }
    const isDeleted = await savePromise;
    if (withToast) {
      await this.loaderService.showSavingToast(false);
    }
    return isDeleted;
  }

  public static getMomentsQuantity(moment: string, drug: EntityDrug): string {
    let quantity: string;

    switch (moment) {
      case "rising":
        quantity = drug.frequency?.quantities?.rise;
        break;
      case "beding":
        quantity = drug.frequency?.quantities?.bedtime;
        break;
      default:
        quantity = drug.frequency?.quantities?.[moment];
        break;
    }

    return quantity;
  }

  public async islinkedDrugExternalRessource(
    atcCode: string,
    drugId?: string,
    bondedDevices?: MedicalBluetoothPillDispenserDevice[]
  ): Promise<boolean> {
    if (!atcCode) {
      return false;
    }
    const externalResssource =
      this.externalRessourceService.peekData()?.length > 0
        ? this.externalRessourceService.peekData()
        : await this.externalRessourceService.getFirstDataAvailable();
    return (
      externalResssource
        .filter(
          (ressource) =>
            ressource.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE_PILL_DISPENSER_SDK &&
            (ressource.meta?.availableLoinc?.indexOf(atcCode) ?? -1) > -1
        )
        .findIndex((ressource) => {
          let device: MedicalBluetoothPillDispenserDevice = null;
          if (drugId && bondedDevices?.length > 0) {
            // if you don't want to check for a specific drug, you don't need the device
            device = MedicalBluetoothSDKPillDispenserService.findBondedDevice(ressource, bondedDevices);
          }
          return !drugId || (device?.bonded && device?.drugId === drugId);
        }) > -1
    );
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { AES256 } from "@ionic-native/aes-256/ngx";
import { IonicStorageModule } from "@ionic/storage-angular";

import { Network } from "@ionic-native/network/ngx";
import { CookieService } from "ngx-cookie-service";
import { ExtraMenuPopOverComponent } from "./components/extra-menu-pop-over/extra-menu-pop-over.component";
import { SideNavComponent } from "./components/side-nav/side-nav.component";

import { Camera } from "@ionic-native/camera/ngx";
import { MomentModule } from "ngx-moment";

import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SimpleLoadingStrategy } from "./simpleLoadingStrategy";

import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";
import { Health } from "@ionic-native/health/ngx";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
import { Market } from "@ionic-native/market/ngx";
import { AutoCompleteModule } from "ionic4-auto-complete";

import { Deeplinks } from "@ionic-native/deeplinks/ngx";

import { Drivers } from "@ionic/storage";
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BluetoothLE } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SecureStorageEcho } from "@ionic-native/secure-storage-echo/ngx";
import { PipesModule } from "./commonModules/pipes.module";
import { CustomI18nTranslateLoader } from "./models/customI18nLoader";
import { ApiService } from "./services/api.service";
import { LocalStorageService } from "./services/storage/local-storage.service";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, ExtraMenuPopOverComponent, SideNavComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomI18nTranslateLoader,
        deps: [ApiService, LocalStorageService, HttpClient],
      },
    }),
    IonicModule.forRoot({
      swipeBackEnabled: false,
    }),
    IonicStorageModule.forRoot({
      name: "storagecare",
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB],
    }),
    AppRoutingModule,
    MomentModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    FontAwesomeModule,
  ],
  providers: [
    StatusBar,
    AppVersion,
    AES256,
    Network,
    CookieService,
    Camera,
    FingerprintAIO,
    InAppBrowser,
    SimpleLoadingStrategy,
    File,
    FileOpener,
    Deeplinks,
    Health,
    Market,
    LocalNotifications,
    SecureStorageEcho,
    Device,
    BluetoothLE,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools-helper";
import { DeviceState } from "src/app/models/externalRessource";
import { StaticImplements } from "src/app/models/sharedInterfaces";
import { InfoAppService } from "../info-app.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { IAveragedObservation } from "../streamObservation.service";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

@Injectable({
  providedIn: "root",
})
export class DayStreamObservationsService
  extends BasicSyncService<IAveragedObservation, IAveragedObservation[]>
  implements StaticImplements<INeedRefresh, typeof DayStreamObservationsService>
{
  public get needRefresh(): { value: boolean } {
    return DayStreamObservationsService._needRefresh;
  }
  private mode: "TEST" | "PROD" = "PROD";
  private lastestSavedDay = "";
  private lastedDayStorageKey = "streamObsLastestDay";
  private lastValueOfParam = "";
  private paramStorageKey = "streamObsParamsKey";
  public static _needRefresh = {
    value: true,
  };
  public synchroTime = 60;

  constructor(
    protected dataService: DataService,
    private accountService: AccountService,
    private infoAppService: InfoAppService,
    private localStorage: LocalStorageService
  ) {
    super(dataService);
    this.infoAppService.getCurrentMode().then((mode: string) => {
      this.mode = mode !== "PROD" ? "TEST" : "PROD";
    });
  }
  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "streamedObservations_",
      entityStoreKey: "list",
      getUrl: "/mobile/onlineDevicesData?externalRessourceRefs=",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }
  public getUrl(): string {
    const today = moment().startOf("minute").toISOString();
    return super.getUrl() + this.lastValueOfParam + "&day=" + today + "&mode=" + this.mode;
  }
  public async init(): Promise<void> {
    try {
      super.init();
      this.lastestSavedDay = await this.localStorage.getData(this.lastedDayStorageKey, false);
      this.lastValueOfParam = await this.localStorage.getData(this.paramStorageKey, false);
    } catch (err) {
      this.lastestSavedDay = "";
      this.lastValueOfParam = "";
    }
  }
  public clear(): void {
    super.clear();
    this.lastestSavedDay = "";
    this.lastValueOfParam = "";
  }
  public watchData(externalRessourceRef?: string, loinc?: string): Observable<IAveragedObservation[]> {
    return this.data$.pipe(
      map((obs: IAveragedObservation[]) => {
        return this.processData(obs, externalRessourceRef, loinc);
      })
    );
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(externalRessourceRef?: string, loinc?: string): IAveragedObservation[] {
    return this.processData(super.peekData(), externalRessourceRef, loinc);
  }
  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IAveragedObservation[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  public getLastestSavedDay(): string {
    return moment(this.lastestSavedDay).format("YYYY-MM-DD HH:mm");
  }

  public async *getDataReader(
    externalRessourceRef?: string,
    loinc?: string
  ): AsyncGenerator<IAveragedObservation[], IAveragedObservation[], IAveragedObservation[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const account = await this.accountService.getFreshestData();
      const devices = account.connectedDevices?.filter((d) => d.deviceState === DeviceState.STARTED);
      const externalRessourceRefs = devices?.map((d) => d.externalRessourceRef);
      if (!externalRessourceRefs?.length) {
        yield [];
        return [];
      }
      externalRessourceRefs.sort();
      const paramObject = Object.assign({}, this.defaultDataParameter);
      const today = moment().startOf("minute").toISOString();
      paramObject.getUrl += externalRessourceRefs.join(",") + "&day=" + today + "&mode=" + this.mode;
      this.lastestSavedDay = today;
      this.lastValueOfParam = externalRessourceRefs.join(",");
      this.localStorage.setData(this.lastedDayStorageKey, this.lastestSavedDay, false);
      this.localStorage.setData(this.lastValueOfParam, this.paramStorageKey, false);
      const dataReader = this.dataService.readv2<IAveragedObservation, IAveragedObservation[]>(paramObject, false, this);
      let d: IAveragedObservation[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, externalRessourceRef, loinc);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.warn("DayStreamObservationsService", "getDataReader()", err);
      yield [];
      return [];
    }
  }
  private processData(obs: IAveragedObservation[], externalRessourceRef?: string, loinc?: string): IAveragedObservation[] {
    try {
      const observations: IAveragedObservation[] = obs;
      if (Tools.isNotDefined(observations?.length) || !Array.isArray(observations)) {
        return [];
      }
      if (!loinc && !externalRessourceRef) {
        return obs;
      }
      return observations.filter(
        (o) =>
          (!externalRessourceRef || o.deviceReference === externalRessourceRef) && (!loinc || o.code === loinc || o.componentCode === loinc)
      );
    } catch (err) {
      FileLogger.error("DayStreamObservationsService", "Error while processing DayStreamObservationsService data: ", err);
      return obs;
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(externalRessourceRef?: string, loinc?: string): Promise<IAveragedObservation[]> {
    const dataReader = this.getDataReader(externalRessourceRef, loinc);
    let iterator = await dataReader.next();
    while (!iterator.done) {
      iterator = await dataReader.next();
    }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(externalRessourceRef?: string, loinc?: string): Promise<IAveragedObservation[]> {
    const dataReader = this.getDataReader(externalRessourceRef, loinc);
    const iterator = await dataReader.next();
    return iterator.value;
  }
}

import { Injectable } from "@angular/core";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { InfoOrganization, StaticImplements } from "src/app/models/sharedInterfaces";
import { DataService } from "./core/data.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ComunicareInfoService
  extends BasicSyncService<InfoOrganization, InfoOrganization>
  implements StaticImplements<INeedRefresh, typeof ComunicareInfoService>
{
  public get needRefresh(): { value: boolean } {
    return ComunicareInfoService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<InfoOrganization>(null);
  }

  protected initWatch(): void {
    this.data$.next(null);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "comunicareInfo_",
      entityStoreKey: "list",
      getUrl: "/comunicareInfo",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }
}

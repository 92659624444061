import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MenuController } from "@ionic/angular";
import { AppConstants } from "../appConstants";

@Injectable({
  providedIn: "root",
})
export class GoToPageService {
  constructor(protected router: Router, protected menuCtrl: MenuController) {}

  public loginPage(param?: unknown, forceNavigate = false) {
    return this.goPage("/login", param, forceNavigate);
  }

  public homePage(param?: unknown, forceNavigate = false) {
    return this.goPage("/home", param, forceNavigate);
  }

  public carePage(param?: unknown) {
    return this.goPage("/mycare", param, true);
  }

  public drugPage(param?: unknown) {
    return this.goPage("mydrugs", param);
  }

  public appointmentPage(param?: unknown) {
    return this.goPage("myappointments", param);
  }

  public messagePage(param?: unknown) {
    return this.goPage("mymessages", param);
  }

  public observationPage(param?: unknown, forceNavigate = false) {
    return this.goPage("/myobservations", param, forceNavigate);
  }

  public feelingPage(param?: unknown, forceNavigate = false) {
    return this.goPage("/myfeelings", param, forceNavigate);
  }

  public advicePage(param?: unknown) {
    return this.goPage("/myadvices", param);
  }

  public notePage(param?: unknown) {
    return this.goPage("/mynotes", param);
  }

  public healthPage(param?: unknown) {
    return this.goPage("/myhealth", param);
  }

  public entouragePage(param?: unknown) {
    return this.goPage("/myentourage", param);
  }

  public rewardPage(param?: unknown) {
    return this.goPage("/myreward", param);
  }

  public contactPage(param?: unknown) {
    return this.goPage("/mycontacts", param);
  }

  public devicesPage(param?: unknown) {
    return this.goPage("/my-ble-devices", param);
  }

  public recommandationPage(param?: unknown) {
    return this.goPage("/recommandation", param);
  }

  public bluetoothMultipleObsPage(param?: unknown) {
    return this.goPage("/bluetooth-multiple-obs", param);
  }

  private async goPage(name: string, param: unknown, forceNavigate = false) {
    if (forceNavigate || this.router.url !== name) {
      // To avoid to navigate on a page then that we are already in
      await this.router.navigate([name], {
        state: param,
        replaceUrl: true,
      });
    }
    this.closeMenu(); // always close the menu
  }

  private closeMenu() {
    return this.menuCtrl.close(AppConstants.MAIN_MENU_NAME);
  }
}

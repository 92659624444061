import { OperationResult, BluetoothLE } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { InfoAppService } from "../../info-app.service";
import { MedicalBluetoothData } from "../medical-bluetooth-data";
import { MedicalBluetoothCharacteristic, MedicalBluetoothDevice, MedicalBluetoothService } from "../medical-bluetooth.service";
import { MedicalBluetoothStatus } from "../medical-bluetooth-status.service";

export interface MedicalBluetoothWeightScaleData {
  kilogramWeight: number;
  poundWeight: number;
  time?: Date;
  userId?: number;
  BMI?: number;
  meterHeight?: number;
  inchHeight?: number;
}

export class MedicalBluetoothWeightScale extends MedicalBluetoothData {
  public serviceType = MedicalBluetoothService.WEIGHT_SCALE;
  public descriptor = {
    address: this.device.address,
    characteristic: MedicalBluetoothCharacteristic.WEIGHT_MEASUREMENT,
    service: MedicalBluetoothService.WEIGHT_SCALE,
  };

  public constructor(
    device: MedicalBluetoothDevice,
    handler: Observable<string>,
    infoAppService: InfoAppService,
    bluetoothle: BluetoothLE,
    onDestroy$: Subject<void>,
    translateService: TranslateService,
    searchPreviousData: boolean,
    medicalBluetoothStatus: MedicalBluetoothStatus
  ) {
    super(device, handler, infoAppService, bluetoothle, onDestroy$, translateService, searchPreviousData, medicalBluetoothStatus);
  }

  public readData() {
    this.standby = false;
    return this.bluetoothle.subscribe(this.descriptor).pipe(
      map((r) => {
        const data = r as unknown as OperationResult;
        if (data.status === "subscribedResult") {
          const decodedData = this.decodeData(data.value);
          if (decodedData.kilogramWeight < 0 || decodedData.kilogramWeight > 500) {
            this._data.next(null);
            this._data.complete();
          } else {
            this._data.next(decodedData);
          }
        }
      }),
      finalize(() => {
        this._data.complete();
      })
    );
  }

  public getData(): Subject<MedicalBluetoothWeightScaleData> {
    return this._data;
  }

  public getHumanReadableData(data: MedicalBluetoothWeightScaleData) {
    return data.kilogramWeight.toFixed(1) + " kg";
  }

  private decodeData(data: string): MedicalBluetoothWeightScaleData {
    const bytes = this.bytesFromString(data);
    const view = new DataView(bytes.buffer);

    let offset = 0;
    const result: MedicalBluetoothWeightScaleData = {
      kilogramWeight: 0,
      poundWeight: 0,
    };

    // Flags (Uint 8)
    const measurementUnit = view.getUint8(0) & 0x01;
    const hasTimeStamp = (view.getUint8(0) & 0x02) > 0;
    const hasUserId = (view.getUint8(0) & 0x04) > 0;
    const hasBMI = (view.getUint8(0) & 0x08) > 0;
    offset += 1;

    // Measure (UINT 16)
    const weight = view.getUint16(offset, true);
    if (measurementUnit === 0) {
      result.kilogramWeight = weight * 0.005;
      result.poundWeight = result.kilogramWeight * 2.2046;
    } else {
      result.poundWeight = weight * 0.01;
      result.kilogramWeight = result.poundWeight / 2.2046;
    }
    offset += 2;

    // Timestamp
    if (hasTimeStamp) {
      result.time = new Date(
        view.getUint16(offset, true),
        view.getUint8(offset + 2),
        view.getUint8(offset + 3),
        view.getUint8(offset + 4),
        view.getUint8(offset + 5),
        view.getUint8(offset + 5)
      );
      offset += 7;
    }

    // User Id
    if (hasUserId) {
      result.userId = view.getUint8(offset);
      offset += 1;
    }

    // BMI & Height
    if (hasBMI) {
      result.BMI = view.getUint16(offset);
      offset += 2;

      const height = view.getUint16(offset, true);
      if (measurementUnit === 0) {
        result.meterHeight = height * 0.001;
        result.inchHeight = result.meterHeight / 0.0254;
      } else {
        result.inchHeight = height * 0.1;
        result.meterHeight = result.inchHeight * 0.0254;
      }
    }

    return result;
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IRule } from "src/app/models/rule";
import { AccountService } from "./account.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

@Injectable({
  providedIn: "root",
})
export class RulesService extends BasicSyncService<IRule, IRule[]> implements StaticImplements<INeedRefresh, typeof RulesService> {
  public get needRefresh(): { value: boolean } {
    return RulesService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService, private accountService: AccountService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IRule[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "rules_",
      entityStoreKey: "list",
      getUrl: "/rules",
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async *getDataReader(): AsyncGenerator<IRule[], IRule[], IRule[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IRule[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("RulesService", "getDataReader()", err);
      yield [];
      return [];
    }
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ENTITY_TYPE, IEntitylink, PARENT_TYPE } from "src/app/models/entitylink";
import { IEntity, StaticImplements, STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { LoaderService } from "../loader.service";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";

@Injectable({
  providedIn: "root",
})
export class NoteService
  extends BasicSyncService<IEntitylink, IEntitylink[]>
  implements StaticImplements<INeedRefresh, typeof NoteService>
{
  public get needRefresh(): { value: boolean } {
    return NoteService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService, private loaderService: LoaderService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IEntitylink[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "entitylinks_notes_",
      entityStoreKey: "list",
      getUrl: "/entitylinks?ENTITY_TYPE=" + ENTITY_TYPE.NOTE,
      setUrl: "/entity",
      expirationDays: 10,
      encrypted: true,
    };
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(includeDeleted = true, parentType: PARENT_TYPE = null): IEntitylink[] {
    return this.processData(super.peekData(), includeDeleted, parentType);
  }

  public async *getDataReader(
    includeDeleted = false,
    parentType: PARENT_TYPE = null
  ): AsyncGenerator<IEntitylink[], IEntitylink[], IEntitylink[]> {
    try {
      const dataReader = super.getDataReader();
      let d: IEntitylink[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, includeDeleted, parentType);
        yield d;
      }
      return d;
    } catch (err) {
      FileLogger.error("NoteService", "getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IEntitylink[], includeDeleted: boolean, parentType: PARENT_TYPE) {
    try {
      let notes: IEntitylink[] = dataResult;
      if (!includeDeleted) {
        notes = notes.filter((entity) => {
          return !IEntity.isDeleted(entity);
        });
      }
      if (parentType) {
        notes = notes.filter((entity) => {
          return entity.parentType === parentType;
        });
      }
      return notes;
    } catch (err) {
      FileLogger.error("NoteService", "Error while processing noteService data: ", err);
      return dataResult;
    }
  }

  public async save(note: IEntitylink, withToast = true): Promise<IEntitylink> {
    const savePromise = this.dataService
      .saveInArray(note, (entity) => entity._id === note._id, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST,
      })
      .then((n: IEntitylink) => {
        const notes = this.peekData(true);
        const i = notes.findIndex((e) => e._id === n._id);
        if (i >= 0 && !n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          notes[i] = n;
        } else if (i < 0 && !n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          notes.push(n);
        }
        this.pokeData(notes);
        return n;
      });
    if (withToast) {
      await this.loaderService.showSavingToast(true);
    }
    const savedNote = await savePromise;
    if (withToast) {
      await this.loaderService.showSavingToast(false);
    }
    return savedNote;
  }

  public async delete(note: IEntitylink, withToast = true): Promise<boolean> {
    IEntity.setDeleted(note);
    const savePromise = this.dataService
      .removeFromArray(note, (entity) => entity._id === note._id, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST,
      })
      .then((success: boolean) => {
        if (!success) return false;
        const notes = this.peekData(true);
        const i = notes.findIndex((d) => d._id === note._id);
        if (i >= 0) {
          notes.splice(i, 1);
          this.pokeData(notes);
        }
        return true;
      });
    if (withToast) {
      await this.loaderService.showSavingToast(true);
    }
    const isDeleted = await savePromise;
    if (withToast) {
      await this.loaderService.showSavingToast(false);
    }
    return isDeleted;
  }
}

export enum FEATURES {
  MENU = "menu",
  AGENDA = "agenda",
  MESSAGES = "messages",
  OBSERVATIONS = "observations",
  FEELINGS = "feelings",
  CONTACTS = "contacts",
  CAREPLANS = "careplans",
  DRUGS = "drugs",
  ADVICE = "advice",
  NOTES = "notes",
  HEALTH = "health",
  LINKS = "links",
  RELATED = "related",
  BLEDEVICES = "bledevices",
  SHARING = "sharing",
  NOTIFICATIONS = "notifications",
  REWARDS = "rewards",
}

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FeatureService {
  constructor() {
    // do nothing.
  }

  public canActivate(feature: any) {
    if (!environment.features || !environment.features.length) {
      return true;
    }
    return environment.features.includes(feature);
  }
}

import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BasePage } from "src/app/baseClasses/base-page";
import { IAccount } from "src/app/helpers/account-helper";
import { ServerResponse, SERVER_RESPONSE_TYPE } from "src/app/helpers/server-response-helper";
import { Tools } from "src/app/helpers/tools-helper";
import { AccountService } from "src/app/services/globalDataProvider/account.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { NetworkService } from "src/app/services/network.service";
import { PopupService } from "src/app/services/popup.service";
import Validation from "../reset-password-modal/password-match.validation";
import { FileLogger } from "src/app/helpers/fileLogger";

@Component({
  selector: "app-account-modal",
  templateUrl: "./account-modal.component.html",
  styleUrls: ["./account-modal.component.scss"],
})
export class AccountModalComponent extends BasePage {
  private accountOriginal: IAccount;
  private account: IAccount;
  public newPasswordForm: UntypedFormGroup;
  public passwordError: string;
  private lang: string;
  public isOnline = true;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected accountService: AccountService,
    protected modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    protected configService: ConfigurationService,
    protected networkService: NetworkService,
    translateService: TranslateService
  ) {
    super(translateService, configService, infoService, popupService);
    this.accountOriginal = this.accountService.cachedAccount;
    this.account = Tools.deepCopy(this.accountOriginal);
    this.lang = this.configService.getCurrentLanguage();
    this.newPasswordForm = this.formBuilder.group(
      {
        newPassword: ["", [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}$/)]],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validators: [Validation.match("newPassword", "confirmPassword")],
      }
    );
  }

  /**
   * Set a new password
   */
  public async save(): Promise<boolean | void> {
    if (this.networkService.isCurrentOffline()) {
      this.isOnline = false;
      return;
    } else {
      this.isOnline = true;
    }
    // Form not valid
    if (!this.newPasswordForm.valid) {
      return;
    }
    try {
      this.account.newPassword = this.newPasswordForm.get("newPassword").value;
      const response = await this.accountService.checkIfPasswordIsValid(this.account);
      const responseType = ServerResponse.type(response);
      switch (responseType) {
        case SERVER_RESPONSE_TYPE.SUCCESS:
          this.account.modified = moment().format();
          return this.modalCtrl.dismiss(this.account);
        case SERVER_RESPONSE_TYPE.INVALID_PASSWORD:
          if (response.additionalData) {
            this.passwordError = `${response.additionalData}.${this.lang}`;
            return;
          }
          return;
        default:
          FileLogger.error("AccountModalComponent", "accountService.checkIfPasswordIsValid error", response);
          return this.popupService.showAlert("application.title", "login.refused.subTitle");
      }
    } catch (error) {
      FileLogger.error("AccountModalComponent", "accountService.checkIfPasswordIsValid", "Error server", error);
      this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
    }
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BasicSyncService, INeedRefresh } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

import { map } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { SYNC_HTTP_METHOD } from "./core/request-sender.service";
import { StaticImplements } from "src/app/models/sharedInterfaces";

export const enum StatToIncrement {
  READ_KNOWLEDGE = "knowledgeRead",
  QUIZ_DONE = "quizzesDone",
}

interface ISummaryUpdate {
  statName: StatToIncrement;
}

@Injectable({
  providedIn: "root",
})
export class StatSummaryService
  extends BasicSyncService<ISummaryUpdate, ISummaryUpdate[]>
  implements StaticImplements<INeedRefresh, typeof StatSummaryService>
{
  public get needRefresh(): { value: boolean } {
    return StatSummaryService._needRefresh;
  }
  public static _needRefresh = {
    value: true,
  };
  constructor(protected dataService: DataService) {
    super(dataService);
  }

  protected clearWatch(): void {
    // this is needed in order to clear the data cached in your
    // service when the user disconnect
    // Most often your data is an array, so you need to create
    // a new BehaviorSubject and put an empty array in it
    this.data$ = new BehaviorSubject<ISummaryUpdate[]>([]);
  }

  protected initWatch(): void {
    // this is needed in order to reset the data cached in your service
    // Most often your data is an array, so you need to put an empty array
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: "statSummary_",
      entityStoreKey: null,
      getUrl: null,
      setUrl: "/mobile/statisticSummary",
      expirationDays: 10,
      encrypted: false,
    };
  }

  /**
   * There's never any summary update saved on the device (only in the queue)
   */
  public peekData(): ISummaryUpdate[] {
    return [];
  }
  /**
   * Don't use this, we don't keep the summary update
   * @param data
   */
  public pokeData(_data: ISummaryUpdate[]): void {
    super.pokeData([]);
  }
  /**
   * No changes in this service's data because we only send summary updates, we don't keep them
   * @return a observable with the service's data
   */
  public watchData(): Observable<ISummaryUpdate[]> {
    return this.data$.pipe(
      map(() => {
        return [];
      })
    );
  }

  /**
   * There's never any summary saved on the device (only in the queue)
   */
  public async *getDataReader(): AsyncGenerator<ISummaryUpdate[], ISummaryUpdate[], ISummaryUpdate[]> {
    yield [];
    return [];
  }
  /**
   * Only useful method. Create a summary update and send it to the server or save it in the queue for later
   * @param statToIncrement
   */
  public async increment(statToIncrement: StatToIncrement): Promise<void> {
    const eventData: ISummaryUpdate = {
      statName: statToIncrement,
    };
    try {
      this.dataService.saveInArray(eventData, () => false, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.PUT,
      });
    } catch (err) {
      FileLogger.error("StatSummaryService", "Error while incrementing stat summary: ", err);
    }
  }
}

import { Injectable } from "@angular/core";
import { NOTIFICATION_TYPE } from "../../models/notification";
import { AppointmentService } from "../globalDataProvider/appointment.service";
import { DrugService } from "../globalDataProvider/drug.service";
import { KnowledgesRecommendationService } from "../globalDataProvider/knowledges-recommendation.service";
import { RelatedAppointmentsService } from "../globalDataProvider/related-appointments.service";
import { RelatedDrugsService } from "../globalDataProvider/related-drugs.service";
import { NotificationsGeneratedService } from "./notifications-generated.service";
@Injectable({
  providedIn: "root",
})
export class NotificationsEventsService {
  constructor(
    private appointmentService: AppointmentService,
    private knRecommendationService: KnowledgesRecommendationService,
    private notificationsGeneratedService: NotificationsGeneratedService,
    private drugService: DrugService,
    private relatedDrugsService: RelatedDrugsService,
    private relatedAppointmentsService: RelatedAppointmentsService
  ) {}

  public async generateAll(): Promise<any> {
    // remark : due to the interweaving of services, the following cannot be in notificationsGeneratedService
    // generate appointments
    await this.appointmentService.getFreshestData(false, true);

    await this.drugService.generateNotifsDrugs([]);
    await this.notificationsGeneratedService.generatedNotifications(null, NOTIFICATION_TYPE.OBSERVATION);
    await this.notificationsGeneratedService.generatedNotifications(null, NOTIFICATION_TYPE.FEELING);
    await this.relatedDrugsService.generateNotifsDrugs([]);
    await this.relatedAppointmentsService.getFreshestData(null, false, true);
    // generate advices, this takes a long time, so do it last and do not wait for it
    this.knRecommendationService.generateNotifsKnowledges([]);
  }

  public async deleteAllFromPlugin() {
    await this.notificationsGeneratedService.deleteAllPluginNotifications();
  }
}

import { ChangeDetectorRef, Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import ContactsX from "cordova-plugin-contacts-x";
import { Tools } from "src/app/helpers/tools-helper";
import { IRelatedPerson, RelatedHelper } from "src/app/models/relatedPerson";
import { Reference } from "src/app/models/sharedInterfaces";
import { RelatedPersonsService } from "src/app/services/globalDataProvider/related-persons.service";
import { RewardScoreService } from "src/app/services/globalDataProvider/reward-score.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoaderService } from "src/app/services/loader.service";
import { NetworkService } from "src/app/services/network.service";
import { PopupService } from "src/app/services/popup.service";
import { SysAccountService } from "src/app/services/sys-account.service";

import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { BasePage } from "src/app/baseClasses/base-page";
import { REWARD_ACTION, REWARD_PAGE_NAME } from "src/app/models/rewardDefinition";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { FileLogger } from "src/app/helpers/fileLogger";

@Component({
  selector: "app-entourage-add-modal",
  templateUrl: "./entourage-add-modal.component.html",
  styleUrls: ["./entourage-add-modal.component.scss"],
})
export class EntourageAddModalComponent extends BasePage {
  private accountId: string;
  public name: string;
  public firstname: string;
  public mail: string;
  public phone: string;
  private relatedPersons: IRelatedPerson[] = [];

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected modalCtrl: ModalController,
    protected networkService: NetworkService,
    private sysAccountService: SysAccountService,
    private relatedPersonsService: RelatedPersonsService,
    protected loaderService: LoaderService,
    private rewardScoreService: RewardScoreService,
    private cdr: ChangeDetectorRef,
    translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
    this.accountId = this.sysAccountService.cachedCaremateId;
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.setupRelatedPersons();
  }

  /**
   *  cancel edition
   */
  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  private async setupRelatedPersons() {
    this.relatedPersons = await this.relatedPersonsService.getFreshestData(true, true);
  }

  public async onInviteEntourage(): Promise<void> {
    try {
      // field mandatory (at least phone or mail)
      const mail = this.mail?.trim();
      let phone = this.phone?.trim()?.replace(/ /g, ""); // fix for android leaving white spaces between numbers
      const name = this.name?.trim();
      const firstname = this.firstname?.trim();

      if (!mail || !phone || !name || !firstname) {
        await this.popupService.showAlert("application.title", "error.mandatory");
        // TODO change error message to plural and more detailed

        return;
      }

      // must be online
      if (this.networkService.isCurrentOffline()) {
        await this.popupService.showAlert("application.title", "error.nonetwork");
        return;
      }

      // check phone
      if (phone) {
        if (!Tools.isValidPhoneNumber(phone)) {
          await this.popupService.showAlert("application.title", "error.invalidPhone");
          return;
        }
        phone = Tools.getFormattedPhoneNumber(phone);
        if (!phone || phone === "") {
          await this.popupService.showAlert("application.title", "error.invalidPhone");
          return;
        }
      }

      // check mail
      if (this.mail) {
        if (Tools.isValidEmail(this.mail) === false) {
          this.popupService.showAlert("application.title", "error.invalidEmail");
          return;
        }
      }

      // create related Person entity
      const patientRef: Reference = {
        reference: this.accountId,
        display: "",
      };
      const relatedPerson = RelatedHelper.asRelatedPerson(patientRef, firstname, name, mail, phone);

      // look if not already exists
      const related = this.relatedPersons.find((r) => {
        return RelatedHelper.getRelatedMail(r) === mail || RelatedHelper.getRelatedPhone(r) === phone ? true : false;
      });

      if (related) {
        await this.popupService.showAlert("application.title", "error.relatedExist");
        return;
      }

      // send invitation to server
      await this.loaderService.showLoading(true);

      this.relatedPersonsService
        .inviteRelatedPerson(relatedPerson)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (newRelated) => {
            if (newRelated) {
              this.loaderService.showLoading(false);
              this.modalCtrl.dismiss(newRelated);
            } else {
              this.loaderService.showLoading(false);
              this.popupService.showAlert("application.title", "error.invalidInvitation");
            }
          },
          (err) => {
            this.loaderService.showLoading(false);
            FileLogger.error("EntourageAddModalComponent", "inviteRelatedPerson", err);
            this.popupService.showAlert("application.title", "error.invalidInvitation");
          },
          () => {
            this.rewardScoreService.update(REWARD_PAGE_NAME.myentourage, REWARD_ACTION.onAdd);
          }
        );
    } catch (error) {
      FileLogger.error("EntourageAddModalComponent", "inviteRelatedPerson catch", error);
      await this.popupService.showAlert("application.title", "error.invalidInvitation");
    }
  }

  /**
   * Get & invite contact from phone
   */
  public onAddContact(): void {
    ContactsX.requestPermission(
      (result) => {
        // Get the contact infos only if permission is granted to avoid error

        if (result.read) {
          this.getContactInfos();
        }
      },
      (error) => {
        FileLogger.error("EntourageAddModalComponent", "onAddContact", error);
      }
    );
  }

  public getContactInfos(): void {
    ContactsX.pick(
      (contact) => {
        if (contact) {
          // reset previous value to avoid mixing data
          this.name = "";
          this.firstname = "";
          this.mail = "";
          this.phone = "";

          // Add new data from the contact card
          this.firstname = contact.firstName?.trim();
          if (contact.familyName) {
            this.name = contact.familyName.trim();
          } else if (contact.middleName) {
            this.name = contact.middleName.trim();
          }
          this.mail = contact.emails[0]?.value;
          this.phone = contact.phoneNumbers[0]?.value;
        }

        // force the change detection
        this.cdr.detectChanges();
      },
      (err) => {
        FileLogger.error("EntourageAddModalComponent", "getContactInfos", err);
      }
    );
  }
}

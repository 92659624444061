import { Pipe, PipeTransform } from "@angular/core";
import { IObservationDefinition, OComponent } from "../helpers/observation-helper";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";

@Pipe({
  name: "obsValueOrMeaning",
})
export class ObsValueOrMeaningPipe implements PipeTransform {
  constructor(private configService: ConfigurationService) {}
  transform(component: OComponent, definition: IObservationDefinition, currentLang: string): unknown {
    const componentDefinition = definition.components.find((comp) => comp.loinc === component.code.coding[0].code);

    if (componentDefinition?.meaning?.length > 0) {
      const meaning = componentDefinition.meaning.find((meaning) => meaning.value === component.valueQuantity.value);
      if (meaning?.description[currentLang]) {
        return meaning.description[currentLang];
      } else return component.valueQuantity.value;
    } else return component.valueQuantity.value;
  }
}

import { Injectable } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { KnowledgeModalComponent } from "../components/modals/knowledge-modal/knowledge-modal.component";
import { IKnowMedia, IKnowledgeBase, KNOW_CATEGORY, KNOW_DOC_CATEGORY } from "../helpers/knowledge-helper";
import { REWARD_ACTION, REWARD_PAGE_NAME } from "../models/rewardDefinition";
import { RewardScoreService } from "./globalDataProvider/reward-score.service";

@Injectable({
  providedIn: "root",
})
export class ModalKnowledgeService {
  constructor(
    private modalCtrl: ModalController,
    private rewardScoreService: RewardScoreService,
    private alertCtrl: AlertController,
    private translateSvc: TranslateService
  ) {}

  /**
   * Display a knowledge in a modal view
   */
  public presentModalKnowledge(
    knowledgeName: string,
    knowledges: IKnowledgeBase[],
    source?: REWARD_PAGE_NAME,
    isRelated = false
  ): Promise<any> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: KnowledgeModalComponent,
          componentProps: {
            knowledges: knowledges,
            name: knowledgeName,
            isRelated: isRelated,
          },
        })
        .then((modal) => {
          modal.onDidDismiss().then((data) => {
            if (data.data?.isLastPage && source) {
              this.rewardScoreService.update(source, REWARD_ACTION.onRead);
            }
            resolve(data.data);
          });
          modal.present();
        });
    });
  }

  public async showMediaFromRuleAlert(titleKey: string, messageKey: string, media: IKnowMedia): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: titleKey,
      message: messageKey,
      buttons: [
        {
          text: "OK",
          role: "cancel",
        },
      ],
      backdropDismiss: false,
    });

    if (media && media.content && media.description) {
      alert.buttons = [
        {
          text: this.translateSvc.instant("application.moreinfo"),
          handler: () => {
            const knowledgeBase: IKnowledgeBase = {
              _id: null,
              modified: null,
              entityStatus: null,
              author: null,
              organization: null,
              healthcareservice: null,
              snomedReference: null,
              category: KNOW_CATEGORY.PATHOLOGY,
              documentCategory: KNOW_DOC_CATEGORY.DESCRIPTION,
              criteria: null,
              medias: [media],
            };
            this.presentModalKnowledge("", [knowledgeBase]);
          },
        },
        {
          text: "OK",
          role: "cancel",
        },
      ];
    }
    await alert.present();
  }
}

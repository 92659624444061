import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { AppConstants } from "src/app/appConstants";
import { BasePage } from "src/app/baseClasses/base-page";
import { ArrayHelper } from "src/app/helpers/array-helper";
import { EnableWhenHelper, IEnableWhen } from "src/app/helpers/enableWhen-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { IKnowMedia, IKnowledgeBase, KNOW_CATEGORY, KNOW_DOC_CATEGORY } from "src/app/helpers/knowledge-helper";
import {
  IEffectiveTiming,
  IObservation,
  IObservationDefinition,
  IObservationDefinitionComponent,
  OComponent,
  Observation,
} from "src/app/helpers/observation-helper";
import { RuleHelperObservationService } from "src/app/helpers/rule-helper-service/rule-helper-observation.service";
import { Tools } from "src/app/helpers/tools-helper";
import { IRuleAlert } from "src/app/helpers/trigger-rule-helper-engine/ruleAlert-helper";
import { ICareplan } from "src/app/models/careplan";
import { IExternalRessource, METHOD_DISPLAY_DEVICE_DATA } from "src/app/models/externalRessource";
import { NOTIFICATION_TYPE } from "src/app/models/notification";
import { IRuleResult } from "src/app/models/rule";
import { ACTION_STATUS_ENTITY, AccessLevel, RULE_TARGET_TYPE } from "src/app/models/sharedInterfaces";
import { MyDevicesPage } from "src/app/my-devices/my-devices.page";
import { CameraService } from "src/app/services/camera.service";
import { CareplanService } from "src/app/services/globalDataProvider/careplan.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import { ExternalRessourceService } from "src/app/services/globalDataProvider/external-ressource.service";
import { KnowledgesObservationService } from "src/app/services/globalDataProvider/knowledges-observation.service";
import { LanguagesService } from "src/app/services/globalDataProvider/languagesService";
import { ObservationDefinitionService } from "src/app/services/globalDataProvider/observation-definition.service";
import { RulesAlertService } from "src/app/services/globalDataProvider/rules-alert.service";
import { RulesService } from "src/app/services/globalDataProvider/rules.service";
import { GoToPageService } from "src/app/services/go-to-page.service";
import { InfoAppService } from "src/app/services/info-app.service";
import { LoaderService } from "src/app/services/loader.service";
import { MedicalBluetoothService } from "src/app/services/medical-bluetooth/medical-bluetooth.service";
import { MedicalBluetoothLungMonitorData } from "src/app/services/medical-bluetooth/medical-hardware/ medical-bluetooth-lung-monitor";
import { MedicalBluetoothBloodPressureData } from "src/app/services/medical-bluetooth/medical-hardware/medical-bluetooth-blood-pressure";
import { MedicalBluetoothGlucometerPersonalizedData } from "src/app/services/medical-bluetooth/medical-hardware/medical-bluetooth-glucometer";
import { MedicalBluetoothOximeterData } from "src/app/services/medical-bluetooth/medical-hardware/medical-bluetooth-oximeter";
import { MedicalBluetoothThermometerData } from "src/app/services/medical-bluetooth/medical-hardware/medical-bluetooth-thermometer";
import { MedicalBluetoothWeightScaleData } from "src/app/services/medical-bluetooth/medical-hardware/medical-bluetooth-weight-scale";
import { ModalKnowledgeService } from "src/app/services/modal-knowledge.service";
import { ModalService } from "src/app/services/modal.service";
import { NotificationsGeneratedService } from "src/app/services/notificationsService/notifications-generated.service";
import { PopupService } from "src/app/services/popup.service";
import { E2E_ID_OBS } from "test/helpers/selectorIdHelper";
import {
  BluetoothDeviceComponent,
  DataBluetooth,
  DataMeasurementBluetooth,
  MeasurementBluetooth,
} from "../../bluetooth-device/bluetooth-device.component";
import { MyProfilModalComponent } from "../my-profil-modal/my-profil-modal.component";

@Component({
  selector: "app-observation-modal",
  templateUrl: "./observation-modal.component.html",
  styleUrls: ["./observation-modal.component.scss"],
})
export class ObservationModalComponent extends BasePage {
  public observation: IObservation;
  public definition: IObservationDefinition;
  public observationType: string;
  public canDelete: boolean;
  public hideLoinc: string[];
  public yesterday: string;
  @ViewChild(BluetoothDeviceComponent) private bluetoothDeviceComponent!: BluetoothDeviceComponent;
  private allObservations: IObservation[];
  private now = moment().format();
  public today: string = new Date().toISOString();
  public physicalActivityLoinc: string;
  private selectedTiming: string;
  public existingTimingCode: string;
  public needsRefValues = false;
  public observationTypeIsMeasurable = false;
  public isPreviousYesterdayObservation = false;
  public validatedValue: DataMeasurementBluetooth;
  public showMesure = true;
  public medias: IKnowMedia[] = [];
  private isMediaOpen = false;
  public recommendationsByComponent: IRuleResult[][]; // this.recommendationsByComponent[i] = array of the recommendations for the component i of the observationDefinition
  public E2E_ID_OBS = E2E_ID_OBS;
  public isAlreadySaving = false;
  private isBleDeviceData: boolean;
  public bleData: {
    loinc: string;
    data: number;
  }[];
  public isBluetoothEdit: boolean;
  public loincDisabled: string[];
  public isEditMode: boolean;
  public dateTimeLocaleFormat: string;
  public isDisabled = false;
  private originalComponent: OComponent[] | undefined | null;
  private originalIssued: string | undefined | null;
  private originalEffectiveTiming?: IEffectiveTiming;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected rulesService: RulesService,
    protected params: NavParams,
    protected obsDefService: ObservationDefinitionService,
    protected modalCtrl: ModalController,
    protected configService: ConfigurationService,
    protected translateService: TranslateService,
    protected notificationGeneratedService: NotificationsGeneratedService,
    protected ruleService: RuleHelperObservationService,
    protected rulesAlertService: RulesAlertService,
    protected gotoPage: GoToPageService,
    protected modalService: ModalService,
    protected cdr: ChangeDetectorRef,
    protected externalRessourceService: ExternalRessourceService,
    protected cameraService: CameraService,
    protected loaderService: LoaderService,
    protected modalKnowledge: ModalKnowledgeService,
    protected knObservationService: KnowledgesObservationService,
    protected languagesService: LanguagesService,
    protected careplanService: CareplanService
  ) {
    super(translateService, configService, infoService, popupService);

    this.physicalActivityLoinc = AppConstants.PHYSICAL_ACTIVITY_LOINC;
    this.yesterday = moment(new Date()).subtract(1, "w").toISOString(); // If you change the value of this variable, you need to change it for the e2e tests too (addObservation.modale.ts -->  method answerObs())

    moment.locale(this.configService.getCurrentLanguage());
    this.languagesService.getFirstDataAvailable().then((langs) => {
      this.dateTimeLocaleFormat = Tools.getDateTimeLocaleFormat(langs, this.configService.getCurrentLanguage());
    });
    this.observation = Tools.deepCopy(this.params.get("observation"));
    this.originalComponent = Tools.deepCopy(this.observation.component);
    this.originalIssued = this.observation.issued;
    this.originalEffectiveTiming = Tools.deepCopy(this.observation.effectiveTiming);
    this.definition = this.params.get("definition");
    this.allObservations = this.params.get("allObservations");
    this.hideLoinc = this.params.get("hideLoinc");
    this.isBluetoothEdit = this.params.get("isBluetoothEdit");
    this.loincDisabled = this.params.get("loincDisabled");
    this.isEditMode = this.params.get("isEditMode");
    this.isDisabled = Tools.isDefined(this.observation.patientAccess) && this.observation.patientAccess < AccessLevel.WRITE;
    if (!this.hideLoinc) {
      this.hideLoinc = [];
    }
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();

    this.rulesService.getFirstDataAvailable().then(() => {
      this.needsRefValues = this.ruleService.ruleNeedVitalSign(this.observation);
    });
    this.canDelete = this.observation.actionStatus !== ACTION_STATUS_ENTITY.CREATED;
    this.observationType = Observation.getObservationType(this.observation);
    if (this.observation.device?.reference) {
      this.setBleData(this.observation.device.type, this.observation.device.reference, this.observation.device.componentAnswer);
    }

    this.isMeasurable();
    this.initializeObservation();
    this.loadLinkedKnowledges();
    this.initRecommendations();
    this.bluetoothDeviceComponent?.refresh();
  }

  public async goToMyBleDevices(loinc: string): Promise<void> {
    const m = await this.presentModalBluetoothDevicesAndReturnIt(loinc);
    m.onDidDismiss().then(() => this.bluetoothDeviceComponent?.refresh());
  }

  private async presentModalBluetoothDevicesAndReturnIt(loinc: string) {
    const m = await this.modalCtrl.create({
      component: MyDevicesPage,
      componentProps: { code: loinc, isModal: true },
    });
    m.present();
    return m;
  }

  /**
   * this.recommendationsByComponent[i] = i, for i = 0 to this.definition.components.length
   */
  private initRecommendations() {
    this.recommendationsByComponent = this.definition.components.map(() => []);
  }

  /**
   * Verify if the id and the date is ok, if not we put a random ObjectId for mongoDb and the date is fixe for "today"
   */
  private initializeObservation() {
    if (Tools.isNotDefined(this.observation._id)) {
      this.observation._id = Tools.genValidId();
    }

    if (Tools.isNotDefined(this.observation.issued)) {
      this.observation.issued = this.now;
      this.updateIsPreviousYesterdayObservation();
    }

    if (Tools.isDefined(this.observation.effectiveTiming?.repeat?.when?.code)) {
      this.existingTimingCode = this.observation.effectiveTiming.repeat.when.code;
    } else {
      this.existingTimingCode = Observation.defaultEffectiveTime(this.definition, moment().format(), this.configService);
    }
  }

  /**
   * updates the variable this.observationType via an observable
   */
  private async isMeasurable() {
    this.observationTypeIsMeasurable = await this.externalRessourceService.isMeasurable(this.observationType);
  }

  public async setMeasurement(bleData: DataBluetooth): Promise<void> {
    let valueBleData:
      | MedicalBluetoothThermometerData
      | MedicalBluetoothBloodPressureData
      | MedicalBluetoothWeightScaleData
      | MedicalBluetoothOximeterData
      | MedicalBluetoothLungMonitorData
      | MedicalBluetoothGlucometerPersonalizedData = bleData.data;

    if (bleData.externalRessource.meta.medicalBluetoothService === MedicalBluetoothService.GLUCOSE) {
      const glucoData = await this.setupGlucometerData(bleData);
      if (!glucoData) {
        return;
      } else {
        valueBleData = glucoData;
      }
    }
    const value: {
      loinc: string;
      data: unknown;
      method: METHOD_DISPLAY_DEVICE_DATA;
      isHiddenLoinc?: boolean;
    }[] = [];

    bleData?.externalRessource?.meta?.componentAnswer?.forEach((cAnswer) => {
      value.push({
        loinc: cAnswer.loinc,
        data: valueBleData[cAnswer.inputName],
        method: cAnswer.displayMethod,
        isHiddenLoinc: cAnswer.isHiddenLoinc,
      });
    });
    value.forEach((v) => {
      const component = this.getObsComponent(v.loinc);
      if (component && v.data) {
        switch (v.method) {
          case METHOD_DISPLAY_DEVICE_DATA.NUMBER_FIXED_1:
            component.valueQuantity.value = Number.parseFloat((v.data as number).toFixed(1));
            break;
          case METHOD_DISPLAY_DEVICE_DATA.BOOLEAN:
            component.valueQuantity.value = v.data ? 1 : 0;
            break;
          default:
            component.valueQuantity.value = v.data as number;
        }
        if (v.isHiddenLoinc) {
          this.hideLoinc = this.hideLoinc.filter((code) => code !== v.loinc);
        }
      }
    });
    this.validatedValue = {
      valid: MeasurementBluetooth.SUCCESS,
      data: bleData,
    };
    this.setBleData(
      bleData.externalRessource?.meta?.medicalBluetoothService,
      bleData.externalRessource?.title,
      bleData.externalRessource?.meta?.componentAnswer
    );
    this.cdr.detectChanges();
    return;
  }

  private async setupGlucometerData(data: DataBluetooth): Promise<MedicalBluetoothGlucometerPersonalizedData> {
    let isOnlyOneData = true;
    let glucoseData: MedicalBluetoothGlucometerPersonalizedData | MedicalBluetoothGlucometerPersonalizedData[];
    if (data.externalRessource?.meta?.searchPreviousData) {
      isOnlyOneData = false;
      const glucoseAllData = data.data as MedicalBluetoothGlucometerPersonalizedData[];
      if (glucoseAllData.length === 1) {
        isOnlyOneData = true;
        glucoseData = glucoseAllData[0];
      } else {
        const glucoseAllDataFiltered = glucoseAllData.filter(
          (d) => this.allObservations.findIndex((o) => o.issued === moment(d.date).format()) === -1
        );
        if (glucoseAllDataFiltered.length === 1) {
          this.showMesure = false;
          isOnlyOneData = true;
          glucoseData = glucoseAllDataFiltered[0];
        } else {
          if (glucoseAllDataFiltered.length < 1) {
            glucoseData = glucoseAllData[glucoseAllData.length - 1];
            isOnlyOneData = true;
          } else {
            this.showMesure = false;
            const showHistory = await this.popupService.showYesNo("application.title", "myobservations.showHistory");
            if (showHistory) {
              this.displayBluetoothGlucometerMultipleData(glucoseAllDataFiltered, data.externalRessource);
            } else {
              isOnlyOneData = true;
              // get only the latest data
              glucoseData = glucoseAllDataFiltered.sort((a, b) => {
                return moment(a.date).isBefore(moment(b.date)) ? 1 : -1;
              })[0];
            }
          }
        }
      }
    }
    if (isOnlyOneData) {
      glucoseData = (glucoseData ? glucoseData : data.data) as MedicalBluetoothGlucometerPersonalizedData;
      const issued = moment(glucoseData.date).format();
      if (this.isOldObservation(issued)) {
        this.validatedValue = {
          valid: MeasurementBluetooth.TOO_OLD,
          data,
        };
        // obliged to this detectChanges to activate the ngOnChange of the child component bluetooth
        this.cdr.detectChanges();
        return;
      } else {
        this.observation.issued = issued;
        this.updateIsPreviousYesterdayObservation();
        return glucoseData;
      }
    }
    return null;
  }

  /**
   * return Observation Definition from its code
   * @param code
   */
  private getDefinition(code: string): IObservationDefinitionComponent {
    const out = this.definition.components.find((c) => c.loinc === code);
    return out;
  }

  /**
   * We have to keep this function for now
   * It's used in the html inside ngModel and pipes aren't allowed in a ngModel
   * @param code
   */
  public getObsComponent(code: string): OComponent {
    const out = this.observation.component.find((c) => c.code.coding[0].code === code);
    return out;
  }

  private async isComplete(compo: OComponent, compIdx: number): Promise<boolean> {
    const code = compo.code.coding[0].code;
    const componentDefinition = this.getDefinition(code);
    if (compIdx > -1) {
      await this.updateRecommendations(compIdx);
    }
    // Check value validity on absolue limits
    if (!this.checkValidity(componentDefinition, compo)) {
      let componentName: string;
      if (componentDefinition.nameTranslation) {
        componentName = InfoAppService.getTranslation(
          componentDefinition.nameTranslation,
          this.configService.getCurrentLanguage(),
          componentDefinition.loinc
        );
      } else if (componentDefinition.name) {
        componentName = this.obsDefService.getTranslatedString(componentDefinition.name, componentDefinition.loinc);
      }
      const errorMessage = this.translateService.instant("myobservations.errorValue.outOfRange", { componentName });
      const appTitle = this.translateService.instant("application.title");
      const minMax = { min: "", max: "" };
      if (componentDefinition.meaning && componentDefinition.meaning.length) {
        const meaningLength = componentDefinition.meaning.length;
        minMax.min = componentDefinition.meaning[0].description[this.configService.getCurrentLanguage()];
        minMax.max = componentDefinition.meaning[meaningLength - 1].description[this.configService.getCurrentLanguage()];
      } else {
        minMax.min = `${componentDefinition.min}`;
        minMax.max = `${componentDefinition.max}`;
      }
      this.popupService.showAlertWithoutTranslation(appTitle, errorMessage, `${minMax.min} - ${minMax.max}`);
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check value validity on absolue limits
   */
  private checkValidity(componentDefinition: IObservationDefinitionComponent, component: OComponent): boolean {
    if (componentDefinition.type === "range" && componentDefinition.step && !Tools.isDefined(component.valueQuantity.value)) {
      component.valueQuantity.value = componentDefinition.min;
    }
    const isEnabled = this.isEnable(componentDefinition.enableWhen);
    if (!isEnabled) return true;
    if (
      (component.valueQuantity.value === null || component.valueQuantity.value === undefined) &&
      (componentDefinition.required === undefined || componentDefinition.required === true)
    ) {
      return false;
    }
    if (Tools.isDefined(componentDefinition.min) && component.valueQuantity.value < componentDefinition.min) {
      return false;
    }
    if (Tools.isDefined(componentDefinition.max) && component.valueQuantity.value > componentDefinition.max) {
      return false;
    }
    return true;
  }

  /**
   *  cancel edition
   */
  public dismiss(): void {
    this.modalCtrl.dismiss();
  }

  /**
   *  save modification
   */
  public saveObservation(): void {
    if (this.isDisabled) {
      return;
    }
    this.isAlreadySaving = true;
    // Remove hidden component from validation
    this.observation.component = this.observation.component.filter((component) => {
      return component.code.coding.findIndex((code) => this.hideLoinc?.includes(code.code));
    });

    // check if user data is needed before rule module
    this.ruleService.checkUserProfile(this.observation).then(
      async (loincCodes2Request) => {
        // nothing to do
        FileLogger.log("ModalObservation.saveObservation, checkUserProfile", "loinc Codes 2 Request", loincCodes2Request);
        if (!loincCodes2Request?.length) {
          this._saveObservation(); // no (more) missing data => allow to save
          return;
        } else {
          const modal = await this.modalCtrl.create({
            component: MyProfilModalComponent,
            componentProps: {
              profileTab: "datas",
              missingVitalSings: loincCodes2Request,
            },
          });
          modal.onDidDismiss().then(() => {
            this.saveObservation();
          });
          await modal.present();
        }
      },
      (err) => {
        FileLogger.error("ObservationModalComponent", "checkUserProfile failed", err);
      }
    );
  }

  private isOldObservation(date: string): boolean {
    return moment(date).isBefore(this.yesterday, "day");
  }

  public updateIsPreviousYesterdayObservation(): void {
    this.isPreviousYesterdayObservation = this.isOldObservation(this.observation.issued);
  }

  public async handleDateChange(): Promise<void> {
    if (this.definition.onePerDay) {
      const observationOfDay = this.allObservations.find(
        (obs) => moment(obs.issued).isSame(this.observation.issued, "day") && String(this.definition.loinc) === obs.code.coding[0].code
      );
      if (observationOfDay) {
        this.observation = Tools.deepCopy(observationOfDay);
        await this.rulesService.getFirstDataAvailable().then(() => {
          this.needsRefValues = this.ruleService.ruleNeedVitalSign(this.observation);
        });

        this.canDelete = this.observation.actionStatus !== ACTION_STATUS_ENTITY.CREATED;
        this.observationType = Observation.getObservationType(this.observation);

        this.isMeasurable();

        this.initializeObservation();
      }
    }
  }

  /**
   * really check rule and save data
   */
  private async _saveObservation(): Promise<void> {
    let i = 0;
    for (const compo of this.observation.component) {
      if (!(await this.isComplete(compo, i))) {
        this.isAlreadySaving = false;
        return;
      }
      ++i;
    }
    if (this.definition.askTimingWhen) {
      this.observation.effectiveTiming = {
        repeat: {
          count: 1,
          when: this.definition.lsTimingWhen.find((t) => t.when.code === this.selectedTiming).when,
        },
      };
    }

    /* if linkedRuleAlerts already exist, then they have been generated using the values already present in the observation.
       In this case, we save these values in the field, so that we know that there has been a modification by the patient.*/
    if (this.observation.linkedRuleAlerts?.length > 0) {
      this.observation.linkedRuleAlerts
        .filter(
          (linkedRuleAlert) => Tools.isNotDefined(linkedRuleAlert.originalComponents) || linkedRuleAlert.originalComponents?.length === 0
        )
        .forEach((linkedRuleAlert) => {
          linkedRuleAlert.originalComponents = this.originalComponent;
          linkedRuleAlert.originalIssued = this.originalIssued;
          linkedRuleAlert.originalEffectiveTiming = this.originalEffectiveTiming;
        });
    }

    if (this.isBleDeviceData) {
      this.checkBleDataNotModified();
    }
    this.observation.issued = moment(this.observation.issued).format();
    this.updateIsPreviousYesterdayObservation();
    if (!this.isBluetoothEdit) {
      this.observation.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    }
    // Rules module activated ?
    if (!AppConstants.RULES_MODULE) {
      this.modalCtrl.dismiss(this.observation, undefined, AppConstants.OBSERVATION_MODAL);
      return;
    }

    // check if alerts have to be generated on this observation
    if (moment(this.observation.issued).isSame(moment(), "day")) {
      // only for today observation (not when modifying past observations)
      this.ruleService.checkObservationAlertRules(this.observation, this.allObservations).then(
        async (ruleAlerts) => {
          if (ruleAlerts.length) {
            const linkedRuleAlerts = ruleAlerts.map((r) => {
              return {
                identifier: r.identifier,
                rule: r.rule,
              };
            });
            this.observation.linkedRuleAlerts =
              this.observation.linkedRuleAlerts && Array.isArray(this.observation.linkedRuleAlerts)
                ? this.observation.linkedRuleAlerts.concat(...linkedRuleAlerts)
                : linkedRuleAlerts;

            const raSavingPromises: Promise<IRuleAlert>[] = [];
            ruleAlerts.forEach((ra) => raSavingPromises.push(this.rulesAlertService.save(ra, false)));
            try {
              await this.loaderService.showSavingToast(true);
              await Promise.all(raSavingPromises);
            } catch (err2) {
              FileLogger.error("ObservationModalComponent", "Error while saving rule alerts", err2);
            }
            await this.loaderService.showSavingToast(false);
            // display alerts messages (in 1 single box)
            this.ruleService.displayRuleAlerts(ruleAlerts).then(() => {
              ruleAlerts.forEach((alert) => {
                /* ATTENTION !!!! dans ce qui suit, on suppose qu'il y a qu'une seule alerte, ruleAlerts est un tableau de
                1 élément. Il faudra modifier ça lors de la refonte des alertes voir ticket CMATE-904.
                */
                if (alert.rule.repeatObservation) {
                  const cleanObs = this.getCleanObservationWithTime(alert.rule.repeatObservation);
                  this.notificationGeneratedService.updateOrCreateNotification(
                    { obs: cleanObs, identifierAlert: alert.identifier[0].value },
                    NOTIFICATION_TYPE.ALERT_OBSERVATION
                  );
                  this.modalCtrl.dismiss(this.observation, undefined, AppConstants.OBSERVATION_MODAL);
                } else {
                  this.notificationGeneratedService.deleteNotification(
                    { obs: this.observation, identifierAlert: alert.identifier[0].value },
                    NOTIFICATION_TYPE.ALERT_OBSERVATION
                  );
                  this.modalCtrl.dismiss(this.observation, undefined, AppConstants.OBSERVATION_MODAL);
                }
              });
            });
          } else {
            this.notificationGeneratedService.deleteNotification(
              { obs: this.observation, identifierAlert: null },
              NOTIFICATION_TYPE.ALERT_OBSERVATION
            );
            this.modalCtrl.dismiss(this.observation, undefined, AppConstants.OBSERVATION_MODAL);
          }
        },
        (err) => {
          FileLogger.error("ObservationModalComponent", "checkObservationAlertRules failed", err);
          this.modalCtrl.dismiss(this.observation, undefined, AppConstants.OBSERVATION_MODAL);
        }
      );
    } else this.modalCtrl.dismiss(this.observation, undefined, AppConstants.OBSERVATION_MODAL);
  }

  /**
   * delete observation (in fact, set status to delete)
   */
  public deleteObservation(): void {
    this.observation.actionStatus = ACTION_STATUS_ENTITY.DELETED;
    this.notificationGeneratedService.deleteNotification(
      { obs: this.observation, identifierAlert: null },
      NOTIFICATION_TYPE.ALERT_OBSERVATION
    );
    this.modalCtrl.dismiss(this.observation, undefined, AppConstants.OBSERVATION_MODAL);
  }

  /**
   * Return an observation with clan values and issued to the notification time
   * @param hour number
   */
  private getCleanObservationWithTime(hour: number): IObservation {
    // using JSON.parse and stringify to copy obj without references
    const observationCpy = JSON.parse(JSON.stringify(this.observation));
    // we stock the date of the notification directly in the observation
    observationCpy.issued = moment().add(hour, "hour").format();
    // we clean the observation value
    observationCpy.component.forEach((val) => {
      val.valueQuantity.value = null;
    });
    return observationCpy;
  }

  /**
   * Save the observation from the component
   */
  public saveFromBt(_data?: unknown): void {
    this.saveObservation();
  }

  private getResponseFromLoinc(code: string) {
    const out = this.observation.component?.find((c) => c.code.coding[0].code === code);
    return out.valueQuantity.value;
  }

  /**
   *
   * @param reference (string) the attribute reference in format: "careplanReference/attributeReference"
   * @param attributeType (string) the type of attribute (activity, addresses...)
   * @returns the status (string) of the attribute
   */
  private getStatusFromCareplanAttributes(reference: string, attributeType: string): string | null {
    const splitRef = reference.split("/");
    const careplanRef = splitRef[0];
    const attributeRef = splitRef.slice(1, splitRef.length).join("/");
    const allCareplans: ICareplan[] = this.careplanService.peekData();
    const foundCareplan: ICareplan = allCareplans?.find((c) => c.support[0].reference === careplanRef);
    if (!foundCareplan) {
      return null;
    }
    // find the attribute's status:
    switch (attributeType) {
      case "careplan.addresses":
        return foundCareplan.addresses?.find((a) => a.reference === attributeRef)?.status;
      case "careplan.activity":
        return foundCareplan.activity?.find((a) => a.reference.reference === attributeRef)?.detail.status;
      default:
        return null;
    }
  }

  /**
   * We have to keep this function for now, it won't work with a pipe
   * Return true if
   * - enableWhen does not exist (-> the question must be always visible)
   * - the condition describe by the field "enableWhen" is respected (-> the question must be visible)
   * Return false if
   * - the condition describe by the field "enableWhen" is not respected (-> the question must not be visible)
   * @param enableWhen
   */
  public isEnable(enableWhen: IEnableWhen[]): boolean {
    if (!enableWhen || enableWhen.length < 1 || !EnableWhenHelper.isEnableWhen(enableWhen)) {
      return true;
    } else {
      const elemArray = [];
      for (const elem of enableWhen) {
        let value;
        if (!elem.code?.coding?.length) {
          value = this.getResponseFromLoinc(elem.question);
        } else if (elem.code.coding[0].code.startsWith("careplan")) {
          value = this.getStatusFromCareplanAttributes(elem.question, elem.code.coding[0].code);
        }
        if (!Tools.isDefined(value)) {
          return false;
        }
        elemArray.push(EnableWhenHelper.computeIsEnabled(elem, value));
      }
      const trueChecker = (currentValue: boolean) => currentValue === true;
      return elemArray.every(trueChecker);
    }
  }

  /**
   * Click to go to health page
   */
  public onHealth(): void {
    this.dismiss();
    this.gotoPage.healthPage();
  }

  public findTiming(event: CustomEvent): void {
    this.initRecommendations(); // init all recommendations
    this.selectedTiming = event.detail.value;
  }

  public async onRefValues(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: MyProfilModalComponent,
      componentProps: { profileTab: "datas" },
    });
    modal.present();
  }

  /**
   * Calculate recommendations if it's an automatic type component
   * Reset all the recommendations linked to this.definition.components[index],
   * i.e. whose the loinc code of the components is in the targets of the recommendations
   * @param index
   */
  public async updateRecommendations(index: number): Promise<void> {
    const loincModify = this.definition.components[index]?.loinc;
    if (loincModify?.length) {
      const promises = [];
      this.definition.components.forEach((c, i) => {
        if (
          Array.isArray(c.recommendation?.targets) &&
          c.recommendation?.targets.map((t) => t.reference).includes(loincModify) &&
          this.recommendationsByComponent?.length
        ) {
          this.recommendationsByComponent[i] = [];
        }
        if (c.type === "automatic" && this.isEnable(c.enableWhen)) {
          promises.push(this.calculateRecommendation(i, c));
        }
      });
      await Promise.all(promises);
    }
  }

  /**
   *
   * @param index of the component in the array this.definition.component
   * @param component
   * @param stopLoop
   * @returns
   */
  public async calculateRecommendation(index: number, component: IObservationDefinitionComponent, stopLoop = false): Promise<void> {
    const targets =
      Array.isArray(component.recommendation?.targets) && component.recommendation?.targets.length > 0
        ? component.recommendation?.targets
            .filter((target) => target.type !== RULE_TARGET_TYPE.DRUGS && target.type !== RULE_TARGET_TYPE.VITAL_PROFILE)
            .map((t) => t.reference)
        : [];

    for (const compo of this.observation.component.filter((c) => c.code.coding.find((co) => targets.includes(co.code)))) {
      if (!(await this.isComplete(compo, -1))) {
        return;
      }
    }

    if (this.definition.askTimingWhen) {
      this.observation.effectiveTiming = {
        repeat: {
          count: 1,
          when: this.definition.lsTimingWhen.find((t) => t.when.code === this.selectedTiming).when,
        },
      };
    }
    const missingLoincs = this.ruleService.checkUserProfileForRecommendation(this.definition);
    if (missingLoincs?.length && !stopLoop) {
      const modal = await this.modalCtrl.create({
        component: MyProfilModalComponent,
        componentProps: {
          profileTab: "datas",
          missingVitalSings: missingLoincs,
        },
      });
      modal.onDidDismiss().then(() => {
        this.calculateRecommendation(index, component, true);
      });
      await modal.present();
    } else if (missingLoincs?.length && stopLoop) {
      this.recommendationsByComponent[index] = [];
    } else {
      const ruleResults = await this.ruleService.getMessagesRecommendation(component, this.observation, this.allObservations);
      if (this.recommendationsByComponent?.length) this.recommendationsByComponent[index] = ruleResults;
      if (component.type === "automatic") {
        this.observation.component[index].valueQuantity.value = +InfoAppService.getTranslation(
          ruleResults[0].value,
          this.configService.getCurrentLanguage(),
          ruleResults[0].value?.fr
        );
      }
    }
  }

  public async onPhoto(i: number): Promise<void> {
    try {
      const base64Picture = await this.cameraService.createPictureBase64();
      if (base64Picture) {
        if (this.observation.component[i].valuePictures) {
          this.observation.component[i].valuePictures.push(base64Picture);
        } else {
          this.observation.component[i].valuePictures = [base64Picture];
        }
      }
    } catch (error) {
      FileLogger.error("ObservationModalComponent", "onPhoto", error);
    }
  }

  public async displayPicture(picture: string, iComponent: number, iPicture: number): Promise<void> {
    const isDeleted = await this.modalService.presentModalDisplayPhoto(picture);
    if (isDeleted) {
      this.observation.component[iComponent].valuePictures.splice(iPicture, 1);
    }
  }

  /**
   * Display media long description
   */
  public onDescriptionDetail(media: IKnowMedia): void {
    const knowledgeBase: IKnowledgeBase = {
      _id: null,
      modified: null,
      entityStatus: null,
      author: null,
      organization: null,
      healthcareservice: null,
      snomedReference: null,
      category: KNOW_CATEGORY.OBSERVATION,
      documentCategory: KNOW_DOC_CATEGORY.DESCRIPTION,
      criteria: null,
      medias: [media],
    };
    if (this.isMediaOpen) {
      return;
    }
    this.isMediaOpen = true;
    this.modalKnowledge.presentModalKnowledge("", [knowledgeBase]).finally(async () => {
      this.isMediaOpen = false;
    });
  }

  /**
   * Load knowledges linked to the observation
   */
  private async loadLinkedKnowledges() {
    try {
      const collectedKnowledges = await this.knObservationService.getFirstDataAvailable();
      if (collectedKnowledges && collectedKnowledges.length) {
        this.medias = [];
        collectedKnowledges.forEach((kn) => {
          if (kn.reference === this.definition.loinc) {
            kn.knowledges.forEach((knowledge) => {
              knowledge.medias.forEach((media) => {
                if (media.description && media.language === this.configService.getCurrentLanguage()) {
                  this.medias.push(media);
                }
              });
            });
          }
        });
        this.medias = this.medias.filter(ArrayHelper.onlyUniqueIKnowMedia);
      }
    } catch (err) {
      FileLogger.error("ObservationModalComponent", "ObservationModalComponent loadLinkedKnowledges()", err);
    }
  }

  private setBleData(bleType: MedicalBluetoothService, bleName: string, componentAnswers: { loinc: string }[]) {
    if (!bleType || !bleName) {
      this.isBleDeviceData = false;
      return;
    }
    this.bleData = [];
    componentAnswers?.forEach((c) => {
      const component = this.getObsComponent(c.loinc);
      if (component?.valueQuantity?.value) {
        this.bleData.push({
          loinc: c.loinc,
          data: component.valueQuantity.value,
        });
      }
    });

    this.isBleDeviceData = true;
    if (!this.observation.device?.reference) {
      this.observation.device = {
        reference: bleName,
        type: bleType,
        componentAnswer: componentAnswers,
      };
    }
  }

  private checkBleDataNotModified() {
    this.bleData?.forEach((bd) => {
      if (this.getObsComponent(bd.loinc).valueQuantity.value !== bd.data) {
        this.observation.device = null;
      }
    });
  }

  private async displayBluetoothGlucometerMultipleData(
    data: MedicalBluetoothGlucometerPersonalizedData[],
    externalRessource: IExternalRessource
  ) {
    await this.modalCtrl.dismiss();
    await this.gotoPage.bluetoothMultipleObsPage({ data, definition: this.definition, externalRessource });
  }
}

import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { ChartOptions } from "chart.js";
import * as moment from "moment";
import { DataChart } from "../components/observation/observation.component";
import { ChartHelper, IChartColors } from "../helpers/chart-helper";
import { FileLogger } from "../helpers/fileLogger";
import { IObservationDefinition } from "../helpers/observation-helper";
import { Tools } from "../helpers/tools-helper";
import { IExternalRessource, OnlineDevice } from "../models/externalRessource";
import { ApiService } from "./api.service";
import { ConfigurationService } from "./globalDataProvider/configuration.service";
import { DayStreamObservationsService } from "./globalDataProvider/dayStreamObservations.service";
import { ObservationDefinitionService } from "./globalDataProvider/observation-definition.service";
import { GraphService } from "./graph.service";
import { InfoAppService } from "./info-app.service";
import { NetworkService } from "./network.service";
import { PopupService } from "./popup.service";
export interface IAveragedObservation {
  avg: number;
  componentCode: string;
  code: string;
  issued: string; // this will just contain the hour of the day
  deviceReference: string;
  unit: string;
  interpretation?: string;
}
@Injectable({
  providedIn: "root",
})
export class StreamObservationService {
  private mode: "TEST" | "PROD" = "PROD";
  private url = "/mobile/onlineDeviceData";
  private basicChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: this.platform.is("desktop") ? false : true, // avoid chart not resizing correctly on desktop
    spanGaps: true,
  };

  private dexcomChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: this.platform.is("desktop") ? false : true, // avoid chart not resizing correctly on desktop,
    scales: {
      yAxes: [
        {
          position: "right",
          ticks: { min: 40, max: 400 },
        },
      ],
      xAxes: [
        {
          gridLines: { display: false },
        },
      ],
    },
    legend: { display: false },
    spanGaps: true,
  };

  public static dexcomColors: IChartColors[] = [
    {
      backgroundColor: "rgba(255, 255, 255, 0.0)",
      borderColor: "rgba(0,0,0,0.0)",
      pointBackgroundColor: "#000000",
      pointBorderColor: "#000000",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#000000",
    },
  ];

  constructor(
    private apiService: ApiService,
    private infoAppService: InfoAppService,
    private networkService: NetworkService,
    private configService: ConfigurationService,
    private obsDefService: ObservationDefinitionService,
    private graphService: GraphService,
    private dayStreamObservationsService: DayStreamObservationsService,
    private popupService: PopupService,
    private platform: Platform
  ) {
    this.infoAppService.getCurrentMode().then((mode: string) => {
      this.mode = mode !== "PROD" ? "TEST" : "PROD";
    });
  }

  public async getObservations(
    externalRessourceRef: string,
    startDate: string,
    endDate?: string,
    loinc?: string
  ): Promise<IAveragedObservation[]> {
    if (!this.networkService.isCurrentOnline()) {
      this.popupService.showAlert("streamObservations.offlineWarningTitle", "streamObservations.offlineWarning");
      return await this.dayStreamObservationsService.getFirstDataAvailable(externalRessourceRef, loinc);
    } else {
      this.dayStreamObservationsService.getFreshestData(externalRessourceRef, loinc);
    }
    if (!endDate) {
      endDate = moment(startDate).endOf("day").toISOString();
    }
    const params = {
      mode: this.mode,
      externalRessourceRef,
      startDate,
      endDate,
      loinc,
    };
    try {
      const result = await this.apiService.getWithPromise(this.url, null, params);
      return result.data;
    } catch (err) {
      FileLogger.error("StreamObservationService", "Error while getting stream observations", err, "none");
      return [];
    }
  }

  /**
   *
   * @param definition the observation definition
   * @param externalRessource the corresponding external ressource
   * @param startDate start of the range of date we want
   * @param endDate null if we are asking for only one day of observations
   */
  public async computeStreamObservationGraph(
    definition: IObservationDefinition,
    externalRessource: IExternalRessource,
    startDate: string,
    endDate: string,
    dexcomClickFunction: (evt, array: any[]) => void
  ): Promise<{
    chartData: DataChart[];
    chartLabels: string[];
    chartOptions: ChartOptions;
    chartColors: IChartColors[];
    streamObservations: IAveragedObservation[];
  }> {
    const chartLabels = new Array<string>();
    const chartData = new Array<DataChart>();
    const values: Map<string, number[]> = new Map<string, number[]>();
    const latestDaysData = await this.getObservations(externalRessource.reference, startDate, endDate, definition.loinc);
    if (!latestDaysData) {
      return { chartData, chartLabels, chartOptions: this.basicChartOptions, chartColors: ChartHelper.chartColors, streamObservations: [] };
    }
    let loincCodes = [...new Set(latestDaysData.map((item) => item.componentCode))];
    loincCodes = loincCodes.filter((l) => !externalRessource.meta.componentAnswer.find((c) => c.loinc === l)?.isHiddenLoinc);
    for (const l of loincCodes) {
      values.set(l, []);
    }
    const data = Tools.deepCopy(latestDaysData).filter((o) => loincCodes.includes(o.componentCode));
    // We make a graph with one point per minute for a whole day:
    for (let minutes = 0; minutes < 1440; ++minutes) {
      const time = Tools.minutesToHours(minutes);
      /*
      const time15before = minutes >= 15 ? Tools.minutesToHours(minutes - 15) : "00:00";
      const time15after = minutes < 1425 ? Tools.minutesToHours(minutes + 15) : "23:59";
      */
      if (time.endsWith("00") || time === "23:59") {
        chartLabels.push(time);
      } else {
        chartLabels.push("");
      }
      // Find the observation for that time:
      const i = data.findIndex((o) => o.issued === time);
      // It's okay if we did not find it, it will still work:
      if (i === -1) {
        for (const l of loincCodes) {
          values.get(l).push(null);
        }
      } else {
        const observ: IAveragedObservation = data[i];
        data.splice(i, 1);
        values.get(observ.componentCode)?.push(Math.round(observ.avg * 100) / 100);
      }
    }

    for (const component of definition.components) {
      const isHiddenLoinc = externalRessource.meta.componentAnswer.find((c) => c.loinc === component.loinc)?.isHiddenLoinc;
      if (isHiddenLoinc) {
        continue;
      }
      const dataChart = new DataChart();
      if (component.shortnameTranslation) {
        dataChart.label = InfoAppService.getTranslation(
          component.shortnameTranslation,
          this.configService.getCurrentLanguage(),
          component.loinc
        );
      } else if (component.nameTranslation) {
        dataChart.label = InfoAppService.getTranslation(
          component.nameTranslation,
          this.configService.getCurrentLanguage(),
          component.loinc
        );
      } else {
        dataChart.label = this.obsDefService.getTranslatedString(component.name, component.loinc);
      }

      dataChart.data = values.get(component.loinc);
      chartData.push(dataChart);
    }

    const chartOptions =
      externalRessource.meta.onlineDevice === OnlineDevice.DEXCOM
        ? Tools.deepCopy(this.dexcomChartOptions)
        : Tools.deepCopy(this.basicChartOptions);
    const chartColors =
      externalRessource.meta.onlineDevice === OnlineDevice.DEXCOM
        ? Tools.deepCopy(StreamObservationService.dexcomColors)
        : Tools.deepCopy(ChartHelper.chartColors);

    if (externalRessource.meta.onlineDevice === OnlineDevice.DEXCOM) {
      chartOptions.onClick = dexcomClickFunction;
    }
    for (const comp of externalRessource.meta.componentAnswer) {
      if (comp.isHiddenLoinc) {
        continue;
      }
      chartData.push(...this.graphService.setupAdditionalGraphInfos(chartOptions, chartColors, comp.graphExtraInfos, 1440));
    }

    return { chartData, chartLabels, chartOptions, chartColors, streamObservations: latestDaysData };
  }
}

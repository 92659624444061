import { Pipe, PipeTransform } from "@angular/core";
import { IObservationDefinitionComponent } from "../helpers/observation-helper";

@Pipe({
  name: "componentDefinition",
})
export class ComponentDefinitionPipe implements PipeTransform {
  transform(loinc: string, componentDefinitions: IObservationDefinitionComponent[]): IObservationDefinitionComponent {
    return componentDefinitions.find((el) => el.loinc === loinc);
  }
}
